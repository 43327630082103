import { HttpClient } from "../http-service/api"

let  url= process.env.REACT_APP_API_URL

export const dashboardService = {
    approvedData,disputeData,invoiceData,pendingData,pendingcnData,approvedCNData,getVendorDisputeDocs,alertsData

};

function approvedData(userRefId:any,aspCategory:any,skip:any) {
    return HttpClient.post(`/api/vendor_v2/vendorAlertsReportData_v2`, { VendorId: userRefId, "aspCategory": aspCategory, "Inflag": "Y", "disflag": "N", "skipParam": skip})
        .then((response:any) => response)
        .then(user => {
            return user;
        });

}



function alertsData(userRefId:any,skip:any) {
    return HttpClient.post(`/api/vendor_v2/getDisputeDetails`, { "vendorId": userRefId, "skipParam": skip })
        .then((response:any) => response)
        .then(user => {
            return user;
        });
}




function disputeData(userRefId:any,aspCategory:any,skip:any) {
    return HttpClient.post(`/api/vendor_v2/vendorAlertsReportData_v2`, { "VendorId": userRefId,"aspCategory":aspCategory, "Inflag": "N", "disflag": "Y", "skipParam": skip })
        .then((response:any) => response)
        .then(user => {
            return user;
        });

}



function invoiceData(userRefId:any,skip:any) {
    return HttpClient.post(`/api/vendor_v2/getVendorInvoices_v2`, { "vendorId": userRefId, "skipParam": skip })
        .then((response:any) => response)
        .then(user => {
            console.log("Data",user)
            return user;
        });

}




function pendingData(userRefId:any,aspCategory:any,skip:any,vendorType:any) {
    return HttpClient.post(`/api/vendor_v2/vendorAlertsReportData_v2`, { "VendorId": userRefId,"aspCategory": aspCategory, Inflag: "N", disflag: "N", "skipParam": skip,"vendorType":vendorType })
        .then((response:any) => response)
        .then(user => {
            return user;
        });

}




function pendingcnData(userRefId:any) {
    return HttpClient.post(`/api/vendor_v2/cnAlertsData`, { "VendorId": userRefId, "cnFlag": false })
        .then((response:any) => response)
        .then(user => {
            return user;
        });

}




function approvedCNData(userRefId:any) {
    return HttpClient.post(`/api/vendor_v2/cnAlertsData`, { "VendorId": userRefId, cnFlag: true })
        // .then(handleResponse)
        .then((response:any )=> response)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}





function getVendorDisputeDocs(userRefId:any) {
       return HttpClient.post(`/api/vendor_v2/getVendorDisputeDocument`, { "documentId": userRefId})
        .then((user:any )=> {
            console.log("data",user.blob())
            return user;
        });
}






export const getVendorProfiles= (userRefId:any) =>{
    return HttpClient.post('/api/vendor_v2/getVendorProfile',{vendorId:userRefId})
    .then(user =>{
       
        return user
    })
}