import { Form, Button, Input, Layout, Row, Col, Card, message, Spin, Checkbox, Modal } from "../../../components/Elements";
import { useNavigate, Link } from 'react-router-dom'
// import { useContext, useEffect, useState } from "react";
import { login } from "../../../services/auth.services";
import { useContext, useEffect, useState, useRef } from "react";
import { UserContext, UserContextType } from "../../../providers/UserContext";
import { FORGOT_PASSWORD} from "../api";
import { ErrorMesages } from "../../../constants";
import storage from "../../../utils/storage";
import { API_URL, APP_URL } from "../../../config";
import { API_LIST } from "../../../http-service/list";



export const ForgotPassword = () => {

    const [loader, setLoader] = useState<boolean>(false);
    const [user, setUser] = useState('')


    const { Content } = Layout;

    const PassLayput = {
        display: "flex",
        justifyContent: "center",
        margin: "0px"
    }
    const CardBodyStyle = {
        minHeight: "300px",
       // marginLeft:" -121px",
        borderRadius: "8px",
        marginTop: "9%",
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = async (values: any) => {

        setLoader(true);
        let payload = {
            "userId" : user,
            // "path" : "https://localhost:81/api/vendor-reg_v2/forgotPassword",
            "path" :  APP_URL + 'reset-password',
        }
        try{
            let response: any = await FORGOT_PASSWORD(payload);
            if(response.status == "success"){
                setLoader(false);
                alert(response.message)
            }else{
                setLoader(false);
                alert(response.message)
            }
        }
        catch (e) {
            setLoader(false);
            message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE);
        }
        


    }


    return (
        <Layout>
            <Content>

                <Row gutter={24} style={PassLayput}>
                    <Col style={{ paddingTop: "10px" }} span={10}>
                    <Spin spinning={loader}>
                        <div style={{display:"flex" , alignContent:"center",justifyContent:"center"}}>
                            <Card bordered={true} className="widthControl" style={CardBodyStyle}    >
                                <div>
                                    <Form
                                        name="ezauto-login"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 24 }}
                                        layout="vertical"
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                    >
                                        <h2 style={{ textAlign: "center" }}>Forgot Password</h2>
                                        <Form.Item
                                            label="Username"
                                            name="username"
                                            rules={[{ required: true, message: 'Please input your username!' }]}
                                        >
                                            <Input onChange={e => setUser(e.target.value)} />
                                        </Form.Item>
                                      
                                    <Form.Item wrapperCol={{ offset: 0, span: 50 }} className="align-right">
                                            <Button className="ant-btn-login" type="primary" htmlType="submit">
                                               SUBMIT
                                            </Button>
                                            </Form.Item>   
                                    </Form>
                                </div>
                            </Card>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )

}
