import { Table, Spin, Checkbox, Card, Input, message, Space, Button, SearchOutlined, Row, Col, DatePicker, Form, DownloadOutlined } from "../../../../components/Elements"
import { useContext, useEffect, useState } from "react"
import { dashboardService } from "../../../../services/dashboard.services"
import { ServicePopup } from '../../../Common/Popup/servicePopup';
import { convertJsonToCsv, downloadFile, getCase, getVendorReportData } from "../../../../services/shared.service"
import Highlighter from "react-highlight-words";
import { InvoicePopup } from "../../../Common/Popup/InvoicePopup";
import { DisputePopup } from "../../../Common/Popup/DisputePopup";
import storage from "../../../../utils/storage";
import { CardStyle, CardStyle1 } from "../../../../helpers/fromhelpercss";
import moment from "moment";
import { UserContext, UserContextType } from "../../../../providers/UserContext";
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import { ResizeObserverFn } from '../../../../utils/resizeObserverUtil';


export const PendingComponent = () => {
  console.log("In Pending Component")
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [popupData, setPopupData] = useState()
  const [popUp, setPopUp] = useState(false)
  // const[invoicePop]
  const [pendingData, setPendingData] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
  const [checked, setChecked] = useState<any>([]);
  const [invPopup, setInvPopup] = useState(false)
  const [disPopup, setDisPopup] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [loader, setLoader] = useState(false)
  const [skipParams, setSkipParams] = useState(0)
  const { User, SaveUser } = useContext(UserContext) as UserContextType;
  const [disableInvoiceButton, setDisableInvoiceButton] = useState<boolean>(false);

  const [form] = Form.useForm();

  const contentRef = useRef<HTMLDivElement>(null); // Create a ref for the content element


  let arr: any = []
  let vendorId: any;
  vendorId = storage.getValue("vendorId")
  let aspCategory: any = "NormalAsp";
  let reg_type: any = storage.getValue("reg_type");
  let EAI_Employee: any = storage.getValue("EAI_Employee")
  if (reg_type == "D") {
    aspCategory = "ReverseAsp";
  } else if (EAI_Employee == true) {
    aspCategory = "EAIAsp";
  }
  let vendorFlag: any;
  vendorFlag = storage.getValue('paymentType')

  const [searchText, setSearchText] = useState<any>()
  const [state, setState] = useState({ searchText: '', searchedColumn: '' })

  // Create a new ResizeObserver
  const resizeObserver = ResizeObserverFn();
  // Start observing the target element
  useEffect(() => {
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    return () => {
      resizeObserver.disconnect(); // Clean up the observer on unmount
    };
  }, [contentRef]);


  const onFinish = async (values: any) => {
    let payload: any = preparePayload(values);


    try {
      if (payload.fromDate && payload.toDate) {
        setLoader(true);
        let res: any = await getVendorReportData(payload)
        if (res.status == 'success' && res.message.length > 0) {

          var data: any = convertJsonToCsv(res.message)
          downloadFile("Report", new Blob([data], { type: 'text/csv;charset=utf-8;' }));
          // storage.setValue('regisrationStatus', "kycSubmitted")
          /*  message.success("Submitted successfully")
           navigate('/kyc', { state: dataforKyc })
           */
          setLoader(false);
        } else if (res.status == 'success' && res.message.length == 0) {
          message.success("No Data Available in selected range")
          setLoader(false);
        }
        else {
          message.error('Please try again')
          setLoader(false);
        }
        return res;
      } else {
        message.error("Please select From and To date")
      }
    } catch (e) {
      console.log(e)
    }
  }


  const preparePayload = (values: any) => {
    let ObjectToUpdate = {
      "VendorId": vendorId,
      "fromDate": moment(new Date(values['from'])).format('YYYY-MM-DD'),
      "toDate": moment(new Date(values['to'])).format('YYYY-MM-DD'),
      "Inflag": "N",
      "disflag": "N",
      "userName": ""

    }
    return ObjectToUpdate

  }


  let searchInput: any;
  const getColumnSearchProps = (dataIndex: any) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }
      : { setSelectedKeys: any, selectedKeys: any, confirm: any, clearFilters: any }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Go
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText({})
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  };


  async function fetchData() {
    setLoading(true)
    var resp = await dashboardService.pendingData(vendorId, aspCategory, skipParams, vendorFlag)
    if (resp.status === "success") {
      setData(resp.message)
      setLoading(false)
      if (resp.blockInvoiceGen == true) {
        setDisableInvoiceButton(true);
      }
    } else {
      setLoading(false)
      alert("Please try again.")
    }

  }


  const antLayoutContent = {
    margin: "80px 15px 0px"
  }

  useEffect(() => {
    SaveUser({ ...User, token: "aaa" })

    fetchData()
  }, [skipParams])
  const columns: any = [
    {
      title: '',
      dataIndex: '',
      align: 'center',
      render: (params: any) => {
        const handleCheck = (event: any) => {

          var updatedList = [...checked];
          console.log("EVENT", event.target.checked)
          let arr: any
          if (checked == null || checked == "") {
            if (event.target.checked) {
              let resJson = {
                "claimNumber": params.claimId,
                "serviceNumber": params.serviceId,
                "totalAmount": params.Amount,
                "invoicePaymentType": params.TypeOfService,
                "EaiAmount": params.EaiAmount,
                "key": params.key,
                "serviceStatus": params.ServiceStatus
              };
              updatedList = [...checked, resJson];
            } else {
              let resJson = {
                "claimNumber": params.claimId,
                "serviceNumber": params.serviceId,
                "totalAmount": params.Amount,
                "invoicePaymentType": params.TypeOfService,
                "EaiAmount": params.EaiAmount,
                "key": params.key,
                "serviceStatus": params.ServiceStatus
              };
              updatedList.splice(checked.indexOf(resJson), 1);
            }
          } else {
            if (checked[0].invoicePaymentType == params.TypeOfService && checked[0].serviceStatus == params.ServiceStatus) {
              if (event.target.checked) {
                let resJson = {
                  "claimNumber": params.claimId,
                  "serviceNumber": params.serviceId,
                  "totalAmount": params.Amount,
                  "invoicePaymentType": params.TypeOfService,
                  "EaiAmount": params.EaiAmount,
                  "key": params.key,
                  "serviceStatus": params.ServiceStatus
                };
                updatedList = [...checked, resJson];
              } else {
                let resJson = {
                  "claimNumber": params.claimId,
                  "serviceNumber": params.serviceId,
                  "totalAmount": params.Amount,
                  "invoicePaymentType": params.TypeOfService,
                  "EaiAmount": params.EaiAmount,
                  "key": params.key,
                  "serviceStatus": params.ServiceStatus
                };
                updatedList.splice(checked.indexOf(resJson), 1);
              }
            } else {
              event.target.checked = false;
              alert("Please select same category type services")
            }

          }


          setChecked(updatedList);
        };

        return <Checkbox onClick={handleCheck} disabled={(params.ServiceType === 'RRP' && params.ServiceStatus === 'Service Failed' && params.blockInvoice == true)} />;
      }
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      align: 'center',
      ...getColumnSearchProps('Date')
    }, {
      title: 'Case ID',
      dataIndex: 'claimId',
      align: 'center',
      ...getColumnSearchProps('claimId')
    },
    {
      title: 'Service ID',
      dataIndex: 'serviceId',
      align: 'center',
      width: '120px',
      ...getColumnSearchProps('serviceId'),
      render: (text: string, params: any) => {
        async function onClick() {

          let resp: any = await getCase(params.serviceId)

          setPopupData(resp.message)
          setPopUp(true)

        }
        return <a style={{ color: "#0c3b93", textDecoration: 'underline' }} onClick={onClick}>{text}</a>;
      },
    },
    {
      title: 'Service Type',
      dataIndex: 'ServiceType',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('ServiceType')
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('Amount')
    },
    {
      title: 'Kms',
      dataIndex: 'TotalKms',
      align: 'center',
      ...getColumnSearchProps('TotalKms')
    },
    {
      title: 'Service Status',
      dataIndex: 'ServiceStatus',
      align: 'center',
      width: '120px',
      ...getColumnSearchProps('ServiceStatus')
    },
    {
      title: 'DRSA Amount',
      dataIndex: 'drsaAmount',
      align: 'center',
      width: '120px',
      ...getColumnSearchProps('drsaAmount')
    },
    {
      title: 'SLA Amount',
      dataIndex: 'slaAmount',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('slaAmount')
    },
    {
      title: 'CSAT Amount',
      dataIndex: 'csatAmount',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('csatAmount')
    },
    {
      title: 'Payment Type',
      dataIndex: 'TypeOfService',
      align: 'center',
      width: '120px',
      ...getColumnSearchProps('TypeOfService'),
      render: (TypeOfInvoice: any) => {
        let color = 'white';
        if (TypeOfInvoice === 'GC-PPC Normal' || TypeOfInvoice === 'GC-PPC Exceed') {
          color = 'rgba(86, 241, 86, 0.5)';
        } else if (TypeOfInvoice === 'Normal' || TypeOfInvoice === 'Normal1' || TypeOfInvoice === 'Normal2') {
          color = 'rgba(248, 141, 2, 0.993)';
        } else if (TypeOfInvoice === 'Dispute') {
          color = 'rgba(241, 86, 86, 0.5)';
        }
        return {
          props: {
            style: { background: color }
          },
          children: <div >{TypeOfInvoice}</div>
        };
      }
    }, {
      title: 'Pro_forma Invoice generated',
      dataIndex: 'isProformaInvoiceGenerated',
      align: 'center',
      width: 150,
      ...getColumnSearchProps('isProformaInvoiceGenerated')
    },
    {
      title: 'Border Charges By Agent',
      dataIndex: 'borderChragesByAgent',
      align: 'center',
      width: 150,
      ...getColumnSearchProps('borderChragesByAgent')
    }
  ];


  const rows: { key: number }[] = [];
  data.map((i: any, j: any) => {
    const obj = i
    obj.key = i.serviceId
    rows.push(obj)
    return rows
  })

  const sendDataToParent = (index: any) => {
    setPopUp(index)
  }
  const sendDataToInvoice = (index: any) => {
    setInvPopup(index)
    // setChecked([])
  }

  const sendDataToDispute = (index: any) => {
    setDisPopup(index)
    // setChecked([])
  }


  const invoiceButton = () => {
    if (checked.length == 0) {
      alert("Please select atleast one service")
    } else {
      setInvPopup(true)
    }

  }

  const disputeButton = () => {
    if (checked.length > 1) {
      alert("Please Select Only One Service For Dispute")
    } else if (checked.length == 0) {
      alert("Please select atleast one service")
    }
    if (checked.length == 1) {
      setDisPopup(true)
    }

  }

  const sendToInvoicePopup = (index: any, refresh: any) => {
    setInvPopup(index)
    setRefresh(true)
    if (refresh == "refresh") {
      fetchData()
      setChecked([])
    } else if (refresh == "loading") {
      setLoading(true)
    }
  }


  const sendToDisputePopup = (index: any, refresh: any) => {
    // setInvPopup(index)
    // setRefresh(true)
    if (refresh == "refresh") {
      fetchData()
      setChecked([])
    } else if (refresh == "loading") {
      setLoading(true)
    }
  }
  const Onnext = () => {
    let data: any = skipParams + 100
    setSkipParams(data)
  }
  const Onback = () => {
    if (skipParams != 0) {
      let data: any = skipParams - 100
      setSkipParams(data)
    }

  }
  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  const navigate = useNavigate();
  if (!User || !User.isLoggedIn) {
    navigate('/login');
    return null;
  } else {

    return (
      loading ?
        <div style={antLayoutContent} ref={contentRef}>
          <div className="example" style={{ textAlign: 'center' }}>
            <Card className="ant-content m-b-20">
              <Spin />
            </Card>
          </div>
        </div>
        :
        <div style={antLayoutContent}>

          <Card className="ant-content">






            <div>
              <Form
                className="registration-details-form upload-documents-form"
                // initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}

                labelAlign={"left"}
                autoComplete="off"
              >
                <div className="pendingForm">
                  <div className="PendingGo">
                    <Form.Item label="From:" name="from" className="PendingDate" >
                      <DatePicker disabledDate={disabledDate} format={'DD-MM-YYYY'} style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item label="To :" name="to">
                      <DatePicker disabledDate={disabledDate} format={'DD-MM-YYYY'} style={{ width: '100%' }} />
                    </Form.Item>

                    <div style={{ display: "flex", alignContent: "center", }}>
                      <Form.Item >
                        <Button htmlType="submit" className="downloadBtn downloadBt"><DownloadOutlined /></Button>
                      </Form.Item >

                      <div style={{ width: "80px", padding: "10px" }}>
                        <Spin spinning={loader}>
                        </Spin>
                      </div>
                    </div>

                  </div>
                  <div className="PendingBtnIn">
                    <Button className="ant-btn-generate" onClick={invoiceButton} disabled={disableInvoiceButton}>Invoices</Button>
                    <Button className="ant-btn-generate" onClick={disputeButton}>Dispute</Button>
                  </div>
                </div>
              </Form>
            </div>





            {/* <div>
            <Col className="m-b-30  " span={4}>

            </Col>
          </div> */}
            <div className='table-responsive'>
              <Table
                columns={columns}
                dataSource={rows}
                bordered
                title={() => 'Pending Table'}
                sticky={true}
                scroll={{ x: "max-content" }}
              />
            </div>
            <Row style={{ width: "100%" }}>
              <Col span={19}></Col>
              <Col className='m-b-30 col-pad-0 align-right' span={5} style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                <Button className='PrevBtn' onClick={Onback}>Prev</Button>
                <Button className='NextBtn' onClick={Onnext}>Next</Button>
              </Col>
            </Row>

          </Card>
          <div className="ButtomMargin">

          </div>
          <div>
            {popUp && <ServicePopup display={true} sendDataToParent={sendDataToParent} data={popupData} />}
          </div>
          <div>
            {invPopup && <InvoicePopup display={true} sendToInvoicePopup={sendToInvoicePopup} sendDataToInvoice={sendDataToInvoice} data={checked} />}
          </div>
          {
            disPopup && <DisputePopup display={true} sendToDisputePopup={sendToDisputePopup} sendDataToDispute={sendDataToDispute} data={checked} />
          }

        </div>
    )
  }
}