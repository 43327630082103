import { Button, Modal, Spin } from "../../../components/Elements"
import { useState, useEffect } from 'react';
import { KycPopUp } from "./kycPopup";
import { getVendorProfiles } from "../../../services/dashboard.services";
import storage from "../../../utils/storage";
import { FALSE } from "node-sass";



export const RegulationsPopup = (props: any) => {
    const [visible, setVisible] = useState(false)
    const [kycpop, setKycpop] = useState(false)
    const [data, setData] = useState()
    const [loader, setLoader] = useState(false)
    const [buttonDisable, setButtonDisable] = useState(false)
    let vendorId: any;
    vendorId = storage.getValue("vendorId")

    useEffect(() => {
        setVisible(true)
    }, [])
    useEffect(() => {


        if (visible == true) {
            setLoader(true)
            const fetchData = async () => {
                var resp: any = await getVendorProfiles(vendorId)
                storage.setValue('phoneNo', resp.message.auth_signatory.mobile_number)
                setLoader(false)
                setData(resp.message)
                setButtonDisable(true)



            }
            fetchData()
        }



    }, [visible])
    const onClick = () => {
        setVisible(false)
        props.sendDataToRegulation(false)
    }
    const onGenerate = () => {
        setKycpop(true)
    }
    const sendDataToKyc = (index: any) => {
        setKycpop(index)
        props.sendDataToRegulation(index)
    }
    return (
        <div>

            <Modal
                title="Click Ok to update KYC details"
                centered
                visible={visible}
                onCancel={onClick}
                // // width={1000}

                footer={[
                    <Button className='ant-btn-view' key="submit" type="primary" onClick={onGenerate} disabled={!buttonDisable}>
                        Yes
                    </Button>,
                    <Button className='ant-btn-prev' key="back" onClick={onClick} >
                        Cancel
                    </Button>,
                ]}

            >
                <Spin spinning={loader}>
                    <h4>Note:</h4>
                    <h5>As per recent RBI circular the IFSC code and bank account details had been changed for certain banks.</h5>
                    <h5>Kindly update your correct Bank account details along with supporting personalised cancel cheque leaf or a passbook copy.</h5>

                </Spin>
            </Modal >



            {kycpop && <KycPopUp sendDataToKyc={sendDataToKyc} data={data} page={"regulations"} />}



        </div>

    )
}