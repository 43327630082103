import Axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from './../config/';
import storage from '../utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
    const token = storage.getToken();
    config.headers = {};
    if(token){
        config.headers.Authorization = token;
    }
  //  config.headers.application = 'application/json';

    return config;
}

export const axios = Axios.create({
    baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if(error.response && error.response.status === 502){
            alert("Network Error, Please try again later")
        }
        return Promise.reject(error.message);
    }
)

setInterval(async () => {
    try {
        const response = await axios.post('https://eza2.softforceapps.com:8089 /api/login/refreshToken'); // Replace with your API endpoint
        console.log('Token refreshed:', response);
        storage.setToken((response as any).ezToken); // Replace 'token' with the actual property name in the response
    } catch (error) {
        console.error('Failed to refresh token:', error);
    }
}, 25 * 60 * 1000);

