var CardStyle = {
    borderRadius: "8px",
    backgroundColor: "#f0f2f5"
};

var CardBodyStyle = {
    padding: "10px"
}
var hintStyle = {
    fontSize: "12px",
    marginBottom: "10px",
    fontWeight: 500,
    color: "#0c3b93"
}

export { CardStyle, CardBodyStyle, hintStyle }
