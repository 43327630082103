import { Button, Card, CustomHeader, Form, Input, message, Row, Spin } from "../../../components/Elements"
import { useState, useEffect } from 'react';
import storage from '../../../utils/storage';
import { changePassword } from "../../../services/auth.services";
import { useNavigate } from 'react-router-dom';
import { UserContext, } from "../../../providers/UserContext";


export const ChangePassword = () => {
    const [oldPwd, setOldPwd] = useState()
    const [newPwd, setNewPwd] = useState()
    const [confirmPwd, setConfirmPwd] = useState()
    const [loader, setLoader] = useState(false)


    let username: any;
    username = storage.getValue('userName')
    console.log("PASSWORD", newPwd)
    useEffect(() => {
        storage.setValue('token', "changePassword")
    }, [])



    let navigate = useNavigate()
    const onFinish = async () => {
        if (newPwd === confirmPwd) {
            let payload: any = {
                "userName": username,
                "cPass": oldPwd,
                "nPass": newPwd
            }
            setLoader(true)
            var resp: any = await changePassword(payload)
            setLoader(false)
            if (resp.status == "success") {
                message.success("Password changed Successfully")
                navigate('/login')
            } else {
                message.error("Please try again")
            }
        } else {
            message.error("Confirm password must match with new password.")
        }
    }
    return (
        <Card >
            <Spin spinning={loader}>
                < CustomHeader title={"Change Password"} />
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                >
                    <Form.Item label='Current Password' name='old_password' rules={[{ required: true }]}>
                        <Input type={'password'} onChange={(e: any) => { setOldPwd(e.target.value) }} />
                    </Form.Item>
                    <Form.Item label='New Password' validateFirst name='new_password' rules={[{ required: true }, { pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, message: "Please enter valid password" }]}>
                        <Input type={'password'} onChange={(e: any) => setNewPwd(e.target.value)} />
                    </Form.Item>
                    <Form.Item label='Confirn Password' name='confirn_password' rules={[{ required: true }]}>
                        <Input type={'password'} onChange={(e: any) => { setConfirmPwd(e.target.value) }} />
                    </Form.Item>
                    <Row align="middle" justify="end" >
                        <Form.Item >
                            <Button htmlType="submit" className="ant-btn-login ant-btn-login" type="primary">Change</Button>
                        </Form.Item>
                    </Row>

                    <Row>
                        <h4 style={{ color: 'red' }}>Note:</h4>
                        <br />- Minimum 8 characters length
                        <br />- Must have upper and lower case characters
                        <br />- Use at least one number
                        <br />- Special characters are optional
                    </Row>

                </Form>
            </Spin>
        </Card>
    )
}