import { HttpClient } from "../http-service/api"

export const login= (userName:any,password:any)  => {
    return HttpClient.post('/api/login/login',{"userName": userName,"password":password,"channel":"PLS","networkDetails":[]})
    .then(response => response)
    .then((user:any) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('user', JSON.stringify(user));
        let VendorData:any = user.userrefId
        console.log(typeof(user),"user in shared ",VendorData)
        localStorage.setItem("VendorData",VendorData)
        return user;
    });

}


export const changePassword= (payload:any)  => {
    return HttpClient.post('/api/common/changePassword',payload)
    .then(response => response)
    .then((user:any) => {
        return user;
    });

}