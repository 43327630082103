var CardStyle = {
    borderRadius: "20px",
    backgroundColor: "#f0f2f5",
    minHeight: 550,
    maxHeight: 600,
    margin: '12px',
    //overflow: "scroll",
    // overflowY: "scroll"


};
var CardStyle1 = {
    borderRadius: "20px",
    backgroundColor: "#f0f2f5",
    margin: '12px',
};
var CardStyle2 = {
    borderRadius: "20px",
    backgroundColor: "#f0f2f5",
    margin: '12px',
    minHeight: 300,
    maxHeight: 300,


};

var CardBodyStyle = {
    padding: "10px"
}
var hintStyle = {
    fontSize: "12px",
    marginBottom: "10px",
    fontWeight: 500,
    color: "#0c3b93"
}

export { CardStyle, CardBodyStyle, hintStyle, CardStyle1, CardStyle2 }

// { { minHeight: 550, maxHeight: 550, borderRadius: "20px", margin: '12px', overflow: 'hidden', overflowY: 'scroll' } }
