import { HttpClient } from '../../../http-service/api';
import { API_LIST } from '../../../http-service/list';

export const LOGIN = (payload: any) => {
    return HttpClient.post(API_LIST.LOGIN, payload)
}

export const GET_VENDOR_REG_STATUS = (payload: any) => {
    return HttpClient.post(API_LIST.CHECK_VENDOR_REG_STATUS, payload)
}

export const GET_VENDOR_DOCUMENT = (payload: any) => {
    return HttpClient.post(API_LIST.GET_VENDOR_DOC, payload)
}


export const VERIFY_OTP = (payload: any) => {
    return HttpClient.post(API_LIST.VERIFY_OTP, payload)
}

export const GETDATA = (payload: any) => {
    return HttpClient.post(API_LIST.GETDATA, payload)
}

export const UPDATEDATA = (payload: any) => {
    return HttpClient.post(API_LIST.UPDATEDATA, payload)
}

export const FORGOT_PASSWORD = (payload: any) => {
    return HttpClient.post(API_LIST.FORGOT_PASSWORD, payload)
}





