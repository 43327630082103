import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message, Spin, Steps } from "../../../components/Elements/Designs";
import { ErrorMesages } from "../../../constants";
import { UserContext, UserContextType } from "../../../providers/UserContext";
import { CREATE_VENDOR, SUBMIT_VENDOR } from "../api";
import { CreateComponent } from "../helpers/DynamicComponent";
import { CVRosServiceList, CVTowingServiceList, RosServiceList, ServicesObject, ServicesObject2, TowingServiceList, parseDataToPreFillSteps, prepareCreateVendorPayload } from "../helpers/formhelper";
import "./index.scss";

export const Layout = (props: any) => {

    const { User, SaveUser } = useContext(UserContext) as UserContextType;
    const { Step } = Steps;
    const navigate = useNavigate();
    const RegistrationFormData: any = {
        PanVerification: {},
        PersonalDetails: {},
        Locations: {},
        OtherDetails: {},
        UploadDocuments: {},
        ServicesList: [],
        isNewRecord: true,
        vendorId: {},
        UserDetails: {}
    }
    const RegistrationSteps = [
        {
            title: 'Pan Verification',
            component: 'PanVerification',
            index: 0,
        },
        {
            title: 'Personal Details',
            component: 'PersonalDetails',
            index: 1,
        },
        {
            title: 'Locations',
            component: 'Locations',
            index: 2,
        },
        {
            title: 'Other Details',
            component: 'OtherDetails',
            index: 3,
        },
        {
            title: 'Upload Documents',
            component: 'UploadDocuments',
            index: 4,
        },
    ];


    useEffect(() => {
        if (User && User.details.registrationDetails) {
            let data = parseDataToPreFillSteps(User.details.registrationDetails);
            let regisrationStatus = User.details.regisrationStatus;
            let step = RegistrationSteps.find((ele) => ele.title == regisrationStatus);
            if (step) {
                setCurrent(step.index + 1);
            }
            setFormData({ ...data, isNewRecord: false, });
        }
        // console.log(User);
    }, [User]);

    useEffect(() => {
        if (props && props.isNewUser) {
            setFormData(RegistrationFormData)
        }
    }, [props]);


    const [current, setCurrent] = useState(0);
    const [FormData, setFormData] = useState(RegistrationFormData);
    const [loader, setLoader] = useState<boolean>(false);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const handleSubmit = async (params: any) => {
        let postDetails = params;
        let body: any;
        let formD: any;
        switch (postDetails.type) {
            case 'PanVerification':
                let panV: any = postDetails.data;
                panV['validated'] = true;
                setFormData({ ...FormData, PanVerification: panV });
                setCurrent(current + 1);
                break;
            case 'PersonalDetails':

                let perV: any = postDetails.data;
                perV['validated'] = true;
                setFormData({ ...FormData, PersonalDetails: perV });
                formD = { ...FormData, PersonalDetails: perV };
                formD['step'] = 'Personal Details';
                formD['formuser'] = FormData.UserDetails;
                body = prepareCreateVendorPayload(formD);
                try {
                    setLoader(true);
                    let createResponse: any = await CREATE_VENDOR(body);
                    setFormData({ ...FormData, PersonalDetails: perV, vendorId: createResponse.message[0]._id, UserDetails: createResponse.message[0] });
                    setLoader(false);
                    setCurrent(current + 1);
                } catch (e) {
                    setLoader(false);
                    message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE)
                }

                break;
            case 'Locations':
                let locationsObj = postDetails.data;
                console.log("SER",postDetails.services)
                let updatedServicesList: any = [];
                if (locationsObj && locationsObj.locations && locationsObj.locations.length) {
                    let locatinskeys = Object.keys(locationsObj.locations[0]);
                    if (locatinskeys && locatinskeys.length) {
                        locationsObj['validated'] = true;
                        if(FormData['ServicesList'].length == 0 ){
                            updatedServicesList = []
                        }else{
                            updatedServicesList = [FormData['ServicesList'].length];

                        }

                        for (let index = 0; index < FormData['ServicesList'].length; index++) {

                            updatedServicesList[index] = FormData['ServicesList'][index];
                            if (postDetails['services'][index]) {
                                let serviceObj = postDetails['services'][index];
                                console.log("DATA", serviceObj)
                                for (let key of Object.keys(serviceObj)) {
                                    updatedServicesList[index][key] = serviceObj[key];
                                }
                            }
                        }
                        for (let index = 0; index < postDetails['services'].length; index++) {
                            if (index >= FormData['ServicesList'].length) {
                                updatedServicesList.push(postDetails['services'][index]);
                            }
                        }
                        // newServices = postDetails['services'].slice(FormData['ServicesList'].length);

                        formD = {
                            ...FormData,
                            Locations: locationsObj,
                            services: updatedServicesList
                        };

                    } else {
                        formD = { ...FormData };
                        formD['services'] = FormData['ServicesList'];
                    }
                }

                formD['step'] = 'Locations';
                formD['user'] = User.details.registrationDetails;
                formD['formuser'] = FormData.UserDetails;
                body = prepareCreateVendorPayload(formD);
                try {
                    setLoader(true);
                    let createResponse: any = await CREATE_VENDOR(body);
                    // if(updatedServicesList.length>0){
                    setFormData({
                        ...FormData,
                        Locations: locationsObj,
                        ServicesList: [...updatedServicesList],
                        vendorId: createResponse.message[0]._id,
                        UserDetails: createResponse.message[0]
                    });
                    // }else{
                    //     setFormData({ ...FormData, Locations: locationsObj, vendorId: createResponse.message[0]._id, UserDetails: createResponse.message[0] });

                    // }
                    setLoader(false);
                    setCurrent(current + 1);
                } catch (e) {
                    setLoader(false);
                    message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE)
                }
                break;
            case 'OtherDetails':
                setFormData({ ...FormData, OtherDetails: postDetails.data });

                formD = { ...FormData, OtherDetails: postDetails.data };
                formD['step'] = 'Other Details';
                formD['services'] = FormData.ServicesList;
                formD['user'] = User.details.registrationDetails;
                formD['formuser'] = FormData.UserDetails;
                body = prepareCreateVendorPayload(formD);

                try {
                    setLoader(true);
                    let createResponse: any = await CREATE_VENDOR(body);
                    setFormData({ ...FormData, OtherDetails: postDetails.data, vendorId: createResponse.message[0]._id, UserDetails: createResponse.message[0] });
                    setLoader(false);
                    setCurrent(current + 1);
                } catch (e) {
                    setLoader(false);
                    message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE)
                }
                break;
            case 'UploadDocuments':
                setFormData({ ...FormData, UploadDocuments: postDetails.data });
                body = { "vendorId": FormData.vendorId };
                try {
                    setLoader(true);
                    let createResponse: any = await SUBMIT_VENDOR(body);
                    setLoader(false);
                    if (createResponse.status == 'failure') {
                        message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE);
                    } else {
                        setFormData({ ...FormData, ...RegistrationFormData });
                        message.success(createResponse.message);
                        navigate('/login');
                    }

                } catch (e) {
                    setLoader(false);
                    message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE)
                }

                break
        }
    }

    const NavigatePrevious = (props: any) => {
        if (props && props.action_type === "previousStep" && props.type) {
            switch (props.type) {
                case 'PersonalDetails':
                    let perV: any = props.data;
                    perV['validated'] = false;
                    perV['validations'] = props['validations'];
                    setFormData({ ...FormData, PersonalDetails: perV });
                    break;
            }
        }

        setCurrent(current - 1);
    }
    const deleteService = (index: number, key: string, type: any, removeType: any) => {
        let services = [...FormData.ServicesList];
        let locations: any = { ...FormData.Locations }
        console.log("#####", index, "&&&&&", key, "KEYEEEE", type)
        console.log("services", services)
        if (removeType === "obj") {
            if(services.length !== 0){
                    console.log("inside")
                    delete services[index][key][type];


            }

        } else if (removeType === "row") {
        

            services.splice(index, 1);
            locations.locations.splice(index, 1)
        }
        if(services.length !== 0){

        setFormData({ ...FormData, ServicesList: services, Locations: locations });
        }
    }


    const ComponentParams = {
        handleSubmit,
        NavigatePrevious,
        RegistrationFormData: FormData,
        DeleteService: deleteService
    }

    const antLayoutContent = {
        margin: "80px 15px 0px"
    }
    return (
        <div style={antLayoutContent}>

            <Steps current={current}>
                {
                    RegistrationSteps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))
                }
            </Steps>
            <div className="steps-content">
                <Spin spinning={loader}>
                    {CreateComponent(RegistrationSteps[current].component, ComponentParams)}
                </Spin>

            </div>
            <div className="steps-action">
                {/* {
                    current < RegistrationSteps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Next
                        </Button>
                    )
                }
                {
                    current === RegistrationSteps.length - 1 && (
                        <Button type="primary" onClick={() => message.success('Processing complete!')}>
                            Done
                        </Button>
                    )
                }
                {
                    current > 0 && (
                        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                            Previous
                        </Button>
                    )
                } */}
            </div>
        </div>
    );

}