import { BrowserRouter, Route, Routes,  useLocation ,Outlet  } from 'react-router-dom';
import { HomeRoutes } from '../modules/Home/route';
import { EZLayout } from '../modules/layout';
import { Routes as Auth } from '../modules/Login/route';
import { RegisterRoutes } from '../modules/Register/route';
import UserProvider from '../providers/UserContext';
import { KycComponent } from '../modules/Home/components/kycComponent/kycComponent';
import storage from '../utils/storage';
import { Routes as SsoAuth } from '../modules/SSO Auth/route';
import { ChangePassword } from '../modules/Change Password/components';
import { ForgotPassword } from '../modules/Login/Password/ForgotPassword';
import { Login } from '../modules/Login/route/Login';

export const ProtectedRoutes = () => {
    return (
        <UserProvider>
            <BrowserRouter>
                <EZLayout>
                    <Routes>
                        <Route path="/" element={<Auth />} />
                        <Route path="/login" element={<Auth />} /> 
                        <Route path="/home/*" element={<HomeRoutes />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/kyc" element={<KycComponent />} />
                        <Route path="/vendor-register" element={<RegisterRoutes />} />
                        <Route path="*" element={<Auth />} />
                        <Route path = "/ssoauthentication" element = {<SsoAuth />} />
                    </Routes>
                </EZLayout>
            </BrowserRouter>
        </UserProvider>

    )
}



