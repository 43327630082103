export const CustomHeader = (props: any) => {

    const HeaderStyle = {
        background: "#1995ad",
        borderLeft: "5px solid #056476",
        padding: "5px",
        fontWeight: 500,
        color: "#fff",
        fontSize: "16px",
    
    }

    return (
        <>
            <div className="customer-header" style={HeaderStyle}>
                <span className="cus-header-title">
                    {
                        props.title
                    }
                </span>

            </div>
        </>
    )
}