import { useContext, useState } from "react";
// import './sideNav.scss'
import { useNavigate } from "react-router-dom";
import { Col, Layout, Menu, Row } from "../../../components/Elements";
import { UserContext, UserContextType } from "../../../providers/UserContext";


export const SideNav = () => {
    const { Header, Content, Sider } = Layout;
    const navigate = useNavigate();
    const { User, SaveUser } = useContext(UserContext) as UserContextType;
    const handleMenu = (element: any) => {
        navigate(element.key);
    }

    const [collapsed, setCollapsed] = useState(false);




    return (
        <Sider             
        breakpoint={"lg"}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      
        collapsedWidth={0}
        width={150}
       
        style={{ minHeight: "100%", background: "#FFFFFF",  
        
       
       
     
        
        left: 0}} className="side-nav-container" >
            {/* <Menu theme="light" mode="inline" onClick={handleMenu} className="side-nav-menu" defaultSelectedKeys={['/home/profile']} selectedKeys={[window.location.pathname]} > */}
            <Menu theme="light" mode="inline" onClick={handleMenu} className="side-nav-menu" defaultSelectedKeys={['/home/aspDashboard']} selectedKeys={[window.location.pathname]} >
                {/* { */}
                {/* User && User.details && User.details.regisrationStatus == 'success' && */}
                    <>
                    {/* <Menu.Item key="/home" style={{ textAlign: 'center' }}>
                            Home
                        </Menu.Item> */}
                        <Menu.Item key="/home/aspDashboard" style={{ textAlign: 'center' }}>
                            Dashboard
                        </Menu.Item>
                        <Menu.Item key="/home/alerts" style={{ textAlign: 'center' }}>
                            Alerts
                        </Menu.Item>
                        <Menu.Item key="/home/profile" style={{ textAlign: 'center' }}>
                            Profile
                        </Menu.Item>
                        <Menu.Item key="/home/approved" style={{ textAlign: 'center' }}>
                            Approved
                        </Menu.Item>
                        <Menu.Item key="/home/pending" style={{ textAlign: 'center' }}>
                            Pending
                        </Menu.Item>
                        <Menu.Item key="/home/disputes" style={{ textAlign: 'center' }}>
                            Disputes
                        </Menu.Item>
                        <Menu.Item key="/home/invoices" style={{ textAlign: 'center' }}>
                            Invoices
                        </Menu.Item>
                        <Menu.Item key="/home/pendingcn" style={{ textAlign: 'center' }}>
                            Pending CN
                        </Menu.Item>
                        <Menu.Item key="/home/approvedcn" style={{ textAlign: 'center' }}>
                            Approved CN
                        </Menu.Item>
                        

                    </>
                {/* // } */}
                {
                    User && User.details && User.details.regisrationStatus == 'pending' &&
                    < Menu.Item key="/home/pending-registration">
                        Registration
                    </Menu.Item>
                }
            </Menu>
        </Sider >
    )
}
