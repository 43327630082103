import { SSO_ENABLE, SSO_URL } from "../../../config"
import { LoginLayout } from "../component"
import { Login } from "./Login"

export const Routes = () => {
    if (SSO_ENABLE === "true") {
        let url : string = SSO_URL
        window.location.href = url;
        return null
    }else{
        return <LoginLayout />
    }
  
}

