import { useLocation } from 'react-router-dom';
import { Card, Col, Row } from '../../../../components/Elements'
import { CardStyle, CardStyle1, CardStyle2 } from '../../../../helpers/fromhelpercss';
import { useEffect, useState } from 'react';
import { RegulationsPopup } from '../../../Common/Popup/RegulationsPopup';
import { getVendorProfiles } from '../../../../services/dashboard.services';
import storage from '../../../../utils/storage';


export const KycComponent = (props: any) => {
    let { state } = useLocation();
    const [messageValue, setMessageValue] = useState<any>()
    const [popUp, setPopUp] = useState(false)
    const [message, setMessage] = useState(false)

    let details: any = state
    useEffect(() => {
        storage.setValue('token', "changePassword")
    }, [])
    console.log(details, "DATA PROPS", props)
    useEffect(() => {
        if (details.message === "Submit") {
            setMessageValue("Your registration is under process")
        } else if (details.message === "Active" && details.kyc_flag === "Submit") {
            setMessage(false)
            setMessageValue("Your kyc request has been submitted.")
        } else if (details.message === "DeActive" && details.kyc_flag === "inActive") {
            setMessageValue("Your registration is under process.")
        } else if (details.message === "Active" && details.kyc_flag === "RMApproved") {
            setMessageValue("Your registration is under process.")
        }
        else if (details.message === "Active" && (details.kyc_flag === "inActive" || details.kyc_flag === "InActive") ) {
            setPopUp(true)
            setMessage(true)
        } else if ((details.message === "DeActive" || details.message === "Deactive") && (details.kyc_flag === "Active" || details.kyc_flag === "InActive" || details.kyc_flag === "Inactive")) {
            setMessageValue("The vendor has been deactivated.Please contact respective RM.")
        }
        else if(details.kyc_flag.toUpperCase()  === "RMREJECT" || details.kyc_flag.toUpperCase() === "INACTIVE"){
            setMessageValue("Please update kyc details to generate invoice/dispute")
            setPopUp(true)
            setMessage(true)

        }else {
            setMessageValue("Your registration is under process")

        }
    }, [details])
    const sendDataToRegulation = (index: any) => {
        setPopUp(index)
    }
    console.log("POP UP", popUp)
    const onClick = () => {
        console.log("inside on click")
        setPopUp(true)
    }

    const antLayoutContent = {
        margin : "80px 15px 0px"
    }




    return (

        <div style = {antLayoutContent}>
        <Row gutter={24}>
                <Col span={12}>
                    <Card style={CardStyle1} >

                        <h4>Vendor Name  &nbsp;: &nbsp;{details.vendorName}</h4>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card style={CardStyle1}>
                        <h4>Status &nbsp;: &nbsp;{details.message}</h4>
                    </Card>
                </Col>
            </Row>
            <Card style={CardStyle1}>
                <p>For Payment & billing related query contact Helpline no - 022 69635301, operational Monday to Friday, 9.30am to 6.30pm</p>
                <p>For Ongoing case related query contact Toll free no - 1800 209 7373, available 24 x 7</p>
            </Card>
            {
                message === true ? <Card style={CardStyle2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}> <h4 >Please update &nbsp;</h4><h4 style={{ color: 'green', textDecoration: 'underline', cursor: 'pointer' }} onClick={onClick}>kyc details </h4><h4> &nbsp;to generate invoice/dispute</h4>
                    </div>
                </Card> : <Card style={CardStyle2}>
                    <h4 style={{ color: 'green' }}>{messageValue}</h4>
                </Card>
            }

            {
                popUp && <RegulationsPopup sendDataToRegulation={sendDataToRegulation} />
            }


        </div>


    )
}