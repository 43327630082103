import { Table, Spin, Card, Input, Space, Button, Row, Col, SearchOutlined } from '../../../../components/Elements';
import { useContext, useEffect, useState } from 'react';
import { dashboardService } from '../../../../services/dashboard.services';
import { getCase } from "../../../../services/shared.service"
import { ServicePopup } from '../../../Common/Popup/servicePopup';
import Highlighter from "react-highlight-words";
import storage from '../../../../utils/storage';
import { CardStyle } from "../../../../helpers/fromhelpercss";
import { UserContext, UserContextType } from '../../../../providers/UserContext';
import { useNavigate } from 'react-router-dom';

import { useRef } from 'react';
import { ResizeObserverFn } from '../../../../utils/resizeObserverUtil';



export const ApprovedComponent = (props: any) => {
  // console.log("In Approved Component")
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [popupData, setPopupData] = useState()
  const [popUp, setPopUp] = useState(false)
  const [skipParams, setSkipParams] = useState(0)
  const { User, SaveUser } = useContext(UserContext) as UserContextType;

  const contentRef = useRef<HTMLDivElement>(null); // Create a ref for the content element

  let vendorId: any;
  vendorId = storage.getValue("vendorId")
  let aspCategory: any = "NormalAsp";
  let reg_type: any = storage.getValue("reg_type");
  let EAI_Employee: any = storage.getValue("EAI_Employee")
  if (reg_type == "D") {
    aspCategory = "ReverseAsp";
  } else if (EAI_Employee == true) {
    aspCategory = "EAIAsp";
  }
  const [searchText, setSearchText] = useState<any>()
  const [state, setState] = useState({ searchText: '', searchedColumn: '' })
  // Create a new ResizeObserver
  const resizeObserver = ResizeObserverFn();
  // Start observing the target element
  useEffect(() => {
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    return () => {
      resizeObserver.disconnect(); // Clean up the observer on unmount
    };
  }, [contentRef]);


  let searchInput: any;
  const getColumnSearchProps = (dataIndex: any) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }
      : { setSelectedKeys: any, selectedKeys: any, confirm: any, clearFilters: any }) => (<div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Go
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText({})
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  };

  useEffect(() => {
    SaveUser({ ...User, token: "aaa" })
    async function fetchData() {
      setLoading(true)
      var resp = await dashboardService.approvedData(vendorId, aspCategory, skipParams)
      console.log("data", resp)
      setData(resp.message)
      setLoading(false)

    }
    fetchData()


  }, [skipParams])

  const antLayoutContent = {
    margin: "80px 15px 0px"
  }

  const columns: any = [
    {
      title: 'Payment Date',
      dataIndex: 'PaymentDate',
      align: 'center',
      width: "120px",
      ...getColumnSearchProps('PaymentDate')
    }, {
      title: 'Case ID',
      dataIndex: 'claimId',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('claimId')
    },
    {
      title: 'Service ID',
      className: 'serviceId',
      dataIndex: 'serviceId',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('serviceId'),
      render: (text: string, params: any) => {
        async function onClick() {
          //  console.log("PARAMS",params)
          // var resp = await getCaseService.getCaseData(params.caseId)
          // // console.log("params", params, typeof (params))
          // console.log("SERVICE", resp.message)
          // setPopupData(params)
          // setPopUp(true)

          // var popData = JSON.stringify(params.row)
          // return alert(JSON.stringify(data))
          let resp: any = await getCase(params.serviceId)
          // console.log("ALERY RESP",resp.message)
          setPopupData(resp.message)
          setPopUp(true)
          // alert(popupData)
          // console.log("POPUP",popUp)
          // console.log("popupdtata",popupData)
        }
        return <a className='fnt-weight' style={{ color: "#0c3b93", textDecoration: 'underline' }} onClick={onClick}>{text}</a>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('Amount')
    },
    {
      title: 'GST',
      dataIndex: 'GST',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('GST')
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'InvoiceAmount',
      align: 'center',
      width: "150px",
      ...getColumnSearchProps('InvoiceAmount')
    },
    {
      title: 'Paid Amount',
      dataIndex: 'PaidAmount',
      align: 'center',
      width: "130px",
      ...getColumnSearchProps('PaidAmount')
    },
    {
      title: 'TDS Amount',
      dataIndex: 'TDSAmount',
      align: 'center',
      width: "150px",
      ...getColumnSearchProps('TDSAmount')
    },
    {
      title: 'Payment Status',
      dataIndex: 'PaymentStatus',
      align: 'center',
      width: "150px",
      ...getColumnSearchProps('PaymentStatus')
    },
    {
      title: 'Transaction Ref No',
      dataIndex: 'TransactionRefNo',
      align: 'center',
      width: 150,
      ...getColumnSearchProps('TransactionRefNo')
    },
    {
      title: 'Payment Type',
      dataIndex: 'TypeOfService',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('TypeOfService'),
      render: (TypeOfInvoice: any) => {
        let color = 'white';
        if (TypeOfInvoice === 'GC-PPC Normal' || TypeOfInvoice === 'GC-PPC Exceed') {
          color = 'rgba(86, 241, 86, 0.5)';
        } else if (TypeOfInvoice === 'Normal' || TypeOfInvoice === 'Normal1' || TypeOfInvoice === 'Normal2') {
          color = 'rgba(248, 141, 2, 0.993)';
        } else if (TypeOfInvoice === 'Dispute') {
          color = 'rgba(241, 86, 86, 0.5)';
        }
        return {
          props: {
            style: { background: color }
          },
          children: <div >{TypeOfInvoice}</div>
        };
      }
    },
  ];


  const rows: { key: number }[] = [];
  console.log("DATA in approved", data)
  if (data && data.length > 0) {
    data.map((i, j) => {
      let obj: any = i
      obj.key = j + 1
      rows.push(obj)
      return rows
    })
  }

  const sendDataToParent = (index: any) => {
    setPopUp(index)
  }
  const Onnext = () => {
    let data: any = skipParams + 100
    setSkipParams(data)
  }
  const Onback = () => {
    if (skipParams != 0) {
      let data: any = skipParams - 100
      setSkipParams(data)
    }

  }
  console.log("DATA", skipParams)

  const navigate = useNavigate();
  if (!User || !User.isLoggedIn) {
    navigate('/login');
    return null;
  } else {

    return (
      loading ?
        <div style={antLayoutContent} ref={contentRef}>
          <div className="example" style={{ textAlign: 'center' }}>
            <Card className="ant-content m-b-20">
              <Spin />
            </Card>
          </div>
        </div>
        :
        <div style={antLayoutContent}>
          <div className='ButtomMarginDiv'>

            <Card className="ant-content ">
              <div className='table-responsive'>
                <Table
                  columns={columns}
                  dataSource={rows}
                  bordered
                  title={() => 'Approved Table'}
                  sticky={true}
                  scroll={{ x: "max-content" }}

                />
              </div>
              <Row style={{ width: "100%" }}>
                <Col span={19}></Col>
                <Col className='m-b-30 col-pad-0 align-right' span={5} style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>

                  <Button className='PrevBtn' onClick={Onback}>Prev</Button>
                  <Button className='NextBtn' onClick={Onnext}>Next</Button>
                </Col>
              </Row>

            </Card>
            <div className='ButtomMargin'>

            </div>
            <div>
              {popUp && <ServicePopup display={true} sendDataToParent={sendDataToParent} data={popupData} />}
            </div>
          </div>
        </div>

    )
  }
}