import { useEffect, useRef, useState } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Row, Select, Spin } from "../../../components/Elements";
import { GET_VEHICLE_LIST, ValidateDelearId, ValidateMobileNumber, ValidatePanCard, GET_VALIDATION_DATA, MATCHED_OTP } from "../api";
import { CardStyle } from "../helpers/formcsshelper";
import { validateMessages } from "../helpers/formhelper";
import { IMobileVerfiyPayload, IPanVerification, IVerifiedStatus } from "../helpers/interface";
import "./panform.scss";
import moment from 'moment';

// import { API_URL, VERIFY_MOBILE } from './../../../config';
import { ErrorMesages } from "../../../constants";
import { VerifyModal } from "../../../components/modals";
import { OTPVerify } from "./OTPVerifyForn";
import { VERIFY_MOBILE, API_URL } from "../../../config";
import { FALSE } from "node-sass";

// import { API_URL } from './../config/';

type LayoutType = Parameters<typeof Form>[0]['layout'];

const RegistrationType: any[] = [
    {
        name: 'Vendor',
        value: 'V'
    },
    {
        name: 'Dealer',
        value: 'D'
    },
    {
        name: 'FTSP',
        value: 'FTSP'
    }
];

export const PanVerification = (props: any) => {

    const [form] = Form.useForm();
    const VerifyModelRef = useRef<any>();
    const [RegistrationFormData, setRegistrationFormData] = useState({});
    const [vehiclesList, setVehiclesList] = useState<[]>([]);
    const [validationMessage, setValidationMessage] = useState<any>(null);
    const [entityNameField, setEntityNameField] = useState<Boolean>(false)
    const [isDealerIdFromPanDetails, setIsDealerIdFromPanDetails] = useState(false);
    const [delearVerified, setDelearVerfiried] = useState<IVerifiedStatus>();
   
    useEffect(() => {
        const GET_VEHICLE_LIST_DATA = async () => {
            let list: any = await GET_VEHICLE_LIST();
            setVehiclesList(list.message);
        }
        GET_VEHICLE_LIST_DATA()
    }, [])
    const getMessages = async (key: String, isError: Boolean) => {
        let response: any = await GET_VALIDATION_DATA({key, is_error:isError});
        setValidationMessage(response.message);
        console.log('New validationMessage state:', response.message); 

    }
    useEffect(() => {
        if (validationMessage && validationMessage.length > 0){        
        if(validationMessage !== 'Invalid OTP, please try again!') {
          message.success(validationMessage);
        }
        else{
            message.error(validationMessage);
        }
       setValidationMessage(null);
    }
      }, [validationMessage]);
    useEffect(() => {
        setRegistrationFormData(props.RegistrationFormData);
    }, [props.RegistrationFormData]);

    useEffect(() => {
        if (props.RegistrationFormData.PanVerification.entity_name) {
            setEntityNameField(true)
        }
        let registerDetails: any = RegistrationFormData;
        if (registerDetails) {
            let panDetails: any = registerDetails['PanVerification'];
            if (panDetails && Object.keys(panDetails)) {
                form.setFieldsValue(panDetails);
            }

            if (panDetails && panDetails.validated) {
                let panValue = panDetails['pancard']
                let mobile = panDetails['mobile_no']
                setPanVerified({ value: panValue.toUpperCase(), verified: true })
                if (panValue[3] === 'P') {
                    setPersonalPan(true)
                }
                setMobileVerified({ value: mobile, verified: true });
                if (panDetails['registration_type'] == 'D') {
                    setDelearVerfiried({ value: panDetails['dealer_id'], verified: true });
                    setIsDealerIdFromPanDetails(true);

                }
            }

            if (panDetails && panDetails['registration_type']) {
                onChangeRegistrationType(panDetails['registration_type']);
                form.setFieldsValue({ 'eai_employee': panDetails['eai_employee'] ? true : false });
            }

        }
    }, [RegistrationFormData])

    const [formLayout, setFormLayout] = useState<LayoutType>('horizontal');
    const [registrationType, setRegistrationType] = useState('');
    const [panVerified, setPanVerified] = useState<IVerifiedStatus>()
    const [personalPan, setPersonalPan] = useState<boolean>(false)
    const [panRequest, setPanRequest] = useState<boolean>(false);
    const [mobileRequest, setMobileRequest] = useState<boolean>(false);
    const [mobileVerified, setMobileVerified] = useState<IVerifiedStatus>()
    const [mobileVerifyOTP, setMobileOTP] = useState<any>();
    const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
        setFormLayout(layout);
    };
  

    const formItemLayout = {};

    const onChangeRegistrationType = (event: any) => {
        if (event) {
            setRegistrationType(event);
        }
        if (event != 'D') {
            form.setFieldsValue({ 'vehicle_make': "", 'dealer_id': '' });
        }

        if (event != 'V') {
            form.setFieldsValue({ 'eai_employee': "" });
        }
    }

    const openModal = () => {
        VerifyModelRef.current.onShowModal();
    }
    const closeModal = (event: any = {}) => {
        VerifyModelRef.current.onCloseModal();
    }

    const validateOTP = async (event: any) => {
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let value = panVerified ? panVerified.value + date : '';
        let res : any = await MATCHED_OTP({ otp: event.validate_otp, serviceId: value });
        if(res && res.status == "success") {
        // if (event.validate_otp && event.validate_otp == mobileVerifyOTP) {
            setMobileVerified({ value: form.getFieldValue('mobile_no'), verified: true })
            // message.success("Mobile Number Validated Successfully.")
            await getMessages('S02', false);
            closeModal();
        } else {
            // message.error("Please enter valid OTP.")
            await getMessages('E01', true);
        }
    }

    /**
     * Method to verify Pan card Number
     * @returns 
     */
    const ValidatePancard = async () => {
        let panValue = form.getFieldValue('pancard');

        // var regex = /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
        // var pan = { C: "Company", P: "Personal", H: "Hindu Undivided Family (HUF)", F: "Firm", A: "Association of Persons (AOP)", T: "AOP (Trust)", B: "Body of Individuals (BOI)", L: "Local Authority", J: "Artificial Juridical Person", G: "Govt" };
        // pan = pan[txt[3]];

        let registerType = form.getFieldValue('registration_type');
        if (!registerType || !panValue) {
            message.error('PAN number or Registration type is missing.');
            return false;
        }
        if (form.getFieldError('pancard').length) {
            message.error('Please enter valid PAN Number');
            return false;
        }
        panValue = panValue.toUpperCase();

        let panverifyBody: IPanVerification = {
            panNumber: panValue,
            Type: registerType
        }
        try {
            setPanRequest(true);
            setPersonalPan(false);
            setPanVerified({ value: panValue, verified: false })
            form.setFieldsValue({ surname: '', middle_name: '', first_name: '', entity_name: '', 'dof_incor_registration': '' });

            // setPersonalPan(true)
            // setPanVerified({ value: panValue, verified: true })
            // message.success("Pan Card Validated Successfully")
            // form.setFieldsValue({ entity_name: "Test", surname: 'Test Sur', middle_name: 'Test Middle', first_name: 'Test First' })
            // setPanRequest(false);
            // return true;


            let response: any = await ValidatePanCard(panverifyBody);
            setPanRequest(false);
            if (response && response.message && response.message.message && response.message.message == 'N') {
                message.error('Please enter valid PAN Number');
                return false;
            } else if (response && response.status == 'failure') {
                // setPanVerified({ value: panValue, verified: true });
                // setPersonalPan(true)
                message.error(response.message);
                return false;
            } else {
                // message.success("Pan Card Validated Successfully")
                await getMessages('S01', false);

               
                setPanVerified({ value: panValue, verified: true })
                if (panValue[3] === 'P') {
                    setPersonalPan(true)
                    if (response && response.message) {
                        let userData = response.message;
                        form.setFieldsValue({ surname: userData['surName'], middle_name: userData['lastName'], first_name: userData['firstName'] })
                    }
                } else {
                    if (response && response.message) {
                        let userData = response.message;
                        form.setFieldsValue({ entity_name: userData['firstName'] })
                    }
                }
            }

        } catch (e) {
            setPanRequest(false);
            message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE);
        }
    }

    /**
     * Method to verify Mobile Number
     * @returns 
     */
    const ValidateMobileNo = async () => {
        let mobileValue = form.getFieldValue('mobile_no');
        if (!mobileValue) {
            message.error('Mobile Number is missing.');
            return false;
        }

        if (form.getFieldError('mobile_no').length) {
            message.error('Please enter valid mobile number.');
            return false;
        }
        
        
        if (VERIFY_MOBILE) {
            try {
                let today = new Date();
                let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                let mobileverifyBody: IMobileVerfiyPayload = {
                    mobileNumber: mobileValue,
                    userName: form.getFieldValue('pancard') + date
                }
                setMobileRequest(true);
                openModal();
                let response: any = await ValidateMobileNumber(mobileverifyBody);
                if (response.message) {
                    setMobileOTP(response.message);
                }
                setMobileRequest(false);
            } catch (e) {
                setMobileRequest(false);
                message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE);
            }
        } else {
            setMobileVerified({ value: mobileValue, verified: true })
            // message.success("Mobile Number Validated Successfully.")
            await getMessages('S02', false);         
           
        }
    }
   

    const onFinish = async (values: any) => {
        let params: any = {
            type: 'PanVerification',
            data: values,
            next: true,
        }
        if (!panVerified?.verified || (form.getFieldValue('pancard').toUpperCase() !== panVerified.value.toUpperCase())) {
            message.error('Please verify Pancard number');
            return false;
        }
        if (!mobileVerified?.verified || (form.getFieldValue('mobile_no') !== mobileVerified.value)) {
            message.error('Please verify Mobile number');
            return false;
        }

        if (registrationType == 'D') {
            try {
                let dealer_id = form.getFieldValue('dealer_id');
                if (dealer_id != delearVerified?.value) {
                    if (dealer_id) {
                        let delearPayload = { dealerId: dealer_id }
                        let response: any = await ValidateDelearId(delearPayload);
                        if (response && response.status == 'success') {
                            setDelearVerfiried({ value: dealer_id, verified: true });
                            message.success(response.message);
                            props.handleSubmit(params);
                        } else {
                            message.error("Dealer Id Verification Failed.");
                        }
                    } else {
                        message.error("Please Enter Delear ID");
                    }
                } else {
                    props.handleSubmit(params);
                }
            } catch (e) {
                message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE);
            }
        } else {
            props.handleSubmit(params);
        }


    }

    const [checked, setChecked] = useState(false);

    const onCheckboxChange = async (e: any) => {
        await setChecked(e.target.checked);
    };
    function disabledDate(current: any) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }

    

    return (
        
        <div className="pan-form-container ezauto-regis-container">
            <Row style={{ justifyContent: "center" }}>
                <Col span={24}>
                    <Card style={CardStyle} >
                        
                            <Form
                                className="registration-details-form other-details-form"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 8 }}
                                {...formItemLayout}
                                layout={'vertical'}
                                form={form}
                                initialValues={{ layout: formLayout }}
                                onValuesChange={onFormLayoutChange}
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                autoComplete="off"
                            >

                                <Form.Item label="Registration Type" name="registration_type"
                                    rules={[{ required: true }]}>
                                    <Select placeholder="Select Type" onChange={($event) => onChangeRegistrationType($event)}>
                                        {
                                            RegistrationType.map((type) => (
                                                <Select.Option key={type.value} value={type.value}>{type.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>

                                {/* Vendor Type */}
                                {
                                    registrationType && registrationType === 'V'
                                    && <Form.Item labelAlign={"left"} label="" name="eai_employee" valuePropName="checked">
                                        <Checkbox checked={checked} onChange={onCheckboxChange}>
                                            EAI Employee
                                        </Checkbox>
                                    </Form.Item>
                                }
                                <div className="PanVerification">
                                    <div className="Panform-width">
                                        <Form.Item validateFirst wrapperCol={{
                                            span: 24,
                                        }} label="Pancard" name="pancard"
                                            // 
                                            rules={
                                                [
                                                    { required: true },
                                                    {
                                                        pattern: /^[a-zA-Z]{3}[pchfatbljgPCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                                                        message: "Please enter valid PAN number",
                                                    },
                                                    { max: 10, message: "Please enter valid PAN number" },
                                                    { message: "Please enter valid PAN number", min: 10 }
                                                ]
                                            }>
                                            <Input placeholder="Enter Pancard" />

                                        </Form.Item>
                                    </div>

                                    <div className="Panform">
                                        <Spin spinning={panRequest} delay={500}>
                                            <Form.Item label='' >
                                                <Button className="ant-btn-login marginBTN" onClick={ValidatePancard}  type="primary">Verify</Button>
                                            </Form.Item>
                                        </Spin>
                                    </div>

                                </div>

                                <div className="PanVerification">
                                    <div className="Panform-width">
                                        <Form.Item validateFirst  wrapperCol={{span:24}} label="Mobile No" name="mobile_no" rules={
                                            [
                                                { required: true },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please enter valid mobile number",
                                                },
                                                { max: 10, message: "Please enter valid mobile number" },
                                                { message: "Please enter valid mobile number", min: 10 }
                                            ]
                                        }>
                                            <Input placeholder="Enter Mobile No" autoComplete="new-password" />
                                        </Form.Item>
                                    </div>
                                    <div className="Panform">
                                        <Spin spinning={mobileRequest} delay={500}>
                                            <Form.Item label='' >
                                                <Button className="ant-btn-login marginBTN" onClick={ValidateMobileNo} type="primary">Verify </Button>
                                            </Form.Item>
                                        </Spin>
                                    </div>
                                </div>

                                {panVerified?.verified && personalPan &&
                                    <Form.Item label="Date of Birth" name="date_of_birth" rules={[{ required: true, },
                                        () => ({
                                            validator(_, value) {
                                              if (!value || value.diff(moment(), 'years') <= -18) {
                                                return Promise.resolve();
                                              }
                                              return Promise.reject(new Error('You must be at least 18 years old'));
                                            },
                                          }),
                                    ]}>
                                        <DatePicker disabledDate={disabledDate} format={'DD-MM-YYYY'} />
                                    </Form.Item>
                                }

                                {/* Data from Pan Card */}
                                {
                                    panVerified?.verified && personalPan &&
                                    <div className="pan-values">
                                        <Form.Item label="Surname" name="surname" rules={[{ required: true }]}>
                                        <Input placeholder="" disabled />
                                        </Form.Item>
                                        <Form.Item label="Middle Name" name="middle_name" rules={[{ required: false }]}>
                                            <Input placeholder="Enter Middle Name" autoComplete="new-password"/>
                                        </Form.Item>
                                        <Form.Item label="First Name" name="first_name" rules={[{ required: true }]}>
                                        <Input placeholder="" disabled />
                                        </Form.Item>
                                        <Form.Item label="Entity Name" name="entity_name" rules={[{ required: true }]}>
                                            <Input placeholder="Enter Entity Name" autoComplete="new-password"/>
                                        </Form.Item>
                                    </div>
                                }

                                {
                                    panVerified?.verified && !personalPan &&
                                    <div className="pan-values">
                                        <Form.Item label="Date of Incorporation/Registration" name="date_of_birth" rules={[{ required: true, }]}>
                                            <DatePicker disabledDate={disabledDate} format={'DD-MM-YYYY'}/>
                                        </Form.Item>
                                        <Form.Item label="Entity Name" name="entity_name" rules={[{ required: true }]}>
                                            <Input placeholder="Enter Entity Name" disabled />
                                        </Form.Item>
                                    </div>
                                }
                                {/* Dealer Type */}

                                {
                                    registrationType && registrationType === 'D'
                                    && <>
                                        <Form.Item label="Vehicle Make" name="vehicle_make" rules={[{ required: true, }]}>
                                            <Select showSearch placeholder="Select Type">
                                                {
                                                    vehiclesList.map((type) => (
                                                        <Select.Option ket={type} value={type}>{type}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label="Dealer ID" name="dealer_id" rules={[{ required: true },
                                       {
                                        validator(_, value) {
                                          if (!value || /^[a-zA-Z0-9]*$/.test(value)) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('Special characters and spaces are not allowed'));
                                        },
                                      },
                                      {
                                        validator(_, value) {
                                          if (!value || value.length <= 10) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('Length should be less than or equal to 10 characters'));
                                        },
                                      },
                                        ]}>
                                            <Input placeholder="Enter Dealer ID" disabled={isDealerIdFromPanDetails}/>
                                        </Form.Item>
                                    </>
                                }
                                <Row align="middle" justify="end" >
                                    <Form.Item >
                                        <Button htmlType="submit" className="ant-btn-login ant-btn-login" type="primary">Next</Button>
                                    </Form.Item>
                                </Row>
                            </Form>
                        
                    </Card>
                </Col>
            </Row>

            <VerifyModal ref={VerifyModelRef} title={"Please Enter OTP"} width={500}>
                <OTPVerify closeModal={() => closeModal} validateOTP={($event: any) => validateOTP($event)} />
            </VerifyModal>
        </div>
    );
}
