import { Divider, Layout } from "../../../components/Elements";
import "./footer.scss";

const { Footer } = Layout
export const FooterLayout = ({ children }: any) => {
    const Year = new Date().getFullYear();
    return (
        <Footer className="ez-auto-layout-footer" >
            {/* <Layout> */}
                RSA Portal Copyrights {Year} Reserved
            {/* </Layout> */}
        </Footer>
    );
}