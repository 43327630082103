import { useEffect, useState } from "react";
import { Row, Col, Card, Popconfirm, EditableTable, EditTwoTone, SaveTwoTone, CloseCircleTwoTone, Form, Popover, Checkbox, Input, message } from "../../../../components/Elements";
import { CardBodyStyle, CardStyle, hintStyle } from "../../helpers/formcsshelper";
import { ServicesObject } from "../../helpers/formhelper";

import "./locations.scss";

interface Item {
    tabIndex: number;
    // [x: string]: boolean;
    // disabled: boolean;
    // BaseKms100: boolean | undefined;
    key: any;
    Towing_Services: string;
    isEditing?: boolean;
    // name: string;
    // age: number;
    // address: string;
    Base_Rate_100: any;
    Rate_for_additional_Kms_100: any;
    Base_Kms: any;
    Base_Rate: any;
    Rate_for_additional_Kms: any;
    Fix_Rate: any;
    Cancellation_Base_Kms: any;
    Cancellation_Rate_for_additional_Kms: any;
    BaseKms100: any;
    Cancellation_Base_Kms_100: any;
    Cancellation_Rate_for_additional_Kms_100: any;
    // children?: Item[];
}

interface RepairOnSpotProps {
    setIsEditable: (value: boolean) => void;
    locaIndex: number;
    ServiceList: any[];
    presetServices: any[];
    servicesList: any[];
    type: string;
    setServicesData: any;
    form: any;
    page: string;
    key: string;
    deleteService: any;
}

export const RepairOnSpot: React.FC<RepairOnSpotProps> = ({ setIsEditable, ...props }) => {
    // const [data, setData] = useState([]);
    const [data, setData] = useState<Item[]>([]);
    const [editingKey, setEditingKey] = useState('');
    const [isEditable, setIsEditableLocal] = useState(false);
    // const [editingRecord, setEditingRecord] = useState({});
    const [editingRecord, setEditingRecord] = useState<Item | {}>({});
    const [form1] = Form.useForm();
    const [checkboxState, setCheckboxState] = useState(false);
    const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);

    useEffect(() => {
        setIsEditable(isEditable);
    }, [isEditable]);

    useEffect(() => {
        let selectedServices: any = {};
        let formFilledData: any = {};
        if (props.presetServices && props.presetServices[props.locaIndex]) {
            let ServicesListObj = ServicesObject
            let serv: any[] = props.presetServices[props.locaIndex];

            if (Array.isArray(serv)) {
                serv.forEach((ele, index) => {
                    if (ServicesListObj[ele.type]) {
                        let typeobj = ServicesListObj[ele.type];
                        Object.keys(typeobj).forEach((ele1: any) => {
                            let serEle = typeobj[ele1];
                            if (serEle['sub_type'] == ele['sub_type']) {
                                if (ele['type'] == 'RRP') {
                                    if (serEle['time'] == ele['time'] || ele['sub_type'] == 'CAR_ROS') {
                                        selectedServices[ele1] = ele;
                                    }
                                } else {
                                    selectedServices[ele1] = ele;
                                }
                            }
                        })
                    }
                });
            }

            Object.keys(ServicesListObj).forEach((type: any) => {
                if (serv[type]) {
                    let d: any[] = serv[type];
                    d.forEach((ele: any) => {
                        formFilledData[ele.key] = ele;
                    })
                }
            });
        }

        if (props.servicesList && props.servicesList[props.locaIndex]) {
            let ServicesListObj = ServicesObject
            let servL: any[] = props.servicesList[props.locaIndex];
            Object.keys(ServicesListObj).forEach((type: any) => {
                if (servL[type]) {
                    let d: any[] = servL[type];
                    d.forEach((ele: any) => {
                        formFilledData[ele.key] = ele;
                    })
                }
            });
        }

        let dataset = props.ServiceList?.map((ele: any) => {
            let obj: any = {};
            obj.key = ele.label;
            obj.Towing_Services = ele.label;
            obj.Base_Rate = '';
            obj.Base_Kms = '';
            obj.Rate_for_additional_Kms = '';
            obj.Fix_Rate = '';
            obj.Cancellation_Base_Kms = '';
            obj.Cancellation_Rate_for_additional_Kms = '';
            obj.BaseKms100 = '';
            obj.Cancellation_Base_Kms_100 = '';
            obj.Cancellation_Rate_for_additional_Kms_100 = '';
            obj.Base_Rate_100 = '';
            obj.Rate_for_additional_Kms_100 = '';

            if (selectedServices && selectedServices[ele.label]) {
                let serObj = selectedServices[ele.label];
                obj['EAI_Service_Id'] = serObj['EAI_Service_Id'];
                obj.Base_Rate = serObj['base_rate'];
                obj.Base_Kms = serObj['base_kms'];
                obj.Rate_for_additional_Kms = serObj['additional_rates_per_km'];
                obj.Fix_Rate = serObj['fixed_rate'];
                obj.Cancellation_Base_Kms = serObj['cancel_charge_base_rate'];
                obj.Cancellation_Rate_for_additional_Kms = serObj['cancel_charge_additionalKms'];
                obj.BaseKms100 = serObj['BaseKms100'];
                obj.Cancellation_Base_Kms_100 = serObj['cancel_charge_base_rate'];
                obj.Cancellation_Rate_for_additional_Kms_100 = serObj['cancel_charge_additionalKms'];
                if (serObj.tier2) {
                    obj.Base_Rate_100 = serObj.tier2['base_rate'] ? serObj.tier2['base_rate'] : '';
                    obj.Rate_for_additional_Kms_100 = serObj.tier2['additional_rates_per_km'] ? serObj.tier2['additional_rates_per_km'] : '';
                } else {
                    obj.Base_Rate_100 = '';
                    obj.Rate_for_additional_Kms_100 = '';
                }
                // props.setServicesData(obj);
            }

            if (formFilledData && formFilledData[ele.label]) {
                let serObj1 = formFilledData[ele.label];
                let serObj2 = selectedServices[ele.label];
                obj = { ...obj, ...serObj1 };
                if (serObj2) {
                    obj['EAI_Service_Id'] = serObj2['EAI_Service_Id'];
                }
                // props.setServicesData(obj);
            }
            return obj;
        });

        setData(dataset);
    }, [props.ServiceList, props.presetServices, props.servicesList]);

    const isEditing = (record: Item) => record.key === editingKey;



    const edit = (record: Partial<Item> & { key: React.Key }) => {

        record.Cancellation_Rate_for_additional_Kms_100 = record.Cancellation_Rate_for_additional_Kms ? record.Cancellation_Rate_for_additional_Kms : null;
        record.Cancellation_Base_Kms_100 = record.Cancellation_Base_Kms ? record.Cancellation_Base_Kms : null;


        setEditingRecord(record);
        setEditingKey(record.key);
        setIsEditableLocal(true);
        setCheckboxState(record.BaseKms100);
        setIsCheckboxEnabled(true);

    };
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && editingRecord && 'key' in editingRecord && isEditing(editingRecord as Item)) {
                event.preventDefault();
                event.stopPropagation();
                message.error('Please save the rates before going to the next step');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [editingRecord]);




    const cancel = async (record: Item) => {
        setEditingKey('');
        setIsEditableLocal(false);
        setEditingRecord({});
        const row: any = form1.validateFields();
        let newData = [...data];
        const index = data.findIndex((item: any) => item.key === record.key);
        let deletedRow: any = {};
        if (index > -1) {

            const item = newData[index];
            //Change for delete**************
            // newData[index] = { ...item, ...row }

            deletedRow = {
                ...item,
                BaseKms100: undefined,
                Cancellation_Rate_for_additional_Kms: undefined,
                Cancellation_Base_Kms: undefined,
                Fix_Rate: undefined,
                Rate_for_additional_Kms: undefined,
                Base_Kms: undefined,
                Base_Rate: undefined,
                Base_Rate_100: undefined,
                Rate_for_additional_Kms_100: undefined,
                Cancellation_Base_Kms_100: undefined,
                Cancellation_Rate_for_additional_Kms_100: undefined,
            }
            //Change for DElete *************
            newData.splice(index, 1)
        };

        setData(newData);
        //Change for DElete *************
        props.setServicesData({ key: deletedRow.key });
        //Change for DElete *************

        // await wait(4000);
        props.deleteService(props.locaIndex, props.type, deletedRow.key, "obj");

    }

    const save = async (key: React.Key) => {
        try {
            const row: any = await form1.validateFields();
            const newData: any = [...data];
            const index = newData.findIndex((item: any) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                 if(row.Base_Rate_100 !=="" && row.Base_Rate_100 !== null && row.Base_Rate_100 !== undefined){
                     row.BaseKms100 = 100
                 }
                newData[index] = { ...item, ...row }
                setData(newData);
                let recordS = JSON.parse(JSON.stringify(row));
                recordS['key'] = newData[index]['key'];
                props.setServicesData(recordS);
                setEditingKey('');
                setIsEditableLocal(false);
                setEditingRecord({});
                setCheckboxState(false);
                setIsCheckboxEnabled(false);

            } else {
                setIsCheckboxEnabled(false);
                newData.push(row);
                setData(newData);
                setIsEditableLocal(false);
                setEditingKey('');
                setEditingRecord({});
                setCheckboxState(false);
            }
            setIsCheckboxEnabled(false);
            setCheckboxState(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const content = (

        <p>Save</p>

    );


    const ActionColumn: any = {
        title: '',
        dataIndex: 'operation',
        width: 50,
        className: "ez-aeditable-table-column",
        render: (_: any, record: Item) => {
            const editable = isEditing(record);

            return (
                <span>
                    {editable ? (
                        <>
                            <Popover style={{ width: "20px", height: "20px" }} content={content}>
                                <SaveTwoTone onClick={() => save(record.key)} style={{ marginRight: 8 }} />
                            </Popover>
                            <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record)}>
                                <CloseCircleTwoTone />
                            </Popconfirm>
                        </>
                    ) : (
                        <EditTwoTone
                            disabled={editingKey !== ''}
                            onClick={() => edit(record)}
                        />
                    )}
                </span>
            );
        },
    };



    const handleCheckboxChange = (key: string, checked: boolean) => {
        const index = data.findIndex(item => item.key === key);
        if (index > -1) {
            const newData = [...data];
            newData[index] = {
                ...newData[index],
                BaseKms100: checked,
            };

            setCheckboxState(checked);
            setData(newData);
        }
    };





    const columns = [
        {
            title: 'Services',
            dataIndex: 'Towing_Services',
            width: 100,
            editable: false,
            className: "ez-aeditable-table-column twn_service"
        },
        {
            title: 'Base Rate',
            className: "ez-aeditable-table-column base_rate_header",
            editable: true,
            width: 200,
            children: [
                {
                    title: 'Base Rate *',
                    dataIndex: 'Base_Rate',
                    width: 70,
                    key: 'Base_Rate',
                    className: "ez-aeditable-table-column base_rate",
                    editable: true,
                    rules:
                        [
                            {
                                required: true,
                                message: `Please Input Base Rate!`,
                            },
                            {
                                pattern: /^\d+(\.\d+)?$/,
                                message: "Please Enter Valid Value"
                            }
                        ],
                    attributes: {
                        onChange: (ele: any) => {
                            let value = ele.target.value;
                            let baserate = 0;
                            if (value > 600) {
                                baserate = value / 2;
                            } else if (value > 300) {
                                baserate = 300;
                            } else {
                                baserate = value;
                            }
                            form1.setFieldsValue({ Cancellation_Base_Kms: baserate })
                            form1.setFieldsValue({ Cancellation_Base_Kms_100: baserate})
                        }
                    },
                },

                {
                    title: 'Base Kms *',
                    dataIndex: 'Base_Kms',
                    className: "ez-aeditable-table-column base_kms",
                    key: 'Base_Kms',
                    editable: true,
                    width: 70,

                    rules:
                        [
                            {
                                required: true,
                                message: `Please Input Base Kms!`,
                            },
                            {
                                pattern: /^\d+(\.\d+)?$/,
                                message: "Please Enter Valid Value"
                            }
                        ]
                },
                {
                    title: "Rate'\s for additional Kms *",
                    dataIndex: 'Rate_for_additional_Kms',
                    key: 'Rate_for_additional_Kms',
                    className: "ez-aeditable-table-column",
                    ellipsis: true,
                    editable: true,
                    width: 70,
                    attributes: {
                        onChange: (ele: any) => {
                            let value = ele.target.value;
                            form1.setFieldsValue({ Cancellation_Rate_for_additional_Kms: value })
                            form1.setFieldsValue({ Cancellation_Rate_for_additional_Kms_100: value })
                            // if (form1.getFieldValue("Base_Rate") && form1.getFieldValue("Base_Kms") && form1.getFieldValue("Rate_for_additional_Kms")) {
                            //     setIsCheckboxEnabled(true);
                            // } else {
                            //     setIsCheckboxEnabled(true);
                            // }
                        }
                    },
                    rules:
                        [
                            {
                                required: true,
                                message: <p style={{ margin: "0px" }}>Please Input <br />Rate'\sfor additional Kms!</p>
                            },
                            {
                                pattern: /^\d+(\.\d+)?$/,
                                message: "Please Enter Valid Value"
                            }
                        ]
                },
                ...(data.some((item) => item.BaseKms100
                ) ? [
                    {
                        title: 'Base Rate for >= 100',
                        dataIndex: 'Base_Rate_100',
                        width: 70,
                        key: 'Base_Rate_100',
                        className: checkboxState ? "ez-aeditable-table-column base_rate" : "ez-aeditable-table-column base_rate disabled",
                        editable: true,
                        attributes: {
                            readOnly: !checkboxState
                        },
                        render: (text: string, record: Item) => {
                            return record.BaseKms100 !== null && record.BaseKms100 !== undefined ? text : null;
                        },
                        rules:
                            checkboxState ? [
                                {
                                    required: true,
                                    message: `Please Input Base Rate!`,
                                },
                                {
                                    pattern: /^\d+(\.\d+)?$/,
                                    message: "Please Enter Valid Value"
                                }
                            ] : [],

                    }, {
                        title: 'Base Kms for >= 100',
                        dataIndex: 'Base_Kms_100',
                        className: "ez-aeditable-table-column base_kms",
                        key: 'Base_Kms_100',
                        editable: false,
                        width: 70,
                        render: (_: any, record: Item) => 100, // Set default value to 100
                    }, {
                        title: "Rate'\s for additional Kms for >= 100",
                        dataIndex: 'Rate_for_additional_Kms_100',
                        key: 'Rate_for_additional_Kms_100',
                        className: checkboxState ? "ez-aeditable-table-column base_rate" : "ez-aeditable-table-column base_rate disabled",
                        ellipsis: true,
                        editable: true,
                        width: 70,
                        attributes: {
                            readOnly: !checkboxState
                        },
                        rules:
                            checkboxState ? [
                                {
                                    required: true,
                                    message: <p style={{ margin: "0px" }}>Please Input <br />Rate'\sfor additional Kms!</p>
                                },
                                {
                                    pattern: /^\d+(\.\d+)?$/,
                                    message: "Please Enter Valid Value"
                                }
                            ] : [],
                    },] : []),
            ]
        },
        {
            title: 'Within city limits',
            ellipsis: true,
            editable: true,
            width: 70,
            className: "ez-aeditable-table-column",
            children: [
                {
                    title: 'Fix Rate',
                    dataIndex: 'Fix_Rate',
                    width: 70,
                    className: "ez-aeditable-table-column",
                    editable: true,
                    rules:
                        [
                            {
                                pattern: /^\d+(\.\d+)?$/,
                                message: "Please Enter Valid Value"
                            }
                        ]
                },
            ]
        },
        {
            title: 'Cancellation Charges',
            ellipsis: true,
            editable: true,
            width: 70,
            className: "ez-aeditable-table-column",
            children: [
                {
                    title: 'Base Rate *',
                    width: 70,
                    dataIndex: 'Cancellation_Base_Kms',
                    editable: true,
                    className: "ez-aeditable-table-column",
                    attributes: {
                        readOnly: true,
                        onChange: (ele: any) => {
                            console.log(ele);
                        }
                    }
                },
                {
                    title: 'Additional Kms *',
                    dataIndex: 'Cancellation_Rate_for_additional_Kms',
                    editable: true,
                    width: 70,
                    className: "ez-aeditable-table-column",
                    attributes: {
                        readOnly: true
                    }
                },
                ...(data.some((item) => item.BaseKms100) ? [
                    {
                        title: 'Base Rate >= 100',
                        width: 70,
                        dataIndex: 'Cancellation_Base_Kms_100',
                        editable: true,
                        className: checkboxState ? "ez-aeditable-table-column base_rate" : "ez-aeditable-table-column base_rate disabled",
                        attributes: {

                            readOnly: !checkboxState,

                            // editable: true,
                            onChange: (ele: any) => {
                                console.log(ele);
                            }
                        },
                        // render: (text: string, record: Item) => {
                        //     return record.BaseKms100 ? text : text;
                        // },
                    },

                    {
                        title: 'Additional Kms for >= 100',
                        dataIndex: 'Cancellation_Rate_for_additional_Kms_100',
                        editable: true,
                        width: 70,
                        className: checkboxState ? "ez-aeditable-table-column base_rate" : "ez-aeditable-table-column base_rate disabled",
                        attributes: {

                            readOnly: !checkboxState

                            // editable: true
                        },
                        // render: (text: string, record: Item) => {
                        //     return record.BaseKms100 ? text : text;
                        // },
                    },



                ] : [])
            ]
        },
        {
            title: 'BaseKms >= 100',
            dataIndex: 'BaseKms100',
            className: 'ez-aeditable-table-column',
            width: 40,
            render: (_: any, record: Item) => (
                // <Checkbox
                //     checked={record.BaseKms100 }
                //     onChange={(e) => handleCheckboxChange(record.key, e.target.checked)     }
                // disabled={record.key !== editingKey || !isCheckboxEnabled}
                <Checkbox
                    checked={record.BaseKms100}
                    onChange={(e) => handleCheckboxChange(record.key, e.target.checked)}
                    disabled={(editingKey !== '' && editingKey !== record.key) || !isCheckboxEnabled}



                />
            ),
        }


    ];
    if (props.page !== "dashboard") {
        columns.push(ActionColumn)

    }
    return (
        <div className="ez-autos-ros-table">
            <Row gutter={8} style={{ justifyContent: "center" }}>
                <Col span={24}>
                    <Card bodyStyle={CardBodyStyle} style={CardStyle}>
                        <div style={hintStyle} className="tab-info-text" >
                            Labour Charges for Scheduled Services Stated Above [For Repair on SPOT-ROS] Including Conveyance upto 20KMS)
                        </div>
                        <Form form={form1} component={false} name="services">
                            <EditableTable scroll={{ x: "max-content" }} key={props.key} name={props.locaIndex} editingKey={editingKey} editingRecord={editingRecord} form={form1} data={data} columns={columns}>
                            </EditableTable>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div >
    );
}