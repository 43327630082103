import { SSO_AUTHENTICATION } from "../api"
import { useContext, useEffect, useState } from "react";
import { UserContext, UserContextType } from "../../../providers/UserContext";
import storage from "../../../utils/storage";
import { Form, Button, Input, Layout, Row, Col, Card,
    message, Spin } from "../../../components/Elements";
import { ErrorMesages } from "../../../constants";
import { GET_VENDOR_DOCUMENT, GET_VENDOR_REG_STATUS } from "../../Login/api";
import { useNavigate } from 'react-router-dom'

export const Routes = () => {


    const { User, SaveUser } = useContext(UserContext) as UserContextType;
    let status: any;
    status = storage.getValue('regisrationStatus');
    const [loader, setLoader] = useState<boolean>(false);
    const navigate = useNavigate();

    // make APi Call to SSO Auth API.
    const ssoAuthCall = async () => {
        const params: any = new URLSearchParams(window.location.search);
        var postObj: any = {};
        for (const [key, value] of params) {
            postObj[key] = value;
        }

        try {
          var loginResponse : any =   await SSO_AUTHENTICATION(postObj);
            console.log("SSO AUTH", loginResponse)
          SaveUser({ ...User, isLoggedIn: true, details: { name: "E-Z Auto" }, token: loginResponse.token })

            if (loginResponse && loginResponse.role == "vendor") {
              let vendorP: any = { vendorId: loginResponse['userrefId'] }
              storage.setValue('vendorId', loginResponse['userrefId']);
              storage.setValue('popCount', 0)
              storage.setVendorId(loginResponse['userrefId']);

              let vendorRegStatus: any = await GET_VENDOR_REG_STATUS(vendorP);
              
              setLoader(false)
              console.log("loginResponse.destination",loginResponse.destination)

              if (vendorRegStatus && vendorRegStatus['regstatus'] != 'Submitted' && vendorRegStatus['message'] == "") {
                  let vendorDocP: any = { userName: loginResponse['userrefId'] };
                  let vendorDocResult: any = await GET_VENDOR_DOCUMENT(vendorDocP);
                  SaveUser({ ...User, isLoggedIn: true, details: { name: "E-Z Auto", regisrationStatus: vendorRegStatus['regstatus'], registrationDetails: vendorDocResult.message[0] } })
                  navigate('/home/pending-registration');
              }
              else if (vendorRegStatus && vendorRegStatus['kyc_flag'] != "Active") {
                  navigate('/kyc', { state: vendorRegStatus });
                  storage.setValue('regisrationStatus', "success")
              }
              else if (vendorRegStatus && vendorRegStatus['message'] == 'Active' && vendorRegStatus['kyc_flag'] == "Active") {
                  SaveUser({ ...User, isLoggedIn: true, details: { name: "E-Z Auto", regisrationStatus: "success" } })
                  storage.setValue('regisrationStatus', "success");
                  storage.setValue('paymentType', vendorRegStatus['paymentType'])
                  console.log("loginResponse.destination",loginResponse.destination)
                  if(loginResponse.destination == 'invoices')
                  {
                    navigate('/home/invoices')
                  }
                  else if(loginResponse.destination == 'pending'){
                    navigate('/home/pending')
                  } else if(loginResponse.destination == 'approved'){
                    navigate('/home/approved')
                  } else if(loginResponse.destination == 'alerts'){
                    navigate('/home/alerts')
                  } else if(loginResponse.destination == 'disputes'){
                    navigate('/home/disputes')
                  }
                  else{

                      navigate('/home/profile');
                  }
              }

          } else {
              SaveUser({ ...User, isLoggedIn: false, details: {}, token: "" })
              message.error("User Not Found, Please try with valid login details.");
              setLoader(false)
          }

        } catch (error) {
             setLoader(false)
            message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE);
        }
       

    }


    useEffect(() => {
        ssoAuthCall();

    }, [])

    return null;

}