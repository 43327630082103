import { useEffect, useState } from "react";
import {
    Layout, Button, Form, Input, Select, Row, Col, Card, Collapse, Spin, Checkbox,
    MinusCircleOutlined, PlusOutlined, SaveOutlined, Tabs, message
} from "../../../../components/Elements";
import { CardStyle } from "../../helpers/formcsshelper";
import {
    CVRosServiceList, detailsUpdateForProfileDashboard, CVTowingServiceList,
    detailsUpdateForProfile, parseDataToFillExistingLocation, RosServiceList, ServicesObject,
    TowingServiceList, validateMessages, RosFTSPServiceList,CustodyList
} from "../../helpers/formhelper";
import { RepairOnSpot } from "./repair_on_spot";


import "./locations.scss";
import { GETS_STATE_LIST, SAVE_GEO_LOCATION } from "../../api";

import { FetchLocationByAddress } from "../../../../services/Geocode.service";
import storage from "../../../../utils/storage";
import { updateLocationWiseMobileInfo, updateTechnician, updateVendorGSTNumber, updateVendorLocations }
    from "../../../../services/shared.service";
import { parse } from "path";

export const Locations = (props: any) => {
    console.log("PROPS",props)
    const [form] = Form.useForm();
    const [RegistrationFormData, setRegistrationFormData] = useState({});
    const [statesList, setStatesList] = useState<any>([]);
    const [servicesList, setServicesList] = useState<any>([]);
    const [presetServices, setpreServices] = useState<any>();
    const { Panel } = Collapse;
    const { TabPane } = Tabs;
    const [dashboardDiable, setDashboardDisable] = useState(true)
    const [check, setCheck] = useState(true)
    const [isDRSA, setIsDRSA] = useState(false)
    const [loader, setLoader] = useState(false)
    const [parseData, setParseData] = useState<any>()
    const [updateText, setUpdateText] = useState<any>('Update Phone Number :')
    const [getDay, setDay] = useState<string>()
    const [getSecurity, setSecurity] = useState();
    const [isAnyRowEditable, setIsAnyRowEditable] = useState(false);
    const [showFTSPServieList, setFTSPServieList] = useState(false)


    let vendorId: any;
    vendorId = storage.getValue("vendorId")

    useEffect(() => {
        setRegistrationFormData(props.RegistrationFormData);
    }, [props.RegistrationFormData]);




    useEffect(() => {

        const getstatesList = async () => {
            try {
                let statesList: any = await GETS_STATE_LIST();
                if (statesList && statesList.status == 'success' && statesList.message.length) {
                    let list: any[] = statesList.message;
                    list = list.sort();
                    list = list.map((state: any) => {
                        let s: any = {};
                        s['name'] = state;
                        s['value'] = state;
                        return s;
                    });
                    setStatesList(list)
                }
            } catch (e) {
            }
        }
        getstatesList();
    }, [])

    useEffect(() => {
        if (props.Locations && props.page == "dashboard") {
            // if(props.Locations.addressess[0].)
            setDashboardDisable(false)
            let parseData = parseDataToFillExistingLocation(props.Locations)
            setParseData(parseData)
            let locationValues: any = parseData['Locations'];
            if (locationValues && Object.keys(locationValues)) {
                if (locationValues.locations) {
                    form.setFieldsValue({ locations: locationValues.locations });
                    if (parseData['ServicesList']) {
                        // setpreServices(parseData['ServicesList']);
                        setServicesList(parseData['ServicesList'])
                    }
                }
            }
            if (props.Locations.reg_type == 'FTSP') {
                setFTSPServieList(true)
            }
        } else {
            if (props.RegistrationFormData.PanVerification.registration_type === "FTSP") {
                setFTSPServieList(true);
            }
        }
    }, [props.Locations])




    useEffect(() => {
        let registerDetails: any = RegistrationFormData;
        if (registerDetails) {
            let locationValues: any = registerDetails['Locations'];
            if (locationValues && Object.keys(locationValues)) {
                if (locationValues.locations) {
                    form.setFieldsValue({ locations: locationValues.locations });
                    if (registerDetails['ServicesList']) {
                        //setpreServices(registerDetails['ServicesList'])
                        setServicesList(registerDetails['ServicesList'])
                    }
                }
            }
        }
    }, [RegistrationFormData]);

    function checkIfEveryObjectHasData(array: any) {
        for (const obj of array) {
            let objectHasData = false;
            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    if (obj[key].length > 0) {
                        objectHasData = true;
                        break;
                    }
                } else {
                    if (Object.keys(obj[key]).length > 0) {
                        objectHasData = true;
                        break;
                    }
                }
            }
            if (!objectHasData) {
                return false;
            }
        }
        return true;
    }
    const onFinish = (values: any) => {
        if (servicesList.length === 0) {
            message.error('Locations should have atleast one selected service');
            return;
        }



        prepareServicesList(servicesList);
        console.log("@@@@@@@@", servicesList)


        let registerDetails: any = RegistrationFormData;
        let locations = registerDetails['Locations']

        let locatinskeys = values.locations && values.locations[0] ? Object.keys(values.locations[0]) : [];
        if ((values && values.locations && values.locations.length && locatinskeys.length)
            || (locations && locations.validated)) {
            if (!checkIfEveryObjectHasData(servicesList) || Object.keys(values.locations).length !== servicesList.length) {
                console.log("1")
                message.error('Locations should have atleast one selected service');
                return;
            } else if (!checkIfEveryObjectHasData(servicesList)) {
                console.log("2")
                message.error('Locations should have atleast one selected service');
                return;
            } else if (Object.keys(values.locations).length !== servicesList.length) {
                console.log("3")
                message.error('Locations should have atleast one selected service');
                return;
            } else {
                console.log("4")

            }
            console.log("SErvice list",servicesList)
            let params: any = {
                type: 'Locations',
                data: values,
                services: servicesList,
                next: true,
            }
            props.handleSubmit(params);
        } else {
            message.error("Please enter atleast one location details.")
        }
    }

    const prepareServicesList = (list: any[]) => {
        let servicesl = [
            {
                name: "RRP",
                servl: showFTSPServieList == true ? RosFTSPServiceList : RosServiceList
            },
            {
                name: "TOW",
                servl: TowingServiceList
            },
            {
                name: "CVRRP",
                servl: CVRosServiceList
            },
            {
                name: "CVTOW",
                servl: CVTowingServiceList
            },
            {
                name: "Custody",
                servl: CustodyList
            }
        ];

        list.forEach((serv: any, index) => {

            let selectedServices: any = {};

            if (Array.isArray(serv)) {
                serv.forEach((ele: any) => {
                    if (ServicesObject[ele.type]) {
                        let typeobj = ServicesObject[ele.type];
                        Object.keys(typeobj).forEach((ele1: any) => {
                            let serEle = typeobj[ele1];
                            if (serEle['sub_type'] == ele['sub_type']) {
                                if (ele['type'] == 'RRP') {
                                    if (serEle['time'] == ele['time']) {
                                        selectedServices[ele1] = ele;
                                    }
                                } else {
                                    selectedServices[ele1] = ele;
                                }
                            }
                        })
                    }
                });
            }

            servicesl.forEach((liele: any) => {
                Array.isArray(liele.servl) && liele.servl.forEach((ele: any) => {
                    let obj: any = {};
                    if (selectedServices && selectedServices[ele.label]) {
                        let serObj = selectedServices[ele.label];
                        obj.key = ele.label;
                        obj['EAI_Service_Id'] = serObj['EAI_Service_Id'];
                        obj.Base_Rate = serObj['base_rate'];
                        obj.Base_Kms = serObj['base_kms'];
                        obj.Rate_for_additional_Kms = serObj['additional_rates_per_km'];
                        obj.Fix_Rate = serObj['fixed_rate'];
                        obj.Cancellation_Base_Kms = serObj['cancel_charge_base_rate'];
                        obj.Cancellation_Rate_for_additional_Kms = serObj['cancel_charge_additionalKms'];
                        setServicesData(index, liele.name, obj);
                    }
                });
            });
        });
    }

    const onFinishFailed = (values: any) => {
        console.log(values)
    }

    const getPanelNum = (keyName: any, key: any) => {
        let locations = form.getFieldsValue()['locations'];
        if (locations && locations.length && locations[keyName] && locations[keyName]["city"]
            && locations[keyName]["address1"]) {
            return locations[keyName]["city"] + [" "] + '(' + locations[keyName]["address1"] + ') :';
        } else {
            let panelIndex = key + 1;
            return 'Location ' + panelIndex + ' :';
        }
    }

    const setServicesData = (name: any, type: string, keyvalues: any) => {
        console.log("KEY",keyvalues,"name",name,"type",type)
        try {
            let servicesListCopy = [...servicesList];
            if (Object.keys(keyvalues).length === 1 && 'key' in keyvalues) {
                console.log("1")
                servicesListCopy[name][type] = servicesList[name][type].filter((item: any) => item.key !== keyvalues.key);
                if (servicesListCopy[name][type].length === 0) {
                    console.log("2")
                    delete servicesListCopy[name][type];
                    if (servicesListCopy[0] && typeof servicesListCopy[0] === 'object'
                        && Object.keys(servicesListCopy[0]).length === 0) {
                        console.log("3")
                        setServicesList([]);
                    }

                    else {
                        console.log("4")

                        setServicesList(servicesListCopy);
                    }
                }
            }
            else if (!servicesListCopy[name]) {
                console.log("5", servicesListCopy)

                servicesListCopy[name] = {};
                if (!servicesListCopy[name][type]) {
                    console.log("6", servicesListCopy[name][type])

                    servicesListCopy[name][type] = [];
                    servicesListCopy[name][type].push(keyvalues)
                    setServicesList(servicesListCopy);

                } else {
                    let items = servicesListCopy[name][type];
                    console.log("7")

                    let foundIndex = items.findIndex((element: any) => { return element.key === keyvalues.key })
                    if (foundIndex != -1) {
                        console.log("8")

                        items.splice(foundIndex, 1, keyvalues)
                        servicesListCopy[name][type] = items
                    } else {
                        console.log("9")

                        servicesListCopy[name][type].push(keyvalues)
                    }
                    setServicesList(servicesListCopy);
                }
            } else {
                if (!servicesListCopy[name][type]) {
                    console.log("10")

                    servicesListCopy[name][type] = [];

                    servicesListCopy[name][type].push(keyvalues)
                    setServicesList(servicesListCopy);


                } else {
                    console.log("11")

                    let items = servicesListCopy[name][type];
                    console.log("items ", items)
                    let foundIndex = items.findIndex((element: any) => { return element.key === keyvalues.key })
                    console.log(" foundIndex ", foundIndex)
                    if (foundIndex != -1) {
                        console.log("12")

                        items.splice(foundIndex, 1, keyvalues)
                        servicesListCopy[name][type] = items
                    } else {
                        console.log("13")

                        servicesListCopy[name][type].push(keyvalues)
                    }
                    setServicesList(servicesListCopy);
                }
            }

        } catch (e) {
            console.log(e);
        }
    }
    console.log("SERvices ",servicesList)

    const OnPincodeChange = async (element: any, index: any) => {

        if (element.target.value && element.target.value.length === 6
            && isNaN(element.target.value) === false) {
            let formData = form.getFieldsValue();
            try {
                formData['locations'][index]['latitude'] = '';
                formData['locations'][index]['longitude'] = '';
                formData['locations'][index]['city'] = '';
                formData['locations'][index]['state'] = '';
                let addressLoc = formData['locations'] && formData['locations'][index]
                    && formData['locations'][index]['address1']
                // let addressPin = element.target.value;
                let addressPin = addressLoc ? addressLoc + ',' + element.target.value : element.target.value;
                let addressComponent: any = await FetchLocationByAddress(addressPin);
                if (addressComponent && addressComponent.status == "OK" && addressComponent.results) {
                    let addressDetails = addressComponent.results[0];
                    if (addressDetails && addressDetails.geometry) {
                        await SAVE_GEO_LOCATION({
                            "Type": "Geocoding",
                            "Page": "Vendor Dashboard", "CaseNumber": "Vendor Dashboard"
                        })
                        formData['locations'][index]['latitude'] = addressDetails.geometry.location.lat;
                        formData['locations'][index]['longitude'] = addressDetails.geometry.location.lng;
                    }
                    if (addressDetails && addressDetails.address_components
                        && addressDetails.address_components.length) {
                        addressDetails.address_components.map((add: any) => {
                            var types = add.types;
                            if (types.includes('administrative_area_level_1')) {
                                let state = statesList.filter((state: any) => state.name == add.long_name);
                                if (state) {
                                    formData['locations'][index]['state'] = add.long_name;
                                }
                            }
                            else if (types.includes('locality')) {
                                formData['locations'][index]['city'] = add.long_name;
                            }
                        });
                    }
                    form.setFieldsValue({ locations: formData['locations'] });
                }
            } catch (e) {
                console.log(e);
                formData['locations'][index]['latitude'] = ''
                formData['locations'][index]['longitude'] = '';
                formData['locations'][index]['city'] = '';
                formData['locations'][index]['state'] = '';
                form.setFieldsValue({ locations: formData['locations'] });
            }

        }
    }
    const checked = (event: any) => {
        setCheck(event.target.checked)
        if (props.Locations.reg_type == "D" && props.Locations.vehicle_make == "ROYAL ENFIELD"
            && props.page == "dashboard") {
            setDashboardDisable(event.target.checked)

        }

    }
    useEffect(() => {
        console.log("check1111", check);
    }, [check]);



    const savePhone = async (index: any) => {
        if (props.Locations.reg_type == "D" && props.Locations.vehicle_make == "ROYAL ENFIELD"
            && props.page == "dashboard") {
            let arr: any = []
            let values: any = form.getFieldsValue().locations[index];
            arr.push(values)
            var result: any = detailsUpdateForProfileDashboard(arr, parseData)
            var obj1: any = { addresses: result };
            let obj2: any = {
                "vendorId": vendorId,
                "username": props['Locations']['entityName']
            }
            let obj: any = { ...obj1, ...obj2 }
            console.log("LOGSSSSS", obj)
            let res: any = await updateVendorLocations(obj)
            if (res.status == "success") {
                alert(res.message)
                setCheck(false)
                console.log("check", check)
                setDashboardDisable(false)
            } else {
                alert(res.message)
                setCheck(false)
                setDashboardDisable(false)
            }

        } else {
            let values: any = form.getFieldsValue().locations[index];
            let payload: any = {
                "vendorId": vendorId,
                "primaryPhone": values['phone'],
                "alterNatePhone": values['alter_phone'],
                "locationId": values['_id']
            }
            // if(props.locations[index].)
            if (window.confirm('Please check your contact details correct or not:\n Phone Number : '
                + payload.primaryPhone + '\nAlternate Number : ' + payload.alterNatePhone)) {
                let res: any = await updateLocationWiseMobileInfo(payload)
                if (res.status == "success") {
                    alert(res.message)
                    setCheck(false)
                    console.log("check", check)
                } else if (res.status == "failure") {
                    alert(res.message)
                }
            }
        }

    }
    const saveDRSA = async (index: any) => {
        let values: any = form.getFieldsValue().locations[index];
        let payload: any = {
            "_id": values['_id'],
            "FirstName": values['technician_name'],
            "MobileNumber": values['technician_phone'],
            "drsa_flag": isDRSA,
            "pwa_drsa_flg": null
        }
        setLoader(true)
        if (payload.FirstName && !/^\d+$/.test(payload.FirstName) && (payload.MobileNumber != null
            && /^\d{10}$/.test(payload.MobileNumber))) {
            let res: any = await updateTechnician(payload)
            setLoader(false)
            if (res.status == "success") {
                message.success("DRSA location activated")
            } else {
                message.error("Error while updating location")
            }
        } else {
            message.error('Please enter valid details')
            setLoader(false)
        }


    }

    const drsaenable = async (event: any, index: any) => {
        setIsDRSA(event.target.checked)
        if (event.target.checked == false) {
            if (window.confirm('Do you want to proceed?')) {
                let values: any = form.getFieldsValue().locations[index];
                let payload: any = {
                    "_id": values['_id'],
                    "FirstName": values['technician_name'],
                    "MobileNumber": values['technician_phone'],
                    "drsa_flag": false,
                    "pwa_drsa_flg": null
                }
                setLoader(true)
                let res: any = await updateTechnician(payload)
                setLoader(false)
                if (res.status == "success") {
                    message.success("DRSA location deactivated")
                } else {
                    message.error("Error while updating location")
                }
            }




        }


    }

    const getDRSAflag = (index: any) => {
        if (props.page === 'dashboard') {
            setCheck(false)

        }
        if (props.page !== 'dashboard') {
            if (parseData) {
                setIsDRSA(parseData["Locations"]["locations"][index]['drsa_flag'])
            }
        }
    }


    const removeServices = (index: any) => {


        let servic: any = [...servicesList];
        if (servic && servic[index]) {
            console.log("#############",servic)
            servic.splice(index, 1);
            console.log("!!!!!!!!!!!!", servic)
            setServicesList(servic);
            props.DeleteService(index, "","","row")
        } else if ((!servicesList) || (servicesList && servicesList.length == 0)) {
            console.log("INSIDE")
            setServicesList([]);
        } else {
            console.log("outside")
        }
    }
    const WeekList =
        [
            { day: "Sunday", value: 0 },
            { day: "Monday", value: 1 },
            { day: "Tuesday", value: 2 },
            { day: "Wednesday", value: 3 },
            { day: "Thursday", value: 4 },
            { day: "Friday", value: 5 },
            { day: "Saturday", value: 6 }

        ]

    const Security =
        [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" }
        ]





    return (
        <div className="ezauto-regis-container ezauto-location-details">
            <Row gutter={8} style={{ justifyContent: "center" }}>
                <Col span={24}>
                    <Card bodyStyle={{ padding: "2px" }} style={CardStyle}>
                        <Spin spinning={loader}>
                            <Form className="registration-details-form dynamic_form_locations"
                                name="dynamic_form_locations" autoComplete="off" layout={'vertical'}
                                form={form}
                                labelCol={{ span: 20 }}
                                wrapperCol={{ span: 24 }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                validateMessages={validateMessages}>
                                <Form.List initialValue={[1]} name="locations">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <Collapse defaultActiveKey={['0']} expandIconPosition={"right"} accordion bordered={false} onChange={(val) => { getDRSAflag(val) }}>

                                                {

                                                    fields.map(({ key, name, ...restField }, index) => (

                                                        <Panel className="locations-panel"
                                                            style={{ color: "red" }}
                                                            header={getPanelNum(key, index)} key={index}>
                                                            {
                                                                fields && fields.length > 1 &&
                                                                <>
                                                                    {props.page === "dashboard" ? '' :
                                                                        <Row style={{
                                                                            marginLeft: "0px",
                                                                            marginRight: "0px", marginTop: "0px"
                                                                        }} gutter={[24, 24]} align="middle" justify="end" >
                                                                            <MinusCircleOutlined style={{ fontSize: "18px", marginBottom: "5px", color: "red" }} onClick={() => { remove(index); removeServices(index) }} />
                                                                        </Row>
                                                                    }

                                                                </>

                                                            }
                                                            <Card bordered={false} style={{ backgroundColor: "#fff" }}>
                                                                {props.page === "dashboard" ?
                                                                    <>
                                                                        {/* {
                                                                            (props.Locations.reg_type === "D"
                                                                                && props.Locations.vehicle_make === "ROYAL ENFIELD" && props.page === "dashboard") ?
                                                                                <>
                                                                                    <Row gutter={24} className="DrsaBtn">

                                                                                        <h5 style={{ fontWeight: "bold" }}>  {"DRSA : "}&nbsp;
                                                                                            <Checkbox onClick={(event) => { drsaenable(event, index) }} checked={isDRSA} />
                                                                                        </h5>




                                                                                        {isDRSA &&
                                                                                            <Button className="saveButton save-button"
                                                                                                onClick={() => { saveDRSA(index) }}  >SAVE</Button>
                                                                                        }
                                                                                    </Row>

                                                                                    {
                                                                                        isDRSA && <>

                                                                                            <div className="StyleDrsa">
                                                                                                <div className="TechStyle" >
                                                                                                    <Form.Item label="Technician Name"
                                                                                                        rules={[{ required: true }, {
                                                                                                            pattern: /\D/,
                                                                                                            message: "Technician Name must contain at least one non-digit character"
                                                                                                        }]}
                                                                                                        {...restField} name={[name, "technician_name"]}>
                                                                                                        <Input />
                                                                                                    </Form.Item>
                                                                                                </div>
                                                                                                <div className="TechPhon">
                                                                                                    <Form.Item label="Technician Phone" rules={[{ required: true },
                                                                                                    {
                                                                                                        pattern: /^\d{10}$/,
                                                                                                        message: 'Please enter a valid 10 digit phone number'
                                                                                                    }
                                                                                                    ]} {...restField} name={[name, "technician_phone"]}>
                                                                                                        <Input />
                                                                                                    </Form.Item>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }


                                                                                </> : ""
                                                                        } */}
                                                                        <Row gutter={24} className="DrsaBtnLocation" >
                                                                            {(props.Locations.reg_type === "D" && props.Locations.vehicle_make === "ROYAL ENFIELD"
                                                                                && props.page === "dashboard") ? <h5 style={{ fontWeight: "bold" }}>{" Update Location Details :"}&nbsp;<Checkbox checked={check} onChange={checked} /></h5> : <h5 style={{ fontWeight: "bold" }}>{"Update Phone Number  :"}&nbsp;<Checkbox checked={check} onChange={checked} /></h5>}




                                                                            <div>
                                                                                {
                                                                                    check && <Button onClick={() => { savePhone(index) }}
                                                                                        className="saveButton saveButton-Btn">SAVE</Button>
                                                                                }

                                                                            </div>
                                                                        </Row>
                                                                    </>
                                                                    : ''
                                                                }
                                                                <Row gutter={24} style={{ margin: "0px" }}>
                                                                    <div className="ProfileStyle">
                                                                        <div className="ProfileLocation">

                                                                            <Form.Item validateFirst key={name} label="Phone" rules={
                                                                                [
                                                                                    { required: true },
                                                                                    {
                                                                                        pattern: /^(?:\d*)$/,
                                                                                        message: "Please enter valid Phone number",
                                                                                    },
                                                                                    { max: 10, message: "Please enter valid Phone number" },
                                                                                    { message: "Please enter valid phone number", min: 10 }
                                                                                ]
                                                                            } name={[name, "phone"]} >
                                                                                <Input disabled={!check} autoComplete="new-password" />
                                                                            </Form.Item>
                                                                            <Form.Item label="Address 1" rules={[{ required: true }]} {...restField} name={[name, "address1"]}>
                                                                                <Input disabled={!dashboardDiable} autoComplete="new-password" />
                                                                            </Form.Item>
                                                                            <Form.Item label="Pin Code" name={[name, "pincode"]} rules={[{ required: true }, {
                                                                                pattern: /^(?:\d*)$/,
                                                                                max: 6,
                                                                                message: "Please enter valid pincode",
                                                                            },
                                                                            ]} {...restField} >
                                                                                <Input onChange={($event) => OnPincodeChange($event, name)}
                                                                                    disabled={!dashboardDiable} autoComplete="new-password" />
                                                                            </Form.Item>
                                                                            <Form.Item label="City" name={[name, "city"]} rules={[{ required: true }]} {...restField} >
                                                                                <Input disabled={!dashboardDiable} autoComplete="new-password" />
                                                                            </Form.Item>
                                                                            <Form.Item label="State" name={[name, "state"]} rules={[{ required: true }]} {...restField} >
                                                                                <Select disabled={!dashboardDiable} >
                                                                                    {
                                                                                        statesList.map((type: any) => (
                                                                                            <Select.Option value={type.value} >{type.name}</Select.Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </Form.Item>

                                                                            {
                                                                                (props.page !== "dashboard" ?
                                                                                    <>
                                                                                        {(props.RegistrationFormData.PanVerification.registration_type === "D"
                                                                                            && props.RegistrationFormData.PanVerification.vehicle_make == "ROYAL ENFIELD")
                                                                                            ? <Form.Item label="Security Guard(After working hrs):"
                                                                                                name={[name, "Security"]} rules={[{ required: true }]} {...restField} >
                                                                                                <Select disabled={!dashboardDiable} onChange={value => setSecurity(value)}>
                                                                                                    {
                                                                                                        Security.map((item) => (
                                                                                                            <Select.Option key={item.id} value={item.label} >{item.label}</Select.Option>
                                                                                                        ))
                                                                                                    }
                                                                                                </Select>
                                                                                            </Form.Item> : ''}
                                                                                    </> : '')
                                                                            }
                                                                            {
                                                                                (props.page === "dashboard" ?
                                                                                    <>
                                                                                        {(props.Locations.reg_type == "D" && props.Locations.vehicle_make == "ROYAL ENFIELD"
                                                                                            && props.page == "dashboard") ? <Form.Item label="Security Guard(After working hrs):"
                                                                                                name={[name, "Security"]} rules={[{ required: true }]} {...restField} >
                                                                                            <Select disabled={!dashboardDiable} onChange={value => setSecurity(value)}>
                                                                                                {
                                                                                                    Security.map((item) => (
                                                                                                        <Select.Option key={item.id} value={item.label} >{item.label}</Select.Option>
                                                                                                    ))
                                                                                                }
                                                                                            </Select>
                                                                                        </Form.Item> : ''}
                                                                                    </> : '')
                                                                            }


                                                                        </div>
                                                                        <div style={{ width: "100%" }}>
                                                                            <Form.Item label="Alternate Phone" name={[name, "alter_phone"]} {...restField}>
                                                                                <Input disabled={!check} autoComplete="new-password" />
                                                                            </Form.Item>
                                                                            <Form.Item label="Address 2" name={[name, "address2"]} {...restField}>
                                                                                <Input disabled={!dashboardDiable} autoComplete="new-password" />
                                                                            </Form.Item>
                                                                            <Form.Item label="Latitude" name={[name, "latitude"]} rules={[{ required: true }]} {...restField} >
                                                                                <Input disabled={!dashboardDiable} />
                                                                            </Form.Item>
                                                                            <Form.Item label="Longitude" name={[name, "longitude"]} rules={[{ required: true }]} {...restField} >
                                                                                <Input disabled={!dashboardDiable} />
                                                                            </Form.Item>

                                                                            {
                                                                                (props.page !== "dashboard" ?
                                                                                    <>
                                                                                        {(props.RegistrationFormData.PanVerification.registration_type == "D"
                                                                                            && props.RegistrationFormData.PanVerification.vehicle_make == "ROYAL ENFIELD") ?
                                                                                            <>
                                                                                                <Form.Item label="Dealership week off:" name={[name, "DealershipWeek"]}
                                                                                                    rules={[{ required: true }]} {...restField} >
                                                                                                    <Select
                                                                                                        disabled={!dashboardDiable}

                                                                                                        onChange={value => setDay(value)}
                                                                                                    >
                                                                                                        {
                                                                                                            WeekList.map((item) => (
                                                                                                                <Select.Option key={item.value} value={item.day}>{item.day}</Select.Option>
                                                                                                            ))
                                                                                                        }
                                                                                                    </Select>
                                                                                                </Form.Item>

                                                                                                <Form.Item className="BranchStyle" label="Branch/Dealer Code:"
                                                                                                    name={[name, "BranchCode"]} rules={[{ required: true }, {
                                                                                                        pattern: /^[a-z0-9]+$/i,

                                                                                                        message: "Please enter valid BranchCode",
                                                                                                    }]}  {...restField} >
                                                                                                    <Input disabled={!dashboardDiable} />
                                                                                                </Form.Item>
                                                                                            </> : ''}
                                                                                    </> : '')
                                                                            }

                                                                            {
                                                                                (props.page == "dashboard" ?
                                                                                    <>
                                                                                        {(props.Locations.reg_type == "D" && props.Locations.vehicle_make == "ROYAL ENFIELD"
                                                                                            && props.page == "dashboard") ?
                                                                                            <>
                                                                                                <Form.Item label="Dealership week off:" name={[name, "DealershipWeek"]}
                                                                                                    rules={[{ required: true }]} {...restField} >
                                                                                                    <Select
                                                                                                        disabled={!dashboardDiable}

                                                                                                        onChange={value => setDay(value)}
                                                                                                    >
                                                                                                        {
                                                                                                            WeekList.map((item) => (
                                                                                                                <Select.Option key={item.value} value={item.day}>{item.day}</Select.Option>
                                                                                                            ))
                                                                                                        }
                                                                                                    </Select>
                                                                                                </Form.Item>

                                                                                                <Form.Item className="BranchStyle" label="Branch/Dealer Code:"
                                                                                                    name={[name, "BranchCode"]} rules={[{ required: true }, {
                                                                                                        pattern: /^[a-z0-9]+$/i,

                                                                                                        message: "Please enter valid BranchCode",
                                                                                                    }]}  {...restField} >
                                                                                                    <Input disabled={!dashboardDiable} />
                                                                                                </Form.Item>
                                                                                            </> : ''}
                                                                                    </> : '')
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="table-responsive">
                                                                        <Tabs defaultActiveKey={name + '1'} className="ProfileTable">
                                                                            <TabPane tab="Repair on the Spot-ROS" key={name + '1'}>
                                                                                <RepairOnSpot setIsEditable={setIsAnyRowEditable} key={`${name - +'RRP'}`}
                                                                                    type="RRP" servicesList={servicesList}
                                                                                    locaIndex={name} presetServices={servicesList}
                                                                                    ServiceList={showFTSPServieList === true ? RosFTSPServiceList : RosServiceList} form={form}
                                                                                    setServicesData={(values: any) => setServicesData(name, 'RRP', values)}
                                                                                    page={props.page}
                                                                                    deleteService={props.DeleteService}
                                                                                >
                                                                                </RepairOnSpot>
                                                                            </TabPane>
                                                                            {(showFTSPServieList === false ?
                                                                                <>
                                                                                    <TabPane tab="Towing Services" key={name + '2'}>
                                                                                        <RepairOnSpot setIsEditable={setIsAnyRowEditable} key={`${name - +'TOW'}`}
                                                                                            type="TOW" servicesList={servicesList} locaIndex={name}
                                                                                            presetServices={servicesList} ServiceList={TowingServiceList} form={form}
                                                                                            setServicesData={(values: any) => setServicesData(name, 'TOW', values)}
                                                                                            page={props.page}
                                                                                            deleteService={props.DeleteService}
                                                                                        >
                                                                                        </RepairOnSpot>
                                                                                    </TabPane>
                                                                                    <TabPane tab="CV ROS Services" key={name + '3'}>
                                                                                        <RepairOnSpot setIsEditable={setIsAnyRowEditable}
                                                                                            key={`${name - +'CVRRP'}`} type="CVRRP" servicesList={servicesList}
                                                                                            locaIndex={name} presetServices={servicesList}
                                                                                            ServiceList={CVRosServiceList} form={form}
                                                                                            setServicesData={(values: any) => setServicesData(name, 'CVRRP', values)}
                                                                                            page={props.page}
                                                                                            deleteService={props.DeleteService}
                                                                                        >
                                                                                        </RepairOnSpot>
                                                                                    </TabPane>
                                                                                    <TabPane tab="CV Towing Services" key={name + '4'}>
                                                                                        <RepairOnSpot setIsEditable={setIsAnyRowEditable} key={`${name - +'CVTOW'}`}
                                                                                            type="CVTOW" servicesList={servicesList}
                                                                                            locaIndex={name} presetServices={servicesList}
                                                                                            ServiceList={CVTowingServiceList} form={form}
                                                                                            setServicesData={(values: any) => setServicesData(name, 'CVTOW', values)}
                                                                                            page={props.page}
                                                                                            deleteService={props.DeleteService}
                                                                                        >
                                                                                        </RepairOnSpot>
                                                                                    </TabPane>
                                                                                    <TabPane tab="Custody" key={name + '5'}>
                                                                                        <RepairOnSpot setIsEditable={setIsAnyRowEditable} key={`${name - +'Custody'}`}
                                                                                            type="Custody" servicesList={servicesList}
                                                                                            locaIndex={name} presetServices={servicesList}
                                                                                            ServiceList={CustodyList} form={form}
                                                                                            setServicesData={(values: any) => setServicesData(name, 'Custody', values)}
                                                                                            page={props.page}
                                                                                            deleteService={props.DeleteService}
                                                                                        >
                                                                                        </RepairOnSpot>
                                                                                    </TabPane>
                                                                                </>
                                                                                : ''
                                                                            )}
                                                                        </Tabs>
                                                                    </div>
                                                                </Row>
                                                            </Card>
                                                        </Panel>
                                                    ))
                                                }
                                            </Collapse>
                                            <Row style={{ marginTop: "10px" }} justify="end" >
                                                <Form.Item wrapperCol={{ span: 24 }}>
                                                    {
                                                        props.page === "dashboard" ? '' :
                                                            <Button className="add-more-btn upload-btn"
                                                                type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                Add More Locations
                                                            </Button>
                                                    }

                                                </Form.Item>
                                            </Row>
                                        </>
                                    )}
                                </Form.List>
                                <Row style={{ marginTop: "20px" }} gutter={[24, 24]} align="middle" justify="end" >
                                    <Col>
                                        <Form.Item >
                                            {props.page === "dashboard" ? '' : <Button onClick={() => props.NavigatePrevious()}
                                                type="primary" className="ant-btn-prev">Previous</Button>}

                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        {/* <Form.Item > */}

                                        {/* <Button onClick={() => onFinishNextStep()} type="primary">Next</Button> */}
                                        {/* <Button htmlType="submit" type="primary">Next</Button>
                                    </Form.Item> */}

                                        <Form.Item >
                                            {props.page === "dashboard" ? '' :
                                                <Button htmlType="submit" type="primary" className="ant-btn-login"
                                                    disabled={isAnyRowEditable}>Next</Button>
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </Card>
                </Col>
            </Row>
        </div >
    );
}
