import { Card } from "antd";
import React, { useEffect, useState } from 'react';
import storage from '../../../../utils/storage';
import { ASP_DASHBOARD_URL,API_URL } from "../../../../config";
import { getVendorProfiles } from "../../../../services/dashboard.services";
import { UserContext, UserContextType } from "../../../../providers/UserContext";
import { useContext } from "react";



export const AspDashboardComponent =  () => {
    const [username, setUsername] = useState(null);
    const { User, SaveUser } = useContext(UserContext) as UserContextType;

    const antLayoutContent = {
        margin: "80px 15px 0px",
        height:"100vh"
    }

    const heightstyle = {
        height: "100vh"
    }
       
    useEffect(() => {
        SaveUser({ ...User, token: "aaa" })

        const fetchData = async () => {
            let vendorId = storage.getValue("vendorId");
            let resp : any= await getVendorProfiles(vendorId);
            if (resp && resp.message) {
                setUsername(resp.message.user_name);
                storage.setValue('phoneNo', resp.message.auth_signatory.mobile_number)
                storage.setValue('EAI_Employee', resp.message.EAI_Employee)
                storage.setValue('reg_type', resp.message.reg_type)
            }
        };

        fetchData();
    }, []);


    console.log("ASP_DASHBOARD_URL",ASP_DASHBOARD_URL)
    var url = ASP_DASHBOARD_URL + "VendorDashboardAuth?userName=" + username

    return (
        <div style={antLayoutContent}>
            <Card className="ant-content m-b-20" style={{ width: '100%', height: '100vh' }}>
                {/* <h1>In ASP Dashboard</h1> */}
                <div style={heightstyle}>
                <iframe
                    title="External Content"
                    width="100%"
                    height="100vh"
                    style = {{height: "100vh", border: "none"}}
                    src={url}
                ></iframe>
                </div>
            </Card>
        </div>

    )
}