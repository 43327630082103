import * as React from 'react';
import useState from 'react';

interface UserContextInterface {
    details: any;
    token: string;
    isLoggedIn: boolean;
    title: string
}

export type UserContextType = {
    User: UserContextInterface;
    SaveUser: (user: any) => void;
};


export const UserContext = React.createContext<UserContextType | null>(null);

const UserProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [User, setUserDetails] = React.useState<UserContextInterface>(
        {
            details: {},
            token: '',
            isLoggedIn: false,
            title: 'E-Z AUTO'
        }
    );
    const SaveUser = (user: any) => {
        setUserDetails({ ...user });
    };
    return <UserContext.Provider value={{ User, SaveUser }}>{children}</UserContext.Provider>;
};

export default UserProvider;
