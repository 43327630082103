import { useState, useEffect } from "react"
import { Modal, Button } from "../../../components/Elements"
import { updateMSLAcceptanceServices } from "../../../services/shared.service";

export const DocumentCheckListPopUp = (props: any) => {
    const [visible, setVisible] = useState(false)
    const onclick = () => {
        props.sendDataToParent(false)
        setVisible(false)

    }
    useEffect(() => {
        if (props.display === true) {
            console.log("inside pop up usestate")
            setVisible(true)
        }
    })
    return (
        <Modal
            title={'Check list of documents for registration'}
            style={{ top: 90 }}
            visible={visible}
            onCancel={onclick}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <p>
                <strong>1. PAN [it will be validated online in NSDL site] </strong>
            </p>
            <p>
                <strong>2. Aadhar ID [it will be used for validation of the agreement / contract / changes
                    / addendum/etc] </strong>
            </p>
            <p>
                <strong>3. ID Proof of Authorised Signatory/Director/Partner/Proprietor </strong>
                <div style={{ paddingLeft: '20px' }}>
                    <p style={{ paddingTop: '10px' }}>i. Aadhar Card</p>
                    <p> ii. PAN Card or </p>
                    <p>iii. Driving License Issued in the state where the
                        shop / factory is established or </p>
                    <p>iv. Voter ID card if issued in the state where the shop/factory is established</p>

                </div>
            </p>

            <p>
                <strong>4. Cancelled Cheque </strong>
            </p>
            <p>
                <strong>5. Contact details </strong>
                <div style={{ paddingLeft: '20px' }}>
                    <p style={{ paddingTop: '10px' }}>i. Valid Email ID</p>
                    <p>ii. Phone no (it should be registered in the name of the vendor
                        only, all communications will be sent to this no. only) [ for contact no change the
                        vendor/dealer has to provide the copy of new post
                        paid bill or prepaid no allotment email / letter]
                    </p>

                </div>
            </p>
            <p>
                <strong>6. GST registration certificate(if Registered)</strong>

            </p>
            <p>
                <strong>7. Establishment license </strong>
                <div style={{ paddingLeft: '20px' }}>
                    <p style={{ paddingTop: '10px' }}>i. Shops and Establishment License or </p>
                    <p> ii. Factory Registration Certificate</p>
                </div>
            </p>
            <p>
                <strong>8.Address Proof </strong>
                <div style={{ paddingLeft: '20px' }}>
                    <p style={{ paddingTop: '10px' }}>i. Electricity Bill or</p>
                    <p>ii. Telephone Bill or</p>
                    <p>iii. Mobile Bill (Postpaid) and</p>
                    <p>iv. Leave and License Agreement</p>
                </div>
            </p>

        </Modal>
    )
}