import { Modal } from '../../../components/Elements'
//import ReactPlayer from 'react-player';
import ReactPlayer from 'react-player';
import { useState, useEffect } from 'react';

export const VideoPopup = (props: any) => {
    console.log("In VIDEO")
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (props.display === true) {
            console.log("inside pop up usestate")
            setVisible(true)
        }
    }, [props.display])

    const onClick = () => {
        setVisible(false)
        props.sendDataTovideoPop(false)
    }
    return (
        <Modal
            title="Video Assistance"
            centered
            visible={visible}
            onCancel={onClick}
            // width={1000}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}


        >
            <ReactPlayer
                className='react-player fixed-bottom'
                url='https://eza2.softforceapps.com:8089 /images/VIDEOASSIST.mp4'
                width='100%'
                // height='100%'
                controls={true}
            />
        </Modal>
    )
}