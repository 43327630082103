const storagePrefix = 'ez_';
const storage = {
    setToken: (value: any) => {
        sessionStorage.setItem(`${storagePrefix}token`, value);
    },
    getToken: () => {
        return sessionStorage.getItem(`${storagePrefix}token`);
    },

    getVendorId: () => {
        return sessionStorage.getItem(`${storagePrefix}_vendorId`);
    },
    setVendorId: (value: any) => {
        sessionStorage.setItem(`${storagePrefix}_vendorId`, value);
    },
    setValue: (key: string, value: any) => {
        sessionStorage.setItem(`${storagePrefix}` + '_' + key, value);
    },

    getValue: (key: string) => {
        return sessionStorage.getItem(`${storagePrefix}` + '_' + key);
    },

    clearSession: () => {
        sessionStorage.clear();
    }
}
export default storage;