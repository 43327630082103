import { SSO_AUTHENTICATION } from "../modules/SSO Auth/api"

const API_LIST = {
    //type: POST
    // payload : {"panNumber":"CEVPB0362K","Type":"FTSP"}

    VERIFY_PAN: 'api/vendor-reg_v2/verifyPan',

    //{"mobileNumber":"9515193569"}
    VERIFY_PHONE: 'api/vendor-reg_v2/verifyOtp',

    //{"email":"satishgoud07@gmail.com","phoneNumber":"9515193569"}
    VERIFY_EMAIL: 'api/vendor-reg_v2/sendOtpForEmailVerification_v2',

    VERIFY_EMAIL_OTP: 'api/vendor-reg_v2/verifyEmailOtp',


    VERIFY_OTP: 'api/login/getOtp',

    GETDATA: 'api/login/getDataforFirstLogin',

    UPDATEDATA : 'api/login/updateDataforFirstLogin',

    //{"userName":"FNZPK3950E","password":"Pass1234","channel":"PLS","networkDetails":{"ip":"157.47.92.176","city":"Hyderabad","region":"Telangana","country":"IN","loc":"17.3840,78.4564","org":"AS55836 Reliance Jio Infocomm Limited","postal":"500001","timezone":"Asia/Kolkata"}}
    LOGIN: 'api/login/login',
    // {"vendorId":"61791a34ef0a5f1786fb0b58"}
    CHECK_VENDOR_REG_STATUS: 'api/vendor_v2/getVendorRegStatus',

    //{"userName":"FNZPK3950E","sId":"RAhqetbl4apOeEBtEAIhH9t2TtKAI7t2"}
    LOGOUT: 'api/common/logout',

    // {"vendor_id":"61791a34ef0a5f1786fb0b58"}
    UPDATE_TERMS_CONDITIONS: 'api/vendor-reg_v2/updateTermsAndConditionsStatus',


    // {"basicDetails":{"EAI_vendor_Id":"","reg_status":"Personal Details","PAN_number":"CEVPB0362K","cell_phone":"9515193569","DOB":"01-Mar-2022","surname":"BANDA","middle_name":"Test Satish","first_name":"SATISH","entityName":"Test","org_name":"","reg_type":"V","vehicle_make":"","dealerId":"","regPassword":"123456789","vendor_email":"satishgoud07@gmail.com","vendLocations":"","country":"India","EAI_Employee":false,"auth_signatory":{"name":"Satish","PAN_number":"CEVPB0362K","Aadhar_number":"147258369322","mobile_number":"9515193569","Designation":"CEO","DOB":"01-Mar-2022"},"shop_act_registration_num":"","factory_registration":"","service_tax_registration_num":"","id_proof":"","bank_account":{"account_number":"","beneficiary_name":"","bank_name":"","branch_addr":"","branch_name":"","IFSC_code":"","micr":""},"Invoice_payment_flag":"FTSP","userName":"CEVPB0362K"}}
    ADD_VENDOR: "api/vendor-reg_v2/addVendor",

    //{"userName":"62275e34d1b4ba5a7de1bdf1"}
    GET_VENDOR_DOC: "api/vendor_v2/getVendorDocument",

    GET_VEHICLE_LIST: "api/vendor-reg_v2/getVehicleMakeList",

    GETS_STATE_LIST: "api/vendor-reg_v2/getAllStatesList",

    // {"ifsc":"ICIC0004166"}
    GET_BANK_DETAILS_BY_IFSC: "api/vendor-reg_v2/getIfsc",

    GET_GST_CODES: "api/vendor-reg_v2/getGSTstateCodes",

    // vendorId: 622dd802d1b4ba5a7df0b520
    // photo: (binary)
    // category: legalEntity/   
    //sub : PAN/GST/idProof/bankDocument/licenceDocument       
    // category: authSignatory/   
    //sub : authPAN/Adhaar
    UPLOAD_DOCUMENT: "api/vendor-reg_v2/uploadVendorSingleDoc",
    // {"vendorId":"622dd802d1b4ba5a7df0b520"}
    GET_VENDOR_DOCUMENT: "api/vendorDocuments/getVendorDocuments",
    //{"vendorId":"622dd802d1b4ba5a7df0b520"}
    SUBMIT_VEDNOR: "api/vendor-reg_v2/vendorSubmit",
    UPLOAD_KYC_DOCUMENT: "/api/vendor_v2/uploadVendorKYCdoc",

    FILE_PREVIEW: "api/vendorDocuments/getVendorSingleDocument",
    DOWNLOAD_INVOICE: "api/vendor_v2/downloadInvoice",
    VERIFY_DEALER_ID: "api/vendor-reg_v2/verifyDealerId",
    KYC_VENDOR_DOC:"api/vendor_v2/getKycOrVendorDocument",

    SSO_AUTHENTICATION : "api/login/ssoauthentication",
    GET_VALIDATION_DATA : "api/vendorDocuments/getValidations",
    FORGOT_PASSWORD : "api/vendor-reg_v2/forgotPassword",
    MATCHED_OTP : "api/eaiExt/verifyOtp",
    SAVE_GEO_LOCATION : "api/common/savegoogleMapsLogs",
    VERIFY_VENDOR_GST : "api/vendor-reg_v2/verifyVendorGST"

}

export { API_LIST }