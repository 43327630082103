import './button.scss'
import { Table, Spin, Button, Card, Input, Space, SearchOutlined, Row, Col } from "../../../../components/Elements"
import { useContext, useEffect, useState } from "react";
import { dashboardService } from "../../../../services/dashboard.services";
import { getCase } from "../../../../services/shared.service"
import { ServicePopup } from '../../../Common/Popup/servicePopup';
import { CreditNotePopup } from "../../../Common/Popup/creditNotePopup";
import Highlighter from "react-highlight-words";
import storage from '../../../../utils/storage';
import { CardStyle } from '../../../../helpers/fromhelpercss';
import { UserContext, UserContextType } from '../../../../providers/UserContext';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { ResizeObserverFn } from '../../../../utils/resizeObserverUtil';


export const PendingCNComponent = () => {
  console.log("In PendingCN Component")
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [creditNoteData, SetCreditNoteData] = useState()
  const [popupData, setPopupData] = useState()
  const [popUp, setPopUp] = useState(false)
  const [creditNotePopUp, SetCreditNotePopUp] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const { User, SaveUser } = useContext(UserContext) as UserContextType;
  const contentRef = useRef<HTMLDivElement>(null); // Create a ref for the content element
  // Create a new ResizeObserver
  const resizeObserver = ResizeObserverFn();
  // Start observing the target element
  useEffect(() => {
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    return () => {
      resizeObserver.disconnect(); // Clean up the observer on unmount
    };
  }, [contentRef]);

  let vendorId: any;
  vendorId = storage.getValue("vendorId")
  const antLayoutContent = {
    margin: "80px 15px 0px"
  }

  const [searchText, setSearchText] = useState<any>()
  const [state, setState] = useState({ searchText: '', searchedColumn: '' })


  let searchInput: any;
  const getColumnSearchProps = (dataIndex: any) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }
      : { setSelectedKeys: any, selectedKeys: any, confirm: any, clearFilters: any }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Go
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText({})
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  };

  async function fetchData() {
    var resp: any = await dashboardService.pendingcnData(vendorId)
    setData(resp.message)
    setLoading(false)

  }

  useEffect(() => {
    SaveUser({ ...User, token: "aaa" })
    fetchData()
  }, [])
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      SetCreditNoteData(selectedRows)
    },
    onSelect: (record: any, selected: any, selectedRows: any) => {
      // console.log(record, selected, selectedRows);
      // console.log("in on select")
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      // console.log(selected, selectedRows, changeRows);
      // console.log("in Select all")
    },
  };

  const columns: any = [
    {
      title: 'Date',
      dataIndex: 'Date',
      align: 'center',
      ...getColumnSearchProps('Date')
    }, {
      title: 'Case ID',
      dataIndex: 'CaseId',
      align: 'center',
      ...getColumnSearchProps('CaseId')
    },
    {
      title: 'Service ID',
      dataIndex: 'ServiceId',
      align: 'center',
      ...getColumnSearchProps('ServiceId'),
      render: (text: string, params: any) => {
        async function onClick() {

          let resp: any = await getCase(params.ServiceId)
          setPopupData(resp.message)
          setPopUp(true)

        }
        return <a style={{ color: "#0c3b93", textDecoration: 'underline' }} onClick={onClick}>{text}</a>;
      },
    },
    {
      title: 'Service Type',
      dataIndex: 'ServiceType',
      align: 'center',
      ...getColumnSearchProps('ServiceType')
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      align: 'center',
      ...getColumnSearchProps('Amount')
    },
    {
      title: 'Kms',
      dataIndex: 'TotalKms',
      align: 'center',
      ...getColumnSearchProps('TotalKms')
    },
    {
      title: 'Service Status',
      dataIndex: 'ServiceStatus',
      align: 'center',
      ...getColumnSearchProps('ServiceStatus')
    },
    {
      title: 'Payment Type',
      dataIndex: 'TypeOfService',
      align: 'center',
      ...getColumnSearchProps('TypeOfService')
    }, {
      title: 'Pro_forma Invoice generated',
      dataIndex: 'isProformaInvoiceGenerated',
      align: 'center',
      ...getColumnSearchProps('isProformaInvoiceGenerated')
    }
  ];


  const rows: { key: number }[] = [];
  data.map((i: any, j: any) => {
    const obj = i
    obj.key = j + 1
    rows.push(obj)
    return rows
  })

  const sendDataToParent = (index: any) => {
    setPopUp(index)
  }

  const sendToCreditNotePopup = (index: any, refresh: any) => {
    console.log("INDEX", index)
    SetCreditNotePopUp(index)
    setRefresh(true)
    if (refresh == "refresh") {
      fetchData()
    } else if (refresh == "loading") {
      setLoading(true)
    }
  }

  const showPopup = () => {
    SetCreditNotePopUp(true)
  }

  const navigate = useNavigate();
  if (!User || !User.isLoggedIn) {
    navigate('/login');
    return null;
  } else {
    return (
      loading ?
        <div style={antLayoutContent} ref={contentRef}>
          <div className="example" style={{ textAlign: 'center' }}>
            <Card className="ant-content m-b-20">
              <Spin />
            </Card>
          </div>
        </div>
        :
        <>

          <div style={antLayoutContent}>
            <Card className="ant-content">
              <Row>
                <Col className='m-b-30 align-center' span={24}>
                  <Button className="btn button-10 ant-btn-generate pull-right" onClick={showPopup}>Generate Credit Note</Button>
                </Col>
              </Row>
              <Table
                columns={columns}
                dataSource={rows}
                bordered
                title={() => 'Pending Credit Note Table'}
                scroll={{ x: 1300 }}
                rowSelection={{ ...rowSelection, type: 'radio' }}
                sticky={true}
              />
            </Card>
            <div>
              {popUp && <ServicePopup display={true} sendDataToParent={sendDataToParent} data={popupData} />}
            </div>
            <div>
              {
                creditNotePopUp && <CreditNotePopup display={true} sendToCreditNotePopup={sendToCreditNotePopup} data={creditNoteData} />
              }
            </div>

          </div>
        </>


    )
  }
}