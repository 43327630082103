import { Button, Modal, Input, Checkbox } from '../..//../components/Elements'
import { useEffect, useState } from 'react'
import { Table } from '../../../components/Elements'
import { MobileOtp } from './mobileOtp'
import { verifyVendorInvoiceNumber } from '../../../services/shared.service'
import storage from '../../../utils/storage'
export const CreditNotePopup = (props: any) => {
    const [visible, setVisible] = useState(false)
    const [otpPopup, setOtpPopup] = useState(false)
    const [selectedOption, setSelectedOption] = useState<any>(false)
    const [invoiceNumber, setInvoiceNumber] = useState<any>()
    const [checked, setChecked] = useState(false)

    let vendorId: any;
    vendorId = storage.getValue("vendorId")
    useEffect(() => {
        console.log("POPUP", props.data)
        if (props.data === null || props.data === undefined) {
            alert("Please select atleast one service")
            props.sendToCreditNotePopup(false)
        }
        if (props.display === true && props.data) {
            console.log("inside pop up usestate")
            setVisible(true)
        }
    }, [props.display])

    const onClick = () => {
        setVisible(false)
        props.sendToCreditNotePopup(false)

    }

    const onGenerate = () => {
        setOtpPopup(true)
        // setVisible(true)
        // props.sendToCreditNotePopup(false)
    }
    const invoiceVerify = (event: any) => {
        setInvoiceNumber(event.target.value)
    }

    const check = (e: any) => {

        setChecked(e.target.checked)
    }

    const columns: any = [
        {
            title: 'Case ID',
            dataIndex: 'CaseId',
            align: 'center'
        }, {
            title: 'Service ID',
            dataIndex: 'ServiceId',
            align: 'center'
        }, {
            title: 'Amount',
            dataIndex: 'Amount',
            align: 'center'
        }
    ]

    const rows: {
        key: number
    }[] = [];
    if (props.data) {
        props.data.map((i: any, j: any) => {
            const obj = i
            obj.key = j + 1
            rows.push(obj)
            return rows
        })
    }
    const sendToMobileOtp = (index: any, refresh: any) => {
        setOtpPopup(index)
        props.sendToCreditNotePopup(index, refresh)
    }
    const verifyInv = async () => {
        let resp: any = await verifyVendorInvoiceNumber(invoiceNumber, vendorId)
        console.log(resp)
        if (resp.status == "success") {
            alert("Invoice number verified")
        }
    }

    console.log("DATA", props.data)
    return (<>{

        < div >
            <Modal title="Click YES to generate credit note" centered
                visible={visible}
                onCancel={onClick}
                width={1000}
                /* cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }} */
                footer={[
                    <Button className='ant-btn-view' key="submit" type="primary" disabled={!checked} onClick={onGenerate} >
                        Yes
                    </Button>,
                    <Button className='ant-btn-prev' key="back" onClick={onClick} >
                        Cancel
                    </Button>,
                ]}

            >
                <Table columns={columns}
                    dataSource={rows}
                    bordered
                    pagination={false} />
                {selectedOption == 'Yes' && <><Input onChange={invoiceVerify} /> <Button className="ant-btn-view m-bt-20 m-b-20 m-al-0" onClick={verifyInv}>Verify</Button></>}
                <Checkbox onChange={check}>
                    <p>
                        I hereby agree that I am the Authorised Signatory of Proprietorship / Partnership Firm / Company/ LLP (hereinafter referred as “Entity”) duly authorisedto digitally sign the invoices, and receive One Time Password (OTP) on my registered mobile number for business purpose on behalf of the said Entity.I hereby provide my consent for the above and to receive OTP on the said number to authenticate details on behalf of the Entity. I understand and agree that the OTP provided herein;
                        - shall be used only for the purpose of authenticating the identity of the Authorised Signatory on behalf of the said Entity through the Mobile based OTP system.,
                        - shall be used to avail my consent for digitally signing the invoices (using my Digital Signature) specific under this EZ-Auto/ Paperless arrangement with Europ Assistance India Private Limited (“EAI”)and not for any other purpose otherwise.
                        I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am aware that I may be held liable for it. I hereby authorize sharing of the information furnished herewith to be used for business purposeagreed herein.
                    </p>
                </Checkbox>

                <div> {
                    otpPopup && <MobileOtp display={true} number={"9819962386"} data={props.data} sendToMobileOtp={sendToMobileOtp} otpType={"creditNote"} />
                }
                </div>

            </Modal>
        </div>
    }
    </>

    )
}
