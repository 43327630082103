import { useContext, useEffect } from "react";
import { Card, Col, Layout, Row } from "../../../components/Elements";
import { UserContext, UserContextType } from "../../../providers/UserContext";
import { Layout as RegisterPanel } from "../../../widgets/Register/components";
import "./register.scss";

export const RegisterLayout = (props: any) => {
    const { Content } = Layout;
    let registerProps: any = {
        currentStep: 0,
        isNewUser: true,
    }
    const { User, SaveUser } = useContext(UserContext) as UserContextType;

    useEffect(() => {
        SaveUser({ ...User, details: {}, title: "RSA VENDOR REGISTRATION" })
    }, []);

    return (
        <Layout className="ezauto-main-layout">
            <Content>
                <Row className="ezauto-row">
                    <Col span={22}>
                        <Card className="ezauto-register-panel">
                            <div className="ez-auto-registration-container">
                                <RegisterPanel {...registerProps} />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Content>

        </Layout>

    );
}