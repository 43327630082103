import { Button, Modal, Table, Checkbox, Radio, Input, Row, Col } from '../..//../components/Elements'
import { useState, useEffect } from 'react'
import { verifyVendorInvoiceNumber } from '../../../services/shared.service'
import { MobileOtp } from './mobileOtp'
import storage from '../../../utils/storage'



export const InvoicePopup = (props: any) => {
    const [visible, setVisible] = useState(false)
    const [totalAmount, setTotalAmount] = useState()
    const [selectedOption, setSelectedOption] = useState<any>(false)
    const [invoiceNumber, setInvoiceNumber] = useState<any>()
    const [otpPopup, setOtpPopup] = useState(false)
    const [checked, setChecked] = useState(false)
    const [radio, setRadio] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");


    let vendorId: any;
    vendorId = storage.getValue("vendorId")



    let calculatedAmount: any = 0
    let calc: any = props.data
    calc.forEach(function (ele: any, index: any) {
        calculatedAmount = calculatedAmount + ele.totalAmount
        return calculatedAmount
    })
    console.log("Checked", checked)

    useEffect(() => {
        if (storage.getValue("gstNo") && storage.getValue("gstNo")?.length == 15) {
            setSelectedOption("Yes")
            setRadio(true)
        } else {
            setSelectedOption("No")
        }
        if (props.display === true) {
            console.log("inside pop up usestate")
            setVisible(true)
        }
    }, [props.display])
    const onClick = () => {
        setVisible(false)
        props.sendDataToInvoice(false)
    }


    const columns: any = [
        {
            title: 'Case ID',
            dataIndex: 'claimNumber',
            align: 'center'
        }, {
            title: 'Service ID',
            dataIndex: 'serviceNumber',
            align: 'center'
        }, {
            title: 'Amount',
            dataIndex: 'totalAmount',
            align: 'center'
        }
    ]

    const rows: {
        key: number
    }[] = [];
    if (props.data) {
        props.data.map((i: any, j: any) => {
            const obj = i
            obj.key = j + 1
            rows.push(obj)
            return rows
        })
    }

    const onValueChange = (e: any) => {
        setSelectedOption(e.target.value)
    }
    const invoiceVerify = (event: any) => {
        setInvoiceNumber(event.target.value)
    }

    const verifyInv = async () => {
        if (!invoiceNumber) {
            setErrorMessage("Please Enter Invoice Number");
            return;
          }
        let resp: any = await verifyVendorInvoiceNumber(invoiceNumber, vendorId)
        console.log(resp)
        if (resp.status == "success") {
            alert("Invoice number verified")
            setErrorMessage("");

        }else if(resp.status == "failure" && resp.message == "Invoice number already exists"){
            alert("Invoice number already exists")
        }
    }

    const sendToMobileOtp = (index: any, refresh: any) => {
        setOtpPopup(index)
        props.sendToInvoicePopup(index, refresh)
    }
    const onGenerate = () => {
        setOtpPopup(true)
    }
    const check = (e: any) => {

        setChecked(e.target.checked)
    }
    return (
        < div >
            <Modal title="Click YES to generate invoice" centered
                visible={visible}
                onCancel={onClick}
                width={1000}
                // onOk={onGenerate}
                footer={[
                    <Button className='ant-btn-view' key="submit" type="primary" disabled={!checked} onClick={onGenerate}>
                        Yes
                    </Button>,
                    <Button className='ant-btn-prev' key="back" onClick={onClick} >
                        Cancel
                    </Button>,
                ]}

            >
                <Table columns={columns}
                    dataSource={rows}
                    bordered
                    pagination={false} />
                <Row>
                    <Col className='m-bt-20' span={24}>
                        Do you want to enter your Invoice No:
                    </Col>
                    <Col className='m-bt-10 m-b-20' span={2}>
                        <input type="radio" value="Yes" checked={selectedOption === "Yes"} onChange={onValueChange} /> Yes
                    </Col>
                    <Col className='m-bt-10 m-b-20' span={2}>
                        <input type="radio" value="No" checked={selectedOption === "No"} disabled={radio} onChange={onValueChange} /> No
                    </Col>
                    <Col className='m-bt-10 m-b-20' span={4}>
                        Total Amount:{calculatedAmount}
                    </Col>
                </Row>

                {selectedOption == 'Yes' && <><Input onChange={invoiceVerify} />    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
 <Button className="ant-btn-view m-bt-20 m-b-20 m-al-0" onClick={verifyInv}>Verify</Button></>}

                <Checkbox onChange={check}> <p>I hereby agree that I am the Authorised Signatory of Proprietorship / Partnership Firm / Company/ LLP (hereinafter referred as “Entity”) duly authorisedto digitally sign the invoices, and receive One Time Password (OTP) on my registered mobile number for business purpose on behalf of the said Entity.I hereby provide my consent for the above and to receive OTP on the said number to authenticate details on behalf of the Entity. I understand and agree that the OTP provided herein;</p>
                    <p>- shall be used only for the purpose of authenticating the identity of the Authorised Signatory on behalf of the said Entity through the Mobile based OTP system.</p>
                    <p>- shall be used to avail my consent for digitally signing the invoices (using my Digital Signature) specific under this EZ-Auto/ Paperless arrangement with Europ Assistance India Private Limited (“EAI”)and not for any other purpose otherwise.</p>
                    <p>I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am aware that I may be held liable for it. I hereby authorize sharing of the information furnished herewith to be used for business purposeagreed herein.</p>

                </Checkbox>
                <div> {
                    otpPopup && <MobileOtp display={true} data={props.data} sendToMobileOtp={sendToMobileOtp} otpType={"Invoice"} invNumber={invoiceNumber} />
                }
                </div>
            </Modal>


        </div>
    )
}