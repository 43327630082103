import { useRef } from 'react';
import { ResizeObserverFn } from '../../../../utils/resizeObserverUtil';
import { Table, Spin, Card, Input, Space, SearchOutlined, Row, Col, CustomHeader, EyeOutlined, } from '../../../../components/Elements'
import { useContext, useEffect, useState } from 'react';
import axios from "axios";
import { Button, Modal } from 'antd';
import { dashboardService } from "../../../../services/dashboard.services";
import { getCase } from "../../../../services/shared.service"
import { ServicePopup } from '../../../Common/Popup/servicePopup';
import Highlighter from 'react-highlight-words';
import storage from '../../../../utils/storage';
import { CardStyle } from '../../../../helpers/fromhelpercss';
import { UserContext, UserContextType } from '../../../../providers/UserContext';
import { colors } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
// import Highlighter from 'react-highlight-words';

export const AlertComponent = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false);
  const [popupData, setPopupData] = useState<any>()
  const [dataSource, setDataSource] = useState(data);
  const [value, setValue] = useState('');
  const [images, setImages] = useState([])
  console.log("VISIBLE", visible)
  const [servicePopupData, setServicePopupData] = useState()
  const [popUp, setPopUp] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState<any>()
  const [searchText, setSearchText] = useState<any>()
  const [searchedColumn, setSearchedColumn] = useState<any>()
  const [state, setState] = useState({ searchText: '', searchedColumn: '' })
  const [skipParams, setSkipParams] = useState(0)
  const { User, SaveUser } = useContext(UserContext) as UserContextType;
  let url = process.env.REACT_APP_API_URL
  let rows: { key: number }[] = [];

  const contentRef = useRef<HTMLDivElement>(null); // Create a ref for the content element

  let vendorId: any;
  vendorId = storage.getValue("vendorId")
  const tokenAuth: any = storage.getToken();


  let searchInput: any;
  const getColumnSearchProps = (dataIndex: any) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }
      : { setSelectedKeys: any, selectedKeys: any, confirm: any, clearFilters: any }) => (<div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Go
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText({})
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  };
  async function fetchData() {
    // let VendorData:any
    // let user = localStorage.getItem(VendorData)
    // console.log("LOCAL USER",user)

    /*  var resp = await axios.post(
         `https://plspps.europassistance.in:8443/api/vendor_v2/getDisputeDetails`,
         {
           vendorId: vendorId,
           skipParam: 0,
         }
       ); */
    var resp = await dashboardService.alertsData(vendorId, skipParams)
    if (Array.isArray(resp.message) && resp.message.length > 0) {
      setData(resp.message);
      let dataRows = resp.message;
      rows = dataRows.map((item: any, index: number) => ({
        key: index + 1
      }));
    }

    setLoading(false)

  }

  useEffect(() => {
    SaveUser({ ...User, token: "aaa" })
    // var resp = dispatch(alertActions.Alert('5b8f9d6a8eaa5c3b3ca8783d'))
    fetchData()
  }, [vendorId, skipParams])

  // Create a new ResizeObserver
  const resizeObserver = ResizeObserverFn();
  // Start observing the target element
  useEffect(() => {
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    return () => {
      resizeObserver.disconnect(); // Clean up the observer on unmount
    };
  }, [contentRef]);


  let columns: any = [
    // { title: 'id', dataIndex: 'key' },
    {
      title: 'Case ID',
      dataIndex: 'claimId',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('case Id')
    },
    {
      title: 'Service ID',
      className: 'serviceId',
      dataIndex: 'serviceId',
      align: 'center',
      width: '100px',

      ...getColumnSearchProps('serviceId'),
      render: (text: string, params: any) => {
        async function onClick() {
          let resp: any = await getCase(params.serviceId)

          setServicePopupData(resp.message)
          setPopUp(true)

        }
        return <a className='fnt-weight' style={{ color: "#0c3b93", textDecoration: 'underline' }} onClick={onClick}>{text}</a>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
      width: '100px',

      ...getColumnSearchProps('type')
    },
    {
      title: 'Amount',
      dataIndex: 'disputeAmount',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('disputeAmount'),
    },
    {
      title: 'Status',
      dataIndex: 'disputeStatus',
      align: 'center',
      width: '100px',

      ...getColumnSearchProps('disputeStatus'),
    },
    {
      title: 'Reason',
      dataIndex: 'disputeReason',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('disputeReason')
    },
    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      width: '100px',
      render: (params: any) => {
        const onClick = (e: any) => {
          // var popData = JSON.stringify(params.row)
          // return alert(JSON.stringify(data))
          console.log("params", params)
          setPopupData(params)
          setVisible(true)

        }
        // return <Button className='ant-btn-view' onClick={onClick} style={{ color: '#0c3b93' }}>View1</Button>;
        return <EyeOutlined onClick={onClick} style={{ color: 'blue' }} />
      },
    }
  ];

  console.log("POPUPDATA", popupData)



  const popupColumns = [

    {
      title: 'Reasons',
      dataIndex: 'disputeReason',

    },
    {
      title: 'Initial Amount',
      dataIndex: 'disputeAmount',
    },
    {
      title: 'Amount',
      dataIndex: 'itemsCurrentDisputeAmount',
    },
    {
      title: 'Action',
      key: 'operation',
      render: (params: any) => {
        const onClick = (e: any) => {
          // var popData = JSON.stringify(params.row)
          // return alert(JSON.stringify(data))                 
          console.log("PArams", params.documents_ref)

          async function fetchimage() {
            // var resp = await dashboardService.getVendorDisputeDocs(params.documents_ref)
            // console.log("data", resp)
            let imageBlob: any
            try {
              imageBlob = (await axios.post(
                `${url}api/vendor_v2/getVendorDisputeDocument`,
                {
                  documentId: params.documents_ref,

                }, {
                headers: {
                  'Authorization': tokenAuth // Example authorization header
                  // Add more headers as needed
                }, responseType: 'blob' as 'json'
              }
              )).data
              console.log("IMAGE", imageBlob)
              let fileURL: any = URL.createObjectURL(imageBlob);
              window.open(fileURL);


            } catch (err) {
              return null
            }
            //   return window.URL.createObjectURL(imageBlob)
          }
          fetchimage()
        }
        return <EyeOutlined className='ant-btn-view' onClick={onClick} style={{ color: '#0c3b93' }} />
      },
    }
  ];

  const popupRows: { key: number }[] = [];
  if (popupData) {
    popupData.Reasons.map((k: any, l: any) => {
      const obj: any = k
      obj.key = l + 1
      popupRows.push(obj)
      return popupRows
    })
  }

  const sendDataToParent = (index: any) => {
    console.log("INDEZ", index)
    setPopUp(index)
  }

  const Onnext = () => {
    let data: any = skipParams + 100
    setSkipParams(data)
  }
  const Onback = () => {
    if (skipParams != 0) {
      let data: any = skipParams - 100
      setSkipParams(data)
    }

  }

  const antLayoutContent = {
    margin: "80px 15px 0px"
  }

  console.log("IMAGE", images)

  const navigate = useNavigate();
  if (!User || !User.isLoggedIn) {
    navigate('/login');
    return null;
  } else {

    return (
      loading ?
        <div ref={contentRef} style={antLayoutContent}>
          <Card className="ant-content">
            <div className="example" style={{ textAlign: 'center' }} >
              <Spin />
            </div>
          </Card>
        </div> :
        <div className='AlertComponentWidth' style={antLayoutContent}>
          <Card className="ant-content">
            <div className='table-responsive'>
              < CustomHeader className="tableHeader" title={"Your registration has been approved."} />
              <Col span={10}></Col>
              <Table
                columns={columns}
                dataSource={rows}
                bordered
                title={() => 'Dispute Alerts'}
                sticky={true}
                scroll={{ x: "max-content" }}
              />
            </div>
            <Row style={{ width: "100%" }}>
              <Col span={20}></Col>
              <Col className='m-b-30 col-pad-0' span={4} style={{ display: "flex", justifyContent: "end" }}>
                <Button className='PrevBtn' onClick={Onback}>Prev</Button>
                <Button className='NextBtn' onClick={Onnext}>Next</Button>
              </Col>
            </Row>
            <div>

              {popupData && <Modal
                title="Dispute Details"
                centered
                className='AlertModal'
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={1000}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
              >
                <Row>
                  <Col className='col-pad' span={12}>
                    <label className='label-title'>Case ID :</label>
                    <Input className='inputs-mrg' value={popupData.claimId} style={{ color: '#0c3b93' }} disabled />

                  </Col>
                  <Col className='col-pad' span={12}>
                    <label className='label-title'>Service ID :</label>
                    <Input className='inputs-mrg' value={popupData.serviceId} style={{ color: '#0c3b93' }} disabled />

                  </Col>
                  <Col className='col-pad' span={12}>
                    <label className='label-title'>Total Distance :</label>
                    <Input className='inputs-mrg' value={popupData.estDistance} style={{ color: '#0c3b93' }} disabled />

                  </Col>
                  <Col className='col-pad' span={12}>
                    <label className='label-title'>Actual Amount :</label>
                    <Input className='inputs-mrg' value={popupData.estAmount} style={{ color: '#0c3b93' }} disabled />

                  </Col>
                </Row>

                <Row>
                  <Col className='col-pad' span={24}>

                    <label className='label-title'>Dispute Reasons</label>

                    <div className='inputs-mrg'>
                      <Table
                        columns={popupColumns}
                        dataSource={popupRows}
                        bordered
                        pagination={false}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className='col-pad' span={12}>
                    <label className='label-title'>Total Dispute Amount :</label>
                    <Input className='inputs-mrg' value={popupData.disputeAmount} style={{ color: '#0c3b93' }} disabled />

                  </Col>
                  <Col className='col-pad' span={12}>
                    <label className='label-title'>Current Dispute Amount :</label>
                    <Input className='inputs-mrg' value={popupData.disputeAmount} style={{ color: '#0c3b93' }} disabled />

                  </Col>
                  <Col className='col-pad' span={12}>
                    <label className='label-title'>Total Amount :</label>
                    <Input className='inputs-mrg' value={popupData.disputeAmount + popupData.estAmount} style={{ color: '#0c3b93' }} disabled />

                  </Col>
                  <Col className='col-pad' span={12}>
                    <label className='label-title'>Reason for Reject :</label>
                    <Input className='inputs-mrg' value={popupData.disputeComments} style={{ color: '#0c3b93' }} disabled />

                  </Col>
                </Row>

              </Modal>
              }
            </div>

            <div>
              {popUp && <ServicePopup display={true} sendDataToParent={sendDataToParent} data={servicePopupData} />}
            </div>
          </Card>
          <div className='ButtomMargin'>

          </div>
        </div>
    );
  }
}