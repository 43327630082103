import { useState, useEffect } from "react"
import { Modal, Button } from "../../../components/Elements"

export const TermsAndCondPopUp = (props: any) => {
    const [visible, setVisible] = useState(false)
    const onclick = () => {
        props.sendDataToParent(false)
        setVisible(false)

    }
    useEffect(() => {
        if (props.display === true) {
            console.log("inside pop up usestate")
            setVisible(true)
        }
    })
    return (
        <Modal
            title={'Terms And Conditions for EzAuto for Authorised Service Provider (ASP) and Dealers'}
            style={{ top: 80 }}
            visible={visible}
            onCancel={onclick}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <>
                <p>
                    <strong>TERMS OF USE & SERVICE:</strong>
                    <div>
                        Terms of Use & Service needs to be read, understood & agreed by ASP before initiating any kind of transaction with Europ Assistance India Private Limited (hereinafter referred to as “EAI” or the Company). When the ASP accesses, browses or uses EzAuto/ E-portal, ASP accepts, without limitation or qualification, the terms and conditions set forth herein below. These Terms of use constitutes the entire agreement between EAI and the ASP with respect to use of this EzAuto/ E-portal. In the case of any violation of these terms and conditions, EAI reserves its rights to seek all remedies available in law and in equity for such violations
                    </div>
                </p>
                <p>
                    <strong>OWNERSHIP:</strong>
                    <div>
                        All materials on this EzAuto/ E-portal, including but not limited to audio, images, software, text, icons and such like (the “Content”), are protected by applicable copyright and other copyright laws. ASP cannot use the Content, except as specified herein. The ASP agrees to follow all instructions on this EzAuto/ E-portal limiting the way the ASP may use the Content. There are number of proprietary logos, service marks and trademarks displayed on this EzAuto/ E-portal whether owned/used by EAI or otherwise. By displaying them on this EzAuto/ E-portal, EAI is not granting to ASP any license to utilize those proprietary logos, service marks, or trademarks, in any manner whatsoever. Any unauthorized use of the Content may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statutes
                    </div>
                </p>
                <p>
                    <strong>MODIFICATIONS:</strong>
                    <div>
                        EAI may add to, change or remove any part of these terms and conditions at any time, without notice. Any changes to these terms and conditions or any terms posted on this EzAuto/ E-portal will become applicable as soon as they are posted. By continuing to use this EzAuto/ E-portal after any changes, the ASP is indicating the acceptance of those changes. EAI may add, change, discontinue, remove or suspend any other Content posted on this EzAuto/ E-portal, including features and specifications, process flow depicted on the EzAuto/ E-portal, temporarily or permanently, at any time, without notice and without any liability. EAI reserves the right to undertake all necessary steps to ensure that the security, safety and integrity of EAI systems remain, well – protected. Towards this end, EAI may take various steps to verify and confirm the authenticity, enforce-ability and validity of the transactions committed by the ASP
                    </div>
                </p>
                <p>
                    <strong>ASP'S OBLIGATIONS:</strong>
                    <div>
                        a) General Obligations: The ASP shall access the site only for lawful and specific purpose(s) for which he/she has opted for and ASP shall be responsible for complying with all applicable laws, statutes and regulations in connection with the use of this EzAuto/ E-portal. The ASP shall not modify copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any information, products or services obtained from this EzAuto/ E-portal. ASP shall not create a hypertext link to the EzAuto/ E-portal or "frame" the EzAuto/ E-portal.<br />
                        b) Information Provided: The information provided by the ASP in the Registration page must be complete and accurate. EAI reserves the right at all times to disclose any information as EAI deems fit & necessary to comply with any applicable law, regulation, legal process, or Central / State Governmental request.<br />
                        c) Use of the Services: ASP may only use this service to make legitimate transaction. Without limitation, any speculative, false or fraudulent transaction is prohibited. The services provided by the EzAuto/ E-portal are opted by the ASP for his/her convenience.<br />
                        d) ASP Responsibility: The ASP confirms to us that ASP is 18 years of age or above and has the legal capacity to enter into this Agreement with us and that all information provided by the ASP in respect of use of the service is true and accurate to the best of ASP's knowledge and belief. ASP is responsible for the security of the password and for all transactions undertaken using his/her password through our service. The ASP confirms that ASP is the authorized holder of the original account used in the transactions and ASP alone uses the "id" and "password" created by the ASP for the EzAuto/ E-portal. EAI or any of its representative, agent, consultant, sub-contractor etc. will not be responsible for any financial loss, inconvenience or mental agony resulting from use or misuse of ASP ID/password/credit card details/Debit card details/bank account details for making transactions on / from this EzAuto/ E-portal.<br />
                        e) Disclaimer: The material displayed on this EzAuto/ E-portal could include technical inaccuracies or typographical errors. EAI or any of its representative, consultant, sub-contractor etc may make changes or improvements at any time to rectify or make improvements in the contents of the EzAuto/ E-portal.
                        f) EAI does not warrant that the functions contained in this EzAuto/ E-portal will be uninterruptedor error free. Error or defects may arise during the normal usage of the EzAuto/ E-portal, that errors/defects will be corrected and make it free of viruses or other harmful components, but shall endeavor to ensure your fullest satisfaction.<br />
                        g) EAI does not warrant or make any representations regarding the use of or the result of the use of the material on this EzAuto/ E-portal in terms of their correctness, accuracy, reliability, or otherwise, insofar as such material is derived from us.<br />
                        h) ASP acknowledges that access to this EzAuto/ E-portal is provided only on the basis set out in these terms and conditions. ASP's uninterrupted access or use of this EzAuto/ E-portal on this basis may be prevented by certain factors outside our reasonable control including, without limitation, the unavailability, inoperability or interruption of the Internet or other telecommunications services or as a result of any maintenance or other service work carried out on this EzAuto/ E-portal. EAI does not accept any responsibility and will not be liable for any loss or damage whatsoever arising out of or in connection with any ability/inability to access or to use the EzAuto/ E-portal.<br />
                    </div>
                </p>
                <p>
                    <strong>FORCE MAJEURE:</strong>
                    <div>
                        EAI shall not be liable for delays or inabilities in performance or non performance in whole or in part of its obligations due to any causes that are not due to its acts or omissions or are beyond its reasonable control, such as acts of God, fire, strikes, embargo, acts of government/any governmental or statutory authority including NPCI/RBI, acts of terrorism or other similar cause.
                    </div>
                </p>
                <p>
                    <strong>RELATIONSHIP:</strong>
                    <div>
                        None of the provisions of any agreement, terms and conditions, notices, or the right to use this EzAuto/ E-portal by the ASP contained herein or any other section or pages of this EzAuto/ E-portal, shall be deemed to constitute a partnership between the ASP and EAI and no party shall have any authority to bind or shall be deemed to be the agent of the other in any way.
                    </div>
                </p>
                <p>
                    <strong>JURISDICTION:</strong>
                    <div>
                        EAI hereby expressly disclaims any implied warranties imputed by the laws of any jurisdiction or country other than those where it is operating its offices. EAI considers itself and intends to be subject to the jurisdiction only of the courts of Mumbai.
                    </div>
                </p>
                <p>
                    <strong>LIMITATION OF LIABILITY:</strong>
                    <div>
                        Notwithstanding anything to the contrary contained herein, neither EAI nor its affiliated companies, subsidiaries, officers, directors, employees or any related party shall have any liability to ASPs or to any third party for any indirect, incidental, special or consequential damages or any loss of revenue or profits arising under or relating to these terms, the site or the offerings, even if any of said parties had been advised of, knew of, or should have known of the possibility of such damages.                    </div>
                </p>
                <p>
                    <strong>INDEMNIFICATION:</strong>
                    <div>
                        ASP shall defend, indemnify, and hold harmless EAI and its affiliates, officers, agents, representatives and employees from and against all claims, lawsuits, actions, demands, liabilities, expenses (including fees and disbursements of counsel), judgments, settlements, and penalties of every kind arising from or in any way relating to: i.ASP's breach/violation of any terms or conditions mentioned on this EzAuto/ E-portal. ii.ASP's violation or alleged violation of any applicable law in relation to use of this EzAuto/ E-portal iii.Any act of fraud, negligence, misrepresentation by ASP. iv.Penalty, fine or any charges imposed by any regulatory/statutory/governmental authority/Bank on EAI with respect to any acts of commission or omission by the ASP. v.ASP's performance/non-performance of his obligations under these terms and conditions.
                    </div>
                </p>
                <p>
                    <strong>DISPUTE RESOLUTION:</strong>
                    <div>
                        ASP agrees that any dispute arising out of, use of this EzAuto/ E-portal or availing EAI services, shall be first resolved by amicable means and failure to resolve the same within 30 days shall entitle EAI to refer the matter to arbitration conducted by sole arbitrator appointed by EAI. The proceedings shall be conducted as per Arbitration and Conciliation Act 1996 or any of its successive statue/amendments. The seat of arbitration shall be at Mumbai and proceeding shall be conducted in English language. The award passed by the arbitrator shall be final and binding on the ASP.
                    </div>
                </p>
                <p>
                    <strong>TERMINATION:</strong>
                    <div>
                        ASP agrees that EAI shall have all the rights to suspend or terminate ASP’s account wholly or partly or usage of services and/or ASP's profile at any time. EAI may also in its sole discretion and at any time discontinue providing access to EAI services, or any part thereof, with or without notice
                    </div>
                </p>
                <p>
                    <strong>ASP ACCEPTANCE OF THESE TERMS:</strong>
                    <div>
                        By using the Services, ASP signifies his/her acceptance of this Policy. If the ASP does not agree to this terms and conditions, the ASP shall not use the EzAuto/ E-portal. ASP continued use of EAI services following the posting of changes to these terms and conditions will be deemed ASP acceptance of those change
                    </div>
                </p>
                <p>
                    <strong>UPDATES TO THIS PRIVACY POLICY:</strong>
                    <div>
                        EAI may change the data privacy practices and update this privacy statement as and when the need arises, and the same shall be made available on the EzAuto/ E-portal. ASPs are requested to keep check on the same
                    </div>
                </p>
                <p>
                    <strong>UNDERTAKING BY REGISTERED AUTHORISED SERVICE PROVIDER (“ASP”):</strong>
                   {/*  <div>
                        I ASP of Europ Assistance India Private Limited (“the Company”), do hereby confirm, undertake and grant my consent to<br />
                        i) Enroll and register my/our Firm/ company on the e-portal offered by the Company for availing all benefits offered under the Paperless Process<br />
                        ii) Upload and provide all mandatory documents for registration including but not limited to KYC, Id & address proofs, registration certificates, licenses and other documents as required<br />
                        iii) Provide registered mobile number to receive SMS for all purposes including OTP and case updates<br />
                        iv) Complete the registration formalities with the external agencies under the said process, if outsourced by the Company<br />
                        v) Authorize Company to share my/our KYC/ Aadhar/ Pan/ Contact details with CDSL/ NSDL/ External agencies for the purpose contemplated under this undertaking including but not limited to biometric access, validations, receiving OTP and other incidental and ancillary activities.<br />
                        vi) E-sign the agreement(s), addendum(s), terms & conditions and related documents thereof and further authorize future amendments as and when need arises<br />
                        vii) Periodically review and provide authorizations on e-portal for activities relating to ASP<br />
                        viii)authorize Company to generate and process invoice through Paperless initiative as per the protocols set and receive payments via online channels (NEFT/ RGTS/ IMPS/ UPI)<br />
                        ix) Receive sms/ email statements only on registered contact no./ email id for all payment and case related details<br />
                        x) To submit original receipts and physical bills to Company for verification in case of toll/ other extra charges<br />
                    </div> */}
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam distinctio illum culpa dicta laudantium temporibus quaerat eveniet, nihil velit placeat vitae iure soluta impedit, minima corrupti ducimus numquam a explicabo.
                </p>
               {/*  <p>
                    <strong>Declaration:</strong>
                    <div>
                        - I/We have read and understood the contents for use of the Paperless Process and hereby agree by the Terms & Conditions in its letter and spirit. If at any time I/We do not agree to the present/ modified terms, I/ We should discontinue to avail the benefits.<br />
                        - I/We undertake to support the Company in enforcement of the Paperless initiative and to do the needful<br />
                        - I/We confirm to not entice or encourage or engage or participate in any activity directly or indirectly, which may lead or possible lead to breach/violation to terms of the said Process<br />
                        - I/We will immediately report any breach or attempted breach to terms of the said Process.<br />
                        - I/We understand that the Process is subject to enhancements and agree to provide necessary support at all times.<br />
                        - I/We hereby undertake that all information/documents provided by me/us are true/accurate/valid and any inconsistency/inaccuracy/invalidity for the said details shall be solely my/our responsibility and accordingly I/We agree to indemnify the Company for and against any loss, injury or damages incurred by the Company as a result of such occurrence.<br />
                        - I/We understand that these terms control the relationship between Company and ASP and do not create any third party beneficiary rights.<br />
                        - I/We hereby state that I/we have the right and authorization to enter into this arrangement on behalf of my/our Firm/ Company.<br />
                    </div>
                </p> */}
            </>
        </Modal>
    )
}