import { useEffect, useRef, useState } from "react";
import { Layout, Button, DatePicker, Form, Input, Select, Row, Col, Card, Spin, message, Checkbox, FormOutlined } from "../../../components/Elements";
import { VerifyModal } from "../../../components/modals";
import { VERIFY_EMAIL, VERIFY_MOBILE } from "../../../config";
import { ErrorMesages } from "../../../constants";
import { ValidateEmailAPI, ValidateMobileNumber, GET_VALIDATION_DATA, MATCHED_OTP, ValidateEmailOTP } from "../api";
import { CardStyle } from "../helpers/formcsshelper";
import { validateMessages } from "../helpers/formhelper";
import { IEmailVerifyPayload, IMobileVerfiyPayload, IVerifiedStatus } from "../helpers/interface";
import { OTPVerify } from "./OTPVerifyForn";
import "./personal-details.scss";
import moment from "moment";
import { DocumentCheckListPopUp } from "../../../modules/Common/Popup/DocumentCheckList";

type LayoutType = Parameters<typeof Form>[0]['layout'];

const Country: any = [
    {
        name: "India",
        value: "India"
    }
];
const Languages: any = [
    {
        name: "Hindi",
        value: "Hindi"
    },
    {
        name: "Bengali",
        value: "Bengali"
    },
    {
        name: "Telugu",
        value: "Telugu"
    },
    {
        name: "Marathi",
        value: "Marathi"
    },
    {
        name: "Tamil",
        value: "Tamil"
    },
    {
        name: "Urdu",
        value: "Urdu"
    },
    {
        name: "Gujarati",
        value: "Gujarati"
    },
    {
        name: "Malayalam",
        value: "Malayalam"
    },
    {
        name: "Kannada",
        value: "Kannada"
    },
    {
        name: "Odia",
        value: "Odia"
    },
    {
        name: "Punjabi",
        value: "Punjabi"
    },

];



export const PersonalDetails = (props: any) => {

    const { Option } = Select;
    const [form] = Form.useForm();
    const VerifyModelRef = useRef<any>();
    const [RegistrationFormData, setRegistrationFormData] = useState<any>({});
    const [disablePassword, setDisablePassword] = useState<boolean>(false);
    const [emailVerified, setEmailVerified] = useState<IVerifiedStatus>();
    const [emailRequest, setEmailRequest] = useState<boolean>(false);
    const [checkListPopup, setCheckListPopup] = useState<boolean>(false);

    const [mobileRequest, setMobileRequest] = useState<boolean>(false);
    const [mobileVerified, setMobileVerified] = useState<IVerifiedStatus>()
    const [mobileVerifyOTP, setMobileOTP] = useState<any>();
    const [validationMessage, setValidationMessage] = useState<any>(null);

    const [aadharVerified, setAAdharVerified] = useState<IVerifiedStatus>()
    const [emailVerifyOTP, setEmailVerifyOtp] = useState<any>();
    const [showVerifyMobile, setShowVerifyMobile] = useState<boolean>(true);
    const [registrationType, setRegistrationType] = useState<any>('');
    const [eaiCheckBoxStatus, seteaiCheckBoxStatus] = useState<any>('');
    // const [emailRequest, setEmailRequest] = useState<boolean>(false);

    const [validateType, setValidateType] = useState<string>();
    const [previousEmail, setPreviousEmail] = useState<any>("");


    useEffect(() => {

        setRegistrationFormData(props.RegistrationFormData);

    }, [props.RegistrationFormData]);

    const getMessages = async (key: String, isError: Boolean) => {
        let response: any = await GET_VALIDATION_DATA({ key, is_error: isError });
        setValidationMessage(response.message);
    }
    useEffect(() => {
        if (validationMessage && validationMessage.length > 0) {
            if (validationMessage !== 'Invalid OTP, please try again!') {
                message.success(validationMessage);
            }
            else {
                message.error(validationMessage);
            }
        }
    }, [validationMessage]);

    useEffect(() => {
        console.log("In PersonalDetails.., ", RegistrationFormData);
        let registerDetails: any = RegistrationFormData;
        if (registerDetails) {
            let panDetails: any = registerDetails['PanVerification'];
            if (panDetails && Object.keys(panDetails)) {

                let userName = panDetails.pancard;
                let resgisType = panDetails.registration_type;
                if (resgisType && resgisType == 'D') {
                    userName = panDetails.dealer_id;
                }
                form.setFieldsValue(
                    {
                        country: "India",
                        // gc_ppc: panDetails.pancard,
                        gc_ppc: registerDetails['UserDetails'].Invoice_payment_flag === 'GC-PPC' ? true : false,
                        username: userName,
                        pancard: panDetails.pancard,
                        dob: panDetails.date_of_birth
                    });

                setRegistrationType(panDetails['registration_type']);
                seteaiCheckBoxStatus(panDetails['eai_employee']);
            }

            let registrationDetails: any = registerDetails['PersonalDetails'];
            if (registrationDetails && Object.keys(registrationDetails)) {
                form.setFieldsValue(registrationDetails);
                if (registerDetails.isNewRecord) {
                    setDisablePassword(false)
                } else {
                    setDisablePassword(true)
                }

                if (registrationDetails && registrationDetails.validated) {
                    let email = registrationDetails['email']
                    let mobile = registrationDetails['mobile_no'];
                    let aadhar_no = registrationDetails['aadhar_no']
                    setMobileVerified({ value: mobile, verified: true });
                    setEmailVerified({ value: email, verified: true });
                    setAAdharVerified({ value: aadhar_no, verified: true })
                }

                if (registrationDetails && registrationDetails.validations) {
                    let validations = registrationDetails.validations;
                    if (validations.email) {
                        let email = registrationDetails['email']
                        setEmailVerified({ value: email, verified: true });
                    }
                    if (validations.mobile) {
                        let mobile = registrationDetails['mobile_no'];
                        setMobileVerified({ value: mobile, verified: true });
                    }
                    if (validations.aadhar) {
                        let aadhar_no = registrationDetails['aadhar_no']
                        setAAdharVerified({ value: aadhar_no, verified: true })
                    }
                }
            }
        }
    }, [RegistrationFormData])

    const { Content } = Layout;
    const [formLayout, setFormLayout] = useState<LayoutType>('horizontal');

    const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
        setFormLayout(layout);
    };

    const formItemLayout = {};

    const onFinish = (values: any) => {
        let params: any = {
            type: 'PersonalDetails',
            data: values,
            next: true,
        }

        if (!emailVerified?.verified || (form.getFieldValue('email') !== emailVerified.value)) {
            message.error('Please Verify Email');
            return false;
        }

        if ((aadharVerified?.value && !aadharVerified?.verified) || (form.getFieldValue('aadhar_no') && (form.getFieldValue('aadhar_no') !== aadharVerified?.value))) {
            message.error('Please Verify Aadhar Number');
            return false;
        }

        if (!mobileVerified?.verified || (form.getFieldValue('mobile_no') !== mobileVerified.value)) {
            message.error('Please Verify Mobile Number');
            return false;
        }

        props.handleSubmit(params);
    }

    const ValidateEmail = async () => {

        let registerDetails: any = RegistrationFormData;
        let panDetails: any = registerDetails['PanVerification'];

        let emailValue = form.getFieldValue('email');
        if (!emailValue) {
            message.error('Email is missng.');
            return false;
        }

        if (form.getFieldError('email').length) {
            message.error('Please enter valid Email.');
            return false;
        }
        if (VERIFY_EMAIL) {
            try {
                let emailVerifyBody: IEmailVerifyPayload = {
                    email: emailValue,
                    phoneNumber: panDetails['mobile_no'] ? panDetails['mobile_no'] : '9515193569'
                }
                setEmailRequest(true);
                openModal();
                let response: any = await ValidateEmailAPI(emailVerifyBody);
                setEmailRequest(false);
                // setEmailVerifyOtp(response.message);
                setValidateType('email');
            } catch (e) {
                setEmailRequest(false);
                message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE);
            }
        } else {
            setEmailVerified({ value: emailValue, verified: true });
            message.success("Email Verified Successfully");
        }
    }

    const ValidateAadharNo = async () => {
        let aadharValue = form.getFieldValue('aadhar_no');
        if (!aadharValue) {
            message.error('Aadhar Number is missng.');
            return false;
        }

        if (form.getFieldError('aadhar_no').length) {
            message.error('Please enter valid Aadhar Number.');
            return false;
        }
        setAAdharVerified({ value: aadharValue, verified: true })
        message.success("Aadhar Verifed Successfuly");
    }

    const openModal = () => {
        VerifyModelRef.current.onShowModal();
    }
    const closeModal = (event: any = {}) => {
        VerifyModelRef.current.onCloseModal();
    }

    const validateOTP = async (event: any) => {

        if (validateType) {
            switch (validateType) {
                case 'mobile':
                    let today = new Date();
                    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                    let value = mobileVerified ? mobileVerified.value + date : '';
                    let response: any = await MATCHED_OTP({ otp: event.validate_otp, serviceId: value });
                    if (response.status == "success") {
                        // if (event.validate_otp && event.validate_otp == mobileVerifyOTP) {
                        setMobileVerified({ value: form.getFieldValue('mobile_no'), verified: true });
                        // message.success("Mobile Validated Successfully.")
                        await getMessages('S02', false);
                        closeModal();
                    } else {
                        // message.error("Please enter valid OTP.")
                        await getMessages('E01', true);
                    }
                    break;
                case 'email':
                    // if (event.validate_otp && event.validate_otp == emailVerifyOTP) {
                    // setEmailVerified({ value: form.getFieldValue('email'), verified: true });
                    // message.success("Email Validated Successfully.")
                    if (event.validate_otp) {
                        let response: any = await ValidateEmailOTP({ otp: event.validate_otp, email: form.getFieldValue('email') });
                        if (response.status == "success") {
                            await getMessages('S03', false);
                            setEmailVerified({ value: form.getFieldValue('email'), verified: true });

                        } else {
                            await getMessages('E01', true);
                            break;

                        }
                        closeModal();
                    }
            }
        }

    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Checkbox disabled>
                {/* I have read the <a href="">agreement</a> */}
            </Checkbox>
        </Form.Item>
    );


    /**
    * Method to verify Mobile Number
    * @returns 
    */
    const ValidateMobileNo = async () => {

        let mobileValue = form.getFieldValue('mobile_no');
        if (!mobileValue) {
            message.error('Mobile Number is missng.');
            return false;
        }

        if (form.getFieldError('mobile_no').length) {
            message.error('Please enter valid mobile number.');
            return false;
        }

        if (VERIFY_MOBILE) {
            try {
                let today = new Date();
                let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                let mobileverifyBody: IMobileVerfiyPayload = {
                    mobileNumber: mobileValue,
                    userName: mobileValue + date
                    // userName: form.getFieldValue('username') + date
                }
                setMobileRequest(true);
                openModal();
                let response: any = await ValidateMobileNumber(mobileverifyBody);
                if (response.message) {
                    setMobileOTP(response.message);
                    setValidateType('mobile')
                }
                setMobileRequest(false);
            } catch (e) {
                setMobileRequest(false);
                message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE);
            }
        } else {
            setMobileVerified({ value: mobileValue, verified: true })
            // message.success("Mobile Number Validated Successfully.")
            await getMessages('S02', false);
        }
    }

    const onChangeMobile = (event: any) => {
        if (event && event.target && event.target.value) {
            let panMobile: any = RegistrationFormData['PanVerification']['mobile_no'];
            if (event.target.value.length == 10 && event.target.value == panMobile) {
                setShowVerifyMobile(false);
                setMobileVerified({ value: form.getFieldValue('mobile_no'), verified: true });
            } else {
                setShowVerifyMobile(true);
                setMobileVerified({ value: form.getFieldValue('mobile_no'), verified: false });
            }
        }
    }

    const onChangeEmail = (event: any) => {
        if (event && event.target && event.target.value) {
            if (event.target.value == previousEmail) {
                setEmailVerified({ value: form.getFieldValue('email'), verified: true });
                setPreviousEmail(event.target.value);
            } else {
                setEmailVerified({ value: form.getFieldValue('email'), verified: false });
            }
        }
    }


    const [checked, setChecked] = useState(false);

    const onCheckboxChange = async (e: any) => {
        await setChecked(e.target.checked);
    };

    const handlePreviousStep = () => {
        let params: any = {
            type: 'PersonalDetails',
            data: form.getFieldsValue(),
            validations: {
                email: emailVerified?.verified,
                mobile: mobileVerified?.verified,
                aadhar: aadharVerified?.verified,
            },
            next: true,
            action_type: "previousStep"
        }
        props.NavigatePrevious(params);
    }
    const documentChecklist = () => {
        setCheckListPopup(true)
        console.log("Inside doc checklist")
    }
    const sendDataToParent = (index: any) => {

        setCheckListPopup(index)
    }
    function disabledDate(current: any) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }

    return (

        <div className="ezauto-regis-container ezauto-personal-details">
            <Row gutter={8} style={{ justifyContent: "center" }}>
                <Col span={22}>
                    <Card style={CardStyle}>
                        <Form
                            className="registration-details-form personal-details-form"
                            {...formItemLayout}
                            layout={'vertical'}
                            form={form}
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 20 }}
                            initialValues={{ layout: formLayout }}
                            onValuesChange={onFormLayoutChange}
                            onFinish={onFinish}
                            autoComplete="off"
                            validateMessages={validateMessages}
                        >
                            <div className="PersonalMain">
                                <div style={{ width: "100%" }}>
                                    {
                                        registrationType && registrationType != 'FTSP' && eaiCheckBoxStatus != true &&
                                        <Form.Item name="gc_ppc"
                                            // rules={[{ required: true }]} 
                                            valuePropName="checked"
                                            // rules={[
                                            //     {
                                            //         validator: async (_, checked) => {
                                            //             if (!checked) {
                                            //                 return Promise.reject(
                                            //                     new Error("Please Select GC-PPC"),
                                            //                 );
                                            //             }
                                            //         },
                                            //     },

                                            // ]}
                                            label="GC-PPC">
                                            {/* <Input addonBefore={prefixSelector} disabled style={{ width: '100%' }} /> */}
                                            <Checkbox checked={checked} onChange={onCheckboxChange}>
                                            </Checkbox>
                                        </Form.Item>
                                    }

                                    <Form.Item name="username" rules={[{ required: true }]} label="User Name">
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item label="Password" name="password" rules={[{ required: true, }]}>
                                        <Input type={'password'} disabled={disablePassword} />
                                    </Form.Item>
                                    <div className="PersonalDetails">
                                        <div style={{ width: "100%" }}>
                                            <Form.Item validateFirst label="Email" name="email" wrapperCol={{ span: 30 }}
                                                rules={
                                                    [
                                                        { required: true },
                                                        { type: "email" },
                                                    ]
                                                }>
                                                <Input placeholder="Enter Email" onChange={onChangeEmail} autoComplete="new-password" />
                                            </Form.Item>
                                        </div>

                                        <div className="MarginBTN">
                                            <Spin spinning={emailRequest} delay={500}>
                                                <Form.Item label='' >
                                                    <Button className="ant-btn-login" onClick={ValidateEmail} disabled={emailVerified?.verified} type="primary">Verify</Button>
                                                </Form.Item>
                                            </Spin>
                                        </div>
                                    </div>

                                    <Form.Item label="Country" name="country" rules={[{ required: true }]}>
                                        <Select placeholder="Select Country" disabled>
                                            {
                                                Country.map((type: any) => (
                                                    <Select.Option key={type.value} value={type.value}>{type.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="vendor_language" label="Language" rules={[{ required: true }]}>
                                        <Select placeholder="Select a language" mode="multiple">
                                            {Languages.map((vendor_language: any) => (
                                                <Select.Option key={vendor_language.value} value={vendor_language.value}>
                                                    {vendor_language.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="preffered_language" label="Preferred Language" rules={[{ required: true }]}>
                                        <Select placeholder="Select a language">
                                            {Languages.map((preffered_language: any) => (
                                                <Select.Option key={preffered_language.value} value={preffered_language.value}>
                                                    {preffered_language.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <h1 style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={documentChecklist}>Document Check List:</h1>
                                    </Form.Item>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <Form.Item>
                                        <h2>Authorized Signatory</h2>
                                    </Form.Item>



                                    <div style={{ width: "100%" }}>
                                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                            <Input autoComplete="new-password" />
                                        </Form.Item>
                                        <Form.Item label="Pancard" name="pancard" rules={[{ required: true, }]}>
                                            <Input />
                                        </Form.Item>

                                        <div className="PersonalDetails">
                                            <div style={{ width: "100%" }}>
                                                <Form.Item label="Aadhar No" name="aadhar_no" wrapperCol={{ span: 30 }}
                                                    rules={
                                                        [
                                                            { required: true, },
                                                            {
                                                                pattern: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
                                                                message: "Please Enter Valid Aadhar Number"
                                                            }
                                                            // {
                                                            //     validator: (_, value) => {
                                                            //         if (!value || value.length !== 12 || value[0] === '0' || value[0] === '1' || /^(\d)\1+$/.test(value) || /\D/.test(value)) {
                                                            //             return Promise.reject(new Error('Please Enter Valid Aadhar Number'));
                                                            //         }
                                                            //         return Promise.resolve();
                                                            //     },
                                                            // },
                                                        ]}>
                                                    <Input onChange={() => {
                                                        setAAdharVerified({ value: form.getFieldValue('aadhar_no'), verified: false });
                                                    }} autoComplete="new-password" />
                                                </Form.Item>
                                            </div>
                                            <div className="MarginBTN">
                                                <Spin spinning={emailRequest} delay={500}>
                                                    <Form.Item label='' >
                                                        <Button className="ant-btn-login" onClick={ValidateAadharNo} type="primary" disabled={aadharVerified?.verified} >Verify</Button>
                                                    </Form.Item>
                                                </Spin>
                                            </div>
                                        </div>
                                        <div className="PersonalDetails">
                                            <div style={{ width: "100%" }}>

                                                <Form.Item label="Authorised Signatory Mobile No" name="mobile_no" wrapperCol={{ span: 24 }} labelCol={{ style: { whiteSpace: 'nowrap' } }}

                                                    rules={
                                                        [
                                                            { required: true },
                                                            {
                                                                pattern: /^(?:\d*)$/,
                                                                message: "Please enter valid mobile number",
                                                            },
                                                            { max: 10, message: "Please enter valid mobile number" },
                                                            { message: "Please enter valid mobile number", min: 10 }
                                                        ]
                                                    }>
                                                    <Input onChange={onChangeMobile} autoComplete="new-password" />
                                                </Form.Item>
                                            </div>
                                            <div className="MarginBTN">
                                                <Spin spinning={emailRequest} delay={500}>
                                                    {/* {
                                                    showVerifyMobile && */}
                                                    <Form.Item label='' >
                                                        <Button className="ant-btn-login" onClick={ValidateMobileNo} disabled={mobileVerified?.verified} type="primary">Verify</Button>
                                                    </Form.Item>
                                                    {/* } */}

                                                </Spin>
                                            </div>
                                        </div>

                                        <Form.Item label="Designation" name="designation" rules={[{ required: true }]}>
                                            <Input autoComplete="new-password" />
                                        </Form.Item>
                                        <Form.Item label="Date of Birth" name="dob" rules={[{ required: true }]}>
                                            <DatePicker disabledDate={disabledDate} format={'DD-MM-YYYY'} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <Row style={{ marginTop: "20px" }} gutter={[24, 24]} align="middle" justify="end" >
                                <Col>
                                    <Form.Item >
                                        <Button onClick={() => handlePreviousStep()} type="primary" className="ant-btn-prev">Previous</Button>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item >
                                        <Button htmlType="submit" type="primary" className="ant-btn-login">Next</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <VerifyModal ref={VerifyModelRef} title={"Please Enter OTP"} width={500}>
                <OTPVerify closeModal={() => closeModal} validateOTP={($event: any) => validateOTP($event)} />
            </VerifyModal>
            {checkListPopup && <DocumentCheckListPopUp display={true} sendDataToParent={sendDataToParent} />}
        </div>
    );
}