import { Button, Modal, Input, Spin, Row, Col, } from '../..//../components/Elements'
import { useEffect, useState } from 'react';
import { verifyOtp, generateCreditNote, downloadPdf1, generatePdf, isMatchedOTP, downloadPdf } from '../../../services/shared.service';
import { pdfInvoice } from '../../../services/shared.service';
import { InvoiceDownlaodPopup } from './downloadInvoicePopup';
import storage from '../../../utils/storage';
import { API_URL } from '../../../config';
import { API_LIST } from '../../../http-service/list';
import axios from 'axios';


export const MobileOtp = (props: any) => {
    const [visible, setVisible] = useState(false)
    const [popUp, setPopUp] = useState(true)
    const [name, setName] = useState('')
    const [otp, setOtp] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [invPopup, setInvPopup] = useState(false)
    let vendorId: any;
    let number: any;
    vendorId = storage.getValue("vendorId")
    number = storage.getValue("phoneNo")


    useEffect(() => {
        if (props.display === true) {
            setVisible(true)
        }

    }, [])

    const onclick = () => {
        props.sendToMobileOtp(false)
        setPopUp(false)
    }
    /*   function myFunction() {
          let text = "Do you want to download invoice";
          if (confirm(text) == true) {
              downloadPdf1(res.message)
              text = "You pressed OK!";
          }
  
      } */

    // function pdfInvoice() {

    // }
    async function verifyotp() {
        if (popUp) {
            let res: any = await verifyOtp(number, vendorId)
            setOtp(res.message)
            setPopUp(false)
        } else {
            let response = await isMatchedOTP(name, vendorId);

            if (response.message === "OTP matched") {
                alert("Otp verified Successfully")
                if (props.otpType === "creditNote") {
                    setVisible(false)
                    props.sendToMobileOtp(false, 'loading')
                    setLoading(true)
                    let res: any = await generateCreditNote(props.data[0].ServiceId)
                    if (res.status === "success") {
                        alert("Credit Note Generated.")
                    } else {
                        alert("Please Try Again")
                    }
                    setLoading(false)
                    props.sendToMobileOtp(false, 'refresh')
                } else {
                    setVisible(false)
                    props.sendToMobileOtp(false, 'loading')
                    setLoading(true)
                    let res: any = await pdfInvoice(props.invNumber, 17.4343648, 78.3955874, props.data, vendorId, name)
                    // .then(response => { res = response })
                    // .catch(e => console.log(e))
                    // let res: any;

                    // let path: any = res.message
                    if (res.status === "success") {
                        setInvPopup(true)
                        let payload: any = {
                            "fileName": res.message
                        }
                        let signed_pdf = payload.fileName;
                        let fileName: any = signed_pdf.split('/')
                        fileName = fileName[fileName.length - 1];
                        let file1: any = fileName.replace('_signed.', '.')
                        let file: any = file1.replace('.pdf', '')
                        let token: any;
                        token = storage.getToken();
                        if (window.confirm('Invoice raised successfully! Do you want to download invoice')) {
                            axios.post(
                                API_URL + API_LIST.DOWNLOAD_INVOICE,// "https://localhost:8443/api/vendorDocuments/getVendorSingleDocument", //hosted with serve
                                payload,
                                { responseType: 'arraybuffer', headers: { Authorization: token } },
                            )
                                .then((response: any) => {
                                    // let imgtype = response.headers['Content-Disposition'];
                                    //  let imgtype = response.headers && response.headers['content-type'] ? response.headers['content-type'] : defaultimgtype;
                                    generatePdf(response.data, file)
                                    // var filedd = new Blob([response.data], {
                                    //     type: imgtype
                                    // });
                                    // // setLoader(false);
                                    // var fileURL1 = URL.createObjectURL(filedd);
                                    // window.open(fileURL1);
                                })
                        }

                    } else {
                        alert("Please Try Again")
                    }
                    setLoading(false)
                    props.sendToMobileOtp(false, 'refresh')

                }


            } else {
                alert("Wrong Otp")
            }
        }



    }
    if (popUp) {
        var title: any = "Mobile Number Registered With Us"
        var header: any = "Please click submit to get the OTP"
        var footer: any = "NOTE: If this is not your mobile number please contact your Regional Manager"
        var button: any = "Submit"
    } else {
        var title: any = "Please Enter OTP"
        var button: any = "GO"
    }

    const sendDataToInvoiceDownload = (index: any) => {
        setPopUp(index)
    }

    return (
        loading ?
            <div className="example" style={{ textAlign: 'center' }}>
                <Spin />
            </div>
            :
            <>

                <Modal
                    title={title}
                    style={{ top: 160 }}
                    visible={visible}
                    //   width={1000}
                    onOk={() => setVisible(false)}
                    onCancel={onclick}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                >
                    <Row>
                        <Col className='m-bt-20 col-pad' span={24}>
                            <p>{header}</p>
                        </Col>
                        <Col className='col-pad' span={20}>

                            {popUp ? <Input placeholder="Basic usage" value={number} /> : <Input placeholder="Enter OTP" onChange={e => setName(e.target.value)} />}



                        </Col>
                        <Col className='col-pad' span={4}>
                            <Button className='ant-btn-view' onClick={verifyotp}>{button}</Button>
                        </Col>
                        <Col className='m-bt-20 col-pad' span={24}>
                            <p>{footer}</p>
                        </Col>
                    </Row>

                </Modal>
            </>
    )
}