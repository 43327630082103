import React from "react";
import { PanVerification } from "../components/PanVerification";
import { PersonalDetails } from "../components/PersonalDetails";
import { Locations } from "../components/locations/Locations";
import { OtherDetails } from "../components/OtherDetails";
import { UploadDocuments } from "../components/UploadDocuments";

const Components: any = {
    'PanVerification': PanVerification,
    'PersonalDetails': PersonalDetails,
    'Locations': Locations,
    'OtherDetails': OtherDetails,
    'UploadDocuments': UploadDocuments
}

const GetComponent = (type: string) => {
    if (Components[type]) {
        return Components[type];
    }
    return '';
}

const CreateComponent = (type: string, params: any) => {
    let component = GetComponent(type);
    if (component) {
        return React.createElement(component, params);
    }
    // component doesn't exist yet
    return React.createElement(
        () => <div>No Daddta.</div>,
        { key: '' }
    );
}
export { CreateComponent }