import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Row, Col, Card, Upload, UploadOutlined, message, CustomHeader, Spin, Checkbox } from "../../../components/Elements";
import { API_URL } from "../../../config";
import { API_LIST } from "../../../http-service/list";
import { UserContext, UserContextType } from "../../../providers/UserContext";
import { GET_DOCUMENTS_LIST, UPLOAD_FILE } from "../api";
import { CardStyle } from "../helpers/formcsshelper";
import { FileuploadParams, validateMessages } from "../helpers/formhelper";
import { TermsAndCondPopUp } from "../../../modules/Common/Popup/TermsAndConditions";

type LayoutType = Parameters<typeof Form>[0]['layout'];


export const UploadDocuments = (props: any) => {

    const DEFAULT_FILE_SIZE = 1;

    const [form] = Form.useForm();

    const { User } = useContext(UserContext) as UserContextType;

    const [RegistrationFormData, setRegistrationFormData] = useState({});
    const [idProofLabel, setIdProof] = useState<any>('Telephone Bill');
    const [vendorId, setVendorId] = useState<any>();
    const [legalPanFileList, setLegalPanFileList] = useState<any[]>([]);
    const [legaGST, setLegalGST] = useState<any[]>([]);
    const [addressFileList, setaddressFileList] = useState<any[]>([]);
    const [authPan, setAuthPanFiles] = useState<any[]>([]);
    const [aadharFiles, setaadharFiles] = useState<any[]>([]);
    const [passBookFiles, setPassBookFiles] = useState<any[]>([]);
    const [agreementFiles, setAgreementFiles] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [checkListPopup, setCheckListPopup] = useState<boolean>(false);
    const [onSubmit, setOnSubmit] = useState<boolean>(false)
    const [documentName, setDocumentName] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    // const [isUpload, setIsUpload] = useState<boolean>(false)
    var isUpload: any = false;
    const uploadFile = async (ele: any) => {
        const { onSuccess, onError } = ele;
        let formData = new FormData();
        let filename: any = ele.filename;
        let fileName: any = ele.file.name;
        if (!ele.file || !['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(ele.file.type)) {
            message.error('Files should be image/pdf type only.');
            return false;
        }
        let fileSize = ele.file.size;

        if ((fileSize / 1024) / 1000 > DEFAULT_FILE_SIZE) {
            message.error('File size should not exceed more than 1MB');
            return false;
        }

        formData.append('vendorId', vendorId);
        formData.append('photo', ele.file);
        let cat = 'legalEntity';
        let sub = 'PAN';
        if (FileuploadParams[filename]) {
            cat = FileuploadParams[filename]['category'];
            sub = FileuploadParams[filename]['sub'];
        }
        formData.append('category', cat);
        formData.append('docGroup', sub);
        // setDocumentName([...documentName,{fileLabel:filename,fileLabelName: fileName}])
        filedocList({ doc_group: sub, doc_name: fileName, vendorId: vendorId, doc_category: cat })
        if (isUpload === true) {
            try {
                setLoader(true);
                let res: any = await UPLOAD_FILE(formData)
                if (res.status === 'success') {
                    onSuccess();
                    setLoader(false);
                    GET_VENDOR_DOCS();
                } else {
                    setLoader(false);
                    onError()
                }
                return res;
            } catch (e) {
                onError()
            }
        }


    }
    const findAndReplaceItem = (updatedItem: any) => {
        // Find the index of the item to replace
        const index = documentName.find(item => item.doc_group === updatedItem.doc_group);
        if (index) {
            var arrIndex = index.index;
            // If the item is found
            if (index !== -1) {
                // Create a new array with the updated item
                const updatedArray = [...documentName];
                updatedItem.index = index.index
                updatedArray[arrIndex] = updatedItem;

                // Update the state with the new array
                setDocumentName(updatedArray);
            }
        } else {
            updatedItem.index = documentName.length + 1
            setDocumentName([...documentName, updatedItem])
        }

    };

    const filedocList = async (ele: any) => {
        if (documentName.length > 0) {

            for (let i = 0; i < documentName.length; i++) {
                const element = documentName[i];
                if ((ele.doc_group === "PAN" || ele.doc_group === "authPAN")) {
                    findAndReplaceItem(ele)
                    isUpload = true;
                    return
                } else if (element.doc_name === ele.doc_name) {
                    alert("Please upload unique file");
                    isUpload = false;
                    return;
                } else {
                    findAndReplaceItem(ele)
                    isUpload = true;
                    return
                }
            }


        }
        else {
            findAndReplaceItem(ele)
            isUpload = true;


        }
    }
    console.log("@@@@@@@@@@@@@", documentName)
    const PreviewFiles = async (ele: any) => {
        try {
            if (ele && ele['uid']) {
                let payload: any = {
                    documentId: ele['uid']
                }

                setLoader(true);
                axios.post(
                    API_URL + API_LIST.FILE_PREVIEW,// "https://localhost:8443/api/vendorDocuments/getVendorSingleDocument", //hosted with serve
                    payload,
                    { responseType: 'arraybuffer' }
                )
                    .then((response: any) => {
                        let defaultimgtype = 'image/png';
                        let imgtype = response.headers && response.headers['content-type'] ? response.headers['content-type'] : defaultimgtype;
                        var filedd = new Blob([response.data], {
                            type: imgtype
                        });
                        setLoader(false);
                        var fileURL1 = URL.createObjectURL(filedd);
                        window.open(fileURL1);
                    }).catch((e: any) => {
                        setLoader(false);
                    });
            }
        } catch (e) {
            console.log(e);
            setLoader(false);
        }
    }

    const propsList: any = {
        action: '',
        headers: {
            authorization: 'authorization-text',
        },
        maxCount: 1,
        customRequest: uploadFile,
        defaultFileList: [],
        showUploadList: {
            showPreviewIcon: true,
            showDownloadIcon: false,
            downloadIcon: 'download ',
            showRemoveIcon: false,
        },
        onChange(info: any) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onPreview(info: any) {
            PreviewFiles(info);
        }
    };


    useEffect(() => {
        setRegistrationFormData(props.RegistrationFormData);
    }, [props.RegistrationFormData]);

    useEffect(() => {
        if (User && User.details) {
            if (User.details.registrationDetails && User.details.registrationDetails._id) {
                setVendorId(User.details.registrationDetails._id);
            }
        }
    }, [User]);


    const addObjectWithIndex = (objects: any) => {
        const newArray = objects.map((object: any, index: any) => ({ ...object, index }));
        setDocumentName(prevArray => [...prevArray, ...newArray]);
    };
    const GET_VENDOR_DOCS = async () => {
        let payload = { "vendorId": vendorId };
        try {
            setLoader(true);
            let panlist: any = [];
            let gstlist: any = [];
            let addresslist: any = [];
            let authpanlist: any = [];
            let authaadhar: any = [];
            let passbook: any = [];
            let agreement: any = [];
            let docs: any = await GET_DOCUMENTS_LIST(payload);
            if (docs && docs.status === 'success') {
                if (count === 0) {
                    /*  docs.message.forEach(function(ele:any){
 
                     }) */
                    // setDocumentName([...documentName, ...docs.message])
                    addObjectWithIndex(docs.message)

                }
                setCount(1)
                docs.message.map((ele: any) => {
                    let obj = { ...ele };
                    obj['uid'] = obj['_id'];
                    obj['name'] = obj['doc_name'];
                    obj['status'] = 'done';
                    if (ele['doc_category'] === 'legalEntity') {
                        if (ele.doc_group === 'PAN') {
                            form.setFieldsValue({ legal_pan_no_f: obj['name'] })
                            panlist.push(obj);
                        }
                        if (ele.doc_group === 'GST') {
                            gstlist.push(obj);
                        }
                        if (ele.doc_group === 'idProof') {

                            addresslist.push(obj);
                        }
                        if (ele.doc_group === 'bankDocument') {
                            form.setFieldsValue({ cancelled_passbook: obj['name'] })
                            passbook.push(obj);
                        }

                        if (ele.doc_group === 'licenceDocument') {

                            agreement.push(obj);
                        }

                    }

                    if (ele['doc_category'] === 'authSignatory') {
                        if (ele.doc_group === 'Adhaar') {
                            form.setFieldsValue({ auth_aadhar_upload: obj['name'] })
                            authaadhar.push(obj);
                        }
                        if (ele.doc_group === 'authPAN') {
                            form.setFieldsValue({ auth_pan_no_f: obj['name'] })
                            authpanlist.push(obj);
                        }
                    }
                });

                setLegalPanFileList(panlist);
                setLegalGST(gstlist);
                setaddressFileList(addresslist);
                setAuthPanFiles(authpanlist);
                setaadharFiles(authaadhar);
                setPassBookFiles(passbook);
                setAgreementFiles(agreement);
                setLoader(false);
            } else {
                setLegalPanFileList([]);
                setLegalGST([]);
                setaddressFileList([]);
                setAuthPanFiles([]);
                setaadharFiles([]);
                setPassBookFiles([]);
                setAgreementFiles([]);
                setLoader(false);
                setLegalPanFileList([]);
                setLegalGST([]);
                setaddressFileList([]);
                setAuthPanFiles(authpanlist);
                setaadharFiles(authaadhar);
                setPassBookFiles(passbook);
                setAgreementFiles(agreement);

            }
        } catch (e) {
            setLoader(false);
        }
    }
    useEffect(() => {
        if (vendorId) {
            GET_VENDOR_DOCS();
        }
    }, [vendorId])

    useEffect(() => {
        let registerDetails: any = RegistrationFormData;
        if (registerDetails) {
            let UploadDocuments: any = registerDetails['UploadDocuments'];
            if (UploadDocuments && Object.keys(UploadDocuments)) {
                form.setFieldsValue(UploadDocuments);
            }
            let registrationDetails: any = registerDetails['PersonalDetails'];
            let OtherDetails: any = registerDetails['OtherDetails'];
            if (registerDetails['vendorId']) {
                setVendorId(registerDetails['vendorId']);
            }
            let gstValue: any
            if (registrationDetails && Object.keys(registrationDetails)) {
                if (OtherDetails['gst_prefix'] + OtherDetails['gst_tax_regn_no']) {
                    // gstValue = OtherDetails['gst_prefix'] + OtherDetails['gst_tax_regn_no']
                    gstValue = OtherDetails['gst_prefix'] + OtherDetails['gst_tax_regn_no']
                } else {
                    gstValue = registrationDetails['gstValue']
                }
                form.setFieldsValue({
                    legal_pan_no: registrationDetails['pancard'],
                    legal_gst_cert: gstValue,
                    auth_aadhar: registrationDetails['aadhar_no'],
                    auth_pan_no: registrationDetails['pancard'],
                });
            }
            if (OtherDetails && Object.keys(OtherDetails)) {
                setIdProof(OtherDetails['id_proof'])
            }

        }
    }, [RegistrationFormData]);

    const [formLayout, setFormLayout] = useState<LayoutType>('horizontal');

    const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
        setFormLayout(layout);
    };

    const formItemLayout = {};

    const onFinish = (values: any) => {
        let params: any = {
            type: 'UploadDocuments',
            data: values,
            next: true,
        }
        props.handleSubmit(params);
    }



    const cusheader = {
        borderRadius: "5px",
        margin: "5px",
        background: "#FFF",
        padding: "10px",
    }
    const TermChecklist = () => {
        setCheckListPopup(true)
    }
    const sendDataToParent = (index: any) => {

        setCheckListPopup(index)
    }
    const submitFn = (index: any) => {

        setOnSubmit(index.target.checked)
    }
    return (
        <div className="upload-docs-container ezauto-regis-container">
            <Row gutter={8} style={{ justifyContent: "center" }}>
                <Col span={22}>
                    <Spin spinning={loader}>
                        <Card style={CardStyle}>
                            <Form
                                className="registration-details-form upload-documents-form"
                                {...formItemLayout}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 30 }}
                                layout={'vertical'}
                                form={form}
                                initialValues={{ layout: formLayout }}
                                onValuesChange={onFormLayoutChange}
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                labelAlign={"left"}
                                //labelWrap={"5px"}
                                autoComplete="off"
                            >
                                <div className="UploadDocuments" style={{ display: 'flex', }}>

                                    <div style={{ width: "100%", padding: "10px" }}>
                                        <CustomHeader title={"Documents To be Uploaded For LegalEntity"} />
                                        <div className="header-separator" style={cusheader}>
                                            <Form.Item style={{ marginLeft: "5px" }} label="PAN No" name="legal_pan_no" rules={[{ required: true }]}>
                                                <Input name="legal_pan_no" readOnly={true} />
                                            </Form.Item>
                                            <Row style={{ marginTop: "5px", marginLeft: "5px" }} gutter={5}>
                                                <Form.Item name="legal_pan_no_f" rules={[{ required: true, message: "Please upload file" }]} >
                                                    <Upload {...propsList} name="legal_pan_no_file" fileList={legalPanFileList}>
                                                        <Button className="upload-btn" icon={<UploadOutlined />}>Click to upload</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Row>
                                            <Form.Item style={{ marginLeft: "5px" }} label="GST Cert" name="legal_gst_cert">
                                                <Input />
                                            </Form.Item>
                                            <Row style={{ marginTop: "5px", marginLeft: "0px" }} gutter={5}>
                                                <Upload {...propsList} name="gst_cert_file" fileList={legaGST} >
                                                    <Button className="upload-btn" icon={<UploadOutlined />}>Click to upload</Button>
                                                </Upload>
                                            </Row>






                                        </div>
                                        <CustomHeader title={"Address Proof"} />
                                        <div className="header-separator" style={cusheader}>
                                            <Form.Item label={idProofLabel} name="add_telephone_no">
                                                <Upload {...propsList} name="address_file" fileList={addressFileList}>
                                                    <Button className="upload-btn" icon={<UploadOutlined />}>Click to upload</Button>
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                        <CustomHeader title={"Documents To be Uploaded For Authorised Signatory"} />
                                        <div className="header-separator" style={cusheader}>
                                            <Form.Item label="PAN No" name="auth_pan_no" rules={[{ required: true }]} >
                                                <Input readOnly={true} />
                                            </Form.Item>
                                            <Row style={{ marginTop: "5px", marginLeft: "0px" }} gutter={5}>
                                                <Form.Item name="auth_pan_no_f" rules={[{ required: true, message: "Please upload file" }]} >
                                                    <Upload {...propsList} name="auth_pan_file" fileList={authPan}>
                                                        <Button className="upload-btn" icon={<UploadOutlined />}>Click to upload</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Row>
                                            <Form.Item label="Aadhar Copy" name="auth_aadhar" rules={[{ required: true }]}>
                                                <Input name="auth_aadhar" />
                                            </Form.Item>
                                            <Row style={{ marginTop: "5px", marginLeft: "0px" }} gutter={5}>
                                                <Form.Item name="auth_aadhar_upload" rules={[{ required: true, message: "Please upload file" }]} >
                                                    <Upload {...propsList} name="aadhar_copy_file" fileList={aadharFiles}>
                                                        <Button className="upload-btn" icon={<UploadOutlined />}>Click to upload</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Row>
                                        </div>
                                        <Checkbox onChange={submitFn}></Checkbox>
                                        &nbsp;&nbsp;
                                        <strong style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={TermChecklist}>I accept the above Terms & Conditions</strong>
                                    </div>

                                    <div className="UploadStyle">
                                        <div className="header-separator" style={cusheader}>
                                            <Form.Item labelCol={{ span: 24 }} label="Cancelled Check/Bank Statement/Passbook" name="cancelled_passbook" rules={[{ required: true }]}>

                                                <Upload {...propsList} name="cancelled_passbook" fileList={passBookFiles} >
                                                    <Button className="upload-btn" icon={<UploadOutlined />}>Click to upload</Button>
                                                </Upload>

                                            </Form.Item>
                                            <Form.Item labelCol={{ span: 24 }} label="Shops and Established Licent or factory Agreement Certificate" name="agreement_certificate" >
                                                <Upload {...propsList} name="agreement_certificate" fileList={agreementFiles} >
                                                    <Button className="upload-btn" icon={<UploadOutlined />}>Click to upload</Button>
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <Row style={{ marginTop: "20px" }} gutter={[24, 24]} align="middle" justify="end" >
                                    <Col>
                                        <Form.Item >
                                            <Button onClick={() => props.NavigatePrevious()} type="primary" className="ant-btn-prev">Previous</Button>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item >
                                            <Button htmlType="submit" className="ant-btn-login" type="primary" disabled={!onSubmit}>Submit</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Spin>
                </Col>
            </Row>
            {checkListPopup && <TermsAndCondPopUp display={true} sendDataToParent={sendDataToParent} />}

        </div>

    );
}
