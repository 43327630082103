import { Divider, Layout } from "../../../components/Elements";
import { HeaderNav } from "./HeaderNav";
import { SideNav } from "./SideNav";
import "./layout.scss";
import { FooterLayout } from "./Footer";
import { useContext } from "react";
import { UserContext, UserContextType } from "../../../providers/UserContext";
import storage from "../../../utils/storage";
import { useEffect } from 'react';

const { Footer } = Layout
export const EZLayout = ({ children }: any) => {

    const { User, SaveUser } = useContext(UserContext) as UserContextType;
    const { Content } = Layout
    let vendorId: any;
    vendorId = storage.getValue("vendorId")
    let regisrationStatus: any;
    let token: any;
    token = storage.getValue("token")
    regisrationStatus = storage.getValue("regisrationStatus")

    useEffect(() => {
        console.log(vendorId, "IN LAYOUT", regisrationStatus)
        if (vendorId && regisrationStatus == "success" && token == "changePassword") {
            SaveUser({ ...User, isLoggedIn: true, details: { regisrationStatus: "success" }, token: "changePassword" })
        }
        else if (vendorId && regisrationStatus == "success") {
            SaveUser({ ...User, isLoggedIn: true, details: { regisrationStatus: "success" } })
        } else if (vendorId && regisrationStatus == "kycSubmitted") {
            SaveUser({ ...User, isLoggedIn: true, details: { name: "E-Z Auto", regisrationStatus: "kycSubmitted" } })
        }


    }, [regisrationStatus])

    // console.log("children",children)
    const currentRoute = window.location.pathname;


    return (
        <Layout className="main-layout-container">
            {/* <HeaderNav /> */}
            {currentRoute !== '/login' && currentRoute !== '/' && currentRoute !== '*' && (
        <>
            <HeaderNav />
            <Divider className="header-divider" />
        </>
    )}
            <Layout>
                {
                    // (User && User.details.regisrationStatus == 'success' && User.token != "changePassword") && <SideNav />
                    (User && User.details.regisrationStatus == 'success' && User.token != "changePassword" && !['/', '*', '/login'].includes(currentRoute)) && <SideNav />

                }



                <Content style={{ margin: "0 15px 0", overflow: "initial", position: "relative" }}>
                    {children}
                </Content>
                {/* <FooterLayout /> */}
                {currentRoute !== '/login' && currentRoute !== '/' && currentRoute !== '*' &&  <FooterLayout />}

            </Layout>

        </Layout>
    );
}