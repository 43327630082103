import { Card, Input, Spin, Col, Row, Button, message, Checkbox } from '../../../../components/Elements'

import { VerifyModal } from '../../../../components/modals';
import { useEffect, useState, useRef, useContext } from "react";
import { getVendorProfiles } from "../../../../services/dashboard.services";
import { KycPopUp } from '../../../Common/Popup/kycPopup'
import { IagPopUp } from '../../../Common/Popup/IagPopup';
import { GstPopUp } from '../../../Common/Popup/GstPopup';
import { VideoPopup } from '../../../Common/Popup/videoPopup';
import storage from '../../../../utils/storage';
import { sendOtpForEmailVerification, verifyOtp, updateVendorDetails, updateVendorGSTNumber, isMatchedOTP, verifyEmailOtp } from '../../../../services/shared.service';
import { OTPVerify } from '../../../../widgets/Register/components/OTPVerifyForn';
import { GcppcPopup } from '../../../Common/Popup/GcppcPopup';
import { Locations } from '../../../../widgets/Register/components';
import { MarutiPopUp } from '../../../Common/Popup/MarutiPopUp';
import { UserContext, UserContextType } from "../../../../providers/UserContext";
import { GET_VALIDATION_DATA } from "../../../../../src/widgets/Register/api/index";
import { useNavigate } from 'react-router-dom';



export const ProfileComponent = () => {

    const VerifyModelRef = useRef<any>();

    const [profData, setProfData] = useState<any>()
    const [loading, setLoading] = useState(true)
    const [popup, setPopup] = useState<any>(false)
    const [gstPopUp, setGstPopUp] = useState<any>(false)
    const [iagPopUp, setIagPopUp] = useState<any>(false)
    const [pop, setPop] = useState(false)
    const [popupVideo, setPopupVideo] = useState(false)
    const [mobileNumber, setMobileNumber] = useState<any>()
    const [authmobileNumber, setAuthMobileNumber] = useState<any>()
    const [emailVerify, setEmailVerify] = useState<any>()
    const [prevEmail, setPrevEmail] = useState<any>("");
    const [prevMobile, setPrevMobile] = useState<any>("");
    const [prevAuthMobile, setPrevAuthMobile] = useState<any>("");
    const [email, setEmail] = useState<any>()
    const [gst, setGst] = useState<any>()
    const [pan_number, setPan_number] = useState<any>()
    const [gstDisable, setGstDisable] = useState(true)
    const [mobileVerifyOTP, setMobileVerifyOTP] = useState<any>();
    const [gcppcPopup, setGcppcPopup] = useState(false)
    const [checked, setChecked] = useState(false)
    const [upgradeShow, setUpgradeShow] = useState(false)
    const [location, setLocation] = useState(false)
    const [locData, setLocData] = useState<any>()
    const [marutiPopup, setMarutiPopup] = useState(false)
    const [defaultChecked, setDefaultChecked] = useState(false)
    const [discheck, setDischeck] = useState(true)
    const [isPhoneChanged, setIsPhoneChanged] = useState(false);
    const [isEmailChanged, setIsEmailChanged] = useState(false);
    const [isAuthPhoneChanged, setIsAuthPhoneChanged] = useState(false);
    const [isOTPValidated, setIsOTPValidated] = useState(false);
    const [isEmailOTPValidated, setIsEmailOTPValidated] = useState(false);
    const [state, setState] = useState({ validationMessage: '', trigger: 0 });
    const { User, SaveUser } = useContext(UserContext) as UserContextType;
    const [isEmailVerification, setIsEmailVerification] = useState(true);
    const [gstData, setGstData] = useState<any>();



    let vendorId: any;
    vendorId = storage.getValue("vendorId")
    let vendor_num: any
    vendor_num = storage.getValue('phoneNo')
    let marutiPopUpCount: any;
    marutiPopUpCount = storage.getValue('popCount')



    useEffect(() => {

        SaveUser({ ...User, token: "aaa" })

        function setProfileData(profData: any) {
            if (profData.user_name) {
                storage.setValue('userName', profData.user_name);
            }
            if ((profData.eligibleForMaruti === true && marutiPopUpCount === "0") && (profData.isVendorAgreedForMarutiRates === undefined || profData.isVendorAgreedForMarutiRates === false)) {
                setMarutiPopup(true);
            }

            let location: any = profData.addresses;
            setLocData(location);
            if (profData.Invoice_payment_flag == 'GC-PPC') {
                setUpgradeShow(false);
                setChecked(false);
                setDefaultChecked(true);
                setDischeck(false);
            } else {
                setUpgradeShow(true);
            }

            setAuthMobileNumber(profData.auth_signatory.mobile_number);
            setMobileNumber(profData.mobile_Number);
            setEmail(profData.vendor_email);
            if (profData.service_tax_registration_num != null && profData.service_tax_registration_num != undefined && profData.service_tax_registration_num != "") {
                setPan_number(profData.service_tax_registration_num);
                setGstDisable(false);


            } else {
                setPan_number('');

            }
        }

        getVendorProfiles(vendorId).then((resp: any) => {
            storage.setValue('phoneNo', resp.message.auth_signatory.mobile_number)
            storage.setValue('gstNo', resp.message.service_tax_registration_num)
            if (resp.message.fleetPicture != null) {
                storage.setValue('fleetPicture', resp.message.fleetPicture.img)
            }
            setProfileData(resp.message);
            setProfData(resp.message)
            if (resp.message?.gst_details) {
                console.log("GST DATA", resp.message.gst_details)
                setGstData(resp.message.gst_details)

            }
            setLoading(false)
            setLocation(true)
        })



    }, [])



    useEffect(() => {
        const handleBackButton = (event: PopStateEvent) => {
            event.preventDefault();
            event.stopPropagation();
            // You can add more logic here if needed
        };

        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', handleBackButton);
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);




    const getMessages = async (key: String, isError: Boolean) => {
        let response: any = await GET_VALIDATION_DATA({ key, is_error: isError });
        setState(prevState => ({ validationMessage: response.message, trigger: prevState.trigger + 1 }));
    }
    useEffect(() => {
        if (state.validationMessage && state.validationMessage.length > 0) {
            if (state.validationMessage !== 'Invalid OTP, please try again!') {
                message.success(state.validationMessage);
            }
            else {
                message.error(state.validationMessage);
            }
        }
    }, [state]);


    const onClick = () => {
        setPopup(true)
    }
    const iagClick = () => {
        setIagPopUp(true)
    }
    const gstClick = () => {
        setGstPopUp(true)
    }
    const sendDataToIag = (index: any) => {
        console.log("INDEZ", index)
        setIagPopUp(index)
    }
    const sendDataToGst = (index: any) => {

        console.log("INDEZ", index)
        setGstPopUp(index)
    }
    const sendDataToParent = (index: any) => {
        console.log("INDEZ", index)
        setPopup(index)
    }

    const sendDataToGcppc = (index: any) => {
        console.log("INDEZ", index)
        setMarutiPopup(index)
    }

    const sendDataToMaruti = (index: any) => {
        console.log("INDEZ", index)
        setGcppcPopup(index)
    }
    const videoPop = () => {
        setPopupVideo(true)
    }
    const sendDataTovideoPop = (index: any) => {
        console.log("INDEZ", index)
        setPopupVideo(index)
    }

    const mobileHandler = (event: any) => {
        setMobileNumber(event.target.value)

        if (event.target.value !== profData.mobile_Number && event.target.value !== prevMobile) {
            setIsPhoneChanged(true);
        } else {
            setIsPhoneChanged(false);
        }


    }

    const phoneVerify = async () => {
        setIsEmailVerification(false)
        if (!validatePhoneNumber(mobileNumber)) {
            message.error("Please enter valid mobile number.")
            return;
        }
        let res: any = await verifyOtp(mobileNumber, vendorId)
        if (res.status == "success") {
            setMobileVerifyOTP(res.message);
            setPrevMobile(mobileNumber);
            // setIsPhoneChanged(false);
            openModal()
        } else {
            message.error("Please try again later.")
        }


    }

    const validatePhoneNumber = (phoneNumber: any) => {
        const regex = /^\d{10}$/;
        return regex.test(phoneNumber);
    };


    const authmobileHandler = (event: any) => {
        setAuthMobileNumber(event.target.value)
        if (event.target.value !== profData?.auth_signatory?.mobile_number && event.target.value !== prevAuthMobile) {
            setIsAuthPhoneChanged(true);
        } else {
            setIsAuthPhoneChanged(false);
        }
    }

    const authphoneVerify = async () => {
        setIsEmailVerification(false)
        if (!validatePhoneNumber(authmobileNumber)) {
            message.error("Please enter valid mobile number.")
            return;
        }
        let res: any = await verifyOtp(authmobileNumber, vendorId)
        if (res.status == "success") {
            setMobileVerifyOTP(res.message)
            setPrevAuthMobile(authmobileNumber);
            openModal()
        } else {
            message.error("Please try again later.")
        }


    }


    const emailHandler = (event: any) => {
        setEmail(event.target.value)
        if (event.target.value !== profData.vendor_email && event.target.value !== prevEmail) {
            setIsEmailChanged(true);
        } else {
            setIsEmailChanged(false);
        }
    }
    const gstHandler = (event: any) => {
        setGst(event.target.value)
    }
    const emailVerification = async () => {
        setIsEmailVerification(true)
        let res: any = await sendOtpForEmailVerification(email, vendor_num)
        if (res.status == "Success") {
            // setMobileOTP(res.message)
            setPrevEmail(email);
            openModal()
        } else {
            message.error("Please try again later.")
        }

    }
    const gstVerification = async () => {
        let pattern: any = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-8]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/g
        let data: any = gst.match(pattern)
        if (data != null) {
            let payload: any = {
                "gstNum": gst,
                "vendorId": vendorId
            }
            let res: any = await updateVendorGSTNumber(payload)
            if (res.status == "success") {
                alert("GST updated successfully")
            } else {
                alert("Error while updating GST number")
            }
        } else {
            alert("Please enter valid GST Number")
        }
    }
    const antLayoutContent = {
        margin: "80px 15px 0px"
    }



    const openModal = () => {
        VerifyModelRef.current.onShowModal();
    }
    // const closeModal = (event: any = {}) => {
    //     VerifyModelRef.current.onCloseModal();
    // }
    const closeModal = (verificationType: string) => {
        if (verificationType === "email") {
            // close email modal
            setIsEmailChanged(false);
        } else if (verificationType === "mobile") {
            // close mobile modal
            setIsPhoneChanged(false);
        }
        VerifyModelRef.current.onCloseModal();
    }


    const validateMobileOTP = async (event: any) => {
        let response = await isMatchedOTP(event.validate_otp, vendorId);
        if (response.message == "OTP matched") {
            // if (event.validate_otp && event.validate_otp == mobileVerifyOTP) {
            // setMobileVerified({ value: form.getFieldValue('mobile_no'), verified: true })
            // message.success("Validated Successfully.")
            setIsOTPValidated(true);
            console.log("OTP validated successfully")
            await getMessages('S03', false);

            let payload: any = {
                "vendorId": vendorId,
                "mobileNumber": mobileNumber,
                "authorisedNumber": authmobileNumber
            }
            updateVendorDetails(payload)
            closeModal("mobile");
            setIsPhoneChanged(false);
            setIsAuthPhoneChanged(false);
        } else {
            // message.error("Please enter valid OTP.")
            setIsOTPValidated(false);
            console.log("OTP validation failed")

            await getMessages('E01', true);
        }
        setIsOTPValidated(false);

    }

    const validateEmailOTP = async (event: any) => {
        console.log("Hii")
        let response = await verifyEmailOtp(email, event.validate_otp);
        if (response.status == "success") {
            setIsEmailOTPValidated(true);
            await getMessages('S03', false);
            let payload: any = {
                "vendorId": vendorId,
                "email": email
            }
            updateVendorDetails(payload);
            closeModal("email");
            setIsEmailChanged(false);


        } else {
            setIsEmailOTPValidated(false);
            await getMessages('E01', true);
        }
        setIsEmailOTPValidated(false);
    }
    const gcppcUpgrade = () => {
        setGcppcPopup(true)
    }

    const check = (e: any) => {

        setChecked(e.target.checked)
    }

    const navigate = useNavigate();

    if (!User || !User.isLoggedIn) {
        navigate('/login');
        return null;
    } else {
        console.log("isEmailVerification", isEmailVerification)

        return (

            <>

                {
                    loading ?
                        <div style={antLayoutContent}>

                            <div className="example" style={{ textAlign: 'center' }}>
                                <Card className='ant-content'>
                                    <Spin />
                                </Card>
                            </div>
                        </div>
                        :
                        <div style={antLayoutContent}>
                            <Row className='ant-content'>
                                <div className='ProfileStyle'>
                                    <div className='ant-card-body ant-bg mrg-15' style={{ width: "100%" }}>


                                        <h4>Vendor Name  &nbsp;: &nbsp;{profData.entityName}</h4>

                                    </div>
                                    <div className='ant-card-body ant-bg marginDiv' style={{ width: "100%" }} >
                                        <h4>Status &nbsp;: &nbsp;{profData.vendor_reg_status}</h4>
                                    </div>
                                </div>
                                {/* <Col span={12}><Col className='ant-card-body ant-bg ' span={24}>
                                    <h4>Status &nbsp;: &nbsp;{data.vendor_reg_status}</h4>
                                </Col></Col> */}

                            </Row>
                            <Card className="ant-content" style={{ borderRadius: "20px", margin: '1px' }}>
                                <p>For Payment & billing related query contact Helpline no - 022 69635301, operational Monday to Friday, 9.30am to 6.30pm.</p>
                                <p>For Ongoing case related query contact Toll free no - 1800 209 7373, available 24 x 7.</p>
                            </Card>

                            <Col span={28}>
                                <Card className="ant-content" style={{ borderRadius: "30px", margin: '1px' }}>
                                    <div className="ProfileStyle">
                                        <div className='col-pad' style={{ width: "100%", padding: "10px" }}>
                                            <div className='verify-wrap'>
                                                <div>
                                                    <label className='label-title'>User ID:</label>
                                                    <Input className='inputs-mrg' value={profData.user_name} style={{ color: "#0c3b93", }} disabled />
                                                </div>
                                                <div>
                                                    <label className='label-title'>Entity Name:</label>
                                                    <Input className='inputs-mrg' value={profData.entityName} style={{ color: "#0c3b93", }} disabled />
                                                </div>
                                                <div style={{ width: "100%", display: "inline-flex" }}>
                                                    <div style={{ width: "83%" }}>
                                                        <label className='label-title'>Email:</label>
                                                        <Input className='inputs-mrg' defaultValue={profData.vendor_email} style={{ color: "#0c3b93", }} onChange={emailHandler} autoComplete="new-password" />

                                                    </div>
                                                    <div className='m-bt-30' style={{ width: "16%", marginLeft: "10px" }}>
                                                        <Button className='ant-btn-login verify-btn-mrg' onClick={emailVerification} disabled={!isEmailChanged || isEmailOTPValidated}>
                                                            Verify
                                                        </Button>

                                                    </div>
                                                </div>
                                                <div style={{position:'relative'}}>
                                                    <label className='label-title'>GST No:</label>
                                                    <Input className='inputs-mrg' defaultValue={pan_number} style={{ color: "#0c3b93", }} onChange={gstHandler} disabled={!gstDisable} />
                                                    {
                                                        gstDisable &&
                                                        <Button className='ant-btn-login verify-btns ' onClick={gstVerification}>
                                                            Verify
                                                        </Button>
                                                    }
                                                </div>
                                                <div>
                                                    <label className='label-title'>Languages:</label>
                                                    <Input className='inputs-mrg' defaultValue={profData.vendor_language} style={{ color: "#0c3b93", }} disabled={true} />

                                                </div>

                                                <label className='label-title'>Green Channel-Premium Partner Club:</label>

                                                {/* <Input className='inputs-mrg' disabled /> */}

                                                <div className=" m-b-10 newUpgrade" >
                                                    <Checkbox onChange={check} defaultChecked={defaultChecked} disabled={!discheck} />

                                                    {
                                                        upgradeShow &&
                                                        <Button className='ant-btn UpgradeBtn ' onClick={gcppcUpgrade} disabled={!checked}>
                                                            Upgrade
                                                        </Button>
                                                    }
                                                </div>

                                                <div></div>
                                            </div>


                                        </div>

                                        <div className='col-pad' style={{ width: "100%", padding: "10px" }}>
                                            <div>
                                                <div>
                                                    <label className='label-title'>Password:</label>
                                                    <Input className='inputs-mrg' value={'******'} style={{ color: "#0c3b93", }} disabled />

                                                </div>
                                            </div>

                                            <div>
                                                <div>
                                                    <label className='label-title'>Country:</label>
                                                    <Input className='inputs-mrg' value={"India"} style={{ color: "#0c3b93", }} disabled />
                                                </div>
                                            </div>

                                            <div style={{ width: "100%", display: "inline-flex" }}>
                                                <div style={{ width: "83%" }}>
                                                    <label className='label-title'>Phone:</label>
                                                    <Input className='inputs-mrg' defaultValue={profData.mobile_Number} style={{ color: "#0c3b93", }} onChange={mobileHandler} autoComplete="new-password" />
                                                </div>
                                                <div className='m-bt-30' style={{ width: "16%", marginLeft: "10px" }}>
                                                    <Button className='ant-btn-login verify-btn-mrg' onClick={phoneVerify} disabled={!isPhoneChanged || isOTPValidated}>
                                                        Verify
                                                    </Button>
                                                </div>
                                            </div>

                                            <div style={{ width: "100%", display: "inline-flex" }}>
                                                <div style={{ width: "83%" }}>
                                                    <label className='label-title'>Authorised Phone:</label >
                                                    <Input className='inputs-mrg' defaultValue={profData.auth_signatory.mobile_number} style={{ color: "#0c3b93", }} onChange={authmobileHandler} autoComplete="new-password" />

                                                </div>
                                                <div className='m-bt-30' style={{ width: "16%", marginLeft: "10px" }}>
                                                    <Button className='ant-btn-login  verify-btn-mrg' onClick={authphoneVerify} disabled={!isAuthPhoneChanged || isOTPValidated}>
                                                        Verify
                                                    </Button>
                                                </div>
                                            </div>

                                            {/* <div style={{ width: "100%", display: "inline-flex" }}> */}
                                            <div>
                                                <label className='label-title'>Preferred Language:</label >
                                                <Input className='inputs-mrg' defaultValue={profData.preffered_language} style={{ color: "#0c3b93", }} disabled={true} />
                                            </div>
                                            {/* </div> */}




                                            <label className='label-title ' onClick={videoPop} style={{ color: "#0c3b93", textDecoration: 'underline', cursor: 'pointer', }}>Video Assistance </label>
                                            <label className='label-title  mrg-ltr' onClick={onClick} style={{ color: "#0c3b93", textDecoration: 'underline', cursor: 'pointer', }} >KYC Details Update</label>
                                            {/* <label className='label-title  mrg-ltr' onClick={iagClick} style={{ color: "#0c3b93", textDecoration: 'underline', cursor: 'pointer', }} >IAG Details</label>  */}
                                            <label className='label-title  mrg-ltr' onClick={gstClick} style={{ color: "#0c3b93", textDecoration: 'underline', cursor: 'pointer', }} >GST Details</label>
                                        </div>
                                    </div>

                                    <div>
                                        {location && <Locations Locations={profData} page={"dashboard"} />}

                                    </div>

                                </Card>
                                <div className='ButtomMargin'>


                                </div>
                                <div>
                                    {popupVideo && <VideoPopup display={true} sendDataTovideoPop={sendDataTovideoPop} />}
                                </div>
                                <div>
                                    {popup && <KycPopUp sendDataToParent={sendDataToParent} data={profData} />}
                                </div>
                                <div>
                                    {iagPopUp && <IagPopUp sendDataToIag={sendDataToIag} data={profData} />}
                                </div>
                                <div>
                                    {gstPopUp && <GstPopUp sendDataToGst={sendDataToGst} visible={gstPopUp} onClose={() => setGstPopUp(false)} data={gstData} />}
                                </div>

                            </Col>
                        </div>

                }
                <VerifyModal ref={VerifyModelRef} title={"Please Enter OTP"} width={500}>
                    {/* <OTPVerify  verificationType={isEmailVerification ? "email" : "mobile"}  closeModal={() => closeModal(isEmailVerification ? "email" : "mobile")} 
                        //  validateOTP={($event: any) => isEmailVerification ? validateEmailOTP($event) : validateMobileOTP($event)} 
                        {
                            isEmailVerification 
                              ? <OTPVerify validateEmailOtp={($event: any) => validateEmailOTP($event)} />
                              : <OTPVerify validateMobileOTP={($event: any) => validateMobileOTP($event)} />
                          }
                         
                         /> */}
                    {isEmailVerification
                        ? <OTPVerify
                            verificationType="email"
                            closeModal={() => closeModal("email")}
                            validateEmailOTP={($event: any) => validateEmailOTP($event)}
                        />
                        : <OTPVerify
                            verificationType="mobile"
                            closeModal={() => closeModal("mobile")}
                            validateMobileOTP={($event: any) => validateMobileOTP($event)}
                        />
                    }
                </VerifyModal>

                {
                    gcppcPopup && <GcppcPopup sendDataToGcppc={sendDataToGcppc} />
                }
                {
                    marutiPopup && <MarutiPopUp sendDataToMaruti={sendDataToMaruti} />
                }


            </>

        )
    }
}