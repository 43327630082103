import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { CardStyle } from "../../../widgets/Register/helpers/formcsshelper";
import { Form, Input, Row, Col, Card, Table, InputNumber } from "../Designs";

interface Item {
    key: string;
    Towing_Services: string;
    Base_Rate: any;
    name: string;
    age: number;
    address: string;
}


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Item;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {

    let cusRecord: any = record;
    let fieldValue: any = cusRecord && dataIndex && cusRecord[dataIndex];
    fieldValue = fieldValue ? fieldValue : '';
    let cusProps: any = restProps;
    let rules: any = (cusProps.col && cusProps.col.rules) ? cusProps.col.rules : [];
    let attributes = (cusProps.col && cusProps.col.attributes) ? cusProps.col.attributes : {};
    const inputNode = inputType === 'number' ? <InputNumber  {...attributes} /> : <Input {...attributes} />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    initialValue={fieldValue}
                    style={{ margin: 0 }}
                    rules={rules}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


export const EditableTable = (props: any) => {

    const form = props.form;
    const dataset = props.data;
    const [data, setData] = useState(dataset);
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record: Item) => record.key === editingKey;

    useEffect(() => {
        setEditingKey(props.editingKey);
    }, [props.editingKey])

    useEffect(() => {
        if (form && Object.keys(form).length) {
            form.setFieldsValue({ ...props.editingRecord });
        }
    }, [props.editingRecord])

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    const columns: any = props.columns;

    const mergedColumns = (col: any) => {

        if (!col.editable) {
            return col;
        }

        let newCol = {
            ...col,
            onCell: (record: any) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                elename: props.name,
                col: col
            }),
        };
        if (col.children) {
            newCol.children = col.children.map(mergedColumns);
        }
        return newCol;
    }

    const columnsList = columns?.map(mergedColumns);

    return (
        <div className="ez-autos-editable-table">
            <Table
                components={{
                    body: {
                        cell: EditableCell
                    },
                }}
                className="ez-aeditable-table"
                bordered
                dataSource={data}
                columns={columnsList}
                rowClassName="editable-row"
                pagination={false}
            />
        </div>
    );
}
