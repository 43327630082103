import { Modal, Table, Select, Upload, Input, SaveOutlined, Button, message, Row, Col, } from "../../../components/Elements"
import { useState, useEffect, useRef } from 'react';
import { axios } from "../../../http-service/service"
import { getVendorDisputeDocument, deleteDisputeDoc, disputeRaiseRequest } from '../../../services/shared.service';

export const DisputePopup = (props: any) => {

    const [visible, setVisible] = useState(false)
    const [disputeobj, setDisputeobj] = useState<any>({})
    const [file, setFile] = useState<any>()
    const [reason, setReason] = useState([])
    const [reasonTable, setReasonTable] = useState(false)
    const [value, setValue] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [calculatedAmount, setCalculatedAmount] = useState<any>()
    const [disputeDocArray, setDisputeDocArray] = useState([])
    const [isFileSaved, setIsFileSaved] = useState<boolean>(false)


  


    let disputeObject: any = {
        "claimNumber": props.data[0].claimNumber,
        "serviceNumber": props.data[0].serviceNumber,
        "totalAmount": props.data[0].totalAmount
    }

    // setCalculatedAmount(parseInt(disputeObject.totalAmount))
    useEffect(() => {
        setDisputeobj(disputeObject)
        let amount: any = parseInt(disputeObject.totalAmount)
        setCalculatedAmount(amount)
    }, [])

    useEffect(() => {
        let arr1: any = [];
        if (reason.length > 0) {
            reason.forEach(function (ele: any, index: any) {
                if (ele.documents_ref) {

                    arr1.push(ele.documents_ref)
                    // setDisputeDocArray[...disputeDocArray, dey]
                }
            })

        }
        setDisputeDocArray(arr1)

    }, [reason])
    console.log("DATA dispute", disputeDocArray)


    const { Option } = Select;
    useEffect(() => {
        if (props.display === true) {
            setVisible(true)
        }
    }, [props.display])

    const onClick = () => {
        setVisible(false)
        props.sendDataToDispute(false)
    }



    const columns: any = [
        {
            title: 'Case ID',
            dataIndex: 'claimNumber',
            align: 'center'
        }, {
            title: 'Service ID',
            dataIndex: 'serviceNumber',
            align: 'center'
        }, {
            title: 'Amount',
            dataIndex: 'EaiAmount',
            align: 'center'
        }
    ]

    const rows: {
        key: number
    }[] = [];
    if (props.data) {
        props.data.map((i: any, j: any) => {
            const obj = i
            obj.key = j + 1
            rows.push(obj)
            return rows
        })
    }

    const columnsReason: any = [
        {
            title: 'Reasons',
            dataIndex: 'disputeReason',
            align: 'center'
        }, {
            title: 'Amount',
            dataIndex: 'disputeAmount',
            align: 'center'
        }, {
            title: 'Action',
            align: 'center',
            render: (params: any) => {
                const onClick = (e: any) => {
                    async function fetchimage() {
                        try {
                            let data: any = await getVendorDisputeDocument(params.documents_ref)

                        } catch (err) {
                            return null
                        }
                    }
                    fetchimage()
                }
                const onDelete = () => {
                    async function deteleimage() {
                        try {
                            let data: any = await deleteDisputeDoc([params.documents_ref])
                            if (data) {
                                if (data.status == "success") {
                                    let number: number = (Math.floor(calculatedAmount) - Math.floor(params.disputeAmount))
                                    setCalculatedAmount(number)
                                    message.success("Dispute document deleted successfully.")
                                    let arr: any = reason
                                    arr.splice(arr.findIndex((x: any) => x.key === params.key), 1)
                                    setReasonTable(false)
                                    if (arr.length > 0) {
                                        setReasonTable(true)
                                    }
                                    setReason(arr)
                                } else {
                                    message.error("Please try again.")
                                }

                            } else {
                                message.error("Please try again.")
                            }

                        } catch (err) {
                            return null
                        }
                    }
                    deteleimage()

                }

                return (
                    <>
                        <Button className='ant-btn-view ' onClick={onClick} style={{ color: '#0c3b93' }}>View File</Button>
                        <Button className='ant-btn-prev' onClick={onDelete}>Delete</Button>
                    </>

                );
            },
        }

    ]

    let rowsReason: {
        key: number
    }[] = [];
    if (reason.length > 0) {
        reason.map((i: any, j: any) => {
            const obj = i
            obj.key = j + 1
            rowsReason.push(obj)
            return rowsReason
        })
    }


    function handleChange(value: any) {
        setValue(`${value}`)
        setDisputeobj({ ...disputeobj, "disputeReason": `${value}` })
    }

    const onChangeHandler = (event: any) => {

        setFile(event.target.files[0])
        let dispFile: any = event.target.files[0];
        setDisputeobj({ ...disputeobj, "DisputeFile": dispFile })

    }

    const onSave = async () => {

        let formData: any = new FormData();
        formData.append('DisputeFile', file)
        formData.append('claimNumber', disputeobj.claimNumber)
        formData.append('serviceNumber', disputeobj.serviceNumber)
        formData.append('totalAmount', disputeobj.totalAmount)
        formData.append('disputeReason', disputeobj.disputeReason)
        formData.append('disputeAmount', disputeobj.disputeAmount)


        let config: any = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        try {
            if (file) {
                let res: any = await axios.post('/api/vendor_v2/diputeFileUpload', formData, config)
                if (res.status == "success") {
                    let number: any = (Math.floor(calculatedAmount) + Math.floor(disputeobj.disputeAmount))
                    setCalculatedAmount(number)
                    setIsFileSaved(true);
                    message.success("Dispute file saved successfully!")
                    let arr: any = res.DisputeDoc.Reasons
                    setReason(arr)
                    setReasonTable(true)
                    setFile('')
                    setValue('')
                } else {
                    message.error("Error while uploading dispute document")
                }
            } else {
                message.error("Please upload a document")
            }

        } catch (err) {
            message.error("Error while uploading dispute document")
        }

    }
    const generateDispute = async () => {
        setLoading(true)
        var res: any = await disputeRaiseRequest(disputeObject.claimNumber, disputeObject.serviceNumber)
        setLoading(false)
        setVisible(false)
        props.sendDataToDispute(false)
        props.sendToDisputePopup(false, 'refresh')
        if (res.status == "success") {
            message.success("Dispute raised successfully.")
        } else {
            message.error("Please try again.")
        }
    }
    const deleteDispute = async () => {
        setLoading(true)
        let data: any = await deleteDisputeDoc(disputeDocArray)
        setLoading(false)
        if (data) {
            if (data.status == "success") {
                setVisible(false)
                props.sendDataToDispute(false)
            } else {
                message.error("Please try again.")
            }

        } else {
            message.error("Please try again.")
        }
    }


    return (


        <Modal title="Click YES to generate dispute" centered
            visible={visible}
            onCancel={deleteDispute}
            width={1000}
            footer={[
                <Button className='ant-btn-view' key="submit" type="primary" onClick={generateDispute} disabled={ !isFileSaved || !disputeobj.disputeAmount || !reasonTable}>
                    Yes
                </Button>,
                <Button className='ant-btn-prev' key="back" onClick={deleteDispute}  >
                    Cancel
                </Button>,
            ]}

        >
            <Row>
                <Col className='m-bt-20 col-pad' span={24}>
                    <Table columns={columns}
                        dataSource={rows}
                        bordered
                        pagination={false} />

                    <div className='m-b-10 m-bt-10'>
                        Total Amount :{calculatedAmount}
                    </div>
                    {reasonTable &&
                        <Table columns={columnsReason}
                            dataSource={rowsReason}
                            bordered
                            pagination={false} />}
                </Col>
            </Row>

            <Row>
                <Col className='m-bt-20 col-pad' span={9}>
                    <h4>Reasons <span style={{ color: 'red' }}>*</span></h4>
                    <Select defaultValue="  " style={{ width: 250 }} onChange={handleChange}>
                        <Option value="Border Charges">Border Charges</Option>
                        <Option value="Hydra Charges">Hydra Charges</Option>
                        <Option value="KM variance" >KM variance</Option>
                        <Option value="Pick and Drop location">Pick and Drop location</Option>
                        <Option value="Toll Tax">Toll Tax</Option>
                        <Option value="Wrong Type of Service selected">Wrong Type of Service selected</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                </Col>
                {value &&
                    <>
                        <Col className='m-bt-20 col-pad' span={7}>


                            <h4>Dispute Amount: <span style={{ color: 'red' }}>*</span>
                            </h4>
                            {/* <Input style={{ width: 188 }}  onChange={(e: any) => setDisputeobj({ ...disputeobj, "disputeAmount": e.target.value })} /> */}
                            <Input 
                             style={{ width: 188 }}  
                                onChange={(e: any) => {
                            if (e.target.value === '0') {
                              message.error("Dispute amount should not be zero");
                            } else {
                              setDisputeobj({ ...disputeobj, "disputeAmount": e.target.value })
                            }
                            }} 
                            />

                        </Col>
                        <Col className='m-bt-20 col-pad' span={8}>

                            <h4>Scan Copy <span style={{ color: 'red' }}>*</span></h4>

                            <input type="file"
                                required accept="image/*,application/pdf"
                                onChange={onChangeHandler} />
                            <div>
                                <div>
                                    <h4>Click on icon to save</h4>
                                </div>
                                <SaveOutlined style={{ fontSize: '200%' }} onClick={onSave} />
                            </div>


                        </Col>
                    </>
                }
            </Row>



        </Modal>
    )
}