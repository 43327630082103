import { useState, useEffect, useContext } from "react"
import { axios } from '../../../http-service/service';
import { Card, Input, Spin, Col, Row, Button, CustomHeader, Form, Upload, UploadOutlined, Modal, message, DatePicker, Select, Radio } from '../../../components/Elements'
import { FetchLocationByAddress } from "../../../services/Geocode.service";
import storage from '../../../utils/storage';
import { submitIagDetails } from '../../../services/shared.service';
import moment from "moment";
import { Table } from 'antd';



export const GstPopUp = (props: any) => {
    console.log("props11111111111111",props)
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);

    let gstDetails = props.data;
    console.log("gstDetails", gstDetails);
    useEffect(() => {
        console.log("propssss",props.visible);
      }, [props.visible]);

    const onCancel = () => {
        setVisible(false)
        // props.sendDataToGst(false)

    }

    const data = [
        {
            key: '1',
            name: "Legal Name",
            value: (gstDetails?.LegalName) || "NA",
        },
        {
            key: '2',
            name: "Trade Name",
            value: (gstDetails?.TradeName) || "NA",
        },
        {
            key: '3',
            name: "Constitution",
            value: (gstDetails?.Constitution) || "NA",
        },
        {
            key: '4',
            name: "Registration Type",
            value: (gstDetails?.RegistrationType) || "NA",
        },
        {
            key: '5',
            name: "Registration Status",
            value: (gstDetails?.RegistrationStatus) || "NA",
        },
        {
            key: '6',
            name: "Registration Date",
            value: (gstDetails?.RegistrationDate) || "NA",
        },
        {
            key: '7',
            name: "Cancellation Date",
            value: (gstDetails?.CancelationDate) || "NA",
        }
    ];

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
        },
      ];

    return (
        <Modal
            title= "GST Details"
            centered
            visible={props.visible}
            onCancel={props.onClose}
            footer={[]}

        >
                        <Table columns={columns} dataSource={data} pagination={false} />

        </Modal>

        


    )





}