import { useState, useEffect, useContext } from "react"
import { axios } from '../../../http-service/service';
import { Card, Input, Spin, Col, Row, Button, CustomHeader, Form, Upload, UploadOutlined, Modal, message, DatePicker, Select, Radio } from '../../../components/Elements'
import { FetchLocationByAddress } from "../../../services/Geocode.service";
import storage from '../../../utils/storage';
import { submitIagDetails } from '../../../services/shared.service';
import moment from "moment";




export const IagPopUp = (props: any) => {
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [loader, setLoader] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string | null>(null);







    let vendorId: any;
    let fleetType: any;
    let fleetCount: any;
    let vehicleMake: any;
    let vehicleModel: any;
    let registrationNumber: any;
    let vinNumber: any;
    let insuranceValidity: any;
    let insuranceCompany: any;
    let policyNumber: any;
    let fuelVariant: any;
    let commercialOrPrivateNumber: any;
    let digitalReadiness: any;
    let gpsEnableFleets: any;
    let driverCount: any;
    let dob: any;
    let driverFamilyCount: any;
    let dobOrAnniversaryOfSpouse: any;
    let spouseDetails: any;
    let mechanicCount: any;
    let mechClientWiseCertification: any;
    let fleetPicture: any;




    vendorId = storage.getValue("vendorId");



    const onClick = () => {
        setVisible(false)
        props.sendDataToIag(false)
    }

    const onFinish = async (values: any) => {
        let payload: any = preparePayload(values);

        let request: any = {
            "vendorId": vendorId,
            "iagData": payload
        }

        try {
            setLoader(true);
            let res: any = await submitIagDetails(request)
            if (res.status == 'success') {
                // storage.setValue('regisrationStatus', "kycSubmitted")
                setVisible(false)
                message.success("Submitted successfully")
                props.sendDataToIag(false)
                setLoader(false);
                // SaveUser({ ...User, isLoggedIn: true, details: { name: "E-Z Auto", regisrationStatus: "kycSubmitted" } })
            } else {
                message.error('Please try again')
                setLoader(false);
            }
            return res;
        } catch (e) {
            console.log(e)
        }

    }
    const onCancel = () => {
        setVisible(false)
        props.sendDataToIag(false)

    }

    const preparePayload = (values: any) => {
        let iagData = {
            "fleetType": values["fleetType"],
            "fleetCount": values["fleetCount"],
            "vehicleMake": values["vehicleMake"],
            "vehicleModel": values["vehicleModel"],
            "registrationNumber": values["registrationNumber"],
            "vinNumber": values["vinNumber"],
            "insuranceValidity": values["insuranceValidity"],
            "insuranceCompany": values["insuranceCompany"],
            "policyNumber": values["policyNumber"],
            "fuelVariant": values["fuelVariant"],
            "commercialOrPrivateNumber": values["commercialOrPrivateNumber"],
            "digitalReadiness": values["digitalReadiness"],
            "gpsEnableFleets": values["gpsEnableFleets"],
            "driverCount": values["driverCount"],
            "dob": values["dob"],
            'driverFamilyCount': values["driverFamilyCount"],
            "dobOrAnniversaryOfSpouse": values["dobOrAnniversaryOfSpouse"],
            "spouseDetails": values["spouseDetails"],
            "mechanicCount": values["mechanicCount"],
            "mechClientWiseCertification": values["mechClientWiseCertification"]
        }

        return iagData;

    }



    const uploadVendorImages = (fleetPicture: any) => {
        const headers = { 'Content-Type': 'multipart/form-data' };
        const formData = new FormData();

        formData.append('fleetPicture', fleetPicture);
        formData.append('VendorId', vendorId);

        axios.post('/api/vendors_v2/uploadVendorImages', formData, { headers })
            .then(response => response)
            .catch(e => e);
    }
    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        if (event.target.files[0]) {
            setFile(event.target.files[0]);
            setFileName(event.target.files[0].name);
          }
        const reader = new FileReader();

        reader.onloadend = () => {

            uploadVendorImages(file);
        }
        reader.readAsDataURL(file);
    };
   fleetPicture =  storage.getValue("fleetPicture")


   useEffect(()=>{
   if(fleetPicture != null && fleetPicture != undefined && fleetPicture != ""){
    setFileName(fleetPicture)
    setFileUrl(`${process.env.REACT_APP_API_URL}/${fleetPicture}`)
    }
},[fleetPicture])


    const validateValue = (value: any) => {
        if (value == undefined || value == 'NA' || value == null) {
            return "";
        } else {
            return value;
        }

    }


    const handleButtonClick = () => {
        if (fileUrl) {
            window.open(fileUrl, '_blank');
          }
        if(file != null){
        const img = new Image();
        img.src = URL.createObjectURL(file);
        const win = window.open("");
        win?.document.write(img.outerHTML);
      }
    }
    let vendorData: any;
    if (props.data !== undefined && props.data != null && props.data !== "") {
        vendorData = props.data.iagDetails;
    }
  

    let picture: any = localStorage.getItem('fleetPicture');


    useEffect(() => {
        if (vendorData != undefined && vendorData != null && vendorData != "") {
            // fleetType = vendorData.fleetType;
            form.setFieldsValue({fleetType:vendorData.fleetType});
            // fleetCount = vendorData.fleetCount;
            form.setFieldsValue({fleetCount:vendorData.fleetCount});
            // vehicleMake = vendorData.vehicleMake;
            form.setFieldsValue({vehicleMake:vendorData.vehicleMake});
            // vehicleModel = vendorData.vehicleModel;
            form.setFieldsValue({vehicleModel:vendorData.vehicleModel});
            // registrationNumber = vendorData.registrationNumber;
            form.setFieldsValue({registrationNumber:vendorData.registrationNumber});
            // vinNumber = vendorData.vinNumber;
            form.setFieldsValue({vinNumber:vendorData.vinNumber});
            // insuranceValidity = vendorData.insuranceValidity;
            insuranceValidity = validateValue(vendorData.insuranceValidity);
            if (insuranceValidity != '' && insuranceValidity != undefined) {
                insuranceValidity = insuranceValidity;
                form.setFieldsValue({ insuranceValidity: moment(vendorData.insuranceValidity) })
            }
            // insuranceCompany = vendorData.insuranceCompany;
            form.setFieldsValue({insuranceCompany:vendorData.insuranceCompany});
            // policyNumber = vendorData.policyNumber;
            form.setFieldsValue({policyNumber:vendorData.policyNumber});
            // fuelVariant = vendorData.fuelVariant;
            form.setFieldsValue({fuelVariant:vendorData.fuelVariant});
            // commercialOrPrivateNumber = vendorData.commercialOrPrivateNumber;
            form.setFieldsValue({commercialOrPrivateNumber:vendorData.commercialOrPrivateNumber});
            // digitalReadiness = vendorData.digitalReadiness;
            form.setFieldsValue({digitalReadiness:vendorData.digitalReadiness});
            // gpsEnableFleets = vendorData.gpsEnableFleets;
            form.setFieldsValue({gpsEnableFleets:vendorData.gpsEnableFleets});
            // driverCount = vendorData.driverCount;
            form.setFieldsValue({driverCount:vendorData.driverCount});
           
            // if ( vendorData.dob !=null &&  vendorData.dob != undefined && moment( vendorData.dob).isValid()) {
            //     // dob = vendorData.dob;
            // form.setFieldsValue({dob:moment(vendorData.dob)}); 
            // }

            dob = validateValue(vendorData.dob);
            if (dob != '' && dob != undefined) {
                dob = dob;
                form.setFieldsValue({ dob: moment(dob) })
            }





            // driverFamilyCount = vendorData.driverFamilyCount;
            form.setFieldsValue({driverFamilyCount: vendorData.driverFamilyCount});
            dobOrAnniversaryOfSpouse = validateValue(vendorData.dobOrAnniversaryOfSpouse);
            if ( dobOrAnniversaryOfSpouse!=null &&  dobOrAnniversaryOfSpouse!= undefined) {
            form.setFieldsValue({dobOrAnniversaryOfSpouse: moment( vendorData.dobOrAnniversaryOfSpouse)});
            }
            // spouseDetails = vendorData.spouseDetails;
            form.setFieldsValue({spouseDetails: vendorData.spouseDetails});
            // mechanicCount = vendorData.mechanicCount;
            form.setFieldsValue({mechanicCount: vendorData.mechanicCount});
            // mechClientWiseCertification = vendorData.mechClientWiseCertification;
            form.setFieldsValue({mechClientWiseCertification: vendorData.mechClientWiseCertification});
            // fleetPicture = vendorData.fleetPicture;
            form.setFieldsValue({fleetPicture: vendorData.fleetPicture});




        }
        
    },[vendorData])




   
    const { Option: AntOption } = Select;

    return (
        <Modal
            title={props.data.entityName}
            centered
            visible={visible}
            onCancel={onClick}
            footer={[]}
        >
            <div>
                <Row gutter={8} style={{ justifyContent: "center" }}>
                    <Col span={24}>

                        <Card >
                            <Spin spinning={loading}>

                                <Form
                                    className="registration-details-form upload-documents-form"

                                    // initialValues={{ remember: true }}
                                    form={form}
                                    onFinish={onFinish}
                                    layout="vertical"
                                    labelAlign={"left"}
                                    // labelWrap={"5px"}
                                    autoComplete="off"
                                >


                                    <Row gutter={8}>
                                        <Col span={24}>
                                            <div className="header-separator pad-lr-0" >
                                                <Row>
                                                    {/* <Col span={24}> */}
                                                    {/* <Row className="PancardMain"> */}
                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item validateFirst label="Fleet Type" name="fleetType">
                                                            <Input defaultValue={fleetType} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item validateFirst label="Fleet Count" name="fleetCount">
                                                            <Input defaultValue={fleetCount} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item validateFirst label="Vehicle Make" name="vehicleMake">
                                                            <Input defaultValue={vehicleMake} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item validateFirst label="Vehicle Model" name="vehicleModel">
                                                            <Input defaultValue={vehicleModel} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="registrationNumber" label="Registration Number">
                                                            <Input defaultValue={registrationNumber}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="vinNumber" label="Vin Number">
                                                            <Input defaultValue={vinNumber}/>
                                                        </Form.Item>
                                                    </Col>

                                                     <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="insuranceValidity" label="Insurance Validity">
                                                            <DatePicker defaultValue = {insuranceValidity} format={'DD-MM-YYYY'}/>
                                                        </Form.Item>
                                                    </Col> 

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="insuranceCompany" label="Insurance Company">
                                                            <Input defaultValue={insuranceCompany}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="policyNumber" label="Policy Number">
                                                            <Input defaultValue={policyNumber}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="fuelVariant" label="Fuel Variant">
                                                            <Input defaultValue={fuelVariant}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="commercialOrPrivateNumber" label="Commercial or Private Number">
                                                            <Input defaultValue={commercialOrPrivateNumber}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="digitalReadiness" label="Digital Readiness">
                                                            <Input defaultValue={digitalReadiness}/>
                                                        </Form.Item>
                                                    </Col>

                                                   

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="gpsEnableFleets" label="GPS Enable Fleets">
                                                            <Input defaultValue={gpsEnableFleets}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="driverCount" label="Driver Count">
                                                            <Input defaultValue={driverCount}/>
                                                        </Form.Item>
                                                    </Col>

                                                     <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="dob" label="DOB">
                                                            <DatePicker  format={'DD-MM-YYYY'}/>
                                                        </Form.Item>
                                                    </Col> 

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="dobOrAnniversaryOfSpouse" label="DOB or Anniversary of Spouse">
                                                            <DatePicker  format={'DD-MM-YYYY'}/>
                                                        </Form.Item>
                                                    </Col> 


                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="driverFamilyCount" label="Driver Family Count">
                                                            <Input defaultValue={driverFamilyCount}/>
                                                        </Form.Item>
                                                    </Col>

                                                    
                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="spouseDetails" label="Spouse Details">
                                                            <Input defaultValue={spouseDetails}/>
                                                        </Form.Item>
                                                    </Col>



                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="mechanicCount" label="Mechanic Count">
                                                            <Input defaultValue={mechanicCount}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad newWidth'>
                                                        <Form.Item name="mechClientWiseCertification" label="Mech Client Wise Certification">
                                                            <Input defaultValue={mechClientWiseCertification}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12} className='col-pad  m-b-0 newPanDocWidth'>
                                                        <Form.Item name="fleetPicture" label="Upload Fleet Picture">
                                                        <label className="custom-file-upload upload-button">

                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleImageChange}
                                                                style={{ display: 'none' }}
                                                            />
                                                            Upload                                                           
                                                        </label>
                                                        {/* {fileName && <span>{fileName}</span>} */}
                                                        {fileName && 
                                                         <button className="view-image-button" onClick={handleButtonClick}>
                                                      View Image
                                                     </button>
                                                     
                                                             }
                                                        </Form.Item>

                                                    </Col>




                                                </Row>
                                                <Row style={{ marginTop: "0px" }} gutter={[24, 24]} align="middle" justify="center" >
                                                    <Col>
                                                        <Form.Item >
                                                            <Button type="primary" className="cancel-btn" onClick={onCancel}>Cancel</Button>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item >
                                                            <Button htmlType="submit" className="submit-btn" type="primary">Submit</Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>



                                            </div>
                                        </Col>

                                    </Row>



                                </Form>
                            </Spin>
                        </Card>
                    </Col>
                </Row>

            </div>





        </Modal>

    )
}
