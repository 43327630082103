import { useState, useEffect, useContext } from "react"
import axios from "axios";
import { getGSTstateCodes, uploadVendorKYCdoc, submitKycUpdateRequest } from '../../../services/shared.service';
import { Card, Input, Spin, Col, Row, Button, CustomHeader, Form, Upload, UploadOutlined, Modal, message, DatePicker, Select, Radio } from '../../../components/Elements'
import { FetchLocationByAddress } from "../../../services/Geocode.service";
import { GETS_STATE_LIST, GET_BANK_DETAILS, GET_DOCUMENTS_LIST, GET_GST_CODES_LIST, UPLOAD_KYC_DOCUMENT, ValidatePanCard, VERIFY_VENDOR_GST } from "../../../widgets/Register/api";
import { IIFSCPayload } from "../../helpers/interface";
import moment from "moment";
import { FileuploadParams } from "../../../widgets/Register/helpers/formhelper";
import storage from '../../../utils/storage';
import { API_URL } from "../../../config";
import { API_LIST } from "../../../http-service/list";
import { useNavigate } from 'react-router-dom'
import { getVendorProfiles } from "../../../services/dashboard.services";
import { ChangeEvent } from 'react'
import { UserContext, UserContextType } from "../../../providers/UserContext";
import { GstPopUp } from "./GstPopup";



export const KycPopUp = (props: any) => {

    const { User, SaveUser } = useContext(UserContext) as UserContextType;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [panVerifyButton, setPanVerifyButton] = useState(false)
    const [statesList, setStatesList] = useState<any>([]);
    const [loader, setLoader] = useState(false)
    const [showGST, setGSTVisibility] = useState<boolean>(false);
    const [RegistrationFormData, setRegistrationFormData] = useState({});
    const [visible, setVisible] = useState(true)
    const [data, setData] = useState([])
    const [authPan, setAuthPan] = useState(false)
    const [showPersonalPan, setShowPersonalPan] = useState(false)
    const [gstNumber, setGstNumber] = useState('');

    const [showSurname, setShowSurname] = useState(false);
    const [showName, setShowName] = useState(false)
    // const [uploadPan, setUploadPan] = useState(false)
    const [sur_Name, setSur_Name] = useState<any>()
    const [first_Name, SetFirst_Name] = useState<any>()
    const [legalPanFileList, setLegalPanFileList] = useState<any[]>([]);
    const [legaGST, setLegalGST] = useState<any[]>([]);
    const [addressFileList, setaddressFileList] = useState<any[]>([]);
    const [authPanFiles, setAuthPanFiles] = useState<any[]>([]);
    const [aadharFiles, setaadharFiles] = useState<any[]>([]);
    const [passBookFiles, setPassBookFiles] = useState<any[]>([]);
    const [agreementFiles, setAgreementFiles] = useState<any[]>([]);
    const [gstDisabled, setGstDisabled] = useState(true)
    const [dob_name, setDob_name] = useState<any>()
    const [getDisable, setDisable] = useState<boolean>(false)
    const [getDisableValue, setDisableValue] = useState<string>("");
    const [isGstPopupVisible, setIsGstPopupVisible] = useState(false);
    const [gstData, setGstData] = useState<any>({});

    let state: any
    //console.log("DAGAASFD PROS", props)
    if (props.data != undefined && props.data != null && props.data != "") {
        state = props.data.vendor_primary_state;
    }
    let city: any;
    let ifscState: any;
    let gst_code: any;
    let vendorId: any;
    let pan_number: any;
    let gst_document: any;
    let sur_name: any;
    let firstName: any;
    let beneficaryName: any;
    let bankAccount: any;
    let ifcs_code: any;
    let bankName: any;
    let branchName: any;
    let micr: any;
    let branch_addr: any;
    let addres1: any;
    let addres2: any;
    let Pin: any;
    let State: any;
    let dob: any;
    let Dob: any;
    let aadhar_number: any;
    let auth_pan: any;
    let designation: any;
    let service_tax_registration_num: any;
    let auth_dob: any;
    let vendor_email: any;
    let authorized_signatory_name: any;
    let service_tax_registration_num1: any;
    let service_tax_registration_num2: any;
    vendorId = storage.getValue("vendorId")
    let documents: any;
    let rmComments: any;

    if (props.data != undefined && props.data != null && props.data != "") {
        documents = props.data.kycDoc.documents_Ref
        if (props.data.KYC_status_flag === "RMReject") {
            rmComments = props.data.KYC_status_comments
        }
    }

    const onFinish = async (values: any) => {
        let payload: any = preparePayload(values);
        let request: any = {
            "vendorId": vendorId,
            "ObjectToUpdate": payload
        }
        let dataforKyc: any = {
            "vendorName": props.data.entityName,
            "message": props.data.vendor_reg_status,
            "kyc_flag": "Submit"

        }
        try {
            setLoader(true);
            let res: any = await submitKycUpdateRequest(request)
            if (res.status === 'success') {
                storage.setValue('regisrationStatus', "kycSubmitted")
                message.success("Submitted successfully")
                onClick()
                navigate('/kyc', { state: dataforKyc })
                setLoader(false);
                SaveUser({ ...User, isLoggedIn: true, details: { name: "E-Z Auto", regisrationStatus: "kycSubmitted" } })
            } else {
                message.error('Please try again')
                setLoader(false);
            }
            return res;
        } catch (e) {
            console.log(e)
        }

    }






    const preparePayload = (values: any) => {
        let designation: any;
        if (values['owner_type'] === "P") {
            designation = 'Proprietor'
        } else if (values['owner_type'] === "O") {
            designation = 'Owner'
        }
        let auth_signatory = {
            "Aadhar_number": values['aadhar_number'],
            "PAN_number": values["authorized_person"],
            "Designation": designation,
            "DOB": values['authorized_person_dob'],
            "name": values['authorized_signatory_name'],
            // "Designation" : designation

        }
        let Billing_Address = {
            "Address1": values['address1'],
            "Address2": values['address2'],
            "City": values['city'],
            "PIN": values['pin'],
            "State": values['state']
        };

        let gst_details = {
            "legalName": gstData.LegalName,
            "tradeName": gstData.TradeName,
            "Constitution": gstData.Constitution,
            "RegistrationType": gstData.RegistrationType,
            "RegistrationStatus": gstData.RegistrationStatus,
            "RegistrationDate": gstData.RegistrationDate,
            "CancelationDate": gstData.CancelationDate

        }


        let bank_account = {
            "account_number": values['account_number'],
            "beneficiary_name": values['beneficary_Name'],
            "bank_name": values['bank_name'],
            "branch_addr": values['bank_branch_address'],
            "branch_name": values['bank_branch_name'],
            "IFSC_code": values['ifsc_code'],
            "micr": values['micr'],
        };

        let ObjectToUpdate = {
            "PAN_number": values['pan_card'],
            "service_tax_registration_num": '',
            "DOB": values['date_of_birth'],
            "auth_signatory": auth_signatory,
            "bank_account": bank_account,
            "Billing_Address": Billing_Address,
            "vendor_email": values['vendor_email'],
            "surname": values['surname'],
            "first_name": values['first_name'],
            "gst_details": gst_details
            //"ownerType":self.ownerType
        }


        return ObjectToUpdate
    }

    useEffect(() => {

    }, [showSurname])

    const get_Vendor_Profiles = async (vend: any) => {
        let res: any = await getVendorProfiles(vendorId)
        return res
    }

    const uploadFile = async (ele: any) => {

        const DEFAULT_FILE_SIZE = 1;
        let payloadObject: any;
        const onFinish1 = async () => {
            let details: any = form.getFieldsValue()

            payloadObject = JSON.stringify(preparePayload(details))

        }
        onFinish1()


        const { onSuccess, onError, file, onProgress } = ele;
        let formData = new FormData();
        let filename: any = ele.filename
        if (!ele.file || !['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(ele.file.type)) {
            message.error('Please select image files only');
            return false;
        }

        let fileSize = ele.file.size;

        if ((fileSize / 1024) / 1000 > DEFAULT_FILE_SIZE) {
            message.error('File size should not exceed more than 1MB');
            return false;
        }

        formData.append('vendorId', vendorId);
        formData.append('kycFile', ele.file);
        let cat = 'legalEntity';
        let sub = 'PAN';
        if (FileuploadParams[filename]) {
            cat = FileuploadParams[filename]['category'];
            sub = FileuploadParams[filename]['sub'];
        }
        formData.append('doc_category', cat);
        formData.append('doc_group', sub);
        formData.append('ObjectToUpdate', payloadObject)
        try {
            setLoader(true);
            let res: any = await uploadVendorKYCdoc(formData)
            if (res.status == 'success') {
                onSuccess();
                let data: any = await get_Vendor_Profiles(vendorId)

                GET_VENDOR_DOCS(data.message.kycDoc.documents_Ref)
                setLoader(false);
            } else {
                setLoader(false);
                onError()
            }
            return res;
        } catch (e) {
            onError()
        }
    }


    useEffect(() => {
        if (vendorId) {
            GET_VENDOR_DOCS(documents);
        }
    }, [vendorId])
    const GET_VENDOR_DOCS = async (documents: any) => {

        let payload = { "vendorId": vendorId };
        try {
            setLoader(true);
            let docs: any = await GET_DOCUMENTS_LIST(payload);


            if (docs && docs.status == 'success') {
                let panlist: any = [];
                let gstlist: any = [];
                let addresslist: any = [];
                let authpanlist: any = [];
                let authaadhar: any = [];
                let passbook: any = [];
                let agreement: any = [];
                let docAraay: any = docs.message
                if (documents) {
                    documents.map((ele: any) => {
                        if (docAraay.findIndex((i: any) => i.doc_group == ele.doc_group) !== -1) {
                            if (ele.doc_group == docAraay[docAraay.findIndex((i: any) => i.doc_group == ele.doc_group)].doc_group) {
                                docAraay[docAraay.findIndex((i: any) => i.doc_group == ele.doc_group)] = ele
                            }
                        } else {
                            docAraay.push(ele)
                        }
                    })
                }


                docAraay.map((ele: any) => {
                    let obj = { ...ele };
                    obj['uid'] = obj['_id'];
                    obj['name'] = obj['doc_name'];
                    obj['status'] = 'done';
                    if (ele['doc_category'] == 'legalEntity') {
                        if (ele.doc_group == 'PAN') {
                            form.setFieldsValue({ legal_pan_no_f: obj['name'] })
                            panlist.push(obj);
                        }
                        if (ele.doc_group == 'GST') {
                            form.setFieldsValue({ gst_cert_f: obj['name'] })
                            gstlist.push(obj);
                        }
                        if (ele.doc_group == 'bankDocument') {
                            form.setFieldsValue({ cancelled_pass: obj['name'] })
                            passbook.push(obj);
                        }



                    }

                    if (ele['doc_category'] == 'authSignatory') {
                        if (ele.doc_group == 'Adhaar') {
                            form.setFieldsValue({ aadhar_copy_f: obj['name'] })
                            authaadhar.push(obj);
                        }
                        if (ele.doc_group == 'authPAN') {
                            form.setFieldsValue({ auth_pan_f: obj['name'] })
                            authpanlist.push(obj);
                        }
                    }
                });

                setLegalPanFileList(panlist);
                setLegalGST(gstlist);
                setaddressFileList(addresslist);
                setAuthPanFiles(authpanlist);
                setaadharFiles(authaadhar);
                setPassBookFiles(passbook);
                setAgreementFiles(agreement);
                setLoader(false);
            }
            else {
                setLoader(false);
            }
        } catch (e) {
            setLoader(false);
        }
    }
    const validateValue = (value: any) => {
        if (value == undefined || value == 'NA' || value == null) {
            return "";
        } else {
            return value;
        }

    }
    let vendorData: any;
    if (props.data != undefined && props.data != null && props.data != "") {
        vendorData = props.data
    }
    let modifiedData: any;
    if (props.data != undefined && props.data != null && props.data != "") {
        modifiedData = props.data.kycDoc.ModifiedDetails
    }





    useEffect(() => {

        form.setFieldsValue({
            "is_gst": "no"
        })
        let formvalue = form.getFieldsValue();
        if (formvalue.is_gst == "no") {
            setGstDisabled(false)
        }
        if (props.data) {


            if (props.data.service_tax_registration_num) {
                let gstprefix = props.data.service_tax_registration_num.substring(0, 12);
                let gstpno = props.data.service_tax_registration_num.substring(12);
                setGSTVisibility(true);
                form.setFieldsValue({ 'gst_prefix': gstprefix, gst_tax_regn_no: gstpno, "is_gst": "yes" });
            }
        }






        if (modifiedData != undefined && validateValue(modifiedData.PAN_number) != "" && validateValue(modifiedData.PAN_number) != validateValue(vendorData.PAN_number)) {
            pan_number = modifiedData.PAN_number
            form.setFieldsValue({ pan_card: pan_number })

        } else {
            pan_number = vendorData.PAN_number
            form.setFieldsValue({ pan_card: pan_number })

        }
        if (modifiedData != undefined && validateValue(modifiedData.surname) != "" && validateValue(modifiedData.surname) != validateValue(vendorData.surname)) {
            sur_name = modifiedData.surname
            form.setFieldsValue({ surname: sur_name })
            setSur_Name(sur_name)
        } else {
            sur_name = vendorData.surname
            form.setFieldsValue({ surname: sur_name })
            setSur_Name(sur_name)
        }
        if (modifiedData != undefined && validateValue(modifiedData.first_name) != "" && validateValue(modifiedData.first_name) != validateValue(vendorData.first_name)) {
            firstName = modifiedData.first_name
            form.setFieldsValue({ first_name: firstName })
            SetFirst_Name(firstName)
        } else {
            firstName = vendorData.first_name
            form.setFieldsValue({ first_name: firstName })
            SetFirst_Name(firstName)
        }


        if (modifiedData != undefined && validateValue(modifiedData.bank_account.beneficiary_name) != "" && modifiedData.bank_account.beneficiary_name != vendorData.bank_account.beneficiary_name) {
            beneficaryName = modifiedData.bank_account.beneficiary_name;
            form.setFieldsValue({ beneficary_Name: beneficaryName })
        } else {
            beneficaryName = vendorData.bank_account.beneficiary_name;
            form.setFieldsValue({ beneficary_Name: beneficaryName })
        }
        if (modifiedData != undefined && modifiedData.bank_account != undefined && validateValue(modifiedData.bank_account.account_number) != "" && modifiedData.bank_account.account_number != vendorData.bank_account.account_number) {
            bankAccount = modifiedData.bank_account.account_number;
            form.setFieldsValue({ account_number: bankAccount })
        } else {
            bankAccount = vendorData.bank_account.account_number;
            form.setFieldsValue({ account_number: bankAccount })
        }
        if (modifiedData != undefined && modifiedData.bank_account != undefined && validateValue(modifiedData.bank_account.IFSC_code) != "" && modifiedData.bank_account.IFSC_code != vendorData.bank_account.IFSC_code) {
            ifcs_code = modifiedData.bank_account.IFSC_code;
            form.setFieldsValue({ ifsc_code: ifcs_code })
        } else {
            ifcs_code = vendorData.bank_account.IFSC_code;
            form.setFieldsValue({ ifsc_code: ifcs_code })
        }
        if (modifiedData != undefined && modifiedData.bank_account != undefined && validateValue(modifiedData.bank_account.bank_name) != "" && modifiedData.bank_account.bank_name != vendorData.bank_account.bank_name) {
            bankName = modifiedData.bank_account.bank_name;
            form.setFieldsValue({ bank_name: bankName })
        } else {
            bankName = vendorData.bank_account.bank_name;
            form.setFieldsValue({ bank_name: bankName })
        }
        if (modifiedData != undefined && modifiedData.bank_account != undefined && validateValue(modifiedData.bank_account.branch_name) != "" && modifiedData.bank_account.branch_name != vendorData.bank_account.branch_name) {
            branchName = modifiedData.bank_account.branch_name;
            form.setFieldsValue({ bank_branch_name: branchName })
        } else {
            branchName = vendorData.bank_account.branch_name;
            form.setFieldsValue({ bank_branch_name: branchName })
        }
        if (modifiedData != undefined && modifiedData.bank_account != undefined && validateValue(modifiedData.bank_account.micr) != "" && modifiedData.bank_account.micr != vendorData.bank_account.micr) {
            micr = modifiedData.bank_account.micr;
            form.setFieldsValue({ micr: micr })
        } else {
            micr = vendorData.bank_account.micr;
            form.setFieldsValue({ micr: micr })
        }
        if (modifiedData != undefined && modifiedData.bank_account != undefined && validateValue(modifiedData.bank_account.branch_addr) != "" && modifiedData.bank_account.branch_addr != vendorData.bank_account.branch_addr) {
            branch_addr = modifiedData.bank_account.branch_addr;
            form.setFieldsValue({ bank_branch_address: branch_addr })
        } else {
            branch_addr = vendorData.bank_account.branch_addr;
            form.setFieldsValue({ bank_branch_address: branch_addr })
        }

        if (modifiedData != undefined && modifiedData.Billing_Address != undefined && validateValue(modifiedData.Billing_Address.Address1) != "" && modifiedData.Billing_Address.Address1 != vendorData.Billing_Address.Address1) {
            addres1 = modifiedData.Billing_Address.Address1;
            form.setFieldsValue({ address1: addres1 })
        } else {
            addres1 = vendorData.Billing_Address.Address1;
            form.setFieldsValue({ address1: addres1 })
        }
        if (modifiedData != undefined && modifiedData.Billing_Address != undefined && validateValue(modifiedData.Billing_Address.Address2) != "" && modifiedData.Billing_Address.Address2 != vendorData.Billing_Address.Address2) {
            addres2 = modifiedData.Billing_Address.Address2;
            form.setFieldsValue({ address2: addres2 })
        } else {
            addres2 = vendorData.Billing_Address.Address2;
            form.setFieldsValue({ address2: addres2 })
        }
        if (modifiedData != undefined && modifiedData.Billing_Address && validateValue(modifiedData.Billing_Address.PIN) != "" && modifiedData.Billing_Address.PIN != vendorData.Billing_Address.PIN) {
            Pin = modifiedData.Billing_Address.PIN;
            form.setFieldsValue({ pin: Pin })
        } else {
            Pin = vendorData.Billing_Address.PIN;
            form.setFieldsValue({ pin: Pin })
        }
        if (modifiedData != undefined && modifiedData.Billing_Address && validateValue(modifiedData.Billing_Address.State) != "" && modifiedData.Billing_Address.State != vendorData.Billing_Address.State) {
            State = modifiedData.Billing_Address.State;
            form.setFieldsValue({ state: State })
        } else {
            State = vendorData.Billing_Address.State;
            form.setFieldsValue({ state: State })
        }
        if (modifiedData && modifiedData.Billing_Address && validateValue(modifiedData.Billing_Address.City) != "" && modifiedData.Billing_Address.City != vendorData.Billing_Address.City) {
            city = modifiedData.Billing_Address.City;
            form.setFieldsValue({ city: city })
        } else {
            city = vendorData.Billing_Address.City;
            form.setFieldsValue({ city: city })
        }


        if (modifiedData != undefined && validateValue(modifiedData.DOB) != "" && modifiedData.DOB != vendorData.DOB) {
            dob = modifiedData.DOB;
            if (dob != '' && dob != undefined) {
                Dob = dob;
                form.setFieldsValue({ date_of_birth: moment(Dob) })

            }

        } else {
            dob = validateValue(vendorData.DOB);
            if (dob != '' && dob != undefined) {
                Dob = dob;
                form.setFieldsValue({ date_of_birth: moment(Dob) })
            }
        }
        if (modifiedData && modifiedData.auth_signatory && validateValue(modifiedData.auth_signatory.Aadhar_number) != "" && modifiedData.auth_signatory.Aadhar_number != vendorData.auth_signatory.Aadhar_number) {
            aadhar_number = modifiedData.auth_signatory.Aadhar_number;

            form.setFieldsValue({ aadhar_number: aadhar_number })


        } else {
            aadhar_number = validateValue(vendorData.auth_signatory.Aadhar_number);
            form.setFieldsValue({ aadhar_number: aadhar_number })
        }
        if (modifiedData && validateValue(modifiedData.auth_signatory.PAN_number) != "" && modifiedData.auth_signatory.PAN_number != vendorData.auth_signatory.PAN_number) {
            auth_pan = modifiedData.auth_signatory.PAN_number;
            form.setFieldsValue({ authorized_person: auth_pan })

        } else {
            auth_pan = validateValue(vendorData.auth_signatory.PAN_number);
            form.setFieldsValue({ authorized_person: auth_pan })
        }
        if (modifiedData != undefined && validateValue(modifiedData.service_tax_registration_num) != "" && modifiedData.service_tax_registration_num != vendorData.service_tax_registration_num) {
            service_tax_registration_num = modifiedData.service_tax_registration_num;
        } else {
            service_tax_registration_num = validateValue(vendorData.service_tax_registration_num);
        }
        if (modifiedData != undefined && modifiedData.auth_signatory != undefined && validateValue(modifiedData.auth_signatory.Designation) != "" && modifiedData.auth_signatory.Designation != vendorData.auth_signatory.Designation) {
            designation = modifiedData.auth_signatory.Designation;

            form.setFieldsValue({ owner_type: designation })

        }
        else {
            designation = vendorData.auth_signatory.Designation;
            form.setFieldsValue({ owner_type: designation })
        }
        if (vendorData.auth_signatory.Designation != "Owner" && vendorData.auth_signatory.Designation != "Proprietor") {
            vendorData.auth_signatory.Designation = "";
            form.setFieldsValue({ owner_type: "" })
        }


        if (modifiedData != undefined && modifiedData.auth_signatory != undefined && validateValue(modifiedData.auth_signatory.DOB) != "" && modifiedData.auth_signatory.DOB != vendorData.auth_signatory.DOB) {
            auth_dob = modifiedData.auth_signatory.DOB;
            if (auth_dob != '' && auth_dob != undefined) {
                auth_dob = auth_dob;
                form.setFieldsValue({ authorized_person_dob: moment(auth_dob) })
            }
        } else {
            auth_dob = vendorData.auth_signatory.DOB;
            if (auth_dob != '' && auth_dob != undefined) {
                auth_dob = auth_dob;
                form.setFieldsValue({ authorized_person_dob: moment(auth_dob) })
            }
        }
        if (modifiedData != undefined && validateValue(modifiedData.vendor_email) != "" && modifiedData.vendor_email != vendorData.vendor_email) {
            vendor_email = modifiedData.vendor_email;

            form.setFieldsValue({ email: vendor_email })
        } else {
            vendor_email = validateValue(vendorData.vendor_email);
            form.setFieldsValue({ email: vendor_email })
        }

        if (modifiedData != undefined && modifiedData.auth_signatory != undefined && validateValue(modifiedData.auth_signatory.name) != "" && modifiedData.auth_signatory.name != vendorData.auth_signatory.name) {
            authorized_signatory_name = modifiedData.auth_signatory.name;
            form.setFieldsValue({ authorized_signatory_name: authorized_signatory_name })
        } else {
            authorized_signatory_name = validateValue(vendorData.auth_signatory.name);
            form.setFieldsValue({ authorized_signatory_name: authorized_signatory_name })

        }

    }, [props.data])




    //change address1 event
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDisableValue(event.target.value);
    };

    useEffect(() => {

        if (getDisableValue.trim().length === 0) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [getDisableValue])










    const onChangeGstReg = async (event: any) => {
        if (event.target.value == 'yes') {
            setGstDisabled(false)
            setLoader(true);
            setGSTVisibility(true);
            try {
                let gstCodes: any = await GET_GST_CODES_LIST();
                if (gstCodes && gstCodes.status === 'success') {
                    let subcodes = gstCodes.message['subCode'];
                    if (subcodes && subcodes.length && state) {
                        let statecode = subcodes.find((ele: any) => ele.name.toUpperCase() === state.toUpperCase());
                        if (statecode) {
                            let registerDetails: any = props.data.PAN_number;
                            if (registerDetails) {
                                let PanVerification: any = registerDetails;
                                form.setFieldsValue({ gst_prefix: statecode.code + PanVerification })
                                setLoader(false);
                            }
                        }
                    }
                }

            } catch (e) {
                console.log(e)
                setLoader(false);
            }
        } else {
            setGSTVisibility(false);
            form.setFieldsValue({ gst_prefix: '' })
        }
    }


    const prefixSelector = (
        <Form.Item name="gst_prefix" noStyle>
            <Input disabled className="GstPrefix" />
        </Form.Item>
    );
    useEffect(() => {
        const verifyGst = async () => {
            if (gstNumber.length === 3) {
                let gstPrefix: any = form.getFieldsValue().gst_prefix;
                let gstNo: any = gstPrefix + gstNumber;
                let res: any = await VERIFY_VENDOR_GST({ "gstNo": gstNo });
                if (res.message.gstFlag === false || res.message === "failure") {
                    message.error("The GST number is not valid / deactive as per GST Government portal");
                    setGstNumber('')
                    setGSTVisibility(false);
                    form.setFieldsValue({
                        "is_gst": "no",
                        "gst_tax_regn_no": ""
                    })
                } else if (res.message.gstFlag === true) {
                    setIsGstPopupVisible(true);
                    setGstData(res.message)
                } else {
                    setIsGstPopupVisible(false);
                }
            } else {
                setIsGstPopupVisible(false);
            }
        };

        verifyGst();
    }, [gstNumber]);
    const ownerType: any[] = [
        {
            name: 'Owner',
            value: 'O'
        },
        {
            name: 'Proprietor',
            value: 'P'
        }
    ];



    useEffect(() => {

        const getstatesList = async () => {
            try {
                let statesList: any = await GETS_STATE_LIST();
                if (statesList && statesList.status == 'success' && statesList.message.length) {
                    let list: any[] = statesList.message;
                    list = list.sort();
                    list = list.map((state: any) => {
                        let s: any = {};
                        s['name'] = state;
                        s['value'] = state;
                        return s;
                    });
                    setStatesList(list)
                }
            } catch (e) {
            }
        }
        getstatesList();
    }, [])
    const onClick = () => {
        setVisible(false)
        if (props.page == "regulations") {
            props.sendDataToKyc(false)
        } else {
            props.sendDataToParent(false)

        }
    }
    useEffect(() => {
        const gstState = () => {
            var gstregex = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-8]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/g;
            if (service_tax_registration_num.length == 15 && service_tax_registration_num != "" && service_tax_registration_num != undefined && service_tax_registration_num != null && gstregex.test(service_tax_registration_num)) {
                service_tax_registration_num1 = service_tax_registration_num.substring(0, 12);
                service_tax_registration_num2 = service_tax_registration_num.substring(12);
                // gstReNo = true;
                // gstNo = "yes";
                // } else if (validateValue(Billing_Address.State) != "") {
                //     self.gstRegNo('yes');
                //     self.gstNo = "yes";
                // } else {
                //     self.gstNo = "no";
                // }
            }
        }


        async function fetchData() {
            let resp: any = await getGSTstateCodes()
            setData(resp.message.subCode)

        }
        fetchData()
    }, [])
    useEffect(() => {
        let pattern: any = /^[a-zA-Z]{3}[pchfatbljgPCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
        let pan: any = props.data.PAN_number
        let result: any = pan.match(pattern)
        if (result == null || result == "" || result == undefined) {
            setPanVerifyButton(true)
            setAuthPan(true)
            // setUploadPan(true)
            setShowPersonalPan(true);
            setShowName(true)
            setShowSurname(true)
        }
        if (result != null && result != undefined && result != "") {
            if (pan.charAt(3) == "P") {
                setAuthPan(false)
                setShowPersonalPan(false);
                setShowSurname(false)
                setShowName(false)
                setDob_name('Date Of Birth:')
                // setUploadPan(false)

                /* self.auth_signatory.PAN_number = self.PAN_number;
                self.authPANdisable = true;
                self.showPersonalPan = true;
                self.showOrganisationPan = false;
                if (newvalue != "orgName" && newvalue != "onLoad") {
                    self.surname = "";
                    self.first_name = "";
                    self.entityOrganisationName = "";
                } */
            } else {
                setAuthPan(false)
                // setUploadPan(false)
                setShowPersonalPan(true);

                setDob_name('Date of Incorporation/Registration:')
                if (first_Name) {
                    setShowName(false)
                } else {
                    setShowName(true)
                }

                if (sur_Name) {

                    setShowSurname(false)
                } else {

                    setShowSurname(true)

                }




            }
        }

    }, [sur_Name])
    data.forEach(function (ele: any) {
        if (ele.name === state.toUpperCase()) {
            gst_code = ele.code.concat(props.data.PAN_number)
            form.setFieldsValue(
                { gst_no: gst_code })

        }
    }, this)


    const propsList: any = {
        action: '',
        headers: {
            authorization: 'authorization-text',
        },
        maxCount: 1,
        customRequest: uploadFile,
        defaultFileList: [],
        showUploadList: {
            showPreviewIcon: true,
            showDownloadIcon: false,
            downloadIcon: 'download ',
            showRemoveIcon: false,
        },
        onChange(info: any) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onPreview(info: any) {
            PreviewFiles(info);
        }
    };
    const PreviewFiles = async (ele: any) => {
        let imageBlob: any
        try {
            if (ele && ele['doc_id']) {
                let payload: any = {
                    documentId: ele['doc_id'],
                    vendor_Id: vendorId
                }

                setLoader(true);

                imageBlob = (await axios.post(
                    API_URL + API_LIST.KYC_VENDOR_DOC,
                    payload,
                    { responseType: 'blob' as 'json' }
                )).data
                let fileURL: any = URL.createObjectURL(imageBlob);
                window.open(fileURL);
                setLoader(false);
            } else if (ele && ele['uid']) {
                let payload: any = {
                    documentId: ele['uid'],
                    vendor_Id: vendorId
                }

                setLoader(true);

                imageBlob = (await axios.post(
                    API_URL + API_LIST.KYC_VENDOR_DOC,
                    payload,
                    { responseType: 'blob' as 'json' }
                )).data
                let fileURL: any = URL.createObjectURL(imageBlob);
                window.open(fileURL);
                setLoader(false);
            }
        } catch (e) {
            console.log(e);
            setLoader(false);
        }
    }

    const OnIFSCodeChange = async (element: any) => {
        // const row: any = await form.validateFields();
        if (element.target.value && element.target.value.length === 11) {
            let payload: IIFSCPayload = {
                ifsc: element.target.value
            }
            setLoader(true);
            try {
                let bankDetails: any = await GET_BANK_DETAILS(payload);
                setLoader(false);
                if (bankDetails && bankDetails.status == 'success') {
                    let bankInfo = bankDetails.message;
                    form.setFieldsValue(
                        {
                            bank_name: bankInfo['Bank'],
                            bank_branch_name: bankInfo['Branch'],
                            bank_branch_address: bankInfo['Address'],
                            micr: bankInfo['MICR']
                        })
                } else {
                    alert('Invalid Ifsc Code')
                    form.setFieldsValue(
                        {
                            bank_name: '',
                            bank_branch_name: '',
                            bank_branch_address: '',
                            micr: ''
                        })
                }
            } catch (e) {
                setLoader(false);
            }
        }
    }

    function compIsType(t: any, s: any) {
        let z: any;
        for (z = 0; z < t.length; ++z)
            if (t[z] == s)
                return true;
        return false;
    }
    const OnPincodeChange = async (element: any) => {
        if (element.target.value && element.target.value.length === 6) {
            try {

                let formData = form.getFieldsValue();
                // formData['locations']['latitude'] = ''
                // formData['locations']['longitude'] = '';
                // formData['locations']['city'] = '';
                // formData['locations']['state'] = '';
                // let addressLoc = formData['locations'] && formData['locations'] && formData['locations']['address1']
                let addressPin = element.target.value;
                let addressComponent: any = await FetchLocationByAddress(addressPin);
                if (addressComponent && addressComponent.status == "OK" && addressComponent.results) {
                    let addressDetails = addressComponent.results[0];


                    if (addressDetails && addressDetails.address_components && addressDetails.address_components.length) {
                        addressDetails.address_components.map((add: any) => {
                            let a: any = addressDetails.address_components
                            var types = add.types;
                            /*   if (types.includes('administrative_area_level_1')) {
                                  let state = statesList.filter((state: any) => state.name == add.long_name);
                                  if (state) {
                                      formData['locations']['state'] = add.long_name;
                                  }
                              }
                              else if (types.includes('locality')) {
                                  formData['locations']['city'] = add.long_name;
                              } */
                            let i: any;
                            let regex: any = /^[a-zA-Z ]*$/
                            for (i = 0; i < a.length; ++i) {
                                var t = a[i].types;
                                if (compIsType(t, 'administrative_area_level_1')) {
                                    ifscState = a[i].long_name; //store the state


                                } else if (compIsType(t, 'locality')) {

                                    if (regex.test(a[i].long_name)) {
                                        city = a[i].long_name; //store the city

                                    } else {
                                        city = "";
                                    }
                                }
                            }
                        })
                        if (ifscState !== null || ifscState !== undefined) {
                            if (ifscState.toUpperCase() == state.toUpperCase()) {
                                form.setFieldsValue(
                                    {
                                        state: ifscState,
                                        city: city,

                                    })
                            } else {
                                alert("You can change the PIN code with in the same state only.")
                            }
                        } else {
                            alert("You can change the PIN code with in the same state only.")
                        }

                    }
                }
            } catch (e) {
                console.log(e)
            }

        }
    }

    const onCancel = () => {
        setVisible(false)
    }
    const verifyPan = async () => {
        let panNumber: any = form.getFieldsValue().pan_card
        let payload: any = {
            "panNumber": panNumber,
            "Type": props.data.reg_type
        }
        setLoader(true)
        let res: any = await ValidatePanCard(payload)
        setLoader(false)
        if (res.status == "success") {
            let pattern: any = /^[a-zA-Z]{3}[pchfatbljgPCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
            let pan: any = panNumber
            let result: any = pan.match(pattern)
            if (pan.charAt(3) == "P") {
                form.setFieldsValue({
                    surname: res.message.surName,
                    first_name: res.message.firstName,
                    authorized_person: panNumber
                })
                setAuthPan(true)
                // setUploadPan(true)
                setShowPersonalPan(false);
                setShowName(false)
                setShowSurname(false)

            } else {
                form.setFieldsValue({
                    surname: res.message.surName,
                    first_name: res.message.firstName

                })
                setShowName(false)
                setShowSurname(false)
            }



        } else {
            message.error(res.message)
        }
    }

    return (
        <Modal
            title={props.data.entityName}
            centered
            visible={visible}
            onCancel={onClick}
            footer={[]}

        >



            <div >
                <Row gutter={8} style={{ justifyContent: "center" }}>
                    <Col span={24}>

                        <Card >
                            <Spin spinning={loader}>
                                <div style={{ color: 'black', fontWeight: 'bold' }}>Comments : {rmComments}</div>
                                <Form
                                    className="registration-details-form upload-documents-form"

                                    // initialValues={{ remember: true }}
                                    form={form}
                                    onFinish={onFinish}
                                    layout="vertical"
                                    labelAlign={"left"}
                                    // labelWrap={"5px"}
                                    autoComplete="off"
                                >

                                    <Row gutter={8}>

                                        <Col span={24}>
                                            <div className="header-separator pad-lr-0" >

                                                <Row>
                                                    <Col span={24}>
                                                        <Row className="PancardMain">
                                                            <Col className='col-pad newWidth'>
                                                                {
                                                                    authPan == true ?
                                                                        <Form.Item validateFirst label="Pancard" name="pan_card"
                                                                            rules={
                                                                                [
                                                                                    { required: true },
                                                                                    {
                                                                                        pattern: /^[a-zA-Z]{3}[pchfatbljgPCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                                                                                        message: "Please enter valid PAN number",
                                                                                    },
                                                                                    { max: 10, message: "Please enter valid PAN number" },
                                                                                    { message: "Please enter valid PAN number", min: 10 }
                                                                                ]
                                                                            } >
                                                                            <Input defaultValue={pan_number} />
                                                                        </Form.Item>
                                                                        :
                                                                        <Form.Item validateFirst label="Pancard" name="pan_card"
                                                                            rules={
                                                                                [
                                                                                    { required: true },
                                                                                    {
                                                                                        pattern: /^[a-zA-Z]{3}[pchfatbljgPCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                                                                                        message: "Please enter valid PAN number",
                                                                                    },
                                                                                    { max: 10, message: "Please enter valid PAN number" },
                                                                                    { message: "Please enter valid PAN number", min: 10 }
                                                                                ]
                                                                            } >
                                                                            <Input defaultValue={pan_number} disabled style={{ fontWeight: 'bold' }} />
                                                                        </Form.Item>
                                                                }
                                                            </Col>

                                                            <Col className='m-bt-25 col-pad newBtnWidth'>
                                                                {panVerifyButton && <Button className='ant-btn-login verify-btn m-al-0' onClick={verifyPan}>Verify</Button>}
                                                            </Col>

                                                            <Col className='col-pad  m-b-0 newPanDocWidth' >
                                                                <Form.Item label="Pan Document:" name="legal_pan_no_f" rules={[{ required: true }]} >
                                                                    <Upload {...propsList} name="legal_pan_no_file" fileList={legalPanFileList} >
                                                                        <Button className="upload-btn " icon={<UploadOutlined />} >Click to upload</Button>
                                                                    </Upload>
                                                                </Form.Item>
                                                            </Col>


                                                            <Col className='col-pad newPanDocWidth'>
                                                                <Form.Item label="Surname" name="surname" rules={[{ required: true }]}>
                                                                    <Input disabled={!showSurname} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className='col-pad newPanDocWidth' >
                                                                <Form.Item label="First Name" name="first_name" rules={[{ required: true }]}>
                                                                    <Input defaultValue={props.data.first_name} disabled={!showName} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>






                                            </div>

                                        </Col>


                                        <Col className='col-pad' span={12}>
                                            <CustomHeader title={"Bank Details:"} />

                                            <Form.Item className='m-bt-10' label={<div><h5>Beneficary Name:</h5>
                                                <h5>(Please enter as per bank records)</h5>
                                                <h5>(Max 45 characters are allowed)</h5></div>} name="beneficary_Name" rules={[{ required: true }, {
                                                    pattern: /^[A-Za-z]{1,44}$/,
                                                    message: "Please enter valid Bank Account Holder Name"

                                                }]}>

                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="IFSC Code" name="ifsc_code" rules={[{ required: true }, {
                                                pattern: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
                                                message: "Please enter valid IFSC Code",
                                            }]}>
                                                <Input onChange={($event) => OnIFSCodeChange($event)} />
                                            </Form.Item>
                                            <Form.Item label="Account Number" name="account_number" rules={[
                                                { required: true },
                                                {
                                                    pattern: /^\d{8,16}$/,
                                                    message: "Please enter valid Bank Accc Number",
                                                },
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="Bank Name" name="bank_name" >
                                                <Input disabled={true} />
                                            </Form.Item>

                                            <Form.Item label="Bank Branch Name" name="bank_branch_name" >
                                                <Input disabled={true} />
                                            </Form.Item>

                                            <Form.Item label="Bank Branch Address" name="bank_branch_address" >
                                                <Input disabled={true} />
                                            </Form.Item>

                                            <Form.Item label="MICR" name="micr" >
                                                <Input disabled={true} />
                                            </Form.Item>



                                            <Row style={{ marginTop: "5px", marginLeft: "5px" }} gutter={5}>
                                                <Form.Item label="Cancel Cheque/Passbook" name="cancelled_pass" rules={[{ required: true, message: "Please upload file" }]}  >
                                                    <Upload {...propsList} name="cancelled_passbook" fileList={passBookFiles} >
                                                        <Button className="upload-btn" icon={<UploadOutlined />} >Click to upload</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Row>

                                        </Col>
                                        <Col className='col-pad' span={12}>

                                            <CustomHeader title={"Billing Address:"} />
                                            <Form.Item className='m-bt-10 m-bt-60' label="Address1:" name="address1" rules={[{ required: true }]} >
                                                <Input onChange={handleInputChange} />
                                            </Form.Item>

                                            <Form.Item label="Address2:" name="address2" >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item label="PIN:" name='pin' rules={[{ required: true }, {
                                                pattern: /[0-9]/,
                                                message: "Please enter valid Pin Code",
                                            }]}>
                                                <Input onChange={($event) => OnPincodeChange($event)} disabled={getDisable} />

                                            </Form.Item>
                                            <Form.Item label="City:" name="city" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>

                                            <Form.Item label="State:" name="state" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                            {
                                                showGST == true ?
                                                    <Row style={{ marginTop: "5px", marginLeft: "5px" }} gutter={5}>
                                                        <Form.Item label="GST Document:" name="gst_cert_f" rules={[{ required: true }, { message: "Please upload file" }]} >
                                                            <Upload {...propsList} name="gst_cert_file" fileList={legaGST}  >
                                                                <Button className="upload-btn" icon={<UploadOutlined />} disabled={gstDisabled}>Click to upload</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Row>

                                                    :
                                                    <Row style={{ marginTop: "5px", marginLeft: "5px" }} gutter={5}>
                                                        <Form.Item label="GST Document:" name="gst_cert_f" >
                                                            <Upload {...propsList} name="gst_cert_file" fileList={legaGST}  >
                                                                <Button className="upload-btn" icon={<UploadOutlined />} disabled={!gstDisabled}>Click to upload</Button>
                                                            </Upload>
                                                        </Form.Item>

                                                    </Row>


                                            }

                                            <Form.Item label={dob_name} name="date_of_birth" rules={[{ required: true }]}>

                                                <DatePicker className="fullwidth" format={'DD-MM-YYYY'} />
                                            </Form.Item>
                                            <Form.Item label="Owner Type:" name="owner_type" rules={[{ required: true }]}>
                                                <Select placeholder="Select Type" >
                                                    {
                                                        ownerType.map((type) => (
                                                            <Select.Option key={type.value} value={type.value}>{type.name}</Select.Option>
                                                        ))
                                                    }

                                                </Select>
                                            </Form.Item>


                                            {/*  <Form.Item label="GST No:" name="gst_no" rules={[{ required: true }]}>

                                                <Input value={gst_code} disabled />
                                                <Input />

                                            </Form.Item> */}
                                        </Col>

                                        <Col span={24}>
                                            <Row>
                                                <Col className='col-pad' span={12}>
                                                    <Form.Item label="Do you have GST regd No.?" name="is_gst" >
                                                        <Radio.Group onChange={onChangeGstReg}>
                                                            <Radio value="yes">Yes</Radio>
                                                            <Radio value="no">No</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                                <Col className='col-pad newPanDocWidth' >
                                                    {
                                                        showGST &&
                                                        <Form.Item label="GST Tax Regn No" name="gst_tax_regn_no"
                                                            rules={
                                                                [
                                                                    { required: true }, {
                                                                        // pattern: /^[a-zA-Z0-9]+$/,
                                                                        pattern: /^([1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
                                                                        message: "Please enter valid GST Tax Regn No",
                                                                    }]
                                                            }>
                                                            <Input addonBefore={prefixSelector} style={{ width: "100%" }}
                                                                onChange={e => setGstNumber(e.target.value)}

                                                            />
                                                        </Form.Item>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <GstPopUp visible={isGstPopupVisible} onClose={() => setIsGstPopupVisible(false)} data={gstData} />

                                        <Col span={24}>
                                            <div className="header-separator mb-0">
                                                <CustomHeader title={"Authorized Signatory:"} />
                                                <Row className='m-bt-10'>
                                                    <Col className='col-pad' span={12}>
                                                        <Form.Item label=" Aadhar Number:" name="aadhar_number" >
                                                            <Input defaultValue={props.data.auth_signatory.Aadhar_number} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='col-pad' span={12}>
                                                        <Form.Item label="Aadhar Document" name="aadhar_copy_f"  >
                                                            <Upload {...propsList} name="aadhar_copy_file" fileList={aadharFiles} >
                                                                <Button className="upload-btn" icon={<UploadOutlined />}>Click to upload</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='col-pad' span={12}>

                                                        <Form.Item label="Authorized Person PAN:" name="authorized_person" rules={[{ required: true }]}>
                                                            <Input disabled={!showPersonalPan} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='col-pad' span={12}>
                                                        <Form.Item label="Authorized Person PAN Document:" name="auth_pan_f" rules={[{ required: true }]} >
                                                            <Upload {...propsList} name="auth_pan_file" fileList={authPanFiles} rules={[{ required: true, message: "Please upload file" }]}>
                                                                <Button className="upload-btn" icon={<UploadOutlined />} >Click to upload</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='col-pad' span={12}>
                                                        <Form.Item label="Authorized Person Date of Birth:" name="authorized_person_dob" rules={[{ required: true }]}>
                                                            {/* <Input defaultValue={props.data.auth_signatory.DOB} /> */}
                                                            <DatePicker className="fullwidth" format={'DD-MM-YYYY'} />

                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='col-pad' span={12}>
                                                        <Form.Item label="Email:" name="email" rules={[{ required: true }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='col-pad' span={12}>
                                                        <Form.Item label="Authorised Signatory Name:" name="authorized_signatory_name" rules={[{ required: true }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row style={{ marginTop: "0px" }} gutter={[24, 24]} align="middle" justify="center" >
                                        <Col>
                                            <Form.Item >
                                                <Button type="primary" className="cancel-btn" onClick={onCancel}>Cancel</Button>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item >
                                                <Button htmlType="submit" className="submit-btn" type="primary">Submit</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </Spin>
                        </Card>

                    </Col>
                </Row>

            </div>




        </Modal >)
}