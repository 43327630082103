import React from 'react';
import { AppRoutes } from '../routes';
import 'antd/dist/antd.css';


function App() {
  return (
    <AppRoutes />
  );
}

export default App;
