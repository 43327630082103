import Geocode from "react-geocode";
import { GOOGLE_KEY } from "../config";

var key: any = GOOGLE_KEY
Geocode.setApiKey(key);

const FetchLocationByAddress = async (address: string) => {
    return await Geocode.fromAddress(address)
}

export {
    FetchLocationByAddress
}