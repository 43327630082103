import moment from "moment";
import { IAddVendorPaylod, IBasicDetails } from "./interface";

var validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const prepareCreateVendorPayload = (registrationForm: any) => {

    let body: IAddVendorPaylod = {
        basicDetails: PrepasebasicDetails(registrationForm)
    };
    return body;
}

const ServicesObject: any = {
    Custody: {
        'Custody': {
            type: 'Custody',
            sub_type: "Custody",
            time: ""
        }
    },
    RRP: {
        '2-Wheeler Day Time': {
            type: 'RRP',
            sub_type: "two_wheeler",
            time: "day time"
        },
        '2-Wheeler Night Time': {
            type: 'RRP',
            sub_type: "two_wheeler",
            time: "night time"
        },
        '2-Wheeler Sunday & National Holiday': {
            type: 'RRP',
            sub_type: "two_wheeler",
            time: "sunday holiday"
        },
        '4-Wheeler Day Time': {
            type: 'RRP',
            sub_type: "four_wheeler",
            time: "day time"
        },
        '4-Wheeler Sunday & National Holiday': {
            type: 'RRP',
            sub_type: "four_wheeler",
            time: "sunday holiday"
        },
        '4-Wheeler Night Time': {
            type: 'RRP',
            sub_type: "four_wheeler",
            time: "night time"
        },
        'CAR_ROS': {
            type: 'RRP',
            sub_type: "CAR_ROS",
            time: ""
        },
        '2-Wheeler Night Time_100': {
            type: 'RRP',
            sub_type: "two_wheeler",
            time: "night time"
        },


    },
    TOW: {
        'Four Wheeler FBT': {
            type: 'TOW',
            sub_type: "four_wheeler_FBT",
            time: ""
        },
        'Two Wheeler FBT': {
            type: 'TOW',
            sub_type: "two_wheeler_FBT",
            time: ""
        },
        'UWL': {
            type: 'TOW',
            sub_type: "UWL",
            time: ""
        },
        'W&T': {
            type: 'TOW',
            sub_type: "WT",
            time: ""
        },
        '4W_FBT_2W': {
            type: 'TOW',
            sub_type: "four_wheeler_FBT_two_wheeler",
            time: ""
        },
        '7 Degree Flatbed': {
            type: 'TOW',
            sub_type: "seven_degree_flatbed",
            time: ""
        },
        'Dolly Wheels': {
            type: 'TOW',
            sub_type: "dolly_wheels",
            time: ""
        },
        'Zero Degree Flatbed': {
            type: 'TOW',
            sub_type: "zero_degree_flatbed",
            time: ""
        },
        'Car to Car': {
            type: 'TOW',
            sub_type: "car_to_car",
            time: ""
        },
        '3W Auto': {
            type: 'TOW',
            sub_type: "3W_Auto",
            time: ""
        },
        'Hydra': {
            type: 'TOW',
            sub_type: "others_hydra",
            time: ""
        },
        'Tempo': {
            type: 'TOW',
            sub_type: "others_tempo",
            time: ""
        },
        'TUK_TUK': {
            type: 'TOW',
            sub_type: "TUK_TUK",
            time: ""
        },

        'Others': {
            type: 'TOW',
            sub_type: "others_others",
            time: ""
        }
    },
    CVRRP: {
        LCVRRP: {
            type: 'CVRRP',
            sub_type: "LCVRRP",
            time: ""
        },
        MCVRRP: {
            type: 'CVRRP',
            sub_type: "MCVRRP",
            time: ""
        },
        HCVRRP: {
            type: 'CVRRP',
            sub_type: "HCVRRP",
            time: ""
        }

    },
    CVTOW: {
        LCVTOW: {
            type: 'CVTOW',
            sub_type: "LCVTOW",
            time: ""
        },
        LCV4WTOW: {
            type: 'CVTOW',
            sub_type: "LCV4WTOW",
            time: ""
        },
        MCVUWL: {
            type: 'CVTOW',
            sub_type: "MCVUWL",
            time: ""
        },
        MCVWT: {
            type: 'CVTOW',
            sub_type: "MCVWT",
            time: ""
        },
        MCV4WTOW: {
            type: 'CVTOW',
            sub_type: "MCV4WTOW",
            time: ""
        },
        MCVTOW: {
            type: 'CVTOW',
            sub_type: "MCVTOW",
            time: ""
        },
        LCVWT: {
            type: 'CVTOW',
            sub_type: "LCVWT",
            time: ""
        },
        LCVUWL: {
            type: 'CVTOW',
            sub_type: "LCVUWL",
            time: ""
        },
        HCVTOW: {
            type: 'CVTOW',
            sub_type: "HCVTOW",
            time: ""
        },
        HCV6WTOW: {
            type: 'CVTOW',
            sub_type: "HCV6WTOW",
            time: ""
        },
        HCVWT: {
            type: 'CVTOW',
            sub_type: "HCVWT",
            time: ""
        },
    }
}
//defining for FTSP
const FTSPServiceObject = {
    '2-Wheeler FTSP Day Time': {
        type: 'RRP',
        sub_type: "two_wheeler_FTSP",
        time: "day time"
    },
    '2-Wheeler FTSP Night Time': {
        type: 'RRP',
        sub_type: "two_wheeler_FTSP",
        time: "night time"
    },
    '2-Wheeler FTSP Sunday & National Holiday': {
        type: 'RRP',
        sub_type: "two_wheeler_FTSP",
        time: "sunday holiday"
    },
    '4-Wheeler FTSP Day Time': {
        type: 'RRP',
        sub_type: "four_wheeler_FTSP",
        time: "day time"
    },
    '4-Wheeler FTSP Sunday & National Holiday': {
        type: 'RRP',
        sub_type: "four_wheeler_FTSP",
        time: "sunday holiday"
    },
    '4-Wheeler FTSP Night Time': {
        type: 'RRP',
        sub_type: "four_wheeler_FTSP",
        time: "night time"
    },
    'CAR ROS FTSP': {
        type: 'RRP',
        sub_type: "car_ros_FTSP",
        time: ""
    }
}

function getKey(time: string, sub_type: string) {
    for (let serviceType in ServicesObject) {
        for (let key in ServicesObject[serviceType]) {
            if (serviceType === 'RRP' && ServicesObject[serviceType][key].time === time && ServicesObject[serviceType][key].sub_type === sub_type) {
                return key;
            } else if (serviceType !== 'RRP' && ServicesObject[serviceType][key].sub_type === sub_type) {
                return key;
            }
        }
    }
    return null;
}


function formatServices(services: any[]) {
    let formattedServices: any = {};
    services.forEach(service => {
        if (service.type) {
            if (!formattedServices[service.type]) {
                formattedServices[service.type] = [];
            }
            let serP: any = {
                "Base_Rate": service['base_rate'],
                "Base_Kms": service['base_kms'],
                "Rate_for_additional_Kms": service['additional_rates_per_km'],
                "Fix_Rate": service['fixed_rate'],
                "Cancellation_Base_Kms": service['cancel_charge_base_rate'],
                "Cancellation_Rate_for_additional_Kms": service['cancel_charge_additionalKms'],
                "key": getKey(service['time'], service['sub_type']),
                "EAI_Service_Id": service['EAI_Service_Id'] ? service['EAI_Service_Id'] : "",
                "Base_Rate_100": service['tier2'] && service['tier2']['base_rate'] ? service['tier2']['base_rate'] : "",
                "Rate_for_additional_Kms_100": service['tier2'] && service['tier2']['additional_rates_per_km'] ? service['tier2']['additional_rates_per_km'] : "",
                "Cancellation_Base_Kms_100": service['tier2'] && service['tier2']['cancel_charge_base_rate'] ? service['tier2']['cancel_charge_base_rate'] : "",
                "Cancellation_Rate_for_additional_Kms_100": service['tier2'] && service['tier2']['cancel_charge_additionalKms'] ? service['tier2']['cancel_charge_additionalKms'] : "",
            }
            let existingService = formattedServices[service.type].find((s: any) => s.key === serP.key);
            if (!existingService) {
                formattedServices[service.type].push(serP);
            }
        }
    });
    return formattedServices;
}






const DateParobj: any = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: 'Apr',
    5: 'May',
    6: "Jun",
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: "Oct",
    11: "Nov",
    12: "Dec"
}
const getFormatedDate = (date: any) => {
    if (date) {
        let newD = new Date(date);
        let month = DateParobj[newD.getMonth() + 1];
        return newD.getDate() + '-' + month + '-' + newD.getFullYear()
    }
    return '';
}

const PrepasebasicDetails = (registrationForm: any) => {

    let panDetails: any = registrationForm['PanVerification'];
    let personalDetails = registrationForm['PersonalDetails'];
    let Locations = registrationForm['Locations'];
    let OtherDetails = registrationForm['OtherDetails'];
    let UploadDocuments = registrationForm['UploadDocuments'];

    let invType: any = ['V', 'D'].includes(panDetails["registration_type"]) ? (personalDetails['gc_ppc'] ? 'GC-PPC' : 'Normal') : "FTSP";
    let EAI_vendor_Id: any = '';

    if (registrationForm['user'] && registrationForm['user']['EAI_vendor_Id']) {
        EAI_vendor_Id = registrationForm['user']['EAI_vendor_Id'];
    }
    if (!EAI_vendor_Id) {
        if (registrationForm['formuser'] && registrationForm['formuser']['EAI_vendor_Id']) {
            EAI_vendor_Id = registrationForm['formuser']['EAI_vendor_Id'];
        }
    }

    let basicDetails: IBasicDetails = {
        EAI_vendor_Id: EAI_vendor_Id,
        reg_status: registrationForm['step'] ? registrationForm['step'] : "Personal Details",
        PAN_number: panDetails['pancard'],
        cell_phone: panDetails['mobile_no'],
        DOB: panDetails['date_of_birth'] ? getFormatedDate(panDetails['date_of_birth']) : '',
        surname: panDetails["surname"],
        middle_name: panDetails['middle_name'],
        first_name: panDetails['first_name'],
        entityName: panDetails['entity_name'],
        org_name: "",
        reg_type: panDetails["registration_type"],
        signon_user_ref: registrationForm['user'] && registrationForm['user']['signon_user_ref'],
        vehicle_make: panDetails['vehicle_make'] ? panDetails['vehicle_make'] : '',
        dealerId: panDetails['dealer_id'] ? panDetails['dealer_id'] : '',
        regPassword: personalDetails["password"],
        vendor_email: personalDetails["email"],
        vendor_language: personalDetails["vendor_language"],
        preffered_language: personalDetails["preffered_language"],
        vendLocations: prepareLocationsPayload(Locations, registrationForm),
        country: personalDetails["country"],

        EAI_Employee: panDetails["eai_employee"],
        auth_signatory: {
            "name": personalDetails["name"],
            "PAN_number": personalDetails["pancard"],
            "Aadhar_number": personalDetails["aadhar_no"],
            "mobile_number": personalDetails["mobile_no"],
            "Designation": personalDetails["designation"],
            "DOB": personalDetails["dob"] ? getFormatedDate(personalDetails["dob"]) : '',
        },
        "shop_act_registration_num": (OtherDetails && OtherDetails['shop_act_regn_no']) ? OtherDetails['shop_act_regn_no'] : '',
        "factory_registration": (OtherDetails && OtherDetails['factry_regn_no']) ? OtherDetails['factry_regn_no'] : '',
        "service_tax_registration_num": (OtherDetails && OtherDetails['gst_prefix']) ? OtherDetails['gst_prefix'] + OtherDetails['gst_tax_regn_no'] : '',
        "gst_details": (OtherDetails && OtherDetails['gst_details']) ? OtherDetails['gst_details'] : {},

        "id_proof": (OtherDetails && OtherDetails['id_proof']) ? OtherDetails['id_proof'] : '',
        "bank_account": {
            "account_number": (OtherDetails && OtherDetails['bank_acnt_no']) ? OtherDetails['bank_acnt_no'] : '',
            "beneficiary_name": (OtherDetails && OtherDetails['bank_acc_hol_name']) ? OtherDetails['bank_acc_hol_name'] : '',
            "bank_name": (OtherDetails && OtherDetails['bank_name']) ? OtherDetails['bank_name'] : '',
            "branch_addr": (OtherDetails && OtherDetails['bank_branch_address']) ? OtherDetails['bank_branch_address'] : '',
            "branch_name": (OtherDetails && OtherDetails['bank_branch_name']) ? OtherDetails['bank_branch_name'] : '',
            "IFSC_code": (OtherDetails && OtherDetails['ifsc_code']) ? OtherDetails['ifsc_code'] : '',
            "micr": (OtherDetails && OtherDetails['micr']) ? OtherDetails['micr'] : '',
        },
        "Invoice_payment_flag": invType,
        "userName": personalDetails["pancard"]
    }

    return basicDetails;
}

const prepareLocationsPayload = (locations: any, registrationForm: any) => {
    let locationsp: any = '';
    if (locations && Object.keys(locations)) {
        locationsp = [];
        let data = locations.locations;
        let services = registrationForm['services'] ? registrationForm['services'] : (registrationForm['ServicesList'] ? registrationForm['ServicesList'] : []);
        data?.map((d: any, index: number) => {
            let loc: any = {
                "_id": d['_id'] ? d['_id'] : '',
                "LocationId": d['LocationId'] ? d['LocationId'] : '',
                "PIN": d['pincode'],
                "AltPhone": d['alter_phone'],
                "PhoneNumber": d['phone'],
                "Country": "India",
                "State": d['state'],
                "City": d['city'],
                "Employee_Weekoff": d['DealershipWeek'] ? d['DealershipWeek'] : '',
                "BranchCode": d['BranchCode'] ? d['BranchCode'] : '',
                "Security_Guard": d['Security'] ? d['Security'] : '',
                "Address2": d['address2'],
                "Address1": d['address1'],
                "addr_type": '',
                "vendor_reg_type": '',
                "EAI_Address_Id": '',
                "__v": "",
                "record_status": {
                    "creation_date": "",
                    "created_user": ""
                },
                "ServicesOffered": prepareServicesOfferedPayload(services[index]),
                "WorkLocation": {
                    "type": "Point",
                    "coordinates": [
                        d['longitude'],
                        d['latitude']
                    ]
                }
            }
            locationsp.push(loc);
        })
    }
    return locationsp;
}

const prepareServicesOfferedPayload = (services: any) => {
    let data: any = [];
    // if (services && typeof services === 'object') {

    Object.keys(services).map((serKey: any) => {
        // if (services[serKey] && Array.isArray(services[serKey])) {
        services[serKey].map((servic: any) => {
            if (servic['key'].includes('FTSP')) {
                ServicesObject[serKey] = FTSPServiceObject
            }
            let serP: any = {
                "base_rate": servic['Base_Rate'],
                "base_kms": servic['Base_Kms'],
                "additional_rates_per_km": servic['Rate_for_additional_Kms'],
                "fixed_rate": servic['Fix_Rate'],
                "cancel_charge_base_rate": servic['Cancellation_Base_Kms'],
                "cancel_charge_additionalKms": servic['Cancellation_Rate_for_additional_Kms'],
                "type": serKey,
                "sub_type": ServicesObject[serKey] && ServicesObject[serKey][servic['key']] && ServicesObject[serKey][servic['key']]['sub_type'] ? ServicesObject[serKey][servic['key']]['sub_type'] : "",
                "time": ServicesObject[serKey] && ServicesObject[serKey][servic['key']] && ServicesObject[serKey][servic['key']]['time'],
                "checked": true,
                "EAI_Service_Id": servic['EAI_Service_Id'] ? servic['EAI_Service_Id'] : "",
                "disableflag": true,
                "serviceActivationFlag": "",
                "tier2": {
                    "base_rate": servic['Base_Rate_100'] || "",
                    "base_kms": "100",
                    "additional_rates_per_km": servic['Rate_for_additional_Kms_100'] || "",
                    "fixed_rate": servic['Fix_Rate'],
                    "cancel_charge_base_rate": servic['Cancellation_Base_Kms_100'],
                    "cancel_charge_additionalKms": servic['Cancellation_Rate_for_additional_Kms_100'],
                    "checked": true,
                    "disableflag": true,
                }
            }
            data.push(serP);
        })
        // } 
        // else if (services[serKey] != undefined) {
        //     services = formatServices(services);
        //     let result = prepareServicesOfferedPayload(services);
        //     data = data.concat(result);

        // }
    });

    return data;
}

const parseDataToPreFillSteps = (data: any) => {
    let RegistrationFormData: any = {
        PanVerification: {
            pancard: data['PAN_number'],
            mobile_no: data['cell_phone'],
            date_of_birth: data['DOB'] ? moment(getFormatedDate(data['DOB']), 'DD-MMM-YYYY') : '',
            surname: data['surname'],
            middle_name: data['middle_name'],
            first_name: data['first_name'],
            entity_name: data['entityName'],
            org_name: "",
            registration_type: data["reg_type"],
            country: data["country"],
            eai_employee: data["EAI_Employee"],
            dealer_id: data['EAI_vendor_Id'],
            vehicle_make: data['vehicle_make'],
            validated: true,
        },
        PersonalDetails: {
            user_name: data['PAN_number'],
            email: data["vendor_email"],
            password: data['signon_user_ref'] ? data['signon_user_ref']['password'] : '',
            country: data["country"] ? data["country"] : 'India',
            "name": data['auth_signatory']["name"],
            "pancard": data['auth_signatory']["PAN_number"],
            "aadhar_no": data['auth_signatory']["Aadhar_number"],
            "mobile_no": data['auth_signatory']["mobile_number"],
            "designation": data['auth_signatory']["Designation"],
            "vendor_language": data["vendor_language"],
            "preffered_language": data["preffered_language"],
            gstValue: data['service_tax_registration_num'],
            validated: true,
            dob: data['auth_signatory']["DOB"] ? moment(getFormatedDate(data['auth_signatory']["DOB"]), 'DD-MMM-YYYY') : '',
        },
        Locations: {
            locations: prepareLocations(data),
            validated: true,
        },
        ServicesList: prepareServices(data),
        OtherDetails: {
            'id_proof': data['id_proof'],
            'bank_acnt_no': data['bank_account']['account_number'],
            'bank_acc_hol_name': data['bank_account']['beneficiary_name'],
            'ifsc_code': data['bank_account']['IFSC_code'],
            'bank_name': data['bank_account']['bank_name'],
            'bank_branch_name': data['bank_account']['branch_name'],
            'bank_branch_address': data['bank_account']['branch_addr'],
            'micr': data['bank_account']['micr'],
            'shop_act_regn_no': data['shop_act_registration_num'],
            'factry_regn_no': data['factory_registration'],
            'is_gst': data['service_tax_registration_num'] ? 'yes' : 'no',
            validated: true,
        },
        vendorId: data && data._id ? data._id : '',
        UserDetails: data,
        UploadDocuments: {}
    }
    return RegistrationFormData;
}

const prepareLocations = (data: any) => {
    let locations: any[] = [];
    data?.Locations.map((loca: any) => {
        let location: any = {};
        location['phone'] = loca['PhoneNumber'];
        location['LocationId'] = loca['LocationId'];
        location['_id'] = loca['_id'];
        location['address1'] = loca['Address1'];
        location['pincode'] = loca['PIN'];
        location['city'] = loca['City'];
        location['state'] = loca['State'];
        location['DealershipWeek'] = loca['Employee_Weekoff'];
        location['BranchCode'] = loca['BranchCode'];
        location['Security'] = loca['Security_Guard'];
        location['alter_phone'] = loca['AltPhone'];
        location['address2'] = loca['Address2']
        location['latitude'] = loca['WorkLocation'] && loca['WorkLocation']['coordinates'] ? loca['WorkLocation']['coordinates'][1] : '';
        location['longitude'] = loca['WorkLocation'] && loca['WorkLocation']['coordinates'] ? loca['WorkLocation']['coordinates'][0] : '';
        locations.push(location);
    });
    return locations;
}
const prepareLocationsAddresses = (data: any) => {
    let locations: any[] = [];
    data?.addresses.map((loca: any) => {
        let location: any = {};
        location['technician_phone'] = loca['PhoneNumber']
        location['phone'] = loca['PhoneNumber'];
        location['LocationId'] = loca['LocationId'];
        location['_id'] = loca['_id'];
        location['address1'] = loca['Address1'];
        location['pincode'] = loca['PIN'];
        location['city'] = loca['City'];
        location['state'] = loca['State'];
        location['alter_phone'] = loca['AltPhone'];
        location['drsa_flag'] = loca['Technicians'][0] && loca['Technicians'][0]['drsa_flag'] ? loca['Technicians'][0]['drsa_flag'] : false;
        location['technician_name'] = loca['Technicians'][0]['FirstName'];
        location['technician_phone'] = loca['Technicians'][0]['MobileNumber'];
        location['DealershipWeek'] = loca['Employee_Weekoff'];
        location['BranchCode'] = loca['BranchCode'];
        location['Security'] = loca['Security_Guard'];
        location['address2'] = loca['Address2'];
        location['latitude'] = loca['WorkLocation'] && loca['WorkLocation']['coordinates'] ? loca['WorkLocation']['coordinates'][1] : '';
        location['longitude'] = loca['WorkLocation'] && loca['WorkLocation']['coordinates'] ? loca['WorkLocation']['coordinates'][0] : '';
        locations.push(location);
    });
    return locations;
}

const ExistingPrepareLocationsAddresses = (data: any) => {
    let locations: any[] = [];
    data.map((loca: any) => {
        let location: any = {};
        location['technician_phone'] = loca['technician_phone']
        location['phone'] = loca['phone'];
        location['LocationId'] = loca['LocationId'];
        location['_id'] = loca['_id'];
        location['address1'] = loca['Address1'];
        location['pincode'] = loca['PIN'];
        location['city'] = loca['City'];
        location['state'] = loca['State'];
        location['alter_phone'] = loca['AltPhone'];
        location['drsa_flag'] = loca['drsa_flag'];
        location['technician_name'] = loca['technician_name'];
        location['DealershipWeek'] = loca['Employee_Weekoff'];
        location['BranchCode'] = loca['BranchCode'];
        location['Security'] = loca['Security_Guard'];
        location['address2'] = loca['Address2'];
        var obj = {
            coordinates: [loca['longitude'] ? loca['longitude'] : '', loca['latitude'] ? loca['latitude'] : '']
        }
        location['CurrentLocation'] = obj;

        locations.push(location);
    });
    return locations;
}

const prepareServices = (data: any) => {
    let services: any[] = [];
    data?.Locations.map((loca: any) => {
        let service = loca['ServicesOffered'] ? loca['ServicesOffered'] : [];
        services.push(service);
    });
    services = convertData(services);
    return services;
}

const prepareServicesObject = (data: any) => {
    let services: any[] = [];
    data?.Locations.map((loca: any) => {
        let service = loca['ServicesOffered'] ? loca['ServicesOffered'] : [];
        services.push(service);
    });
    // return services;

}


const prepareServicesAddress = (data: any) => {
    let services: any[] = [];
    data?.addresses.map((loca: any) => {
        let service = loca['ServicesOffered'] ? loca['ServicesOffered'] : [];
        services.push(service);
    });
    services = convertData(services);
    return services;
}

const columnsList = [
    {
        title: 'Towing Services',
        dataIndex: 'Towing_Services',
        width: 150,
        editable: false,
        className: "ez-aeditable-table-column twn_service"
    },
    {
        title: 'Base Rate',
        className: "ez-aeditable-table-column base_rate_header",
        editable: true,
        children: [
            {
                title: 'Base Rate',
                dataIndex: 'Base_Rate',
                key: 'Base_Rate',
                className: "ez-aeditable-table-column base_rate",
                editable: true,
                rules:
                    [
                        {
                            required: true,
                            message: `Please Input Base Rate!`,
                        },
                    ]
            },
            {
                title: 'Base Kms',
                dataIndex: 'Base_Kms',
                className: "ez-aeditable-table-column base_kms",
                key: 'Base_Kms',
                editable: true,
                rules:
                    [
                        {
                            required: true,
                            message: `Please Input Base Kms!`,
                        },
                    ]
            },
            {
                title: "Rate'\s for additional Kms",
                dataIndex: 'Rate_for_additional_Kms',
                key: 'Rate_for_additional_Kms',
                className: "ez-aeditable-table-column",
                ellipsis: true,
                editable: true,
                rules:
                    [
                        {
                            required: true,
                            message: `Please Input Rate'\s for additional Kms!`,
                        },
                    ]
            },
        ]
    },
    {
        title: 'Within city limits',
        ellipsis: true,
        editable: true,
        className: "ez-aeditable-table-column",
        children: [
            {
                title: 'Fix Rate',
                dataIndex: 'Fix_Rate',
                className: "ez-aeditable-table-column",
                editable: true,
            },
        ]
    },
    {
        title: 'Cancellation Charges',
        ellipsis: true,
        editable: true,
        className: "ez-aeditable-table-column",
        children: [
            {
                title: 'Base Kms *',
                dataIndex: 'Base_Kms',
                editable: true,
                className: "ez-aeditable-table-column",
                attributes: {
                    readOnly: true
                }
            },
            {
                title: 'Additional Kms *',
                dataIndex: 'Rate_for_additional_Kms',
                editable: true,
                className: "ez-aeditable-table-column",
                attributes: {
                    readOnly: true
                }
            },
        ]
    },
];

const RosServiceList = [
    { label: '2-Wheeler Day Time', value: '2-Wheeler Day Time' },
    { label: '2-Wheeler Night Time', value: 'Night Time' },
    { label: '2-Wheeler Sunday & National Holiday', value: 'Sunday & National Holiday' },
    { label: '4-Wheeler Day Time', value: 'Day Time' },
    { label: '4-Wheeler Night Time', value: 'Night Time' },
    { label: '4-Wheeler Sunday & National Holiday', value: 'Sunday & National Holiday' },
    { label: 'CAR_ROS', value: 'CAR_ROS' },
    // { label: 'Custody', value: 'Custody' },
];

const RosFTSPServiceList = [
    { label: '2-Wheeler FTSP Day Time', value: '2-Wheeler Day Time' },
    { label: '2-Wheeler FTSP Night Time', value: 'Night Time' },
    { label: '2-Wheeler FTSP Sunday & National Holiday', value: 'Sunday & National Holiday' },
    { label: '4-Wheeler FTSP Day Time', value: 'Day Time' },
    { label: '4-Wheeler FTSP Night Time', value: 'Night Time' },
    { label: '4-Wheeler FTSP Sunday & National Holiday', value: 'Sunday & National Holiday' },
    { label: 'CAR ROS FTSP', value: 'CAR ROS' },
];

const TowingServiceList = [
    { label: 'Four Wheeler FBT', value: 'Four Wheeler FBT' },
    { label: 'Two Wheeler FBT', value: 'Two Wheeler FBT' },
    { label: 'UWL', value: 'UWL' },
    { label: 'W&T', value: 'W&T' },
    { label: '4W_FBT_2W', value: '4W_FBT_2W' },
    { label: '7 Degree Flatbed', value: '7 Degree Flatbed' },
    { label: 'Dolly Wheels', value: 'Dolly Wheels' },
    { label: 'Zero Degree Flatbed', value: 'Zero Degree Flatbed' },
    { label: 'Car to Car', value: 'Car to Car' },
    { label: '3W Auto', value: '3W Auto' },
    { label: 'Hydra', value: 'Hydra' },
    { label: 'Tempo', value: 'Tempo' },
    { label: 'TUK_TUK', value: 'TUK_TUK' },
    { label: 'Others', value: 'Others' },
];

const CVRosServiceList = [
    { label: 'LCVRRP', value: 'LCVRRP' },
    { label: 'MCVRRP', value: 'MCVRRP' },
    { label: 'HCVRRP', value: 'HCVRRP' }
];

const CVTowingServiceList = [
    { label: 'LCVTOW', value: 'LCVTOW' },
    { label: 'LCV4WTOW', value: 'LCV4WTOW' },
    { label: 'LCVUWL', value: 'LCVUWL' },
    { label: 'LCVWT', value: 'LCVWT' },
    { label: 'MCVTOW', value: 'MCVTOW' },
    { label: 'MCV4WTOW', value: 'MCV4WTOW' },

    { label: 'MCVWT', value: 'MCVWT' },
    { label: 'MCVUWL', value: 'MCVUWL' },
    { label: 'HCVTOW', value: 'HCVTOW' },
    { label: 'HCV6WTOW', value: 'HCV6WTOW' },
    { label: 'HCVWT', value: 'HCVWT' },
];
const CustodyList = [
    { label: 'Custody', value: 'Custody' },

];

// category: legalEntity/   
//sub : PAN/GST/idProof/bankDocument/licenceDocument   

// category: authSignatory/   
//sub : authPAN/Adhaar

const FileuploadParams: any = {
    legal_pan_no_file: {
        category: "legalEntity",
        sub: "PAN"
    },
    gst_cert_file: {
        category: "legalEntity",
        sub: "GST"
    },
    address_file: {
        category: "legalEntity",
        sub: "idProof"
    },
    auth_pan_file: {
        category: "authSignatory",
        sub: "authPAN"
    },
    aadhar_copy_file: {
        category: "authSignatory",
        sub: "Adhaar"
    },
    cancelled_passbook: {
        category: "legalEntity",
        sub: "bankDocument"
    },
    agreement_certificate: {
        category: "legalEntity",
        sub: "licenceDocument"
    },
}

const parseDataToFillExistingLocation = (data: any) => {
    let RegistrationData: any = {
        Locations: {
            locations: prepareLocationsAddresses(data),
            validated: true,
        },
        ServicesList: prepareServicesAddress(data),

    }
    return RegistrationData;

}



const detailsUpdateForProfile = (locations: any, registrationForm: any) => {
    let locationsp: any = '';
    if (locations && Object.keys(locations)) {
        locationsp = [];
        let data = locations;
        let services = registrationForm['services'] ? registrationForm['services'] : (registrationForm['ServicesList'] ? registrationForm['ServicesList'] : []);
        data?.map((d: any, index: number) => {
            let loc: any = {
                "_id": d['_id'] ? d['_id'] : '',
                "LocationId": d['LocationId'] ? d['LocationId'] : '',
                "PIN": d['pincode'],
                "AltPhone": d['alter_phone'],
                "PhoneNumber": d['phone'],
                "Country": "India",
                "State": d['state'],
                "City": d['city'],
                "Employee_Weekoff": d['DealershipWeek'] ? d['DealershipWeek'] : '',
                "BranchCode": d['BranchCode'] ? d['BranchCode'] : '',
                "Security_Guard": d['Security'] ? d['Security'] : '',
                "Address2": d['address2'],
                "Address1": d['address1'],
                "addr_type": '',
                "vendor_reg_type": '',
                "EAI_Address_Id": '',
                "__v": "",
                "record_status": {
                    "creation_date": "",
                    "created_user": ""
                },
                "ServicesOffered": prepareServicesOfferedPayload(services[index]),
                "WorkLocation": {
                    "type": "Point",
                    "coordinates": [
                        d['latitude'],
                        d['longitude']
                    ]
                }
            }
            locationsp.push(loc);
        })
    }
    return locationsp;
}

const detailsUpdateForProfileDashboard = (locations: any, registrationForm: any) => {
    let locationsp: any = '';
    if (locations && Object.keys(locations)) {
        locationsp = [];
        let data = locations;
        let services = registrationForm['services'] ? registrationForm['services'] : (registrationForm['ServicesList'] ? registrationForm['ServicesList'] : []);
        data?.map((d: any, index: number) => {
            let loc: any = {
                "_id": d['_id'] ? d['_id'] : '',
                "LocationId": d['LocationId'] ? d['LocationId'] : '',
                "PIN": d['pincode'],
                "AltPhone": d['alter_phone'],
                "PhoneNumber": d['phone'],
                "Country": "India",
                "State": d['state'],
                "City": d['city'],
                "Employee_Weekoff": d['DealershipWeek'] ? d['DealershipWeek'] : '',
                "BranchCode": d['BranchCode'] ? d['BranchCode'] : '',
                "Security_Guard": d['Security'] ? d['Security'] : '',
                "Address2": d['address2'],
                "Address1": d['address1'],
                "addr_type": '',
                "vendor_reg_type": '',
                "EAI_Address_Id": '',
                "__v": "",
                "record_status": {
                    "creation_date": "",
                    "created_user": ""
                },
                "ServicesOffered": prepareServicesOfferedPayload(services[index]),
                "Technicians": ExistingPrepareLocationsAddresses(locations),
                "WorkLocation": {
                    "type": "Point",
                    "coordinates": [
                        d['longitude'],
                        d['latitude']

                    ]
                }
            }
            locationsp.push(loc);
        })
    }
    return locationsp;
}


const ServicesObject2: any = {

    '2-Wheeler Day Time': {
        type: 'RRP',
        sub_type: "two_wheeler",
        time: "day time",
    },
    '2-Wheeler Night Time': {
        type: 'RRP',
        sub_type: "two_wheeler",
        time: "night time"
    },
    '2-Wheeler Sunday & National Holiday': {
        type: 'RRP',
        sub_type: "two_wheeler",
        time: "sunday holiday"
    },
    '4-Wheeler Day Time': {
        type: 'RRP',
        sub_type: "four_wheeler",
        time: "day time"
    },
    '4-Wheeler Sunday & National Holiday': {
        type: 'RRP',
        sub_type: "four_wheeler",
        time: "sunday holiday"
    },
    '4-Wheeler Night Time': {
        type: 'RRP',
        sub_type: "four_wheeler",
        time: "night time"
    },
    'CAR_ROS': {
        type: 'RRP',
        sub_type: "CAR_ROS",
        time: ""
    },
    'Custody': {
        type: 'Custody',
        sub_type: "Custody",
        time: ""
    },
    '2-Wheeler Night Time_100': {
        type: 'RRP',
        sub_type: "two_wheeler",
        time: "night time"
    },



    'Four Wheeler FBT': {
        type: 'TOW',
        sub_type: "four_wheeler_FBT",
        time: ""
    },
    'Two Wheeler FBT': {
        type: 'TOW',
        sub_type: "two_wheeler_FBT",
        time: ""
    },
    'UWL': {
        type: 'TOW',
        sub_type: "UWL",
        time: ""
    },
    'W&T': {
        type: 'TOW',
        sub_type: "W&T",
        time: ""
    },
    '4W_FBT_2W': {
        type: 'TOW',
        sub_type: "four_wheeler_FBT_two_wheeler",
        time: ""
    },
    '7 Degree Flatbed': {
        type: 'TOW',
        sub_type: "seven_degree_flatbed",
        time: ""
    },
    'Dolly Wheels': {
        type: 'TOW',
        sub_type: "dolly_wheels",
        time: ""
    },
    'Zero Degree Flatbed': {
        type: 'TOW',
        sub_type: "zero_degree_flatbed",
        time: ""
    },
    'Car to Car': {
        type: 'TOW',
        sub_type: "car_to_car",
        time: ""
    },
    '3W Auto': {
        type: 'TOW',
        sub_type: "3W_Auto",
        time: ""
    },
    'Hydra': {
        type: 'TOW',
        sub_type: "others_hydra",
        time: ""
    },
    'Tempo': {
        type: 'TOW',
        sub_type: "others_tempo",
        time: ""
    },
    'TUK_TUK': {
        type: 'TOW',
        sub_type: "TUK_TUK",
        time: ""
    },

    'Others': {
        type: 'TOW',
        sub_type: "others_others",
        time: ""
    },

    LCVRRP: {
        type: 'CVRRP',
        sub_type: "LCVRRP",
        time: ""
    },
    MCVRRP: {
        type: 'CVRRP',
        sub_type: "MCVRRP",
        time: ""
    },
    HCVRRP: {
        type: 'CVRRP',
        sub_type: "HCVRRP",
        time: ""
    },


    LCVTOW: {
        type: 'CVTOW',
        sub_type: "LCVTOW",
        time: ""
    },
    LCV4WTOW: {
        type: 'CVTOW',
        sub_type: "LCV4WTOW",
        time: ""
    },
    MCVUWL: {
        type: 'CVTOW',
        sub_type: "MCVUWL",
        time: ""
    },
    MCVWT: {
        type: 'CVTOW',
        sub_type: "MCVWT",
        time: ""
    },
    MCV4WTOW: {
        type: 'CVTOW',
        sub_type: "MCV4WTOW",
        time: ""
    },
    MCVTOW: {
        type: 'CVTOW',
        sub_type: "MCVTOW",
        time: ""
    },
    LCVWT: {
        type: 'CVTOW',
        sub_type: "LCVWT",
        time: ""
    },
    LCVUWL: {
        type: 'CVTOW',
        sub_type: "LCVUWL",
        time: ""
    },
    HCVTOW: {
        type: 'CVTOW',
        sub_type: "HCVTOW",
        time: ""
    },
    HCV6WTOW: {
        type: 'CVTOW',
        sub_type: "HCV6WTOW",
        time: ""
    },
    HCVWT: {
        type: 'CVTOW',
        sub_type: "HCVWT",
        time: ""
    },
    '2-Wheeler FTSP Day Time': {
        type: 'RRP',
        sub_type: "two_wheeler_FTSP",
        time: "day time"
    },
    '2-Wheeler FTSP Night Time': {
        type: 'RRP',
        sub_type: "two_wheeler_FTSP",
        time: "night time"
    },
    '2-Wheeler FTSP Sunday & National Holiday': {
        type: 'RRP',
        sub_type: "two_wheeler_FTSP",
        time: "sunday holiday"
    },
    '4-Wheeler FTSP Day Time': {
        type: 'RRP',
        sub_type: "four_wheeler_FTSP",
        time: "day time"
    },
    '4-Wheeler FTSP Sunday & National Holiday': {
        type: 'RRP',
        sub_type: "four_wheeler_FTSP",
        time: "sunday holiday"
    },
    '4-Wheeler FTSP Night Time': {
        type: 'RRP',
        sub_type: "four_wheeler_FTSP",
        time: "night time"
    },
    'CAR ROS FTSP': {
        type: 'RRP',
        sub_type: "car_ros_FTSP",
        time: ""
    }


}

type Item = {
    record_status: {
        creation_date: string;
        status_flag: string;
    };
    tier2: {
        base_rate: null | number;
        base_kms: number;
        additional_rates_per_km: null | number;
        fixed_rate: number;
        cancel_charge_base_rate: number;
        cancel_charge_additionalKms: number;
    };
    tier1Limit: number;
    _id: string;
    EAI_Service_Id: string;
    base_rate: number;
    base_kms: number;
    additional_rates_per_km: number;
    fixed_rate: number;
    cancel_charge_base_rate: number;
    cancel_charge_additionalKms: number;
    type: string;
    sub_type: string;
    serviceActivationFlag: string;
    time: string;
};

function convertData(data: Item[][]): { RRP: any[]; TOW: any[]; CVTOW: any[]; CVRRP: any[]; Custody: any[] }[] {
    const convertedData: { RRP: any[]; TOW: any[]; CVTOW: any[]; CVRRP: any[]; Custody: any[] }[] = [];
    data.forEach((array) => {
        const obj: any = {
            RRP: [],
            TOW: [],
            CVRRP: [],
            CVTOW: [],
            Custody: []
        };

        let servicesTypes = new Set();

        array.forEach((item: any) => {
            //console.log("ITEM",item)
            // if(item.type)
            var sTypeKey = getKeyFromObject(ServicesObject2, item.type, item.sub_type, item.time)
            // console.log("KEYYYYYY", sTypeKey)

            let newItem = {
                key: sTypeKey,
                // Towing_Services: item.sub_type === "two_wheeler" ? "2-Wheeler Day Time" : "Four Wheeler FBT",
                Base_Rate: item.base_rate,
                Base_Kms: item.base_kms,
                Rate_for_additional_Kms: item.additional_rates_per_km,
                Fix_Rate: item.fixed_rate,
                Cancellation_Base_Kms: item.cancel_charge_base_rate,
                Cancellation_Rate_for_additional_Kms: item.cancel_charge_additionalKms,
                BaseKms100: item?.tier2?.base_kms !== null && item?.tier2?.base_rate !== null ? item?.tier2?.base_kms : "",
                Cancellation_Base_Kms_100: item?.tier2?.base_kms !== null && item?.tier2?.base_rate !== null ? item?.tier2?.cancel_charge_base_rate : "",
                Cancellation_Rate_for_additional_Kms_100: item?.tier2?.base_kms !== null && item?.tier2?.base_rate !== null ? item?.tier2?.cancel_charge_additionalKms : "",
                Base_Rate_100: item?.tier2?.base_kms !== null && item?.tier2?.base_rate !== null ? item?.tier2?.base_rate : "",
                Rate_for_additional_Kms_100: item?.tier2?.base_kms !== null && item?.tier2?.base_rate !== null ? item.tier2?.additional_rates_per_km : "",
            };
            if (item.type === "RRP") {
                obj.RRP.push(newItem);
            } else if (item.type === "TOW") {

                if (!servicesTypes.has(sTypeKey)) {
                    obj.TOW.push(newItem);
                    servicesTypes.add(sTypeKey);
                }
            }
            else if (item.type === "CVTOW") {
                if (!servicesTypes.has(sTypeKey)) {
                    obj.CVTOW.push(newItem);
                    servicesTypes.add(sTypeKey);
                }
            }
            else if (item.type === "CVRRP") {
                if (!servicesTypes.has(sTypeKey)) {
                    obj.CVRRP.push(newItem);
                    servicesTypes.add(sTypeKey);
                }
            } else if (item.type === "Custody") {
                if (!servicesTypes.has(sTypeKey)) {
                    obj.Custody.push(newItem);
                    servicesTypes.add(sTypeKey);
                }
            }
        });
        // console.log("CONVERTED DATA",obj)
        convertedData.push(obj);
    });
    return convertedData;
}
const getKeyFromObject = (obj: any, type: any, subtype: any, time: any) => {
    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (obj[key].type === type && obj[key].sub_type === subtype && (obj[key].time === time || obj[key].time === "")) {
            return key;
        }
    }
    return null;
}

export {
    ServicesObject, FileuploadParams, RosServiceList, TowingServiceList, CVRosServiceList,
    CVTowingServiceList, validateMessages, prepareCreateVendorPayload,
    parseDataToPreFillSteps, columnsList, parseDataToFillExistingLocation,
    detailsUpdateForProfile, detailsUpdateForProfileDashboard, ServicesObject2, RosFTSPServiceList, CustodyList
}