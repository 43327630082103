import {Modal,Button,Input,Row,Col} from '../../../components/Elements/Designs'
import moment from 'moment';
import { useState, useEffect } from 'react';
export const ServicePopup = (props:any) => {
    const [visible, setVisible] = useState(false);
  const [caseCreationDate, setCaseCreationDate] = useState<any>()
  const [vendorReachedLoc, setVendorReachedLoc] = useState<any>()
  const [subscriberCallTime, setSubscriberCallTime] = useState<any>()
  const [vendorActivationTime, setVendorActivationTime] = useState<any>()
  const [vendorCompleteTime, setVendorCompleteTime] = useState<any>()
    const [data, setData] = useState<any>()
    useEffect(()=>{
        if(props.display === true){
        setVisible(true)
        }

      if (props.data[0].CaseDoc[0].TimeOfFirstCall) {
        let date: any = moment(new Date(props.data[0].CaseDoc[0].TimeOfFirstCall)).format('DD-MM-YYYY HH:mm')
        setCaseCreationDate(date)
      } else {
        setCaseCreationDate("DD-MM-YYYY HH:mm")
      }
      if (props.data[0].TimeVendorReachedLoc) {
        let date1: any = moment(new Date(props.data[0].TimeVendorReachedLoc)).format('DD-MM-YYYY HH:mm')
        setVendorReachedLoc(date1)
      } else {
        setVendorReachedLoc("DD-MM-YYYY HH:mm")
      }
      if (props.data[0].CaseDoc[0].TimeOfFirstCall) {
        let date2: any = moment(new Date(props.data[0].CaseDoc[0].TimeOfFirstCall)).format('DD-MM-YYYY HH:mm')
        setSubscriberCallTime(date2)
      } else {
        setSubscriberCallTime("DD-MM-YYYY HH:mm")
      }
      if (props.data[0].VendorAssignedTime) {
        let date3: any = moment(new Date(props.data[0].VendorAssignedTime)).format('DD-MM-YYYY HH:mm')
        setVendorActivationTime(date3)
      } else {
        setVendorActivationTime("DD-MM-YYYY HH:mm")
      }
      if (props.data[0].TimeVendorReachedLoc) {
        let date4: any = moment(new Date(props.data[0].TimeVendorReachedLoc)).format('DD-MM-YYYY HH:mm')
        setVendorCompleteTime(date4)
      } else {
        setVendorCompleteTime("DD-MM-YYYY HH:mm")
      }


    },[props.display])
    


    const onClick = ()=>{
       setVisible(false)
        props.sendDataToParent(false)

    }

    let charges = props.data[0].Charges
    let tollCharges :any 
    let waitingCharge :any
    charges.forEach(function(ele:any,index:any){
        if(ele.ChargeType=== 'toll'){
            tollCharges = ele.Amount
        }
        if(ele.ChargeType === 'waiting')
        {
            waitingCharge= ele.Amount
        }

    },this)
    let supplier = props.data[0].selectedSuppliers
    let selectedSupplier:any = "NA"
    let selectedSupplierAddress:any = "NA"
    let selectedSupplierName:any = "NA"
    supplier.forEach(function(data:any,index:any){
        if(data.regtype === "D"||data.regtype ==="DV" ){
            selectedSupplier = data.supplierName;
            selectedSupplierAddress = data.supplierAddress
            selectedSupplierName =data.supplierPhone
        }
    })


    return (
        <div className='antmodel'>
           <Modal
                title="Case Details"
                        centered
                        visible={visible}
                        onCancel={onClick}
                        // width={1000}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}
                        
                    >
                      
                            
         
                      <Row>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Scheme Type:</label>
                        <Input className='inputs-mrg' value={props.data[0].CaseDoc[0].SchemeType} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Created By:</label>
                        <Input className='inputs-mrg' value={props.data[0].CaseDoc[0].CreatedBy} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />
                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Vehicle Regn no:</label>
              <Input className='inputs-mrg' value={props.data[0].vehicleDoc[0].VehicleRegnNumber} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />


            </Col>
            <Col className='col-pad' span={12}>
              <label className='label-title'>VIN/CHASIS details:</label>
              <Input className='inputs-mrg' value={props.data[0].vehicleDoc[0].ChasisNumber} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />


                    </Col>
            {/* <Col className='col-pad' span={12}>
                        <label className='label-title'>VIN/CHASIS details:</label>
                        <Input className='inputs-mrg' value={props.data[0].VehicleDoc[0].ChasisNumber} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />
                    </Col> */}

                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Case Creation Date:</label>
              <Input className='inputs-mrg' value={caseCreationDate} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />
  
                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Vehicle Model:</label>
                        <Input className='inputs-mrg' value={props.data[0].VehicleModel} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />
 
                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Time when subscriber called:</label>
              <Input className='inputs-mrg' value={subscriberCallTime} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />
 
                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Time when vendor activated:</label>
              <Input className='inputs-mrg' value={vendorActivationTime} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Time vendor reached BD location:</label>
              <Input className='inputs-mrg' value={vendorReachedLoc} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />
  
                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Time vendor completed:</label>
              <Input className='inputs-mrg' value={vendorCompleteTime} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Status:</label>
                        <Input className='inputs-mrg' value={props.data[0].VendorStatus} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Total Distance:</label>
                        <Input className='inputs-mrg' value={props.data[0].TotalKms} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Toll Charges:</label>
                        <Input className='inputs-mrg' value={tollCharges} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Waiting Hrs Amount: </label>
                        <Input className='inputs-mrg' value={waitingCharge} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />
 
                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Total Amount:</label>
                        <Input className='inputs-mrg' value={props.data[0].TotalAmount} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />
 
                      </Col>
                      <Col className='col-pad' span={12}>
                      <label className='label-title'>Service Type:</label>
                        <Input className='inputs-mrg' value={props.data[0].ServiceType} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                          </Col>
                          <Col className='col-pad' span={12}>
                          <label className='label-title'>Service Subtype:</label>
                        <Input className='inputs-mrg' value={props.data[0].ServiceSubType} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                          </Col>
                          <Col className='col-pad' span={12}>
                          <label className='label-title'>Dealer/Preferred Name:</label>
                        <Input className='inputs-mrg' value={selectedSupplier} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                          </Col>
                          <Col className='col-pad' span={12}>
                          <label className='label-title'>Dealer/Preferred Address:</label>
                        <Input className='inputs-mrg' value={selectedSupplierAddress} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                          </Col>
                          <Col className='col-pad' span={12}>
                          <label className='label-title'>Dealer contact phone:</label>
                        <Input className='inputs-mrg' value={selectedSupplierName} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                          </Col>
                          <Col className='col-pad' span={12}>
                          <label className='label-title'>City:</label>
                        <Input className='inputs-mrg' value={props.data[0].BDLocation.City} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />

                          </Col>
                          <Col className='col-pad' span={12}>
                          <label className='label-title'>State:</label>
                        <Input className='inputs-mrg' value={props.data[0].BDLocation.State} style={{ color: '#999999', fontSize: 15, fontWeight: 'bold' }} disabled />
           
                          </Col>
                          
                      </Row>
            
            </Modal>

        </div>
    )
}