import { useEffect, useState } from "react";
import { Layout, Button, Checkbox, Form, Input, Select, Row, Col, Card, Spin, Radio, message } from "../../../components/Elements";
import { GETS_STATE_LIST, GET_BANK_DETAILS, GET_GST_CODES_LIST, VERIFY_VENDOR_GST } from "../api";
import { CardStyle } from "../helpers/formcsshelper";
import { validateMessages } from "../helpers/formhelper";
import { IIFSCPayload } from "../helpers/interface";
import { GstPopUp } from "../../../modules/Common/Popup/GstPopup";

type LayoutType = Parameters<typeof Form>[0]['layout'];

const IDProofList: any = [
    {
        name: "Electricity Bill",
        value: "Electricity Bill"
    },
    {
        name: "Leave and License Agreement",
        value: "Leave and License Agreement"
    },
    {
        name: "Mobile Bill",
        value: "Mobile Bill"
    },
    {
        name: "Telephone Bill",
        value: "Telephone Bill"
    },
    {
        name: "others",
        value: "others"
    }
];

export const OtherDetails = (props: any) => {
    console.log("OTHER DETAILS",props)

    const [form] = Form.useForm();

    const [RegistrationFormData, setRegistrationFormData] = useState({});

    const [loader, setLoader] = useState<boolean>(false);
    const [showGST, setGSTVisibility] = useState<boolean>(false);
    const [defaultState, setDefaultState] = useState<string>('');
    const [gstNumber, setGstNumber] = useState('');
    const [isGstPopupVisible, setIsGstPopupVisible] = useState(false);
    const [gstData, setGstData] = useState<any>({});


    useEffect(() => {
        setRegistrationFormData(props.RegistrationFormData);
    }, [props.RegistrationFormData]);


    useEffect(() => {
        let registerDetails: any = RegistrationFormData;
        if (props.RegistrationFormData.OtherDetails === undefined || null || " ") {
            form.setFieldsValue({ is_gst: "no" })
        }
        if (registerDetails) {
            let OtherDetails: any = registerDetails['OtherDetails'];
            if (OtherDetails && Object.keys(OtherDetails)) {
                form.setFieldsValue(OtherDetails);
            }

            let UserDetails: any = registerDetails['UserDetails'];
            if (UserDetails && Object.keys(UserDetails)) {
                setDefaultState(UserDetails['vendor_primary_state']);
                if (UserDetails['service_tax_registration_num']) {
                    let gstprefix = UserDetails['service_tax_registration_num'].substring(0, 12);
                    let gstpno = UserDetails['service_tax_registration_num'].substring(12);
                    setGSTVisibility(true);
                    form.setFieldsValue({ 'gst_prefix': gstprefix, gst_tax_regn_no: gstpno });
                }
            }
        }

    }, [RegistrationFormData])

    const [formLayout, setFormLayout] = useState<LayoutType>('horizontal');

    const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
        setFormLayout(layout);
    };

    const onFinish = (values: any) => {
        let params: any = {
            type: 'OtherDetails',
            data: values,
            next: true,
        }
        props.handleSubmit(params);
    }

    const formItemLayout = {};

    const OnIFSCodeChange = async (element: any) => {
        // const row: any = await form.validateFields();
        if (element.target.value && element.target.value.length === 11) {
            let payload: IIFSCPayload = {
                ifsc: element.target.value
            }
            setLoader(true);
            try {
                let bankDetails: any = await GET_BANK_DETAILS(payload);
                setLoader(false);
                if (bankDetails && bankDetails.status == 'success') {
                    let bankInfo = bankDetails.message;
                    form.setFieldsValue(
                        {
                            bank_name: bankInfo['Bank'],
                            bank_branch_name: bankInfo['Branch'],
                            bank_branch_address: bankInfo['Address'],
                            micr: bankInfo['MICR']
                        })
                } else {
                    form.setFieldsValue(
                        {
                            bank_name: '',
                            bank_branch_name: '',
                            bank_branch_address: '',
                            micr: ''
                        })
                }
            } catch (e) {
                setLoader(false);
            }
        }
    }
    console.log("DATSASd", form.getFieldsValue().is_gst)




    const prefixSelector = (
        <Form.Item name="gst_prefix" noStyle>
            <Input disabled style={{ width: '130px' }} />
        </Form.Item>
    );

    useEffect(() => {
        const verifyGst = async () => {
            if (gstNumber.length === 3) {
                let gstPrefix: any = form.getFieldsValue().gst_prefix;
                console.log("GST PREFIX", gstPrefix);
                let gstNo: any = gstPrefix + gstNumber;
                let res: any = await VERIFY_VENDOR_GST({ "gstNo": gstNo });
                if (res.message.gstFlag === false || res.status === "failure") {
                    message.error("The GST number is not valid / deactive as per GST Government portal");
                    form.setFieldsValue({
                        "gst_tax_regn_no": "",
                        "is_gst": "no"
                    })
                    setGSTVisibility(false);

                } else if (res.message.gstFlag === true) {
                    setIsGstPopupVisible(true);
                    setGstData(res.message)
                    if (showGST) {
                        form.setFieldsValue({

                            gst_details: {
                                "legalName": res.message.LegalName,
                                "tradeName": res.message.TradeName,
                                "Constitution": res.message.Constitution,
                                "RegistrationType": res.message.RegistrationType,
                                "RegistrationStatus": res.message.RegistrationStatus,
                                "RegistrationDate": res.message.RegistrationDate,
                                "CancelationDate": res.message.CancelationDate
                            }

                        });
                    }
                } else {
                    setIsGstPopupVisible(false);
                }
            } else {
                setIsGstPopupVisible(false);
            }
        };

        verifyGst();
    }, [gstNumber]);

    const onChangeGstReg = async (event: any) => {


        if (event.target.value === 'yes') {
            setLoader(true);
            setGSTVisibility(true);
            try {
                let gstCodes: any = await GET_GST_CODES_LIST();
                if (gstCodes && gstCodes.status === 'success') {
                    let subcodes = gstCodes.message['subCode'];
                    if (subcodes && subcodes.length && defaultState) {
                        let statecode = subcodes.find((ele: any) => ele.name.toUpperCase() === defaultState.toUpperCase());
                        if (statecode) {
                            let registerDetails: any = RegistrationFormData;
                            if (registerDetails) {
                                let PanVerification: any = registerDetails['PanVerification'];
                                form.setFieldsValue({ gst_prefix: statecode.code + PanVerification['pancard'] })
                                setLoader(false);
                            }
                        }
                    }
                }

            } catch (e) {
                console.log(e)
                setLoader(false);
            }
        } else {
            setGSTVisibility(false);
            form.setFieldsValue({ gst_prefix: '' })
        }
    }

    return (

        <div className="ezauto-regis-container ezauto-other-details">
            <Row style={{ justifyContent: "center" }}>
                <Col span={22}>
                    <Spin spinning={loader}>
                        <Card style={CardStyle}>
                            <Form
                                className="registration-details-form other-details-form"
                                {...formItemLayout}
                                layout={'vertical'}
                                form={form}
                                initialValues={{ layout: formLayout }}
                                onValuesChange={onFormLayoutChange}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                autoComplete="off"
                            >
                                <div className="PersonalMain">
                                    <div style={{ width: "100%", padding: "10px" }}>
                                        <div>
                                            <Form.Item label="Address Proof" name="id_proof" >
                                                <Select >
                                                    {
                                                        IDProofList.map((type: any) => (
                                                            <Select.Option value={type.value}>{type.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="m-bt-38">
                                            <Form.Item label={<div><h5>Bank Account Holder Name :</h5>
                                                <h4>(Please enter as per bank records)</h4>
                                                <h4>(Max 45 characters are allowed)</h4></div>} name="bank_acc_hol_name" rules={[{ required: true }, {
                                                    pattern:/^[A-Za-z]{1,44}$/,
                                                    message: "Please enter valid Bank Account Holder Name"
                                                    
                                                }]}>
                                                <Input autoComplete="new-password" />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item label="Bank Name" name="bank_name" rules={[{ required: true }]}>
                                                <Input autoComplete="new-password" disabled={true} />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item label="Bank Branch Address" name="bank_branch_address" rules={[{ required: true }]}>
                                                <Input autoComplete="new-password" disabled={true} />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item label="Shop Act Regn No:" name="shop_act_regn_no">
                                                <Input autoComplete="new-password" />
                                            </Form.Item>
                                        </div>

                                    </div>
                                    <div style={{ width: "100%", padding: "10px" }}>
                                        <div>
                                            <Form.Item label="Bank Account No" name="bank_acnt_no"
                                                rules={
                                                    [
                                                        { required: true },
                                                        {
                                                            pattern: /^\d{8,16}$/,
                                                            message: "Please enter valid Bank Accc Number",
                                                        },
                                                    ]
                                                }>
                                                <Input autoComplete="new-password" />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item label="IFSC Code" name="ifsc_code"
                                                rules={
                                                    [
                                                        { required: true },
                                                        {
                                                            pattern: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
                                                            message: "Please enter valid IFSC Code",
                                                        },
                                                    ]}>
                                                <Input onChange={($event) => OnIFSCodeChange($event)} autoComplete="new-password" />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item label="Bank Branch Name" name="bank_branch_name" rules={[{ required: true }]}>
                                                <Input autoComplete="new-password" disabled={true} />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item label="MICR" name="micr">
                                                <Input autoComplete="new-password" disabled={true} />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item label="Factory Regn No:" name="factry_regn_no">
                                                <Input autoComplete="new-password" />
                                            </Form.Item>
                                        </div>
                                        <div className="m-bt-20">
                                            <Col className="col-pad align-right" span={12}>
                                                <Form.Item label="Do you have GST regd No.?" name="is_gst" className="align-right">
                                                    <Radio.Group onChange={onChangeGstReg}>
                                                        <Radio value="yes">Yes</Radio>
                                                        <Radio value="no">No</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </div>
                                    </div>
                                </div>
                                {/* */}

                                <Row>
                                    <Col className="col-pad" span={12}>
                                        {
                                            showGST &&
                                            <Form.Item label="GST Tax Regn No" name="gst_tax_regn_no"
                                                rules={
                                                    [
                                                        { required: true }, {
                                                            // pattern: /^[a-zA-Z0-9]+$/,
                                                            pattern: /^([1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
                                                            message: "Please enter valid GST Tax Regn No",
                                                        }]
                                                }>
                                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} onChange={e => setGstNumber(e.target.value)} />
                                            </Form.Item>

                                        }
                                        <Form.Item name={['gst_details', 'legalName']} hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={['gst_details', 'tradeName']} hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={['gst_details', 'Constitution']} hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={['gst_details', 'RegistrationType']} hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={['gst_details', 'RegistrationStatus']} hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={['gst_details', 'RegistrationDate']} hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={['gst_details', 'CancelationDate']} hidden>
                                            <Input />
                                        </Form.Item>

                                    </Col>

                                </Row>
                                <GstPopUp visible={isGstPopupVisible} onClose={() => setIsGstPopupVisible(false)} data={gstData} />




                                <Row style={{ marginTop: "20px" }} gutter={[24, 24]} align="middle" justify="end" >
                                    <Col>
                                        <Form.Item >
                                            <Button onClick={() => props.NavigatePrevious()} type="primary" className="ant-btn-prev">Previous</Button>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item >
                                            <Button htmlType="submit" className="ant-btn-login" type="primary">Next</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Spin>

                </Col>
            </Row>
        </div>
    );
}



