
import { Modal, Button, Table, message } from "../../../components/Elements"
import { useState, useEffect } from 'react';
import { updateGcppc } from "../../../services/shared.service";
import storage from "../../../utils/storage";



export const GcppcPopup = (props: any) => {
    const [visible, setVisible] = useState(false)
    let vendorId: any;
    vendorId = storage.getValue("vendorId")

    useEffect(() => {
        setVisible(true)
    }, [])
    const onClick = () => {
        setVisible(false)
        props.sendDataToGcppc(false)
    }
    const dataSource = [
        {
            key: '1',
            name: 'GC Payment',
            Segment: '1 Week Payment',
            address: '10%',
        },

    ];
    const columns = [
        {
            title: 'Sr #',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Segment',
            dataIndex: 'Segment',
            key: 'Segment',
        },
        {
            title: 'Discount on Overall Bill Raised',
            dataIndex: 'address',
            key: 'address',
        },
    ];

    const dataSource1 = [
        {
            key: '1',
            name: 'DRSA',
            Segment: '0%',

        },
        {
            key: '2',
            name: 'SLA',
            Segment: '3%',

        },
        {
            key: '3',
            name: 'CSAT',
            Segment: '2%',

        },
        {
            key: '4',
            name: 'Total',
            Segment: '5%',

        },

    ];
    const columns1 = [
        {
            title: 'Incentive Slab',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '% Weightage',
            dataIndex: 'Segment',
            key: 'Segment',
        },

    ];
    const onGenerate = async () => {
        let payload = {
            "vendorId": vendorId,
            "PTM7flag": true
        }
        let res: any = await updateGcppc(payload)
        if (res.status == "success") {
            message.success("Request Submitted Successfully")
            props.sendDataToGcppc(false)

        } else {
            message.success("Request Already Submitted")
            props.sendDataToGcppc(false)
        }
    }
    return (
        <Modal title="Green Channel - Premium Partner Club Terms & Conditions" centered
            visible={visible}
            onCancel={onClick}
            width={1000}
            footer={[
                <Button key="submit" type="primary" onClick={onGenerate} >
                    I agree
                </Button>

            ]}>
            <div>
                <h4>Green Channel is an agreement with EAI and our ASP Fastrack Towing Services</h4>
                <h5>where in we give our ASP priority in business and ASP gets paid faster also keeping in mind that ASP has a chance to make earn some extra money.</h5>
                <h4>Quicker Payment Terms</h4>
                <h5>Instead of waiting for 45 business days, Once enrolled with EAI Green channel, ASP will be paid within 7 calendar days at a flat 10% discount on that particular case.
                    Eg : if the ASP has raised an invoice of Rs 10000/- post enrollment, he will get paid Rs 9000/- (keeping in mind that he will get paid in 7 working days with 10% discount)</h5>

                <Table bordered dataSource={dataSource} columns={columns} pagination={false} />
                <h4>Incentive Slab</h4>
                <h5>ASP has an opportunity to earn almost 50% of the discount which he has give for Green Channel. ASP has the opportunity to earn incentive based on the following parameters mentioned in the table below.</h5>
                <Table bordered dataSource={dataSource1} columns={columns1} pagination={false} />
                <h4>Option to have more columns in the system</h4>
                <h5>Eg : Same example from above, keeping in mind the ASP has met all 2 slabs in the above scenario, he would get to earn Rs. 500/- and so instead of getting paid Rs. 9000/- in 7 calendar days, he would now get paid Rs. 9,500/- for the above case as he has met all 2 parameters. If the ASP has missed out on any one of the parameters he will be missing out on the Slab % value for that particular parameter.</h5>
            </div>

        </Modal>
    )

}