import { Form, Button, Input, Layout, Row, Col, Card, message, Spin, Checkbox, Modal } from "../../../components/Elements";
import { useNavigate, Link } from 'react-router-dom'
// import { useContext, useEffect, useState } from "react";
import { login } from "../../../services/auth.services";
import { useContext, useEffect, useState, useRef } from "react";
import { UserContext, UserContextType } from "../../../providers/UserContext";
import { GET_VENDOR_DOCUMENT, GET_VENDOR_REG_STATUS, LOGIN, VERIFY_OTP, GETDATA, UPDATEDATA } from "../api";
import { ErrorMesages } from "../../../constants";
import storage from "../../../utils/storage";
import './Login.scss';
import logo from '../../../assets/Images/eai-logo.png';
import sideimg from '../../../assets/Images/sideimg.png';


export const LoginLayout = () => {
    const VerifyModelRef = useRef<any>();
    const { Content } = Layout;
    const { User, SaveUser } = useContext(UserContext) as UserContextType;
    const [loader, setLoader] = useState<boolean>(false);
    const [verifyOtp, setVerifyOtp] = useState<any>('');
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [otpGenerated, setOtpGenerated] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailId, setEmailId] = useState("");
    const navigate = useNavigate();
    let status: any;
    status = storage.getValue('regisrationStatus')

    useEffect(() => {
        if (status == 'success') {
            storage.clearSession()
        }
        SaveUser({ ...User, title: "E-Z Auto", token: "", isLoggedIn: false })

    }, []);

    useEffect(() => {
        const handleBackButton = (event: PopStateEvent) => {
            event.preventDefault();
            event.stopPropagation();
        };

        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', handleBackButton);
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const handleGenerateOtp = async (values: any) => {
        console.log("OTP", values)
        // let payload: any = preparePayload(values);
        let payload = {
            "userName": user,
            "password": password,
        }
        let getDataForFirstLogin: any = await GETDATA(payload);
        if (getDataForFirstLogin.status == "success") {
            setMobileNumber(getDataForFirstLogin.message.mobileNumber);
            setEmailId(getDataForFirstLogin.message.email_id);
            if (getDataForFirstLogin.message.profileUpdated == true) {
                let verifyOtp: any = await VERIFY_OTP(payload);
                setVerifyOtp(verifyOtp.OTP);
                if (verifyOtp && verifyOtp.status == 'Success') {
                    setOtpGenerated(true);
                    message.success("Otp sent to your registered Mobile Number  " + getDataForFirstLogin.message.mobileNumber)
                }
                else {
                    message.error("OTP not sent")
                }
            } else {
                setOtpGenerated(false);
                verifyDetails();
            }
        } else {
            message.error(getDataForFirstLogin.message);
        }

    }

    const onFinished = async (values: any) => {
        console.log("values", values);
        let payload: any = {
            userName: user,
            password: password,
            mobileNumber: values['phone'],
            email_id: values['email']
        }
        let updateData: any = await UPDATEDATA(payload);
        if (updateData.status == "success") {
            let pload: any = {
                "userName": user,
                "password": password,
            }
            let verifyOtp: any = await VERIFY_OTP(pload);
            setVerifyOtp(verifyOtp.OTP);
            if (verifyOtp && verifyOtp.status == 'Success') {
                setOtpGenerated(true);
                // message.success("OTP sent successfully")
            }
            else {
                message.error("OTP not sent")
            }
            setIsModalVisible(false);
        }
        else {
            message.error(updateData.message)
        }

    }

    const onFinish = async (values: any) => {
        console.log("values", values)
        let payload: any = preparePayload(values);
        setLoader(true)
        try {
            let loginResponse: any = await LOGIN(payload);
            SaveUser({ ...User, isLoggedIn: true, details: { name: "E-Z Auto" }, token: "aaa" })
            // if (String(otp).trim() != String(verifyOtp).trim()) {
            //     alert('The entered OTP does not match the received OTP.');
            //     setLoader(false);
            //     return;
            // }

            if (!checked) {
                message.error('Please agree to the terms and conditions before logging in.');
                setLoader(false);
                return;
            }


            if (loginResponse && loginResponse.status == 'success' && loginResponse.role == "vendor") {
                if (loginResponse.token) {
                    let vendorP: any = { vendorId: loginResponse['userrefId'] }
                    storage.setValue('vendorId', loginResponse['userrefId']);
                    storage.setValue('popCount', 0)
                    storage.setVendorId(loginResponse['userrefId']);
                    storage.setToken(loginResponse['token']);

                    await (2000)

                    let vendorRegStatus: any = await GET_VENDOR_REG_STATUS(vendorP);
                    setLoader(false)
                    if (vendorRegStatus && vendorRegStatus['regstatus'] != 'Submitted' && vendorRegStatus['message'] == "") {
                        let vendorDocP: any = { userName: loginResponse['userrefId'] };
                        let vendorDocResult: any = await GET_VENDOR_DOCUMENT(vendorDocP);
                        SaveUser({
                            ...User, isLoggedIn: true, details: {
                                name: "E-Z Auto",
                                regisrationStatus: vendorRegStatus['regstatus'],
                                registrationDetails: vendorDocResult.message[0]
                            }
                        })
                        navigate('/home/pending-registration');
                    }

                    else if (vendorRegStatus && vendorRegStatus['message'] == 'Active' && vendorRegStatus['kyc_flag'] == "Active") {
                        SaveUser({ ...User, isLoggedIn: true, details: { name: "E-Z Auto", regisrationStatus: "success" } })
                        storage.setValue('regisrationStatus', "success");
                        storage.setValue('paymentType', vendorRegStatus['paymentType'])

                        // navigate('/home/profile');
                        navigate('/home/aspdashboard');
                    }
                    else if (vendorRegStatus) {
                        navigate('/kyc', { state: vendorRegStatus });
                        storage.setValue('regisrationStatus', "success")
                        SaveUser({ ...User, isLoggedIn: true, details: { name: "E-Z Auto" }, token: "changePassword" })

                    }
                } else {
                    message.error("Please pass jwt token");
                    setLoader(false)
                }

            } else if (loginResponse && loginResponse.status === 'failure' && (loginResponse.message === "User Not Active" || loginResponse.message === "user acount expired")) {
                SaveUser({ ...User, isLoggedIn: false, details: {}, token: "" })
                message.error("User account expired");
                setLoader(false)
            } else {
                SaveUser({ ...User, isLoggedIn: false, details: {}, token: "" })
                message.error("User Not Found, Please try with valid login details.");
                setLoader(false)
            }
        } catch (e) {
            setLoader(false)
            message.error(ErrorMesages.DEFAULT_CUSTOM_MESSAGE);
        }
    };

    const preparePayload = (values: any) => {
        let payload = {
            "userName": values['username'],
            "password": values["password"],
            "otp": values["otp"],
            "channel": "PLS",
            // "networkDetails": {
            //     "ip": "157.47.92.176",
            //     "city": "Hyderabad",
            //     "region": "Telangana",
            //     "country": "IN",
            //     "loc": "17.3840,78.4564",
            //     "org": "AS55836 Reliance Jio Infocomm Limited",
            //     "postal": "500001",
            //     "timezone": "Asia/Kolkata"
            // }
        }
        return payload
    }



    const handleCheckboxChange = (e: any) => {
        setChecked(e.target.checked);
    };

    // Function to handle the terms and conditions link click
    const handleTermsClick = () => {
        setVisible(true);
    };

    const verifyDetails = () => {
        setIsModalVisible(true);
    }

    const closeDetails = () => {
        setIsModalVisible(false);
    }

    // Function to handle the modal close
    const handleModalClose = () => {
        setVisible(false);
    };

    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [otp, setOtp] = useState<any>('');

    useEffect(() => {
        let userObject = {
            details: {},
            token: '',
            isLoggedIn: false
        }
        SaveUser(userObject)
    }, [])
    // async function onFinish() {
    //     if(user && password){
    //         let resp:any =  await login(user,password)
    //         console.log("LOGIN data",resp)
    //         if(resp.status==="success"&& resp.role === "vendor"){
    //             let userObject = {
    //                 details: { name: "Settings" },
    //                 token: 'sasa',
    //                 isLoggedIn: true
    //             }
    //             SaveUser(userObject)
    //             navigate('/home');
    //         }else{
    //             alert("Please enter correct credentials")
    //         }


    //     }
    // }


    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleRegister = () => {
        navigate('/vendor-register')
    }
    const LoginLayput = {
        display: "flex",
        justifyContent: "center",
        margin: "0px",
        background: "url(../../../assets/Images/ea-loginbg.jpg) no-repeat center center !important",
        backgroundSize: "cover !important",
    }

    const CardBodyStyle = {
        minHeight: "300px",
        // marginLeft:" -121px",
        borderRadius: "8px",
        marginTop: "9%",
    }

    const RegisterButton = {
        paddingLeft: "0px"
    }

    // const loginbg{
    //     background: url(../../../assets/Images/ea-loginbg.jpg) no-repeat center center !important;
    //     background-size: cover !important;
    // }
    return (
        <Layout className="login-bg" >
            <Content>
                <div className="wht-bg">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo-bg">
                            <img src={logo} className="logo" />
                        </div>
                    </div>
                </div>
                </div>
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mw-45 col-6-disNone">
                            <div className="logo-img mt-5" style={{display:'none'}}>
                           <img src={sideimg} className="img-responsive" />
                            </div>
                        </div>
                        <div className="col-lg-5 mt-30 col-lg-5-width">
                        <div className="col-lg-12">
                            <div className="login-title">
                                <h4>
                                Sign in to EAI
                                </h4>
                            </div>
                            <div className="login-title">
                                <p>Enter your details below.</p>
                            </div>
                            </div>
                             
                             <div className="col-lg-12">
                             <Spin spinning={loader}>

    {/* <Card bordered={true} className="widthControl" style={CardBodyStyle}    > */}
    <div className="inputs-width">
        <Form className="abtl-post"
            name="ezauto-login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            // wrapperCol={{ span: 100 }}
            // initialValues={{ remember: true }}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{ fontSize: '16px', color: 'black' }}
        >
            <Form.Item
                label={<span style={{ color: 'black' }}>Email/UserId/Phone:</span>}

                name="username" 
                rules={[{ required: true, message: 'Please input your username!' }]}


            >
                <Input  placeholder="Enter user name" className="inpt-fild" onChange={e => setUser(e.target.value)} />
            </Form.Item>

            <Form.Item
                label={<span style={{ color: 'black' }}>Password</span>}
                className="label-title"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]
                }
            >
                <Input.Password placeholder="Enter password" className="inpt" onChange={e => setPassword(e.target.value)} autoComplete="random-string" />
            </Form.Item>


            <Form.Item
                label={<span style={{ color: 'black' }}>Enter OTP:</span>}
                name="otp"
                rules={[{ required: true, message: 'Please enter your OTP!' }]}
            >
                <Input placeholder="Enter OTP" className="inpt-fild" onChange={e => setOtp(e.target.value)} />

            </Form.Item>
            <>
                <Button className="ant-btn-regi otp-sub" onClick={handleGenerateOtp} >
                    Generate OTP
                </Button>

                {!otpGenerated && (
                    <Modal
                        title="Please Update below details"
                        open={isModalVisible}
                        onCancel={closeDetails}
                        footer={null}
                    >
                        <Form onFinish={onFinished} initialValues={{ email: emailId, phone: mobileNumber }}>
                            <Form.Item
                                name="email"
                                label="Email ID:"
                                rules={[{ required: true, message: 'Please enter your email!' },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email!',
                                },
                                ]}
                            >
                                <Input autoComplete="new-password" />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label="Phone Number:"
                                rules={[
                                    { required: true },
                                    {
                                        pattern: /^(?:\d*)$/,
                                        message: "Please enter valid Phone number",
                                    },
                                    { max: 10, message: "Please enter valid Phone number" },
                                    { message: "Please enter valid phone number", min: 10 }
                                ]} >
                                <Input autoComplete="new-password" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                )}
            </>


            <Modal open={visible} onCancel={handleModalClose} title={<div className="modal-title">Terms And Conditions</div>}
                footer={null}>
                <div className="modal-content">
                    <h3>UNDERTAKING BY REGISTERED AUTHORISED SERVICE PROVIDER (“ASP”)</h3>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis deserunt, facilis mollitia minus ipsum rerum, nulla quae aut porro eligendi harum. Quis at, commodi itaque placeat ipsam aliquid impedit aliquam.
                   {/*  <p>I ASP of Europ Assistance India Private Limited (“the Company”), do hereby confirm, undertake and grant my consent to</p>
                    <p>i) enroll and register my/our Firm/ company on the e-portal offered by the Company for availing all benefits offered under the Paperless Process</p>
                    <p>ii) upload and provide all mandatory documents for registration including but not limited to KYC, Id & address proofs, registration certificates, licenses and other documents as required</p>
                    <p>iii) provide registered mobile number to receive SMS for all purposes including OTP and case updates</p>
                    <p>iv) complete the registration formalities with the external agencies under the said process, if outsourced by the Company</p>
                    <p>authorize Company to share my/our KYC/ Aadhar/ Pan/ Contact details with CDSL/ NSDL/ External agencies for the purpose contemplated under this undertaking including but not limited to biometric access, validations, receiving OTP and other incidental and ancillary activities.</p>
                    <p>vi) e-sign the agreement(s), addendum(s), terms & conditions and related documents thereof and further authorize future amendments as and when need arises</p>
                    <p>vii) periodically review and provide authorizations on e-portal for activities relating to ASP</p>
                    <p>viii)authorize Company to generate and process invoice through Paperless initiative as per the protocols set and receive payments via online channels (NEFT/ RGTS/ IMPS/ UPI)</p>
                    <p>ix) receive sms/ email statements only on registered contact no./ email id for all payment and case related details</p>
                    <p>x) to submit original receipts and physical bills to Company for verification in case of toll/ other extra charges</p>
                    <h3>Declaration:</h3>
                    <p>- I/We have read and understood the contents for use of the Paperless Process and hereby agree by the Terms & Conditions in its letter and spirit. If at anytime I/We do not agree to the present/ modified terms, I/ We should discontinue to avail the benefits.</p>
                    <p>- I/We undertake to support the Company in enforcement of the Paperless initiative and to do the needful</p>
                    <p>- I/We confirm to not entice or encourage or engage or participate in any activity directly or indirectly, which may lead or possible lead to breach/violation to terms of the said Process</p>
                    <p>- I/We will immediately report any breach or attempted breach to terms of the said Process.</p>
                    <p>- I/We understand that the Process is subject to enhancements and agree to provide necessary support at all times.</p>
                    <p>- I/We hereby undertake that all information/documents provided by me/us are true/accurate/valid and any inconsistency/inaccuracy/invalidity for the said details shall be solely my/our responsibility and accordingly I/We agree to indemnify the Company for and against any loss, injury or damages incurred by the Company as a result of such occurrence.</p>
                    <p>- I/We understand that these terms control the relationship between Company and ASP and do not create any third party beneficiary rights.</p>
                    <p>- I/We hereby state that I/we have the right and authorization to enter into this arrangement on behalf of my/our Firm/ Company.</p> */}
                </div>
            </Modal>
            <Form.Item>
                <Checkbox checked={checked} onChange={handleCheckboxChange} style={{ marginRight: '10px' }} />
                <p><span style={{ color: 'black' , fontSize:"16px" }}>I Agree to </span><a onClick={handleTermsClick} style={{color:"black", fontSize:"16px"}}>Terms & Conditions</a></p>
                <div className="frgt">

                    <Link to="/forgot-password" className="forgot-password-link" style={{fontSize:"16px", color:"black"}}>
                        Forgot Password?
                    </Link>
                </div>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 0, span: 50 }}>
                <div className="dis-inl">
                    <Button className="ant-btn-regiter" style={RegisterButton} onClick={handleRegister} type="link">REGISTER</Button>
                    <Button className="ant-btn-login" type="primary" htmlType="submit">
                        LOGIN
                    </Button>
                </div>
                {/* <Link to="/forgot-password" className="forgot-password-link">
                 Forgot Password?
                 </Link> */}
            </Form.Item>

        </Form>

    </div>


    {/* </Card> */}

</Spin>
                             </div>
                           
                        </div>
                        
                    </div>
                </div>
            </div>

                {/* <Row gutter={24} style={LoginLayput}>
<Col style={{ paddingTop: "10px" }} span={1}></Col>
<Col style={{ paddingTop: "10px" }} span={7}>
    <div className="sso-img"><img src={sideimg} className="logo-login"/></div>
    </Col>
    <Col style={{ paddingTop: "10px" }} span={1}></Col>
    <Col style={{ paddingTop: "10px" }} span={9}>
        <div className="mb-20">
            <label className="label-title">Username</label>
            <Input type="text" placeholder="Username" onChange={e => setUser(e.target.value)} />
        </div>
        <div className="mb-20">
            <label className="label-title">Username</label>
            <Input type="text" placeholder="Username" onChange={e => setUser(e.target.value)} />
        </div>
        <div className="mb-220">
            <label className="label-title">Username</label>
            <Input type="text" placeholder="Username" onChange={e => setUser(e.target.value)} />
        </div>
    </Col>
    </Row> */}
                
            </Content>
        </Layout>
    )


}