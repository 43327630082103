import { HttpClient } from "../http-service/api"
import { axios } from '../http-service/service';
let FileSaver = require('file-saver')


export const getCase = (userRefId: any) => {
    return HttpClient.post('/api/vendor_v2/getService', { "serviceId": userRefId })
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user;
        })
        .catch(e => e)

}


export const updateVendorGSTNumber = (payload: any) => {
    return HttpClient.post('/api/vendor_v2/updateVendorGSTNumber', payload)
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user;
        })
        .catch(e => e)

}


export const updateVendorLocations = (payload: any) => {
    return HttpClient.post('/api/vendor_v2/updateVendorLocations', payload)
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user;
        })
        .catch(e => e)
}

export const updateTechnician = (payload: any) => {
    return HttpClient.post('/api/rmDashboard/updateTechnician', payload)
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user;
        })
        .catch(e => e)

}



export const updateLocationWiseMobileInfo = (payload: any) => {
    return HttpClient.post('/api/vendor_v2/updateLocationWiseMobileInfo', payload)
        .then(response => response)
        .then(user => {
            return user;
        })
        .catch(e => e)
}


export const getVendorReportData = (payload: any) => {
    return HttpClient.post('/api/vendor_v2/getVendorReportData', payload)
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user;
        })
        .catch(e => e)
}

export const updateMSLAcceptanceServices = (payload: any) => {
    return HttpClient.post('/api/vendor_v2/updateMSLAcceptanceServices', payload)
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user;
        })
        .catch(e => e)

}


export const updateGcppc = (payload: any) => {
    return HttpClient.post('/api/vendor-reg_v2/vendorPtmsevenSubmit', payload)
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user
        })
        .catch(e => e)
}



export const uploadVendorKYCdoc = (payload: any) => {
    return HttpClient.post('/api/vendor_v2/uploadVendorKYCdoc', payload)
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)
}




export const submitKycUpdateRequest = (payload: any) => {
    return HttpClient.post('/api/vendor_v2/submitKYCupdateRequest', payload)
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)
}

export const submitIagDetails = (payload: any) => {
    return HttpClient.post('/api/vendor_v2/uploadIagDetails', payload)
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)
}


export const sendOtpForEmailVerification = (email: any, phoneNumber: any) => {
    return HttpClient.post('/api/vendor-reg_v2/sendOtpForEmailVerification_v2', { "email": email, "phoneNumber": phoneNumber })
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user;
        })
        .catch(e => e)

}

export const verifyEmailOtp = (email: any, otp: any) => {
    return HttpClient.post('/api/vendor-reg_v2/verifyEmailOtp', { "email": email, "otp": otp })
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user;
        })
        .catch(e => e)

}

export const disputeRaiseRequest = (caseId: any, serviceId: any) => {
    return HttpClient.post('/api/vendor_v2/disputeRaiseRequest', { "claimNumber": caseId, "serviceNumber": serviceId })
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)
}

export const verifyVendorInvoiceNumber = (invoiceNumber: any, userRefId: any) => {
    return HttpClient.post('/api/vendor_v2/verifyVendorInvoiceNumber', { "invoiceNumber": invoiceNumber, "vendorId": userRefId })
        .then(response => response)
        .then(user => {
            console.log("user in shared ", user)
            return user;
        })
        .catch(e => e)
}


/* export const pdfInvoice = (invoiceNumber:any,lat:any,long:any,serviceArray:any,vendorId:any,verifiedOtp:any) =>{
    return HttpClient.post('/api/vendor-reg_v2/pdfInvoice',{"invoiceNumber":invoiceNumber,"lat":lat,"long":long,"serviceArray":serviceArray,"vendorId":vendorId,"verifiedOtp":verifiedOtp})
    .then(response =>response)

    .then(user =>{
        console.log("HEADERS!@#",user.headers)
        return user
    })
} */

export async function pdfInvoice(invoiceNumber: any, lat: any, long: any, serviceArray: any, vendorId: any, verifiedOtp: any) {
    return HttpClient.post('/api/vendor-reg_v2/pdfInvoice', { "invoiceNumber": invoiceNumber, "lat": lat, "long": long, "serviceArray": serviceArray, "vendorId": vendorId, "verifiedOtp": verifiedOtp })
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)
}


export const verifyOtp = (mobileNumber: any, userName: any) => {
    return HttpClient.post('/api/vendor-reg_v2/verifyOtp', { "mobileNumber": mobileNumber, "userName": userName })
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)
}


export const isMatchedOTP = (otp: any, serviceId: any) => {
    return HttpClient.post('/api/eaiExt/verifyOtp', { "otp": otp, "serviceId": serviceId })
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)
}


export const updateVendorDetails = (payload: any) => {
    return HttpClient.post('/api/vendor_v2/updateVendorDetails', payload)
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)
}


export const generateCreditNote = (ServiceId: any) => {
    return HttpClient.post('/api/vendor_v2/generateCreditNote', { "serviceId": ServiceId })
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)

}

export const getGSTstateCodes = () => {
    return HttpClient.post('/api/vendor-reg_v2/getGSTstateCodes', {})
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)

}
export const deleteDisputeDoc = (id: any) => {
    return HttpClient.post('/api/vendor_v2/deleteDisputeDoc', { "docId": id })
        .then(response => response)
        .then(user => {
            return user
        })
        .catch(e => e)
}




export async function downloadPdf(fileFullPath: any, fileName: any) {
    console.log(fileName, "path", fileFullPath)
    let res: any = await axios.post('/api/vendor_v2/downloadInvoice', { fileName: fileFullPath }, { responseType: 'arraybuffer' })
    generatePdf(res, fileName)
}
export async function downloadPdf1(fileFullPath: any) {


    //   generatePdf(res,fileName)
    let res: any = await axios.post('/api/vendor_v2/downloadInvoice', { fileName: fileFullPath });
    console.log("res@@@@@", res.headers);
}

export async function disputeFileUpload(fromData: any, config: any) {

    let response: any = await axios.post('/api/vendor_v2/diputeFileUpload', fromData, config)
        .then(response => response)
        .then(user => {
            console.log(typeof (user), "ueer", user.status)
            let status: any = user.status;
            return status
        })
        .catch(e => e)
}

export async function getVendorDisputeDocument(documentId: any) {
    let imageBlob: any = await axios.post('/api/vendor_v2/getVendorDisputeDocument', { "documentId": documentId }, { responseType: 'blob' as 'json' })
    let fileURL: any = URL.createObjectURL(imageBlob);
    window.open(fileURL);
    return fileURL
}


// function downloadInvoice(filePath:any) {
//     // var fileName = filePath.split('\\');
//     // fileName = fileName[fileName.length - 1];
//     VendorDashboardSVC.downloadInvoice(filePath).then(function (response:any) {
//         var filename = response.headers('Content-Disposition');
//         //var downloadFileName = 'eai_invoice_' + new Date().getTime() + '.pdf';
//         var downloadFileName = filename;
//         let data:any = new Uint8Array(response.data)

//         var decodedString = String.fromCharCode.apply(null,data);
//         /**check if json is array or error**/
//         var obj;
//         if (validateJSON(decodedString))
//             obj = JSON.parse(decodedString);
//         else
//             obj = '';
//         /**if file doesnot exist**/
//         if (obj.status == 'failure') {
//             alert("File doesn't exist");
//         } else {
//             /**if file exits download file**/
//             var file = new Blob([response.data], {
//                 type: 'application/pdf'
//             });
//             var a = document.createElement("a");
//             document.body.appendChild(a);
//             a.href = window.URL.createObjectURL(file);
//             a.download = downloadFileName;
//             a.click();
//         }
//     });
// }


export function generatePdf(buffer: any, fileName: string) {
    let data: any = new Uint8Array(buffer)

    let decodedString = String.fromCharCode.apply(null, data);
    let obj: any;
    validateJSON(decodedString) ? (obj = JSON.parse(decodedString)) : (obj = '')

    /**if file doesnot exist**/
    if (obj.status == 'failure') {
        console.log("Failed")
        alert("File doesn't exist");
    } else {
        console.log("Success")
        const blob = new Blob([buffer], { type: 'application/pdf' });
        var pdfName = fileName + '.pdf';
        FileSaver.saveAs(blob, pdfName);
    }
}


function validateJSON(data: any) {
    try {
        JSON.parse(data);
    } catch (e) {
        return false;
    }
    return true;

}



export function convertJsonToCsv(JSONData: any) {
    var arrData =
        typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
    var CSV = "";
    var row = "";
    for (var index in arrData[0]) {
        row += index + ",";
    }
    row = row.slice(0, -1);
    CSV += row + "\r\n";
    for (var i = 0; i < arrData.length; i++) {
        var row = "";
        for (var index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
        }

        row.slice(0, row.length - 1);
        CSV += row + "\r\n";
    }
    return CSV
}


export function downloadFile(fileName: any, data: any) {
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    const url = URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
}