import { useContext, useEffect, useState } from "react"
import { DownloadOutlined, Card, Input, Space, Button, SearchOutlined, Table, Row, Col, Spin, message } from '../../../../components/Elements'
import { dashboardService } from "../../../../services/dashboard.services"
import { downloadPdf } from "../../../../services/shared.service"
import Highlighter from "react-highlight-words";
import storage from "../../../../utils/storage";
import { CardStyle } from "../../../../helpers/fromhelpercss";
import { UserContext, UserContextType } from "../../../../providers/UserContext";
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import { ResizeObserverFn } from '../../../../utils/resizeObserverUtil';



export const InvoicesComponent = () => {
  console.log("In Invoices Component")
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [skipParams, setSkipParams] = useState(0)
  const { User, SaveUser } = useContext(UserContext) as UserContextType;

  const contentRef = useRef<HTMLDivElement>(null); // Create a ref for the content element
  // Create a new ResizeObserver
  const resizeObserver = ResizeObserverFn();
  // Start observing the target element
  useEffect(() => {
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    return () => {
      resizeObserver.disconnect(); // Clean up the observer on unmount
    };
  }, [contentRef]);

  let vendorId: any;
  vendorId = storage.getValue("vendorId")

  const [searchText, setSearchText] = useState<any>()
  const [state, setState] = useState({ searchText: '', searchedColumn: '' })
  const antLayoutContent = {
    margin: "80px 15px 0px"
  }

  let searchInput: any;
  const getColumnSearchProps = (dataIndex: any) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }
      : { setSelectedKeys: any, selectedKeys: any, confirm: any, clearFilters: any }) => (

      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Go
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText({})
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  };

  try {
    useEffect(() => {
      SaveUser({ ...User, token: "aaa" })
      async function fetchData() {
        setLoading(true)
        var resp: any = await dashboardService.invoiceData(vendorId, skipParams)
        setData(resp)
        setLoading(false)

      }
      fetchData()
    }, [skipParams])
  } catch (err) {
    message.error("Please upload a document")
    alert('Please upload a document')

  }
  const columns: any = [
    // { title: 'id', dataIndex: 'key' },
    {
      title: 'Service Details',
      dataIndex: 'ServiceIds',
      align: 'center',
      width: '130px',
      ...getColumnSearchProps('ServiceIds')

    }, {
      title: 'Date',
      dataIndex: 'Date',
      align: 'center',
      ...getColumnSearchProps('Date')
    },
    // {
    //     title: 'Download',
    //     className: 'serviceId',
    //     dataIndex: 'Signed_pdf',
    //     align: 'center',
    // },
    {
      title: 'Invoice No',
      dataIndex: 'InvoiceNo',
      align: 'center',
      ...getColumnSearchProps('InvoiceNo')
    },
    {
      title: 'Payment Date',
      dataIndex: 'PaymentDate',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('PaymentDate')
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('Amount')
    },
    {
      title: 'GST',
      dataIndex: 'GstAmount',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('GstAmount')
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'InvoiceAmount',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('InvoiceAmount')
    },
    {
      title: 'Paid Amount',
      dataIndex: 'PaidAmount',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('PaidAmount')
    },
    {
      title: 'TDS',
      dataIndex: 'TDSAmount',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('TDSAmount')
    },
    {
      title: 'Payment Status',
      dataIndex: 'PaymentStatus',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('PaymentStatus')
    }, {
      title: 'Transaction Details',
      dataIndex: 'TransactionDetails',
      align: 'center',
      width: 120,

      ...getColumnSearchProps('TransactionDetails')
    }, {
      title: 'Invoice Type',
      dataIndex: 'TypeOfInvoice',
      align: 'center',
      width: '100px',
      ...getColumnSearchProps('TypeOfInvoice'),
      render: (TypeOfInvoice: any) => {
        let color = 'white';
        if (TypeOfInvoice === 'GC-PPC Normal' || TypeOfInvoice === 'GC-PPC Exceed') {
          color = 'rgba(86, 241, 86, 0.5)';
        } else if (TypeOfInvoice === 'Normal' || TypeOfInvoice === 'Normal1' || TypeOfInvoice === 'Normal2') {
          color = 'rgba(248, 141, 2, 0.993)';
        } else if (TypeOfInvoice === 'Dispute') {
          color = 'rgba(241, 86, 86, 0.5)';
        }
        return {
          props: {
            style: { background: color }
          },
          children: <div>{TypeOfInvoice}</div>
        };
      }
    },
    {
      title: 'Download',
      className: 'serviceId',
      dataIndex: 'Signed_pdf',
      align: 'center',
      width: '100px',
      render: (text: string, params: any) => {
        // let downloadPdf=()=>{
        // alert("params")
        // console.log("params",params)
        // var res = downloadPdf(params)


        // }
        async function onClick() {
          let resp: any = await downloadPdf(text, params.InvoiceNo)

        }

        return <DownloadOutlined style={{ fontSize: '150%' }} onClick={onClick} />;
      }
    }
  ];


  const rows: { key: number }[] = [];

  if (data != undefined && data.status == "success") {
    data.message.map((i: any, j: any) => {
      let obj: any = i
      obj.key = j + 1
      rows.push(obj)
      return rows
    })
  }
  const Onnext = () => {
    let data: any = skipParams + 100
    setSkipParams(data)
  }
  const Onback = () => {
    if (skipParams != 0) {
      let data: any = skipParams - 100
      setSkipParams(data)
    }

  }
  const navigate = useNavigate();
  if (!User || !User.isLoggedIn) {
    navigate('/login');
    return null;
  } else {
    return (
      loading ?
        <div style={antLayoutContent} ref={contentRef}>
          <div className="example" style={{ textAlign: 'center' }}>
            <Card className="ant-content">
              <Spin />
            </Card>
          </div>
        </div>
        :
        <div style={antLayoutContent}>
          <Card className=" ant-content">
            <div className='table-responsive'>
              <Table
                columns={columns}
                dataSource={rows}
                bordered
                title={() => 'Invoices Table'}
                //scroll={{ x: 1300 }}
                sticky={true}
                scroll={{ x: "max-content" }}

              />
            </div>
            <Row style={{ width: "100%" }}>
              <Col span={20}></Col>
              <Col className='m-b-30 col-pad-0' span={4} style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                <Button className='PrevBtn' onClick={Onback}>Prev</Button>
                <Button className='NextBtn' onClick={Onnext}>Next</Button>
              </Col>
            </Row>
          </Card>
        </div>

    )
  }
}