
import { Card, Col, Layout, Row } from "../../../../components/Elements/Designs";
import { Layout as RegisterPanel } from "../../../../widgets/Register/components";
import "./register-p.scss";

export const PendingRegisterLayout = (props: any) => {
    const { Content } = Layout;
    return (
        <Layout className="pending-registration-layout">
            <Content>
                <Row className="ezauto-row">
                    <Col span={20}>
                        <Card className="ezauto-register-panel">
                            <div className="ez-auto-registration-container">
                                <RegisterPanel />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Content>

        </Layout>
    );
}