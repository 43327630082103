import { Button, Form, Input, Row, Col, Card } from "../../../components/Elements";
import { CardStyle } from "../helpers/formcsshelper";
import { validateMessages } from "../helpers/formhelper";
import "./personal-details.scss";

export const OTPVerify = (props: any) => {
    const [form] = Form.useForm();
    console.log("OTPVerify -> props", props)
    const onFinish = (values: any) => {
        // props.validateOTP(values);
        console.log("OTPVerify -> values", values)
        if (props.verificationType === "email") {
            props.validateEmailOTP(values);
        } else if (props.verificationType === "mobile") {
            props.validateMobileOTP(values);
        } else {
            props.validateOTP(values);
        }
        // console.log("typeof props.validateEmailOTP",typeof props.validateEmailOTP)
        // if (props.verificationType === "email") {
        //     if (typeof props.validateEmailOTP === 'function') {
        //         props.validateEmailOTP(values);
        //     } else {
        //         console.error('validateEmailOTP is not a function');
        //     }
        // } else if (props.verificationType === "mobile") {
        //     if (typeof props.validateMobileOTP === 'function') {
        //         props.validateMobileOTP(values);
        //     } else {
        //         console.error('validateMobileOTP is not a function');
        //     }
        // }
    }


    return (
        <div className="ezauto-regis-container OTP_Validation_Container">
            <Row gutter={8} style={{ justifyContent: "center" }}>
                <Col span={22}>
                    <Card style={CardStyle}>
                        <Form
                            className="registration-details-form OTP_Validation_Form"
                            layout={'vertical'}
                            form={form}
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 30 }}
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                        >
                            <Row gutter={8} style={{ justifyContent: "center" }}>
                                <Col span={18}>
                                    <Form.Item name="validate_otp" rules={[{ required: true, message: "Please enter OTP" }, { len: 6, message: "OTP should be 6 digits" }]} label="">
                                        <Input placeholder="Enter OTP" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item >
                                        <Button htmlType="submit" type="primary">GO</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}