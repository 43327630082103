import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, Button } from '../../Elements';

import "./verify.scss";

const VerifyModalFn = (props: any, ref: any) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const width = props.width ? props.width : 500;
    const wrapperClassName = props.wrapperClassName ? props.wrapperClassName : 'Verify_Modal';
    const maskClosable = props.maskClosable ? props.maskClosable : false;
    const keyboard = props.keyboard ? props.keyboard : false;

    useImperativeHandle(ref, () => ({
        onShowModal() {
            showModal();
        },
        onCloseModal() {
            handleCancel();
        }
    }))
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Modal title={props.title}
                centered
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={width}
                wrapClassName={wrapperClassName}
                maskClosable={maskClosable}
                destroyOnClose={true}
                keyboard={keyboard}
                footer={null}
            >
                {props.children}
            </Modal>
        </>
    );
};

export const VerifyModal = forwardRef(VerifyModalFn);