import { useNavigate } from "react-router-dom";
import { Button, Col, Dropdown, Avatar, Layout, Menu, MenuProps, Row, Tooltip, } from "../../../components/Elements";
import storage from "../../../utils/storage";
import LOGO from "../../../assets/Images/eai-logo.png";
import { useContext, useEffect, useState } from "react";
import { UserContext, UserContextType } from "../../../providers/UserContext";
import { SSO_ENABLE, SSO_URL } from "../../../config";

import {
    UserOutlined,

    LockOutlined,
    PoweroffOutlined
} from "@ant-design/icons";
import { Divider } from "@material-ui/core";


export const HeaderNav = () => {

    const { User, SaveUser } = useContext(UserContext) as UserContextType;

    const { Header, Content } = Layout;
    const [title, setTitle] = useState('E-Z AUTO');
    const navigate = useNavigate();
    const HandleLogout = () => {
        localStorage.clear()
        storage.clearSession();
        if(SSO_ENABLE === "true"){
            let url : string = SSO_URL
        window.location.href = url;
        return null;
        }else{
            navigate('/login');  
        }
    }

    const handleLogoClick = () => {
        HandleLogout(); 
        navigate('/login');
    }


    const handleMenu = (e: any) => {
        navigate(e.key);
    }
    const profileMenu = (
        <Menu onClick={handleMenu}>
            {
                <Menu.Item onClick={HandleLogout}>
                    Logout
                </Menu.Item>
            }
        </Menu>
    );
    const handleRegister = () => {
        navigate('/vendor-register')
    }
    const handleLogin = () => {
        navigate('/login')
    }
    const logoutCss = {
        cursor: "pointer"
    }
    const changePassword = () => {
        SaveUser({ ...User, token: "changePassword" })
        navigate('/home/changePassword')
    }




    const widgetMenu = (
        <Menu>

            <Menu.Item onClick={changePassword} >
                <span className="iconText">
                    <LockOutlined className="icon"/>
                    <p>&nbsp;&nbsp;</p>
                     Change Password
                </span>
            </Menu.Item>

            <Menu.Item onClick={HandleLogout}>
                <span className="iconText">
                    <PoweroffOutlined className="icon" />
                    <p>&nbsp;&nbsp;</p>
                    Logout
                </span>

            </Menu.Item>
        </Menu>
    );
    const newMenu = (
        <Menu>

            <Menu.Item onClick={() => handleLogin()} >
                <span className="iconText">
                    <LockOutlined className="icon" />
                    <p>&nbsp;&nbsp;</p>
                    Login
                </span>
            </Menu.Item>

            <Menu.Item onClick={()=>handleRegister()} >
                <span className="iconText">
                    <Avatar className="icon" icon={<UserOutlined/>} />
                    <p>&nbsp;&nbsp;</p>
                    Vendor Registration
                </span>

            </Menu.Item>
        </Menu>

    )




    return (
        <>
            <Header className="header-nav-container">
                <Row>

                    <div className="header-style">
                        <div className="logo-contianer">
                            <img src={LOGO} className="logo" onClick={handleLogoClick}/>
                        </div>

                        <div className="title-container" >
                            <span className="app-title">
                                {"RSA VENDOR"}
                            </span>
                        </div>

                        {
                            User && User.isLoggedIn
                            // <Col span={8} className="profile-menu">
                            //     <Row style={{ float: "right" }} >
                            //         <Col style={{ float: "right" }} span={24}>
                            //             <span className="app-title" style={logoutCss} onClick={changePassword}>
                            //                 Change Password &nbsp; &nbsp;
                            //             </span>
                            //             <span className="app-title" style={logoutCss} onClick={HandleLogout}>
                            //                 Logout
                            //             </span>


                            //         </Col>

                            //     </Row>
                            // </Col>
                            &&
                            <div style={{marginTop:"14px"}}>
                                <Dropdown overlay={widgetMenu}>
                                    <UserOutlined />
                                </Dropdown>
                            </div>

                        }

                        {
                            User && !User.isLoggedIn &&
                            <Col span={8} style={{ display: "flex", alignContent: "center", justifyContent: "space-between", width: "100%" }}>
                                <Row justify="end">
                                    <Col span={24}>
                                        <div style={{ float: "right" }} className="login-page-header-actions">
                                            <Button onClick={() => handleLogin()} type="link">Login</Button>
                                            <Button onClick={() => handleRegister()} type="link">Vendor Registration</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            &&
                            <div>
                                <Dropdown overlay={newMenu}>
                                    <UserOutlined />
                                </Dropdown>
                            </div>
                        }
                    </div>
                </Row>
            </Header>
        </>
    )
}