import { axios as Instance } from "./service";

class HttpRequest {
    delete(url: string) {
        return Instance.delete(`${url}`);
    }
    get(url: string) {
        return Instance.get(`${url}`);
    }
    post(url: string, body: any) {
        return Instance.post(`${url}`, body);
    }
    put(url: string, body: any) {
        return Instance.put(`${url}`, body);
    }
}
const HttpClient = new HttpRequest();
export { HttpClient }