import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChangePassword } from '../../Change Password/components';
import {
    AlertComponent, ApprovedCNComponent, ApprovedComponent, HomeComponent, InvoicesComponent,
    PendingCNComponent, PendingComponent, PendingRegisterLayout, DisputesComponent, AspDashboardComponent
}
    from '../components';
import { ProfileComponent } from '../components/profile';
import { KycComponent } from '../components/kycComponent';

export const HomeRoutes = () => {
    console.log("home routes")
    return (


        <Routes>
            <Route path="/" element={<AspDashboardComponent />} />

            <Route path="/pending-registration" element={<PendingRegisterLayout />} />{/*  */}
            <Route path="/alerts" element={<AlertComponent />} />
            <Route path="/profile" element={<ProfileComponent />} />
            <Route path="/approved" element={<ApprovedComponent />} />
            <Route path="/pending" element={<PendingComponent />} />
            <Route path="/disputes" element={<DisputesComponent />} />
            <Route path="/invoices" element={<InvoicesComponent />} />
            <Route path="/pendingcn" element={<PendingCNComponent />} />
            <Route path="/kyc" element={<KycComponent />} />
            <Route path="/changePassword" element={<ChangePassword />} />

            <Route path="/approvedcn" element={<ApprovedCNComponent />} />
            <Route path="/aspDashboard" element={<AspDashboardComponent />} />

            <Route path="*" element={<AspDashboardComponent />} />

        </Routes>

    );
}
