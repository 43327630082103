import { HttpClient } from '../../../http-service/api';
import { API_LIST } from '../../../http-service/list';
import { IEmailVerifyPayload, IIFSCPayload, IMobileVerfiyPayload, IPanVerification } from '../helpers/interface';

export const ValidatePanCard = (body: IPanVerification) => {
    return HttpClient.post(API_LIST.VERIFY_PAN, body)
}

export const ValidateMobileNumber = (body: IMobileVerfiyPayload) => {
    return HttpClient.post(API_LIST.VERIFY_PHONE, body)
}

export const ValidateEmailAPI = (body: IEmailVerifyPayload) => {
    return HttpClient.post(API_LIST.VERIFY_EMAIL, body);
}

export const ValidateEmailOTP = (body: any) => {
    return HttpClient.post(API_LIST.VERIFY_EMAIL_OTP, body);
}

export const CREATE_VENDOR = (body: any) => {
    return HttpClient.post(API_LIST.ADD_VENDOR, body);
}

export const SUBMIT_VENDOR = (body: any) => {
    return HttpClient.post(API_LIST.SUBMIT_VEDNOR, body);
}

export const GET_BANK_DETAILS = (payload: IIFSCPayload) => {
    return HttpClient.post(API_LIST.GET_BANK_DETAILS_BY_IFSC, payload);
}

export const GET_GST_CODES_LIST = (payload = {}) => {
    return HttpClient.post(API_LIST.GET_GST_CODES, payload);
}

export const GETS_STATE_LIST = (payload = {}) => {
    return HttpClient.post(API_LIST.GETS_STATE_LIST, payload);
}

export const UPLOAD_FILE = (payload: any) => {
    return HttpClient.post(API_LIST.UPLOAD_DOCUMENT, payload);
}

export const UPLOAD_KYC_DOCUMENT = (payload: any) => {
    return HttpClient.post(API_LIST.UPLOAD_KYC_DOCUMENT, payload);
}

export const GET_VEHICLE_LIST = (payload = {}) => {
    return HttpClient.post(API_LIST.GET_VEHICLE_LIST, payload);
}

export const GET_DOCUMENTS_LIST = (payload = {}) => {
    return HttpClient.post(API_LIST.GET_VENDOR_DOCUMENT, payload);
}

export const GET_FILE_CONTENT = (payload = {}) => {
    return HttpClient.post(API_LIST.FILE_PREVIEW, payload);
}

export const GET_DOWNLOAD_INVOICE = (payload = {}) => {
    return HttpClient.post(API_LIST.DOWNLOAD_INVOICE, payload);
}
export const GET_KYC_VENDOR_DOC = (payload = {}) => {
    return HttpClient.post(API_LIST.KYC_VENDOR_DOC, payload);
}

export const ValidateDelearId = (payload = {}) => {
    return HttpClient.post(API_LIST.VERIFY_DEALER_ID, payload);
}

export const GET_VALIDATION_DATA = (payload = {}) => {
    return HttpClient.post(API_LIST.GET_VALIDATION_DATA, payload);
}

export const MATCHED_OTP = (payload = {}) => {
    return HttpClient.post(API_LIST.MATCHED_OTP, payload);
}

export const SAVE_GEO_LOCATION = (payload = {}) => {
    return HttpClient.post(API_LIST.SAVE_GEO_LOCATION, payload);
}


export const VERIFY_VENDOR_GST = (payload = {}) => {
    return HttpClient.post(API_LIST.VERIFY_VENDOR_GST, payload);
}