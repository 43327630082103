import { useState, useEffect } from "react"
import { Modal, Button } from "../../../components/Elements"
import { updateMSLAcceptanceServices } from "../../../services/shared.service";
import storage from "../../../utils/storage";


export const MarutiPopUp = (props: any) => {
    const [visible, setVisible] = useState(false)
    let vendorId: any;
    vendorId = storage.getValue("vendorId")
    useEffect(() => {
        setVisible(true)
    }, [])

    const onclick = () => {
        props.sendDataToMaruti(false)
        setVisible(false)
        storage.setValue('popCount', 1)

    }
    const agree = () => {
        let payload: any = {
            "vendorId": vendorId,
            "mslTermsagreedStatus": true
        }
        updateMSLAcceptanceServices(payload)
        setVisible(false)
        storage.setValue('popCount', 1)
        props.sendDataToMaruti(false)
    }
    const disagree = () => {
        let payload: any = {
            "vendorId": vendorId,
            "mslTermsagreedStatus": false
        }
        updateMSLAcceptanceServices(payload)
        setVisible(false)
        storage.setValue('popCount', 1)
        props.sendDataToMaruti(false)
    }
    return (
        <Modal
            title={'Agree Maruti Rates'}
            style={{ top: 160 }}
            visible={visible}
            //   width={1000}
            onOk={() => setVisible(false)}
            onCancel={onclick}
            footer={[
                <Button className='ant-btn-view' key="submit" type="primary" onClick={agree} >
                    Agree
                </Button>,
                <Button className='ant-btn-prev' key="back" onClick={disagree} >
                    Remind me later
                </Button>,
            ]}
        >

            <h5>Congratulations !!! You have been selected as a valuable Business partner to serve Maruti India Customers. As discussed, the service price would be modified to 20Kms base rate for all Maruti India customers.
                Kindly join to proceed further.</h5>
        </Modal>
    )
}