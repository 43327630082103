import { Table, Spin, Card, Input, Space, Button, SearchOutlined, DatePicker, Form, Row, message, Col } from "../../../../components/Elements"
import { useContext, useEffect, useState } from "react"
import { dashboardService } from "../../../../services/dashboard.services"
import { convertJsonToCsv, downloadFile, getCase, getVendorReportData } from "../../../../services/shared.service"
import { ServicePopup } from '../../../Common/Popup/servicePopup';
import Highlighter from "react-highlight-words";
import storage from "../../../../utils/storage";
import { CardStyle, CardStyle1 } from "../../../../helpers/fromhelpercss";
import moment from "moment";
import { UserContext, UserContextType } from "../../../../providers/UserContext";
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import { ResizeObserverFn } from '../../../../utils/resizeObserverUtil';


export const DisputesComponent = () => {
  console.log("In Disputes Component",)

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [popupData, setPopupData] = useState()
  const [popUp, setPopUp] = useState(false)
  const [loader, setLoader] = useState(false)
  const [skipParams, setSkipParams] = useState(0)
  const { User, SaveUser } = useContext(UserContext) as UserContextType;

  const contentRef = useRef<HTMLDivElement>(null); // Create a ref for the content element
  // Create a new ResizeObserver
  const resizeObserver = ResizeObserverFn();
  // Start observing the target element
  useEffect(() => {
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    return () => {
      resizeObserver.disconnect(); // Clean up the observer on unmount
    };
  }, [contentRef]);
  const [form] = Form.useForm();


  let vendorId: any;
  vendorId = storage.getValue("vendorId")
  let aspCategory: any = "NormalAsp";
  let reg_type: any = storage.getValue("reg_type");
  let EAI_Employee: any = storage.getValue("EAI_Employee")
  if (reg_type == "D") {
    aspCategory = "ReverseAsp";
  } else if (EAI_Employee == true) {
    aspCategory = "EAIAsp";
  }
  const [searchText, setSearchText] = useState<any>()
  const [state, setState] = useState({ searchText: '', searchedColumn: '' })

  const onFinish = async (values: any) => {
    let payload: any = preparePayload(values);
    try {
      if (payload.fromDate && payload.toDate) {
        setLoader(true);
        let res: any = await getVendorReportData(payload)
        console.log("RESILT dispute", res)

        if (res.status === 'success' && res.message.length === 0) {
          message.success("No Data Available in selected range")
          setLoader(false);
        } else if (res.status === 'success') {

          var data: any = convertJsonToCsv(res.message)
          downloadFile("Report", new Blob([data], { type: 'text/csv;charset=utf-8;' }));
          setLoader(false);
        }
        else {
          message.error('Please try again')
          setLoader(false);
        }
        return res;
      } else {
        message.error("Please select From and To date")
      }
    } catch (e) {
      console.log(e)
    }
  }

  const antLayoutContent = {
    margin: "80px 15px 0px"
  }


  const preparePayload = (values: any) => {
    let ObjectToUpdate = {
      "VendorId": vendorId,
      "fromDate": moment(new Date(values['from'])).format('YYYY-MM-DD'),
      "toDate": moment(new Date(values['to'])).format('YYYY-MM-DD'),
      "Inflag": "N",
      "disflag": "Y",
      "userName": ""

    }
    return ObjectToUpdate

  }


  let searchInput: any;
  const getColumnSearchProps = (dataIndex: any) => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }
      : { setSelectedKeys: any, selectedKeys: any, confirm: any, clearFilters: any }) => (<div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Go
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText({})
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  };


  useEffect(() => {
    SaveUser({ ...User, token: "aaa" })

    async function fetchData() {
      setLoading(true)
      var resp = await dashboardService.disputeData(vendorId, aspCategory, skipParams)
      setData(resp.message)
      setLoading(false)

    }
    fetchData()


  }, [skipParams])

  const columns: any = [

    {
      title: 'Date',
      dataIndex: 'Date',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('Date')
    },
    {
      title: 'Case ID',
      dataIndex: 'claimId',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('claimId')
    },
    {
      title: 'Service ID',
      className: 'serviceId',
      dataIndex: 'serviceId',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('serviceId'),
      render: (text: string, params: any) => {
        async function onClick() {
          let resp: any = await getCase(params.serviceId)

          setPopupData(resp.message)
          setPopUp(true)

        }
        return <a className="fnt-weight" style={{ color: "#0c3b93", textDecoration: 'underline' }} onClick={onClick}>{text}</a>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('Amount')
    },
    {
      title: 'Service Status',
      dataIndex: 'ServiceStatus',
      align: 'center',
      width: "100px",
      ...getColumnSearchProps('ServiceStatus')
    }
  ];


  const rows: { key: number }[] = [];
  if (data && data.length > 0) {
    data.map((i: any, j: any) => {
      const obj = i
      obj.key = j + 1
      rows.push(obj)
      return rows
    })
  }
  const sendDataToParent = (index: any) => {
    console.log("INDEZ", index)
    setPopUp(index)
  }
  const Onnext = () => {
    let dataLength: any = skipParams + 100
    console.log("DATA", data)
    if (data.length <= 10) {
      alert('No records found');
    } else {
      setSkipParams(dataLength)
    }
  }
  const Onback = () => {
    if (skipParams != 0) {
      let data: any = skipParams - 100
      setSkipParams(data)
    }

  }
  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  const navigate = useNavigate();
  if (!User || !User.isLoggedIn) {
    navigate('/login');
    return null;
  } else {

    return (

      loading ?
        <div style={antLayoutContent} ref={contentRef}>

          <div className="example" style={{ textAlign: 'center' }}>
            <Card className="ant-content m-b-20">
              <Spin />
            </Card>
          </div>
        </div>
        :
        <div style={antLayoutContent}>

          <Card className="ant-content ">
            <div className="DisputesForm">
              <Form
                className="registration-details-form upload-documents-form"

                // initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}

                labelAlign={"left"}
                // labelWrap={"5px"}
                autoComplete="off"
              >
                <div className="DisputesForm" >
                  <div className="DisputeMain">
                    <div>
                      <Form.Item label="From:" name="from" className="DisputeDate">
                        <DatePicker disabledDate={disabledDate} format={'DD-MM-YYYY'} style={{ width: '100%' }} />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item label="To:" name="to">
                        <DatePicker disabledDate={disabledDate} format={'DD-MM-YYYY'} style={{ width: '100%' }} />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <Form.Item >
                      <Button htmlType="submit" type="primary" className="ant-btn-login-go">Go</Button>
                    </Form.Item >
                  </div>
                  <div>
                    <Spin spinning={loader} delay={500}>
                    </Spin>
                  </div>
                </div>
              </Form>
            </div>



            <Row>

            </Row>

            <div className='table-responsive'>
              <Table
                columns={columns}
                dataSource={rows}
                bordered
                title={() => 'Disputes Table'}
                sticky={true}
                scroll={{ x: "max-content" }}

              />
            </div>
            <Row style={{ width: "100%" }}>
              <Col span={19}></Col>
              <Col className='m-b-30 col-pad-0 align-right' span={5} style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                <Button className='PrevBtn' onClick={Onback}>Prev</Button>
                <Button className='NextBtn' onClick={Onnext}>Next</Button>
              </Col>
            </Row>
          </Card>
          <div>
            {popUp && <ServicePopup display={true} sendDataToParent={sendDataToParent} data={popupData} />}
          </div>
        </div>

    )
  }
}